import { useRef } from "react";
import * as React from "react";
import { IInputProps } from "react-ts-form";

import useResolver from "../../hooks/useResolver";
import IControlledProps from "../../model/IControlledProps";

export interface AsyncAdapter<T, U> {
    fromValue(value: T): Promise<U>;
    toValue(data: U): Promise<T>;
}

type AdapterInputProps<T, U extends IInputProps<V>, V = U['value']> = { component: React.ComponentType<U>; adapter: AsyncAdapter<T, V>; } & IControlledProps<T> & Omit<U, keyof IControlledProps<V>>;

export default function AdapterInput<T, U extends IInputProps<V>, V = U['value']>(props: AdapterInputProps<T, U, V>) {
    const { adapter, component, disabled, onChange, value, ...rest } = props;
    const resolver = useRef(() => adapter.fromValue(value));
    const { data, loading, setState } = useResolver(resolver.current);

    const handleChange = (next: V) => {
        setState(prev => ({...prev, data: next}));
        adapter.toValue(next).then(onChange);
    }

    return React.createElement(component, { 
        ...rest,
        disabled: disabled || loading,
        value: data,
        onChange: handleChange
    } as any);
}
