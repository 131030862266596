import Axios from "axios";
import { Award, AwardIssued } from "../model/Award";
import createEntityService from "./createEntityService";
import ResponseData from "../model/ResponseData";
import SearchResult from "../model/SearchResult";

const AwardService = {
    ...createEntityService<Award>({
        path: '/awards',
        allowDelete: false,
    }),

    searchIssued: async (awardId: number, params: any) => await Axios
        .get<ResponseData<SearchResult<AwardIssued>>>(`/awards/${awardId}/issued`, { params: {...params, partial:true } })
        .then(r => r.data),
}

export default AwardService;