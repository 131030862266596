import dateFormat from "date-fns/locale/ja";
import { jaJP } from "@mui/material/locale";
import dictionary from "./ja_JP.json";

import { ILocale } from "../Locale";

const ja_JP: ILocale = {
    rtl: false, // right to left
    dictionary,
    dateFormat,
    muiLocale: jaJP
};

export default ja_JP;
//japanese