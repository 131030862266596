import { Box, Chip, ChipProps } from "@mui/material";
import * as React from "react";
import { IInputProps } from "react-ts-form";

import CheckIcon from "@mui/icons-material/Check";

import { DefaultChoiceRender, IOptionsProps } from "../IOptionsProps";

interface IChipToggleListProps<T> extends IInputProps<T[]>, IOptionsProps<T> {
    size?: ChipProps['size'];
}

export default function ChipToggleList<T>(props: IChipToggleListProps<T>) {

    const choiceRenderer = props.choiceRenderer || DefaultChoiceRender;

    return (
        <Box display="flex" flexWrap="wrap" m={-0.5}>
            {
                (props.options || []).map(o => {

                    const selected = !!(props.value?.find(v => choiceRenderer.equals(v, o)));

                    return (
                        <Box key={choiceRenderer.getKey(o)} m={0.5}>
                            <Chip
                                color={selected ? 'primary' : 'default'}
                                size={props.size}
                                icon={selected ? <CheckIcon /> : undefined}
                                clickable={!props.disabled}
                                disabled={props.disabled}
                                onClick={
                                    props.disabled ? undefined : () => {
                                        if (selected) {
                                            props.onChange((props.value || []).filter(v => !choiceRenderer.equals(v, o)))
                                        } else {
                                            props.onChange([...(props.value || []), o]);
                                        }
                                    }
                                }
                                label={choiceRenderer.getLabel(o)}
                            />
                        </Box>
                    );
                })
            }
        </Box>
    );
}

ChipToggleList.ofType = function<T>() {
    return ChipToggleList as React.ComponentType<IChipToggleListProps<T>>;
}
