import { ILocale } from "../Locale";
import { enUS } from '@mui/material/locale';
import dictionary from "../dictionary";

const en_US: ILocale = {
    dictionary,
    muiLocale: enUS,
    rtl: false,
};

export default en_US;
