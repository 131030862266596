import { Input} from "react-ts-form";

import Label from "../core/Label";
import { LayoutInput } from "../form/inputs/LayoutInput";
import TextInput from "../form/inputs/TextInput";
import { Shuttle } from "../types";

export default class TemplateData {

    public id!: number;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="name" />,
            required: true
        }
    })
    public name!: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="externalId" />
        },
        inputProps: {
            maxLength: 128
        }
    })
    public externalId?: string;

    @Input({
        component: LayoutInput,
        meta: {
            title: <Label k="layout" />
        }
    })
    public config!: Shuttle.LayoutConfig;

}
