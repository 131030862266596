import { FunctionComponent } from "react";
import { IInputProps } from "react-ts-form";
import { FormControl, InputLabel, MenuItem, Select, SelectProps, SxProps } from "@mui/material";

import { DefaultChoiceRender, IOptionsProps } from "../IOptionsProps";
import useId from "../../hooks/useId";

export interface IDropDownChoiceInputProps<T> extends IInputProps<T>, IOptionsProps<T> {
    placeholder?: React.ReactNode;
    style?: React.CSSProperties;
    variant?: SelectProps['variant'];
    small?: boolean;
    sx?: SxProps;
}

export default function DropDownChoiceInput<T>({ choiceRenderer = DefaultChoiceRender, disabled, id, onChange, options = [], placeholder, required, small, style, sx, value, variant }: IDropDownChoiceInputProps<T>) {
    
    const labelId = useId();

    const select = (
        <Select 
            disabled={disabled}
            inputProps={{
                id
            }}
            fullWidth
            labelId={placeholder ? labelId : undefined}
            label={placeholder}
            onChange={e => onChange(options.find(o => choiceRenderer.getKey(o) === e.target.value) as T)}
            required={required}
            style={style}
            size={small ? 'small' : undefined}
            value={value ? choiceRenderer.getKey(value) : ''}
            variant={variant}
        >
            {
                options.map(option => {
                    const key = choiceRenderer.getKey(option);
                    return (
                        <MenuItem key={key} dense={small} value={key}>
                            {choiceRenderer.getLabel(option)}
                        </MenuItem>
                    );
                })
            }
        </Select>
    );

    if (placeholder) {
        return (
            <FormControl fullWidth size={small ? 'small' : undefined} required={required} sx={sx}>
                <InputLabel id={labelId} required={required}>{placeholder}</InputLabel>
                {select}
            </FormControl>
        );
    }
    
    return select;
}

DropDownChoiceInput.ofType = function<T>() {
    return DropDownChoiceInput as FunctionComponent<IDropDownChoiceInputProps<T>>;
}

DropDownChoiceInput.of = function<T>(settings: Omit<IDropDownChoiceInputProps<T>, keyof IInputProps<T>>) {
    return (props: IInputProps<T>) => <DropDownChoiceInput {...settings} {...props} />;
}
