import bestLocaleMatch from "../../i18n/util";
import { getAlternative, isHidden } from "../../player/utils";
import { Shuttle } from "../../types";

export interface ResolvedContent {
    contentNode: Shuttle.ContentNode;
    content: Shuttle.ContentNodeContent;
}

export function flattenDescendents(content: Shuttle.ContentNodeContent, constraints: Shuttle.Constraints, launchableOnly: boolean, list: ResolvedContent[] = []) {
    for (const child of content.children ?? []) {
        const content = getAlternative(constraints, child);
        if (launchableOnly) {
            if (content.menu) {
                flattenDescendents(content, constraints, launchableOnly, list);
            } else {
                list.push({ content, contentNode: child });
            }
        } else {
            list.push({ content, contentNode: child });
            flattenDescendents(content, constraints, launchableOnly, list);
        }
    }
    return list
}

export interface FilterContentParams {
    content: Shuttle.ContentNodeContent;
    constraints: Shuttle.Constraints;
    tracking: Shuttle.TrackingMap;
    bookmarks: Shuttle.BookmarkData[];
    locale: string;

    flatten?: boolean;
    distinctContent?: boolean; // distinct contents only (do not include twice if same content appears in two branches)
    tags?: string[];
    targets?: string[];
    requiredOnly?: boolean;
    incompleteOnly?: boolean;
    nextOnly?: boolean;
    bookmarkedOnly?: boolean;
    search?: string;
    launchers?: string[];
}

export function filterContentChildren({
    content,
    constraints,
    tracking,
    bookmarks,
    locale,
    flatten,
    distinctContent,
    tags: filterTags,
    targets,
    requiredOnly,
    incompleteOnly,
    nextOnly,
    bookmarkedOnly,
    search,
    launchers,
}: FilterContentParams) {
    const contents: ResolvedContent[] = [];
    const tags: Shuttle.ContentNodeTag[] = [];
    let requiredCount = 0;

    let children: ResolvedContent[];
    if (nextOnly) {
        children = flattenDescendents(content, constraints, true);
        const lastVisited = nextOnly ? children.reduce<null | [ResolvedContent, number, number]>((a, c, i) => {
            const status = tracking[c.content.uuid];
            if (status?.lastVisitDate && status.completion !== 'Completed' && status.completion !== 'Passed') {
                const ts = new Date(status.lastVisitDate).getTime();
                if (!a || a[1] <= ts) {
                    return [c, ts, i];
                }
            }
            return a;
        }, null) : null;
        if (lastVisited) {
            children = children.slice(lastVisited[2]);
        }
    } else {
        children = flatten ? flattenDescendents(content, constraints, false) : content.children?.map(child => ({
            content: getAlternative(constraints, child),
            contentNode: child,
        })) ?? [];
    }

    const added: number[] = [];

    for (const child of children) {

        const { content, contentNode } = child;

        if (targets?.length && (!contentNode.target || !targets.includes(contentNode.target))) {
            continue;
        }

        if (launchers?.length && !launchers.includes(content.launcher)) continue;

        if (contentNode.required) requiredCount++;

        if (isHidden(constraints, contentNode)) continue;

        content.tags.forEach(tag => !tags.find(t => t.name === tag.name) && tags.push(tag));

        if (requiredOnly && !contentNode.required) continue;

        if (bookmarkedOnly && !bookmarks.some(b => b.contentId === content.id)) continue;

        if (filterTags?.length && !filterTags.every(t => !!content.tags?.find(ct => ct.name === t))) {
            continue;
        }

        const { completion } = tracking[content.uuid] ?? {};

        if (incompleteOnly && completion !== 'Passed' && completion !== 'Completed') {
            continue;
        }

        if (search && !!(
            bestLocaleMatch(content.title, locale) 
            + ' ' + (bestLocaleMatch(content.subTitle ?? {}, locale) || '') 
            + ' ' + (bestLocaleMatch(content.description ?? {}, locale) || '')
            + ' ' + content.tags.map(t => t.name + ' ' + Object.values(t.displayName ?? {}).join(' ')).join(' ')
        ).toLowerCase().includes(search.toLowerCase())) {
            continue;
        }

        if (distinctContent) {
            if (added.includes(content.id)) {
                continue;
            }
            added.push(content.id);
        }
        
        contents.push(child);
    }

    return { contents, requiredCount, tags };
}
