import { Input } from "react-ts-form";
import TextInput from "../form/inputs/TextInput";
import { Consumer } from "./Consumer";
import { ConsumerInput, ThemeInput } from "../form/common";
import ThemeData from "./ThemeData";

export class TenantAlias {

    public id!: number;

    @Input({
        component: TextInput,
        meta: {
            title: 'Alias',
            description: 'Host/domain to map to this account.',
            required: true,
        },
        inputProps: {
            pattern: '[a-zA-Z0-9\\-\\.]+',
        }
    })
    public alias!: string;

    @Input({
        component: TextInput,
        meta: {
            title: 'Base Path',
            description: '(optional) context path to host this site under. This can only be a single path segment, and may not contain /apiv{n} or /ws.',
        },
        inputProps: {
            pattern: '(^$|^\\/[a-zA-Z0-9\\-\\_]+$)',
        },
    })
    public basePath?: string;

    @Input({
        component: TextInput,
        meta: {
            title: 'External ID',
        },
    })
    public externalId?: string;

    public _default?: boolean;

    @Input({
        component: ConsumerInput,
        meta: {
            title: 'Consumer',
            description: '(optional) specific consumer to bind alias to, for sub-portal support.'
        },
        inputProps: {
            entityRemoveToken: true,
        }
    })
    public consumer?: Consumer;

    @Input({
        component: ThemeInput,
        meta: {
            title: 'Theme',
            description: 'Override default site theme when accessing via this alias',
        },
    })
    public theme?: ThemeData;

}
