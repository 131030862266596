import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import { Input } from "react-ts-form";
import Label from "../../core/Label";
import RunningText from "../../core/ui/RunningText";
import QuestionModel from "../../model/quiz/QuestionModel";
import { IQuestionType } from "../QuestionType";
import CheckIcon from "@mui/icons-material/Check";
import CrossIcon from "@mui/icons-material/Close";
import { Shuttle } from "../../types";
import LangStringInput from "../../form/inputs/LangStringInput";

class FlashCardModel extends QuestionModel {

    @Input({
        component: LangStringInput,
        meta: {
            title: <Label k="answer" />,
            required: true
        },
        inputProps: {
            type: 'richText',
        },
    })
    public answer!: Shuttle.LangString

}

export const FlashCardQuestionType: IQuestionType<FlashCardModel, { correct: boolean; }> = {
    name: 'question.type.FLASHCARD',
    clazz: FlashCardModel,
    Component(props) {
        const [show, setShow] = useState(typeof (props.value?.correct) === 'boolean');

        if (show) {
            return (
                <>
                    <Typography variant="h5" gutterBottom>
                        <Label k="answer" />
                    </Typography>
                    <RunningText html={props.model.answer} />
                    <Box sx={{ p: 3, textAlign: 'center' }}>
                        <Typography sx={{ pb: 2 }}>
                            <Label k="flashcard.prompt" />
                        </Typography>
                        <Button 
                            color={(props.value?.correct) === true ? 'success' : 'inherit'} 
                            variant="outlined" 
                            onClick={() => props.onChange({ correct: true })} style={{marginRight: 5}}
                            startIcon={(props.value?.correct) === true && <CheckIcon />}
                            disabled={props.disabled}
                        >
                            <Label k="yes" />
                        </Button>
                        <Button 
                            color={(props.value?.correct) === false ? 'error' : 'inherit'} 
                            variant="outlined" 
                            onClick={() => props.onChange({ correct: false })}
                            startIcon={(props.value?.correct) === false && <CrossIcon />}
                            disabled={props.disabled}
                        >
                            <Label k="no" />
                        </Button>
                    </Box>
                </>
            );
        }

        return (
            <Box sx={{ p: 3, textAlign: 'center' }}>
                <Button color="primary" variant="contained" onClick={() => setShow(true)}>
                    <Label k="check_answer" />
                </Button>
            </Box>
        );
    }
}
