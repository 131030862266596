import axios, { AxiosRequestConfig } from "axios";

import FileData from "../model/FileData";

export default class UploadService {

    private static instance: UploadService;

    public static get() {
        return this.instance || (this.instance = new UploadService());
    }

    private constructor() {}

    public upload = async (file: File, onUploadProgress?: AxiosRequestConfig['onUploadProgress']) => {
        const fd = new FormData();
        fd.append('file', file);
        fd.append('name', file.name);
        fd.append('size', file.size + "");
        fd.append('type', file.type);
        return axios.post<FileData>('/upload', fd, { onUploadProgress }).then(res => res.data);
    }

    public uploadAsset = async (file: File, onUploadProgress?: AxiosRequestConfig['onUploadProgress']) => {
        const fd = new FormData();
        fd.append('file', file);
        fd.append('name', file.name);
        fd.append('size', file.size + "");
        fd.append('type', file.type);
        return axios.post<FileData>('/upload/asset', fd, { onUploadProgress }).then(res => res.data);
    }

    public directUpload = async (dir: string, file: File, onUploadProgress?: (total: number, loaded: number) => void) => {

        const params = {
            key: dir + '/' + file.name,
            contentType: file.type,
            web: true,
        };

        const { url, uploadUrl, headers } = await axios.post<{ url: string; uploadUrl: string; headers: { [name: string]: string; } }>('/upload', params).then(r => r.data);

        await new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open('PUT', uploadUrl, true);
            for (const [name, value] of Object.entries(headers)) {
                xhr.setRequestHeader(name, value);
            }

            if (onUploadProgress) {
                xhr.onprogress = e => {
                    onUploadProgress(e.total, e.loaded);
                }
            }

            xhr.onreadystatechange = function() {
                if (this.readyState === 4) {
                    if (this.status === 200) {
                        resolve(this.responseText);
                    } else {
                        reject(new Error('failed to upload file via presigned url!'));
                    }
                }
            }

            xhr.send(file);
        });

        return url;
    }
}
