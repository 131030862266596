import { Form, IFormMeta } from "react-ts-form";

import { Class } from "../util/Class";
import IControlledProps from "../model/IControlledProps";
import * as templates from "./templates";
import useId from "../hooks/useId";

export interface IDecoratorInputProps<T extends object, U extends T = T> extends IControlledProps<T> {
    clazz: Class<T>;
    subClazz?: (data: T) => undefined | Class<U>; // return undefined falls back to default
    context?: object;
    templates?: IFormMeta;
}

export default function DecoratorInput<T extends object>(props: IDecoratorInputProps<T>) {
    const idPrefix = useId();
    return (
        <Form 
            meta={props.templates || templates} 
            idPrefix={idPrefix} 
            {...props} 
            clazz={(props.subClazz && props.subClazz(props.value)) || props.clazz} 
        />
    );
}
