import dateFormat from "date-fns/locale/fr";
import { frFR } from "@mui/material/locale";
import dictionary from "./fr_FR.json";

import { ILocale } from "../Locale";

const fr_FR: ILocale = {
    rtl: false, // right to left 
    dictionary,
    dateFormat,
    muiLocale: frFR
};

export default fr_FR;
//french