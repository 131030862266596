import { IInputProps } from "react-ts-form";
import { Switch, SwitchProps } from "@mui/material";

interface ISwitchInputProps extends IInputProps<boolean> {
    color?: SwitchProps['color'];
    size?: SwitchProps['size'];
}

export default function SwitchInput({ color, disabled, id, onChange, required, size, value }: ISwitchInputProps) {
    return (
        <Switch 
            checked={Boolean(value)}
            color={color}
            disabled={disabled}
            id={id}
            onChange={e => onChange(e.target.checked)}
            required={required}
            size={size}
        />
    );
}
