import { createContext, Dispatch, SetStateAction } from "react";
import { Shuttle } from "../types";

export const AppContext = createContext<[
    Shuttle.AppContext,
    Dispatch<SetStateAction<Shuttle.AppContext>>,
]>([
    {} as Shuttle.AppContext,
   () => {},
]);
