import { Shuttle } from "../types";
import { Divider, useTheme } from "@mui/material";
import SpacerIcon from '@mui/icons-material/Height';
import NumberInput from "../form/inputs/NumberInput";
import Label from "../core/Label";
import { Input } from "react-ts-form";
import SwitchInput from "../form/inputs/SwitchInput";

class SpacerConfig {

    @Input({
        component: NumberInput,
        meta: {
            title: <Label k="size" />
        },
        inputProps: {
            min: 0,
            max: 10,
        }
    })
    public size?: number;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="divider" />
        }
    })
    public divider?: boolean;

}

const SpacerElement: Shuttle.LayoutElementType<SpacerConfig> = {
    name: 'layout_spacer',
    Icon: SpacerIcon,
    ConfigClass: SpacerConfig,
    Component({ config, direction }) {
        const theme = useTheme();

        const size = typeof config.size === 'number' ? Math.min(10, Math.max(0, config.size)) : 0;
        const padding = direction === 'row' ? theme.spacing(0, size) : theme.spacing(size, 0);

        return (
            <div style={{ padding }}>
                {
                    config.divider && (
                        <Divider orientation={direction === 'row' ? 'vertical' : 'horizontal'} />
                    )
                }
            </div>
        );
    }
};

export default SpacerElement;
