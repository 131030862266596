import { Button, Checkbox, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { createElement } from "react";
import { Input, IAddComponentProps } from "react-ts-form";

import AddIcon from "@mui/icons-material/Add";
import CorrectIcon from "@mui/icons-material/Check";
import IncorrectIcon from "@mui/icons-material/Close";

import Label from "../../core/Label";
import SwitchInput from "../../form/inputs/SwitchInput";
import QuestionModel from "../../model/quiz/QuestionModel";
import { IQuestionType } from "../QuestionType";
import StringUtil from "../../util/StringUtil";
import RunningText from "../../core/ui/RunningText";
import LangStringInput from "../../form/inputs/LangStringInput";
import { Shuttle } from "../../types";

class Option {

    public uuid!: string;

    @Input({
        component: LangStringInput,
        meta: {
            title: <Label k="text" />
        },
        inputProps: {
            type: 'richText',
        },
    })
    public text!: Shuttle.LangString

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="correct" />
        }
    })
    public correct!: boolean;

}

function AddOption(props: IAddComponentProps<Option>) {

    return (
        <Button 
            variant="contained"
            color="primary"
            onClick={() => {
                const option = new Option();
                option.uuid = StringUtil.uuid();
                props.onAdd([option]);
            }}
        >
            <AddIcon />
            <Label k="add.option" />
        </Button>
    );
}

class MRQuestionModel extends QuestionModel {

    @Input({
        clazz: Option,
        meta: {
            title: <Label k="options" />
        },
        array: {
            sort: true,
            remove: true,
            addComponent: AddOption,
        }
    })
    public options: Option[] = [];

    @Input({
        component: LangStringInput,
        meta: {
            title: <Label k="feedbackCorrect" />
        },
        inputProps: {
            type: 'richText',
        },
    })
    public feedbackCorrect?: Shuttle.LangString

    @Input({
        component: LangStringInput,
        meta: {
            title: <Label k="feedbackIncorrect" />
        },
        inputProps: {
            type: 'richText',
        },
    })
    public feedbackIncorrect?: Shuttle.LangString

}

export const MultipleResponseQuestionType: IQuestionType<MRQuestionModel, { selected: string[]; }> = {
    name: 'question.type.MULTIPLE_RESPONSE',
    clazz: MRQuestionModel,
    Component(props) {
        const total = Object.values<number>(props.stats?.data.selectedAnswers ?? {}).reduce((a, c) => a + c, 0)
        return (
            <List>
                {
                    props.model?.options?.map(o => {
                        const id = props.id ? (props.id + '_' + o.uuid) : undefined;
                        const selected = !!(props.value?.selected?.find(v => v === o.uuid));
                        return (
                            <ListItemButton
                                key={o.uuid}
                                dense
                                disabled={props.disabled}
                                selected={selected}
                                onClick={() => {
                                    if (selected) {
                                        props.onChange({
                                            ...(props.value || {}), 
                                            selected: props.value.selected.filter(v => v !== o.uuid)
                                        });
                                    } else {
                                        props.onChange({
                                            ...(props.value || {}), 
                                            selected: [...(props.value?.selected || []), o.uuid]
                                        })
                                    }
                                }}
                                style={{
                                    opacity: 1,
                                }}
                            >
                                <ListItemIcon>
                                    <Checkbox 
                                        edge="start"
                                        checked={selected}
                                        tabIndex={-1}
                                        disableRipple
                                        aria-labelledby={id} 
                                        color="secondary"
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<RunningText html={o.text} />}
                                    secondary={
                                        total > 0 && (
                                            <Label
                                                k="question_statistics_answer"
                                                args={{
                                                    percent: Math.round((props.stats?.data.selectedAnswers[o.uuid] ?? 0) / total * 100),
                                                }}
                                            />
                                        )
                                    }
                                />
                                {
                                    (props.showCorrectAnswer && props.type === 'QUIZ') && (
                                        <ListItemIcon>
                                            {createElement(o.correct ? CorrectIcon : IncorrectIcon, { color: o.correct ? undefined : 'error' })}
                                        </ListItemIcon>
                                    )
                                }
                            </ListItemButton>
                        );
                    })
                }
            </List>
        );
    }
};
