import * as React from "react";

export default function VerticalCenter(props: React.PropsWithChildren<{ className?: string; style?: React.CSSProperties; }>) {
    return (
        <div className={props.className} style={{...(props.style || {}), display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100%'}}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-around'}}>
                {props.children}
            </div>
        </div>
    );
}
