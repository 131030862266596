import { Fragment, useCallback, useState } from "react";
import { Avatar, Box, Button, Chip, Grid, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { Input } from "react-ts-form";
import { Link } from "react-router-dom";

import OpenIcon from "@mui/icons-material/OpenInNew";
import CopyIcon from "@mui/icons-material/FileCopy";
import DownloadIcon from "@mui/icons-material/CloudDownload";
import SyncIcon from "@mui/icons-material/Sync";
import TreeIcon from "@mui/icons-material/AccountTree";
import CertIcon from "@mui/icons-material/MilitaryTech";

import { anyEditor, createEntitySection, createFormAction, createWizardAction, hasRole } from "../../core/admin/AdminSection";
import ContentData from "../../model/ContentData";
import ContentSearchParams from "../../model/ContentSearchParams";
import ContentService from "../../services/ContentService";
import { DateLabel } from "../../core/DateLabel";
import LabelledIconButton from "../../core/ui/LabelledIconButton";
import ContentChildData from "../../model/ContentChildData";
import ContentChildSearchParams from "../../model/ContentChildSearchParams";
import SwitchInput from "../../form/inputs/SwitchInput";
import DecoratorFormPopover from "../../form/DecoratorFormPopover";
import NumberData, { SecondsData } from "../../model/util/NumberData";
import { ConsumerMultiInput, ContentContributorTypeInput, ContentInput, ContributorInput, QuestionsMultiInput } from "../../form/common";
import Label from "../../core/Label";
import TypeUtil from "../../util/TypeUtil";
import { createWizardStep } from "../../form/DecoratorWizard";
import EnrollmentService from "../../services/EnrollmentService";
import EnrollmentData from "../../model/EnrollmentData";
import BrowserUtil from "../../util/BrowserUtil";
import useResolver from "../../hooks/useResolver";
import StringUtil from "../../util/StringUtil";
import ChipToggleList from "../../form/inputs/ChipToggleList";
import ContentContributorData from "../../model/ContentContributorData";
import ContributorData from "../../model/ContributorData";
import ContentAlternativeData from "../../model/ContentAlternativeData";
import ModalDialog from "../../core/ui/ModalDialog";
import StringData from "../../model/util/StringData";
import ConsumerContentData from "../../model/ConsumerContentData";
import EnrollmentTreePreview from "../../core/EnrollmentTreePreview";
import ContentTreePreview from "../../core/ContentTreePreview";
import EnrollmentXApiBrowser from "../../core/EnrollmentXApiHistory";
import { launcherRegistry } from "../../registries";
import EnrollmentQuizAttempt from "../../model/quiz/EnrollmentQuizAttempt";
import Axios from "axios";
import EnrollmentSearchParams from "../../model/EnrollmentSearchParams";
import ContentQuestionData from "../../model/quiz/ContentQuestionData";
import QuestionData from "../../model/quiz/QuestionData";
import QuizQuestionService from "../../services/QuizQuestionService";
import ContentQuizModeData from "../../model/quiz/ContentQuizModeData";
import QuizModeData from "../../model/quiz/QuizModeData";
import ListSingleChoiceInput from "../../form/inputs/ListSingleChoiceInput";
import QuizModeService from "../../services/QuizModeService";
import { Consumer } from "../../model/Consumer";
import { useFeedbackContext } from "../../core/FeedbackContext";
import DateTimeInput from "../../form/inputs/DateTimeInput";
import NumberInput from "../../form/inputs/NumberInput";
import { Award, AwardIssued, getAwardValue } from "../../model/Award";
import AwardService from "../../services/AwardService";
import AwardSearchParams from "../../model/AwardSearchParams";
import AwardIssuedSearchParams from "../../model/AwardIssuedSearchParams";
import AwardIssuedService from "../../services/AwardIssuedService";

class ExistingContentChildData extends ContentChildData {

    @Input({
        component: ContentInput,
        meta: {
            title: <Label k="contents" />,
            required: true
        },
        inputProps: {
            paramsClass: ContentSearchParams,
        }
    })
    public content!: ContentData;

}

class ContentContributorAddData extends ContentContributorData {

    @Input((_, { parent }) => {
        return {
            component: ContributorInput,
            meta: {
                title: <Label k="contributor" />,
                required: true,
                disabled: !!parent.id
            }
        };
    })
    public contributor!: ContributorData;
}

class ContentAlternativeAddParams extends ContentAlternativeData {

    @Input({
        component: ContentInput,
        meta: {
            title: <Label k="alternative" />,
            required: true,
        }
    })
    public alternative!: ContentData;
}

class ConsumerContentAdd {

    @Input({
        component: ConsumerMultiInput,
        meta: {
            title: 'Consumers',
            required: true,
        }
    })
    public consumers!: Consumer[];

    @Input({
        component: DateTimeInput,
        meta: {
            title: <Label k="startDate" />
        }
    })
    public startDate?: string;

    @Input({
        component: DateTimeInput,
        meta: {
            title: <Label k="endDate" />
        }
    })
    public endDate?: string;

    @Input({
        component: NumberInput,
        meta: {
            title: <Label k="seatLimit" />
        }
    })
    public seatLimit?: number;

}

class AddQuestionsData {
    
    @Input({
        component: QuestionsMultiInput,
        meta: {
            title: <Label k="questions" />
        }
    })
    public questions!: QuestionData[];
}

class AddQuizModeData extends ContentQuizModeData {

    @Input({
        component: ListSingleChoiceInput.ofType<QuizModeData>(),
        meta: {
            title: <Label k="mode" />,
            required: true,
        },
        inputProps: {
            options: async params => QuizModeService.search(params).then(r => r.data),
            choiceRenderer: {
                getKey: o => o.id,
                getLabel: o => o?.name,
                equals: (left, right) => left?.id === right?.id,
            },
        },
    })
    public quizMode!: QuizModeData;

}

function TreePreview({ content: { id } }: { content: ContentData; }) {

    const [params] = useState<{ [name: string]: string; }>({});
    const tree = useResolver(useCallback(() => ContentService.previewTree(id, params), [id, params]));

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
                {
                    tree.data ? (
                        <ContentTreePreview content={tree.data} />
                    ) : "loading..."
                }
            </Grid>
        </Grid>
    );
}

function ContentTreePreviewButton({ content }: { content: ContentData }) {

    const [open, setOpen] = useState(false);

    return (
        <Fragment>
            <LabelledIconButton 
                k="preview_content_tree"
                icon={TreeIcon}
                onClick={() => setOpen(true)}
            />
            <ModalDialog 
                onClose={() => setOpen(false)}
                open={open}
                title={<Label k="preview_content_tree" />}
            >
                <TreePreview content={content} />
            </ModalDialog>
        </Fragment>
    );
}

function SyncIncomplete({ content }: { content: ContentData }) {

    const feedback = useFeedbackContext();

    const [busy, setBusy] = useState(false);

    return (
        <Button
            disabled={busy}
            variant="contained"
            color="primary"
            startIcon={<SyncIcon />}
            style={{marginLeft: '5px'}}
            onClick={async () => {
                setBusy(true);
                const { count } = await ContentService.syncIncompletes(content.id);
                setBusy(false);
                if (count > 0) {
                    feedback.push({
                        intent: 'success',
                        content: `Re-processing completion status for ${count} enrollment(s).`
                    });
                } else {
                    feedback.push({
                        intent: 'info',
                        content: 'No incomplete enrollments to sync.'
                    });
                }
            }}
        >
            Re-Process Incompletes
        </Button>
    );
}

function SyncRemote({ content }: { content: ContentData }) {

    const feedback = useFeedbackContext();

    const [busy, setBusy] = useState(false);

    return (
        <Button
            disabled={busy}
            variant="contained" 
            color="primary"
            onClick={
                async () => {
                    setBusy(true);
                    await ContentService.syncTracking(content);
                    setBusy(false);
                    feedback.push({
                        intent: 'success',
                        content: 'Remote sync in progress.'
                    });
                }
            }
            startIcon={<SyncIcon />}
        >
            Sync Tracking
        </Button>
    );
}

export default createEntitySection<ContentData, ContentSearchParams>({
    model: ContentData,
    params: ContentSearchParams,
    getId: o => o.id,
    label: 'name',
    defaultSort: '-createDate',
    ...ContentService,
    columns: [
        {
            key: 'name',
            sort: 'name',
            renderCell({data, params, setParams}) {
                return (
                    <div style={{ maxWidth: 300 }}>
                        <Typography gutterBottom={!!data.tags?.length}>
                            {data.name}
                        </Typography>
                        {
                            !!data.tags?.length && (
                                <ChipToggleList 
                                    options={data.tags.map(t => t.name)}
                                    onChange={tags => setParams({...params, page: 0, tags})}
                                    size="small"
                                    value={params.tags || []}
                                />
                            )
                        }
                    </div>
                );
            }
        },
        {
            key: "externalId",
            sort: "externalId",
        },
        {
            key: 'type',
            sort: 'type.name',
            renderCell({data, params, setParams}) {
                return (
                    <ChipToggleList 
                        options={[data.type.name]}
                        onChange={types => setParams({...params, page: 0, types})}
                        size="small"
                        value={params.types || []}
                    />
                );
            }
        },
        {
            key: 'createDate',
            sort: 'createDate',
            renderCell({data}) {
                return <DateLabel date={data.createDate} />
            }
        },
        {
            key: 'lastModified',
            sort: 'lastModified',
            renderCell: ({data}) => <DateLabel date={data.lastModified} />
        },
        {
            key: 'actions',
            padding: 'checkbox',
            renderCell({appContext, data}) {
                return (
                    <Box sx={{ display: 'flex' }}>
                        <ContentTreePreviewButton content={data} />
                        <LabelledIconButton 
                            k="open" 
                            icon={OpenIcon}
                            onClick={
                                () => window.open(`${(appContext.tenant.basePath || '')}/launch/${data.uuid}?mode=Browse`, '_launch')
                            }
                        />
                    </Box>
                );
            }
        }
    ],
    editEnabled: ({ appContext }) => anyEditor(appContext),
    actions: [
        createFormAction<ContentData>({
            key: 'create',
            clazz: ContentData,
            onSubmit: async content => {
                await ContentService.create(content);
                return true;
            },
            enabled: (_, appContext) => anyEditor(appContext),
        }),
    ],
    sections: [
        {
            path: '/enrollments',
            label: 'admin.section.contents.enrollments',
            section: createEntitySection({

                model: EnrollmentData,
                params: EnrollmentSearchParams,

                getId: o => o.id,
                label: o => o.user.firstName + ' ' + o.user.lastName,

                defaultSort: '-lastVisitDate',

                get: async id => EnrollmentService.get(id),
                search: async (params, context) => EnrollmentService.search({...params, contentIds: [context.id]}),
                update: async enrollment => EnrollmentService.update(enrollment),

                downloads: [
                    {
                        key: 'enrollments-csv',
                        label: 'Enrollments (CSV)',
                        download: (params, context) => EnrollmentService.downloadContentEnrollments(context.id, params),
                    },
                    {
                        key: 'quiz-csv',
                        label: 'Quiz Responses (CSV)',
                        enabled: content => Boolean(launcherRegistry.get(content.type.launcher)?.hasQuestions),
                        download: (_, context) => ContentService.downloadQuestionResponses({ contentIds: [context.id] }),
                    },
                ],

                columns: [
                    {
                        key: 'consumer',
                        sort: 'user.consumer.name',
                        renderCell: ({ data }) => (
                            <Typography component={Link} color="inherit" to={`/admin/consumers/${data.user.consumer.id}`}>
                                {data.user.consumer.name}
                            </Typography>
                        )
                    },
                    {
                        key: 'user',
                        sort: 'user.fullName',
                        renderCell: ({data}) => (
                            <Typography component={Link} color="inherit" to={`/admin/users/${data.user.id}`}>
                                {data.user.fullName || `@${data.user.id}`}
                            </Typography>
                        )
                    },
                    {
                        key: 'externalId',
                        sort: 'externalId',
                    },
                    {
                        key: 'completion',
                        sort: 'completion',
                        renderCell: ({data}) => <Label k={data.completion} />,
                    },
                    {
                        key: 'progress',
                        sort: 'progress',
                        renderCell: ({data}) => `${data.progress || 0}%`,
                    },
                    {
                        key: 'score',
                        sort: 'score',
                        renderCell: ({data}) => typeof data.score === 'number' ? data.score + '%' : "--",
                    },
                    {
                        key: 'status',
                        sort: 'status',
                        renderCell: ({data}) => (
                            <Chip
                                label={<Label k={data.status} />}
                                color={
                                    data.status === 'Deleted'
                                        ? 'error'
                                        : data.status === 'Archived'
                                            ? 'default'
                                            : 'info'
                                }
                                size="small"
                            />
                        ),
                    },
                    {
                        key: 'timeSpent',
                        sort: 'timeSpent',
                        renderCell: ({data}) => {
                            return StringUtil.formatHMS(data.timeSpent || 0);
                        }
                    },
                    {
                        key: 'uniqueTimeViewed',
                        sort: 'uniqueTimeViewed',
                        renderCell: ({ data }) => data.uniqueTimeViewed ? StringUtil.formatHMS(data.uniqueTimeViewed) : '--',
                    },
                    {
                        key: 'firstVisitDate',
                        sort: 'firstVisitDate',
                        renderCell: ({data}) => <DateLabel date={data.firstVisitDate} format="Pp" />,
                    },
                    {
                        key: 'lastVisitDate',
                        sort: 'lastVisitDate',
                        renderCell: ({data}) => <DateLabel date={data.lastVisitDate} format="Pp" />,
                    },
                    {
                        key: 'completionDate',
                        sort: 'completionDate',
                        renderCell: ({data}) => <DateLabel date={data.completionDate} format="Pp" />
                    },
                    {
                        key: 'completionTimeSpent',
                        sort: 'completionTimeSpent',
                        renderCell: ({data}) => data.completionTimeSpent ? StringUtil.formatHMS(data.completionTimeSpent) : ''
                    },
                    {
                        key: 'completedBy'
                    },
                    {
                        key: 'controls',
                        renderCell: ({data}) => (
                            <Stack direction="row" spacing={1}>
                                <EnrollmentXApiBrowser enrollment={data} />
                                <EnrollmentTreePreview.DialogButton enrollmentId={data.id} />
                            </Stack>
                        )
                    },
                ],

                moreActions: content => (
                    <>
                        {
                            content.type.launcher === 'zoom' && (
                                <Grid item xs="auto" key="sync-remote">
                                    <SyncRemote content={content} />
                                </Grid>
                            )
                        }
                        <Grid item xs="auto" key="sync-incomplete">
                            <SyncIncomplete content={content} />
                        </Grid>
                    </>
                ),

                editEnabled: ({ appContext }) => hasRole(appContext, ['ROLE_SUPPORT', 'ROLE_ADMIN']),

                sections: [
                    {
                        path: '/quiz-attempts',
                        enabled: e => !!launcherRegistry.get(e.content.type.launcher)?.hasQuestions,
                        label: 'admin.section.quiz.attempts',
                        section: createEntitySection({

                            model: EnrollmentQuizAttempt,
                            getId: o => o.id,
                            label: o => o.startTime,

                            search: (params, enrollment) => EnrollmentService.searchQuizAttempts(enrollment.id, params),
                            get: async () => ({} as any),

                            downloads: [
                                {
                                    key: 'responses-csv',
                                    label: 'Responses (CSV)',
                                    download: (_, context) => ContentService.downloadQuestionResponses({ enrollmentIds: [context.id] })
                                }
                            ],

                            columns: [
                                {
                                    key: 'startTime',
                                    sort: 'startTime',
                                    renderCell: ({data}) => data.startTime && <DateLabel date={data.startTime} format="Pp" />
                                },
                                {
                                    key: 'endTime',
                                    sort: 'endTime',
                                    renderCell: ({data}) => data.endTime && <DateLabel date={data.endTime} format="Pp" />
                                },
                                {
                                    key: 'status',
                                    sort: 'status',
                                },
                                {
                                    key: 'result',
                                    sort: 'result',
                                },
                                {
                                    key: 'score',
                                    sort: 'score',
                                    renderCell: ({data}) => !!data.maxScore && `${Math.floor(data.score / data.maxScore * 100)}% (${data.score}/${data.maxScore})`,
                                }
                            ],

                        })
                    }
                ]
            }),
        },
        {
            path: '/children',
            label: 'admin.section.contents.children',
            enabled: data => !!data.type.allowsChildren,
            section: createEntitySection({

                model: ContentChildData,
                params: ContentChildSearchParams,

                getId: o => o.content.id,
                label: o => o.content.name,

                defaultSort: 'index',

                search: ContentService.searchChildren,
                get: ContentService.getChild,
                update: ContentService.addChild,
                delete: ContentService.removeChild,

                editEnabled: ({ appContext }) => anyEditor(appContext),

                actions: [
                    createFormAction({
                        key: 'add.existing',
                        clazz: ExistingContentChildData,
                        onSubmit: async (child, context) => {
                            await ContentService.addChild(child, context);
                            return true;
                        },
                        enabled: (_, appContext) => anyEditor(appContext),
                    }),
                    createWizardAction({
                        key: 'add.new',
                        steps: TypeUtil.tuple(
                            createWizardStep({
                                clazz: ContentData,
                                title: <Label k="content.create" />,
                                isCompleted: content => !!(content?.name && content?.type)
                            }),
                            createWizardStep({
                                clazz: ContentChildData,
                                title: <Label k="content.child.settings" />,
                                isCompleted: child => !!child,
                            }),
                        ),
                        onSubmit: async ([content, child], context) => {
                            content = (await ContentService.create(content)).data;
                            await ContentService.addChild({...child, content}, context);
                            return true;
                        },
                        enabled: (_, appContext) => anyEditor(appContext),
                    }),
                ],

                columns: [
                    {
                        key: 'name',
                        sort: 'content.name',
                        renderCell: ({data}) => (
                            <Fragment>
                                <Typography component={Link} color="inherit" to={'/admin/contents/' + data.content.id}>
                                    {data.content.name}
                                </Typography>
                                <Typography display="block" variant="caption" color="textSecondary">
                                    {data.content.externalId}
                                </Typography>
                            </Fragment>
                        )
                    },
                    {
                        key: 'type',
                        sort: 'content.type.name',
                        renderCell: ({data}) => data.content.type.name,
                    },
                    {
                        key: 'required',
                        sort: 'required',
                        renderCell({appContext, context, data, reload}) {
                            return (
                                <SwitchInput
                                    value={data.required}
                                    onChange={(required) => {
                                        ContentService.addChild({...data, required}, context).then(reload)
                                    }}
                                    disabled={!anyEditor(appContext)}
                                />
                            );
                        }
                    },
                    {
                        key: 'target',
                        sort: 'target',
                        renderCell: ({appContext, context, data, reload}) => {
                            if (!anyEditor(appContext)) {
                                return data.target ?? '';
                            }
                            return (
                                <DecoratorFormPopover
                                    clazz={StringData}
                                    initialValue={{value: data.target || ""}}
                                    onSubmit={
                                        async n => {
                                            await ContentService.addChild({...data, target: n.value}, context).then(reload);
                                            return n;
                                        }
                                    }
                                >
                                    {
                                        setAnchor => (
                                            <Button
                                                size="small"
                                                onClick={e => setAnchor(e.currentTarget)}
                                                sx={{
                                                    textTransform: 'none',
                                                }}
                                            >
                                                {data.target || '--'}
                                            </Button>
                                        )
                                    }
                                </DecoratorFormPopover>
                            );
                        },
                    },
                    {
                        key: 'weight',
                        sort: 'weight',
                        renderCell({appContext, context, data, reload}) {
                            if (!anyEditor(appContext)) {
                                return data.weight ?? 0;
                            }
                            return (
                                <DecoratorFormPopover
                                    clazz={NumberData}
                                    initialValue={{value: data.weight || 0}}
                                    onSubmit={
                                        async n => {
                                            await ContentService.addChild({...data, weight: n.value}, context).then(reload);
                                            return n;
                                        }
                                    }
                                >
                                    {
                                        setAnchor => (
                                            <Button size="small" onClick={e => setAnchor(e.currentTarget)}>
                                                {data.weight || '0'}
                                            </Button>
                                        )
                                    }
                                </DecoratorFormPopover>
                            );
                        },
                    },
                    {
                        key: 'hidden',
                        sort: 'hidden',
                        renderCell({appContext, context, data, reload}) {
                            return (
                                <SwitchInput
                                    value={data.hidden}
                                    onChange={(hidden) => {
                                        ContentService.addChild({...data, hidden}, context).then(reload)
                                    }}
                                    disabled={!anyEditor(appContext)}
                                />
                            );
                        }
                    },
                    {
                        key: 'exclude_time_spent',
                        sort: 'excludeTimeSpent',
                        enabled: content => !content.type.hasOwnTracking,
                        renderCell({appContext, context, data, reload}) {
                            return (
                                <SwitchInput
                                    value={data.excludeTimeSpent}
                                    onChange={(excludeTimeSpent) => {
                                        ContentService.addChild({...data, excludeTimeSpent}, context).then(reload)
                                    }}
                                    disabled={!anyEditor(appContext)}
                                />
                            );
                        }
                    },
                    {
                        key: 'exclude_from_navigation',
                        sort: 'excludeFromNavigation',
                        renderCell({appContext, context, data, reload}) {
                            return (
                                <SwitchInput
                                    value={data.excludeFromNavigation}
                                    onChange={(excludeFromNavigation) => {
                                        ContentService.addChild({...data, excludeFromNavigation}, context).then(reload)
                                    }}
                                    disabled={!anyEditor(appContext)}
                                />
                            );
                        }
                    },
                    {
                        key: 'displayOrder',
                        sort: 'index',
                        align: 'center',
                        renderCell({appContext, context, data, reload}) {
                            if (!anyEditor(appContext)) {
                                return data.index ?? 0;
                            }
                            return (
                                <DecoratorFormPopover
                                    clazz={NumberData}
                                    initialValue={{value: data.index || 0}}
                                    onSubmit={
                                        async n => {
                                            await ContentService.addChild({...data, index: n.value}, context).then(reload);
                                            return n;
                                        }
                                    }
                                >
                                    {
                                        setAnchor => (
                                            <Button size="small" onClick={e => setAnchor(e.currentTarget)}>
                                                {data.index}
                                            </Button>
                                        )
                                    }
                                </DecoratorFormPopover>
                            );
                        }
                    },
                    {
                        key: 'sequenceOrder',
                        sort: 'sequenceIndex',
                        align: 'center',
                        enabled: content => content.sequential,
                        renderCell({appContext, context, data, reload}) {
                            if (!anyEditor(appContext)) {
                                return data.sequenceIndex ?? 0;
                            }
                            return (
                                <DecoratorFormPopover
                                    clazz={NumberData}
                                    initialValue={{value: data.sequenceIndex || 0}}
                                    onSubmit={
                                        async n => {
                                            await ContentService.addChild({...data, sequenceIndex: n.value}, context).then(reload);
                                            return n;
                                        }
                                    }
                                >
                                    {
                                        setAnchor => (
                                            <Button size="small" onClick={e => setAnchor(e.currentTarget)}>
                                                {data.sequenceIndex}
                                            </Button>
                                        )
                                    }
                                </DecoratorFormPopover>
                            );
                        }
                    },
                ]
            }),
        },
        {
            path: '/alternatives',
            label: 'admin.section.alternatives',
            section: createEntitySection({
                model: ContentAlternativeData,

                getId: o => o.alternative.id,
                label: o => o.alternative.name,

                defaultSort: 'index',

                get: ContentService.getAlternative,
                search: ContentService.searchAlternatives,
                update: ContentService.addAlternative,
                delete: ContentService.removeAlternative,

                editEnabled: ({ appContext }) => anyEditor(appContext),

                columns: [
                    {
                        key: 'name',
                        sort: 'alternative.name',
                        renderCell: ({data}) => (
                            <Typography component={Link} color="inherit" to={'/admin/contents/' + data.alternative.id}>
                                {data.alternative.name}
                            </Typography>
                        )
                    },
                    {
                        key: 'condition',
                        renderCell: ({data}) => data.condition.name,
                    },
                    {
                        key: 'order',
                        sort: 'index',
                        align: 'center',
                        renderCell({appContext, context, data, reload}) {
                            if (!anyEditor(appContext)) {
                                return data.index;
                            }
                            return (
                                <DecoratorFormPopover
                                    clazz={NumberData}
                                    initialValue={{value: data.index || 0}}
                                    onSubmit={
                                        async n => {
                                            await ContentService.addAlternative({...data, index: n.value}, context).then(reload);
                                            return n;
                                        }
                                    }
                                >
                                    {
                                        setAnchor => (
                                            <Button size="small" onClick={e => setAnchor(e.currentTarget)}>
                                                {data.index}
                                            </Button>
                                        )
                                    }
                                </DecoratorFormPopover>
                            );
                        }
                    },
                ],

                actions: [
                    createFormAction({
                        key: 'add.existing',
                        clazz: ContentAlternativeAddParams,
                        enabled: (_, appContext) => anyEditor(appContext),
                        onSubmit: async (data, context) => {
                            await ContentService.addAlternative(data, context);
                            return true;
                        }
                    }),
                    createWizardAction({
                        key: 'add.new',
                        enabled: (_, appContext) => anyEditor(appContext),
                        steps: TypeUtil.tuple(
                            createWizardStep({
                                clazz: ContentData,
                                title: <Label k="content.create" />,
                                isCompleted: content => !!(content?.name && content?.type)
                            }),
                            createWizardStep({
                                clazz: ContentAlternativeData,
                                title: <Label k="alternative" />,
                                isCompleted: child => !!child,
                            }),
                        ),
                        onSubmit: async ([alternative, settings], context) => {
                            alternative = (await ContentService.create(alternative)).data;
                            await ContentService.addAlternative({...settings, alternative}, context);
                            return true;
                        },
                    }),
                ]
            }),
        },

        {
            path: '/questions',
            label: 'admin.section.quiz.questions',
            enabled: data => !!launcherRegistry.get(data.type.launcher)?.hasQuestions,
            section: createEntitySection({
                model: ContentQuestionData,
                getId: o => o.question.id,
                label: o => o.question.name,
                search: async (params, content) => await ContentService.searchQuestions(content.id, params),
                get: async (questionId, content) => await ContentService.getQuestion(content.id, questionId),
                update: async (data, content) => {
                    data = {
                        ...data,
                        question: await QuizQuestionService.update(data.question).then(r => r.data),
                    }
                    return await ContentService.addQuestion(content.id, data.question.id, data)
                },
                delete: async ({ question }, content) => await ContentService.removeQuestion(content.id, question.id),
                defaultSort: 'displayOrder',
                editEnabled: ({ appContext }) => anyEditor(appContext),
                columns: [
                    {
                        key: 'name',
                        sort: 'question.name',
                        renderCell: ({data}) => data.question.name,
                    },
                    {
                        key: 'externalId',
                        sort: 'question.externalId',
                        renderCell: ({data}) => data.question.externalId,
                    },
                    {
                        key: 'page',
                        sort: 'pageIndex',
                        align: 'center',
                        renderCell: ({appContext, context, data, reload}) => {
                            if (!anyEditor(appContext)) {
                                return data.pageIndex;
                            }
                            return (
                                <DecoratorFormPopover
                                    clazz={NumberData}
                                    initialValue={{value: data.pageIndex || 0}}
                                    onSubmit={
                                        async n => {
                                            await ContentService.addQuestion(context.id, data.question.id, { ...data, pageIndex: n.value }).then(reload);
                                            return n;
                                        }
                                    }
                                >
                                    {
                                        setAnchor => (
                                            <Button size="small" onClick={e => setAnchor(e.currentTarget)}>
                                                {data.pageIndex}
                                            </Button>
                                        )
                                    }
                                </DecoratorFormPopover>
                            );
                        }
                    },
                    {
                        key: 'displayOrder',
                        sort: 'displayOrder',
                        align: 'center',
                        renderCell: ({appContext, context, data, reload}) => {
                            if (!anyEditor(appContext)) {
                                return data.displayOrder;
                            }
                            return (
                                <DecoratorFormPopover
                                    clazz={NumberData}
                                    initialValue={{value: data.displayOrder || 0}}
                                    onSubmit={
                                        async n => {
                                            await ContentService.addQuestion(context.id, data.question.id, { ...data, displayOrder: n.value }).then(reload);
                                            return n;
                                        }
                                    }
                                >
                                    {
                                        setAnchor => (
                                            <Button size="small" onClick={e => setAnchor(e.currentTarget)}>
                                                {data.displayOrder}
                                            </Button>
                                        )
                                    }
                                </DecoratorFormPopover>
                            )
                        },
                    },
                    {
                        key: 'displayTime',
                        sort: 'displayTime',
                        align: 'center',
                        renderCell: ({appContext,context, data, reload}) => {
                            const value = Math.max(0, data.displayTime ?? 0);
                            if (!anyEditor(appContext)) {
                                return StringUtil.formatHMS(value);
                            }
                            return (
                                <DecoratorFormPopover
                                    clazz={SecondsData}
                                    initialValue={{ value }}
                                    onSubmit={
                                        async n => {
                                            await ContentService.addQuestion(context.id, data.question.id, { ...data, displayTime: n.value }).then(reload);
                                            return n;
                                        }
                                    }
                                >
                                    {
                                        setAnchor => (
                                            <Button size="small" onClick={e => setAnchor(e.currentTarget)}>
                                                {StringUtil.formatDuration(data.displayTime ?? 0)}
                                            </Button>
                                        )
                                    }
                                </DecoratorFormPopover>
                            );
                        },
                    },
                ],
                actions: [
                    createFormAction({
                        key: 'add.new',
                        clazz: ContentQuestionData,
                        onSubmit: async (data, context) => {
                            data = {...data, question: (await QuizQuestionService.create(data.question)).data};
                            await ContentService.addQuestion(context.id, data.question.id, data);
                            return true;
                        },
                        enabled: (_, appContext) => anyEditor(appContext),
                    }),
                    createFormAction({
                        key: 'add.existing',
                        clazz: AddQuestionsData,
                        onSubmit: async (data, context) => {
                            if (data?.questions?.length) {
                                for (const question of data.questions) {
                                    await ContentService.addQuestion(context.id, question.id, { question, displayOrder: 0, pageIndex: 0 });
                                }
                            }
                            return true;
                        },
                        enabled: (_, appContext) => anyEditor(appContext),
                    }),
                ],
            }),
        },
        {
            path: '/quiz-modes',
            label: 'admin.section.quiz.modes',
            enabled: data => !!launcherRegistry.get(data.type.launcher)?.hasQuestions,
            section: createEntitySection({
                model: ContentQuizModeData,
                getId: data => data.quizMode.id,
                label: data => data.quizMode.name,
                search: async (params, content) => await ContentService.searchQuizModes(content.id, params),
                get: async (quizModeId, content) => await ContentService.getQuizMode(content.id, quizModeId),
                update: async (data, content) => await ContentService.addQuizMode(content.id, data.quizMode.id, data),
                delete: async (data, content) => await ContentService.removeQuizMode(content.id, data.quizMode.id),
                defaultSort: 'displayOrder',
                editEnabled: ({ appContext }) => anyEditor(appContext),
                columns: [
                    {
                        key: 'name',
                        sort: 'quizMode.name',
                        renderCell: ({data}) => data.quizMode.name,
                    },
                    {
                        key: 'externalId',
                        sort: 'quizMode.externalId',
                        renderCell: ({data}) => data.quizMode.externalId,
                    },
                    {
                        key: 'displayOrder',
                        sort: 'displayOrder',
                        align: 'center',
                        renderCell: ({appContext, context, data, reload}) => {
                            if (!anyEditor(appContext)) {
                                return data.displayOrder;
                            }
                            return (
                                <DecoratorFormPopover
                                    clazz={NumberData}
                                    initialValue={{value: data.displayOrder || 0}}
                                    onSubmit={
                                        async n => {
                                            await ContentService.addQuizMode(context.id, data.quizMode.id, { ...data, displayOrder: n.value }).then(reload);
                                            return n;
                                        }
                                    }
                                >
                                    {
                                        setAnchor => (
                                            <Button size="small" onClick={e => setAnchor(e.currentTarget)}>
                                                {data.displayOrder}
                                            </Button>
                                        )
                                    }
                                </DecoratorFormPopover>
                            );
                        }
                    },
                ],
                actions: [
                    createFormAction({
                        key: 'add.new',
                        clazz: QuizModeData,
                        onSubmit: async (data, context) => {
                            data = await QuizModeService.create(data).then(r => r.data);
                            await ContentService.addQuizMode(context.id, data.id, {
                                quizMode: data,
                                displayOrder: 0,
                            });
                            return true;
                        },
                        enabled: (_, appContext) => anyEditor(appContext),
                    }),
                    createFormAction({
                        key: 'add.existing',
                        clazz: AddQuizModeData,
                        onSubmit: async (data, context) => {
                            await ContentService.addQuizMode(context.id, data.quizMode.id, data);
                            return true;
                        },
                        enabled: (_, appContext) => anyEditor(appContext),
                    }),
                ],
            }),
        },
        {
            path: '/contributors',
            label: 'admin.section.contributors',
            section: createEntitySection({

                model: ContentContributorData,

                getId: o => o.contributor.id,
                label: o => o.contributor.name,

                defaultSort: 'index',

                search: ContentService.searchContributors,
                get: ContentService.getContributor,
                update: ContentService.addContributor,
                delete: ContentService.removeContributor,

                editEnabled: ({ appContext }) => anyEditor(appContext),

                columns: [
                    {
                        key: 'avatar',
                        renderCell: ({data}) => (
                            <Avatar alt={data.contributor.name} src={data.contributor.avatar?.url}>
                                {data.contributor.name.charAt(0)}
                            </Avatar>
                        ),
                        padding: 'checkbox'
                    },
                    {
                        key: 'name',
                        sort: 'contributor.name',
                        renderCell: ({data}) => data.contributor.name
                    },
                    {
                        key: 'index',
                        sort: 'index',
                        renderCell: ({appContext, context, data, reload}) => (
                            <DecoratorFormPopover
                                clazz={NumberData}
                                initialValue={{value: data.index || 0}}
                                onSubmit={
                                    async n => {
                                        await ContentService.addContributor({...data, index: n.value}, context).then(reload);
                                        return n;
                                    }
                                }
                            >
                                {
                                    setAnchor => (
                                        <Button disabled={!anyEditor(appContext)} size="small" onClick={e => setAnchor(e.currentTarget)}>
                                            {data.index}
                                        </Button>
                                    )
                                }
                            </DecoratorFormPopover>
                        )
                    },
                    {
                        key: 'type',
                        sort: 'type',
                        renderCell: ({appContext, context, data, reload}) => (
                            <ContentContributorTypeInput
                                value={data.type}
                                onChange={type => {
                                    ContentService.addContributor({...data, type}, context).then(reload);
                                }}
                                disabled={!anyEditor(appContext)}
                            />
                        )
                    },
                    {
                        key: 'principal',
                        sort: 'principal',
                        renderCell: ({appContext, context, data, reload}) => (
                            <SwitchInput
                                value={data.principal}
                                onChange={(principal) => {
                                    ContentService.addContributor({...data, principal}, context).then(reload);
                                }}
                                disabled={!anyEditor(appContext)}
                            />
                        )
                    },
                ],

                actions: [
                    createFormAction({
                        key: 'add',
                        clazz: ContentContributorAddData,
                        enabled: (_, appContext) => anyEditor(appContext),
                        onSubmit: async (data, context) => {
                            await ContentService.addContributor(data, context);
                            return true;
                        }
                    })
                ]

            })
        },
        {
            path: '/consumers',
            label: 'admin.section.consumers',
            section: createEntitySection({

                model: ConsumerContentData,
                getId: o => o.consumer.id,
                label: o => o.consumer.name,

                search: ContentService.searchConsumers,
                get: ContentService.getConsumer,
                update: ContentService.addConsumer,

                editEnabled: ({ appContext }) => anyEditor(appContext),

                columns: [
                    {
                        key: 'name', 
                        sort: 'consumer.name',
                        renderCell: ({data}) => data.consumer.name
                    },
                    {
                        key: 'createDate',
                        sort: 'createDate',
                        renderCell: ({data}) => <DateLabel date={data.createDate} />,
                    },
                    {
                        key: 'startDate',
                        sort: 'startDate',
                        renderCell: ({data}) => data.startDate ? <DateLabel date={data.startDate} /> : '--',
                    },
                    {
                        key: 'endDate',
                        sort: 'endDate',
                        renderCell: ({data}) => data.endDate ? <DateLabel date={data.endDate} /> : '--',
                    },
                    {
                        key: 'seatLimit',
                        sort: 'seatLimit',
                        renderCell: ({data}) => Boolean(data.seatLimit) ? data.seatLimit : '--',
                    },
                ],

                expand: ({ data }) => {

                    const launchUrl = `${window.location.protocol}//${window.location.host}${Axios.defaults.baseURL}/launch/${data.content.uuid}/${data.consumer.uuid}`;
                    
                    return (
                        <>
                            <TextField 
                                label={<Label k="launch.link" />} 
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <LabelledIconButton
                                                k="copy.link" 
                                                icon={CopyIcon}
                                                onClick={() => {
                                                    BrowserUtil.copyToClipboard(launchUrl);
                                                    alert('Copied to clipboard');
                                                }}
                                            />
                                        </InputAdornment>
                                    )
                                }}
                                fullWidth
                                value={launchUrl}
                                variant="outlined"
                                helperText={<Label k="launch.link.help" />}
                            />
                            <Box py={2}>
                                <Button 
                                    color="primary"
                                    onClick={() => ContentService.downloadScorm(data.content, data.consumer.uuid)}
                                    startIcon={<DownloadIcon />}
                                    sx={{ mr: 1 }}
                                    variant="contained"
                                >
                                    <Label k="download.scorm" />
                                </Button>
                                <Button
                                    color="primary"
                                    onClick={() => ContentService.downloadAicc(data.content, data.consumer.uuid)}
                                    startIcon={<DownloadIcon />}
                                    variant="contained"
                                >
                                    <Label k="download.aicc" />
                                </Button>
                            </Box>
                        </>
                    );
                },

                actions: [
                    createFormAction({
                        key: 'create',
                        clazz: ConsumerContentAdd,
                        enabled: (_, appContext) => anyEditor(appContext),
                        onSubmit: async ({ consumers, ...rest }, content) => {
                            for (const consumer of consumers) {
                                await ContentService.addConsumer({ consumer, ...rest } as any, content);
                            }
                            return true;
                        }
                    })
                ]

            })
        },
        {
            path: '/awards',
            label: 'admin.section.awards',
            section: createEntitySection({
                model: Award,
                params: AwardSearchParams,
                getId: o => o.id,
                label: o => o.type.name,

                search: ContentService.searchAwards,
                get: AwardService.get,
                update: AwardService.update,

                columns: [
                    {
                        key: 'type',
                        sort: 'type.name',
                        renderCell: ({ data }) => data.type.name,
                    },

                    {
                        key: 'consumer',
                        renderCell: ({ data }) => data.consumer?.name,
                    },
                    {
                        key: 'award.autoClaim',
                        sort: 'autoClaim',
                        renderCell: ({ data }) => data.autoClaim ? <Label k='yes' /> : <Label k = 'no' />,
                    },
                    {
                        key: 'extractor',
                        sort: 'extractor',
                        renderCell: ({ data }) => data.valueExtractor === 'None' ? '' : data.valueExtractor + getAwardValue(data),
                    },       
                ],
                actions: [
                    createFormAction({
                        key: 'create',
                        getInitialData: content => {
                            const d = new Award();
                            d.autoClaim = false;
                            d.content = content;
                            d.allowSetType = true;
                            d.allowChangeContent = false;
                            return d;
                        },
                        clazz: Award,
                        onSubmit: async award => {
                            await AwardService.create(award);
                            return true;
                        }
                    })
                ],
                sections: [
                ],

            })
        },
        {
            path: '/issued',
            label: 'admin.section.awards.issued',
            section: createEntitySection<AwardIssued, AwardIssuedSearchParams, ContentData>({
                model: AwardIssued,
                params: AwardIssuedSearchParams,
                getId: o => o.id,
                label: o => o.uuid,
                ...AwardIssuedService,
                search: ContentService.searchAwardsIssued,
                columns: [
                    {
                        key: 'id',
                        renderCell: ({ data }) => data.uuid,
                    },
                    {
                        key: 'type',
                        renderCell: ({ data }) => data.award.type.name,
                    },
                    {
                        key: 'consumer',
                        renderCell: ({ data }) => data.user.consumer.name,
                    },
                    {
                        key: 'firstName',
                        sort: 'user.firstName',
                        renderCell: ({ data }) => data.user.firstName,
                    },
                    {
                        key: 'lastName',
                        sort: 'user.lastName',
                        renderCell: ({ data }) => data.user.lastName,
                    },
                    {
                        key: 'award.issueDate',
                        sort: 'issueDate',
                        renderCell: ({ data }) => <DateLabel date={ data.issueDate } />,
                    },
                    {
                        key: 'value',
                        sort: 'value',
                        renderCell: ({ data }) => data.value,
                    },
                    {
                        key: 'award.claimDate',
                        sort: 'claimDate',
                        renderCell: ({ data }) => <DateLabel date={ data.claimDate } />,
                    },
                    {
                        key: 'actions',
                        padding: 'checkbox',
                        renderCell({data}) {
                            return data.artifactUrl?
                            (
                                <Box sx={{ display: 'flex' }}>
                                    <LabelledIconButton 
                                        k="open" 
                                        icon={CertIcon}
                                        onClick={
                                            () => window.open(data.artifactUrl, '_cert')
                                        }
                                    />
                                </Box>
                            ): null;
                        }
                    }                        
                ],
            }),
        },
    ]
    
});
