import { Suspense, useContext, useEffect, useState } from "react";
import VerticalCenter from "../util/VerticalCenter";
import { Alert, Button, CircularProgress, Container, Dialog, Typography } from "@mui/material";
import { SumSubSdk } from "../lazy";
import { AuthService } from "../services/AuthService";
import BrowserUtil from "../util/BrowserUtil";
import { WebSDKEvents } from "@sumsub/websdk/types/types";
import Refresh from "@mui/icons-material/Refresh";
import Label from "./Label";
import UserData from "../model/UserData";
import { AppContext } from "./AppContext";

interface IdentityVerificationProps {
    next?: boolean;
    onSuccess?: (user: UserData) => void;
    onFailure?: (user: UserData) => void;
}

export default function IdentityVerification({ next = false, onSuccess, onFailure }: IdentityVerificationProps) {
    const [, setAppContext] = useContext(AppContext);
    const [token, setToken] = useState('');
    const [polling, setPolling] = useState(false);
    const [showRefreshButton, setShowRefreshButton] = useState(false);
    
    useEffect(() => {
        AuthService.verifyIdentity(next).then(setToken);
    }, [next]);

    const pollProfile = async () => {
        const user = await AuthService.getProfile();
        if (user.verification !== 'PENDING') {
            setAppContext(prev => ({ ...prev, user }));
            if (user.verification === 'SUCCESS') {
                onSuccess?.(user);
            } else {
                onFailure?.(user);
            }
            return false;
        }
        return true;
    };

    const waiting = (
        <VerticalCenter>
            <CircularProgress size={60} thickness={5} />
        </VerticalCenter>
    );

    return (
        <Dialog fullScreen open>
            {
                token ? (
                    <Suspense fallback={waiting}>
                        <SumSubSdk
                            accessToken={token}
                            expirationHandler={async () => await AuthService.verifyIdentity(next)}
                            onMessage={async (type, payload) => {
                                console.log(type, payload);
                                let poll = false;
                                if (type === 'idCheck.onApplicantStatusChanged') {
                                    const { reviewStatus } = payload as WebSDKEvents['idCheck.onApplicantStatusChanged'];
                                    if (reviewStatus === 'completed') {
                                        poll = true;
                                    }
                                }
                                if (type === 'idCheck.actionCompleted') {
                                    poll = true;
                                }
                                if (poll) {
                                    setPolling(true);
                                    // poll quickly for 5 minutes
                                    let completed = await BrowserUtil.poll(pollProfile, 10_000, 30); // 5 minutes - every 10 seconds
                                    if (completed) {
                                        return;
                                    }
                                    setShowRefreshButton(true);
                                }
                            }}
                        />
                    </Suspense>
                ) : waiting
            }
            {
                polling && !showRefreshButton && (
                    <Container maxWidth="sm">
                        <Alert severity="info" icon={<CircularProgress color='inherit' size={16} />}>
                            Verifying your information...
                        </Alert>
                    </Container>
                )
            }
            {
                showRefreshButton && (
                    <Container sx={{ my: 3, textAlign: 'center' }} maxWidth="sm">
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                            It took longer than expected to verify your information.
                            Please check back later or click the refresh button below.
                        </Typography>
                        <Button
                            variant="contained"
                            startIcon={<Refresh />}
                            onClick={() => pollProfile()}
                        >
                            <Label k="refresh" />
                        </Button>
                    </Container>
                )
            }
        </Dialog>
    );
}
