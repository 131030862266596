import axios from "axios";
import ILaunchData from "../model/ILaunchData";

export default class LaunchService {

    private static instance: LaunchService;

    public static get() {
        return this.instance || (this.instance = new LaunchService());
    }

    private constructor() {}

    public launchContent = async (contentUuid: string, params?: any) => axios.get<ILaunchData>('/launch/_c/' + contentUuid, { params }).then(res => res.data);

    public launchEnrollment = async (enrollmentUuid: string) => axios.get<ILaunchData>(`/launch/_e/${enrollmentUuid}`).then(r => r.data);

}
