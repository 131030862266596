import Axios from "axios";
import { AwardType, Award, AwardIssued } from "../model/Award";
import createEntityService from "./createEntityService";
import ResponseData from "../model/ResponseData";
import SearchResult from "../model/SearchResult";
import UploadService from "./UploadService";

async function saveIcon(data: AwardType) {
    if (data.icon?.file) {
        data = { ...data, icon: await UploadService.get().upload(data.icon.file) };
    }

    return data;
}

const AwardTypeService = {
    ...createEntityService<AwardType>({
        path: '/award-types',
        allowDelete: false,
        beforeCreate: saveIcon,
        beforeUpdate: saveIcon,
    }),
    searchAwards: async (typeId: number, params: any) => await Axios
        .get<ResponseData<SearchResult<Award>>>(`/award-types/${typeId}/awards`, { params: {...params, partial:true } })
        .then(r => r.data),
    searchIssued: async (typeId: number, params: any) => await Axios
        .get<ResponseData<SearchResult<AwardIssued>>>(`/award-types/${typeId}/issued`, { params: {...params, partial:true } })
        .then(r => r.data),
    search: async (params: any) => await Axios
        .get<ResponseData<SearchResult<AwardType>>>(`/award-types`, { params: {...params, partial:true } })
        .then(r => r.data),
}

export default AwardTypeService;