
const Constants = {

    // Headers
    REFRESH_TOKEN: 'X-Shuttle-Refresh-Token',
    X_TOKEN: "X-Shuttle-Token",

    // storage keys
    KEY_SETTINGS: "shuttle:settings",

    // Learning Session Modes
    MODE_NORMAL: "Normal",
    MODE_PREVIEW: "Preview",
    MODE_REVIEW: "Review",
    MODE_EDIT: "Edit",

    // MIME types
    MIME_TEXT: "text/plain",
    MIME_HTML: "text/html",

    // query string parameter names
    PARAM_REDIRECT: "r",

    NO_OP_FUNC: () => {},

    REGEX_DIMENSION: /^\d+x\d+$/,
    REGEX_VARIABLES: /\{[a-zA-Z0-9_\-.]+(\|[^}]*)?\}/gi,
    REGEX_LOCALE: /^[a-z]{2}([_-][A-Z]{2})?$/gi,
    REGEX_PLAY_PATH: /^[0-9/]*$/,
    REGEX_IMAGE_MIME: /^image\/.+$/,
    REGEX_ESCAPE: /[.*+?^${}()|[\]\\]/g,
    REGEX_ISO_DATE: /^(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))$/,

    NEW_WINDOW_POPUP: 'simpatico-launch-popup',

}

export default Constants as Readonly<typeof Constants>;
