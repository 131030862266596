import { Input } from "react-ts-form";
import Label from "../core/Label";
import SwitchInput from "../form/inputs/SwitchInput";
import SearchParams from "./SearchParams";

export default class EnrollmentSearchParams extends SearchParams {

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="top_level_only" />
        }
    })
    public rootOnly?: boolean;


    public contentIds?: number[];

    public consumerIds?: number[];

    public userIds?: number[];

}
