import Axios from "axios";

export interface FileTextTrackData {
    id: number;
    kind: string;
    locale: string;
    content: string;
    status: 'READY' | 'PENDING' | 'FAILED';
    enabled: boolean;
}

export const FileService = {

    listTranscripts: async (fileId: number) => await Axios
        .get<FileTextTrackData[]>(`/files/${fileId}/transcripts`)
        .then(r => r.data),

    createTranscript: async (fileId: number, data: Omit<FileTextTrackData, 'id'>) => await Axios
        .post<FileTextTrackData>(`/files/${fileId}/transcripts`, data)
        .then(r => r.data),

    updateTranscript: async (fileId: number, transcriptId: number, data: Omit<FileTextTrackData, 'id'>) => await Axios
        .patch<FileTextTrackData>(`/files/${fileId}/transcripts/${transcriptId}`, data)
        .then(r => r.data),

    deleteTranscript: async (fileId: number, transcriptId: number) => await Axios
        .delete(`/files/${fileId}/transcripts/${transcriptId}`)
        .then(r => r.data),

    generateTranscript: async (fileId: number, locale: string, diarize: boolean) => await Axios
        .post(`/files/${fileId}/transcripts/generate`, { locale, diarize })
        .then(r => r.data),

    translateTranscript: async (fileId: number, transcriptId: number, targetLanguage: string) => await Axios
        .post(`/files/${fileId}/transcripts/${transcriptId}/translate`, { targetLanguage })
        .then(r => r.data),

}
