import { Input } from "react-ts-form";
import Label from "../core/Label";
import DropDownChoiceInput from "../form/inputs/DropDownChoiceInput";
import SwitchInput from "../form/inputs/SwitchInput";
import TextInput from "../form/inputs/TextInput";
import { RegistryKeyChoiceRenderer } from "../form/IOptionsProps";
import { behaviorRegistry } from "../registries";

export const behaviorTypeRenderer = new RegistryKeyChoiceRenderer(behaviorRegistry);

export default class BehaviorData {

    public id!: number;

    public uuid!: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="name" />
        }
    })
    public name!: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="externalId" />
        }
    })
    public externalId?: string;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="enabled" />
        }
    })
    public enabled?: boolean;

    @Input((_, { parent }) => ({
        component: DropDownChoiceInput.of({
            options: behaviorRegistry.keys(),
            choiceRenderer: behaviorTypeRenderer, 
        }),
        meta: {
            title: <Label k="type" />,
            required: true,
            disabled: !!parent.id
        }
    }))
    public handler!: string;

    @Input((_, { parent }) => {
        return { clazz: behaviorRegistry.get(parent.handler || '')?.ConfigClass };
    })
    public config?: any;

}
