import { useEffect, useRef } from "react";
import { Shuttle } from "../types"
import { Input } from "react-ts-form";
import SwitchInput from "../form/inputs/SwitchInput";

class CopyPreventionConfig {

    @Input({
        component: SwitchInput,
        meta: {
            title: 'Disable Text Block Selection',
            description: 'WARNING: this may have accessibility side effects.',
        }
    })
    public disableSelection?: boolean;

}

const CopyPreventionBehavior: Shuttle.BehaviorType<CopyPreventionConfig, {}> = {
    name: 'behavior_copy_prevention',
    ConfigClass: CopyPreventionConfig,
    BodyComponent: ({ config: { disableSelection } }) => {

        disableSelection = !!disableSelection;

        const styleRef = useRef<HTMLStyleElement>();

        useEffect(() => {

            const listener = (e: Event) => {
                e.preventDefault();
            };

            document.addEventListener('copy', listener);
            window.addEventListener('contextmenu', listener);

            if (disableSelection && !styleRef.current) {
                styleRef.current = document.createElement('style');
                styleRef.current.appendChild(document.createTextNode(`
                    .text-block * {
                        -webkit-user-select: none; /* Safari */
                        -moz-user-select: none; /* Firefox */
                        -ms-user-select: none; /* IE10+/Edge */
                        user-select: none; /* Standard */
                    }
                `));
                document.head.appendChild(styleRef.current);
            }

            return () => {
                document.removeEventListener('copy', listener);
                window.addEventListener('contextmenu', listener);
                if (styleRef.current) {
                    styleRef.current.remove();
                    styleRef.current = undefined;
                }
            };
        }, [disableSelection]);

        return null;
    },
};

export default CopyPreventionBehavior;
