import { Shuttle } from "../../types";
import TinCanIcon from "@mui/icons-material/DataObject";
import Embed from "../Embed";
import { Fieldsets, Input } from "react-ts-form";
import FileData from "../../model/FileData";
import FileInput from "../../form/inputs/FileInput";
import TextField from "../../form/inputs/TextInput";
import Label from "../../core/Label";

@Fieldsets({
    tincanupload: <Label k="tincanupload" />
})

class TinCanSettings {

    @Input({
        component: FileInput,
        meta: {
            title: <Label k="tincan.upload" />
        },
        inputProps: {
            accept: ".xml,.zip"
        },
        fieldset: "tincanupload"
    })
    public resource?: FileData;

    @Input((_, { parent }) => {
        if (!parent.uri) {
            return {};
        }
        
        return {
            component: TextField,
            meta: {
                title: <Label k="launch.uri" />,
                disabled: true
            },
            fieldset: "tincanupload"
        };
    })
    public uri?: string;
    
    @Input((_, { parent }) => {
        if (!parent.activityId) {
            return {};
        }

        return {
            component: TextField,
            meta: {
                title: <Label k="tincan.activity.id" />,
                disabled: true
            },
            fieldset: 'tincanupload'
        };
    })
    public activityId?: string;    
}

export const TinCanLauncher: Shuttle.Launcher = {
    name: 'launcher.tincan',
    Icon: TinCanIcon,
    ConfigClass: TinCanSettings,
    Component: Embed,
    hasXApi: true,
};
