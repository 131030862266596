import { Box, Button } from "@mui/material";
import { useState } from "react";
import { IInputProps } from "react-ts-form";
import Label from "../../core/Label";
import TextInput from "./TextInput";
import UploadIcon from "@mui/icons-material/UploadFile";
import UploadService from "../../services/UploadService";

interface AssetUrlInputProps extends IInputProps<string> {
    accept?: string;
    dir?: string;
}

export default function AssetUrlInput({ accept, dir, disabled, onChange, required, value }: AssetUrlInputProps) {
    const [file, setFile] = useState<File | null>(null);
    const [uploading, setUploading] = useState(false);

    return (
        <div>
            <TextInput 
                disabled={disabled} 
                value={value} 
                placeholderKey="url"
                onChange={onChange}
                required={required}
            />
            <Box sx={{ display: 'flex' }}>
                <input 
                    type="file" 
                    accept={accept}
                    onChange={e => setFile(e.currentTarget.files?.[0] || null)}
                />
                {
                    file && (
                        <Button 
                            disabled={uploading}
                            onClick={async () => {
                                if (file) {
                                    setUploading(true);
                                    const url = await UploadService.get().directUpload(dir || 'assets', file);
                                    setUploading(false);
                                    setFile(null);
                                    onChange(url);
                                }
                            }}
                            startIcon={<UploadIcon />}
                            size="small" 
                            variant="contained"
                        >
                            <Label k="upload_file" />
                        </Button>
                    )
                }
            </Box>
        </div>
    );
}
