
export default class ArrayUtil {

    public static first<T>(arr: T[]): T | null {
        return (Array.isArray(arr) && arr.length) ? arr[arr.length - 1] : null;
    }

    public static last<T>(arr: T[]): T | null {
        return (Array.isArray(arr) && arr.length) ? arr[arr.length - 1] : null;
    }

    public static move<T = any>(arr: T[], currentIndex: number, targetIndex: number) {
        if (targetIndex >= arr.length) {
            let k = targetIndex - arr.length + 1;
            while (k--) {
                (arr as any).push(undefined);
            }
        }
        arr.splice(targetIndex, 0, arr.splice(currentIndex, 1)[0]);
        return arr;
    }

    public static page<T = any>(arr: T[], page: number, itemsPerPage: number) {
        if (!itemsPerPage || !(arr?.length)) {
            return arr;
        }
        const start = (page || 0) * itemsPerPage;
        return arr.slice(start, start + itemsPerPage);
    }

    public static sortByProperty<T>(arr: T[], key: ((o: T) => any), desc?: boolean) {
        return arr.sort((a, b) => {
            const left = key(a);
            const right = key(b);
            let val = left < right ? -1 : left > right ? 1 : 0;
            if (desc && val !== 0) val = -val;
            return val;
        });
    }

    public static toggle<T>(arr: T[], item: T, eq: (left: T, right: T) => boolean = Object.is, enabled: boolean | undefined = undefined) {
        const i = arr.findIndex(x => eq(x, item));
        if (i > -1) {
            if (enabled === true) return arr;
            return arr.filter(x => !eq(x, item));
        }
        if (enabled === false) return arr;
        return [...arr, item];
    }

    /*
    public static omit<T extends object, U extends (keyof T)[]>(obj: T, ...keys: U) {
        if (!keys?.length) return obj;
        const o = {} as { [K in keyof T]: K extends U ? never : T[K]; };
        for (const [key, value] of Object.entries(obj) as [keyof T, any][]) {
            if (keys.includes(key as keyof T)) continue;
            o[key] = value;
        }
        return o;
    }
    */
    
}
