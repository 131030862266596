import { Shuttle } from "../types";

class ContentHasMinTimeConfig {

}

export const contentHasMinTimeCondition: Shuttle.Condition<ContentHasMinTimeConfig> = {
    name: "condition_content_min_time_spent",
    ConfigClass: ContentHasMinTimeConfig,
    evaluate: (_, { content }) => content.minTimeSpent > 0,
};
