import { Input } from "react-ts-form";
import Label from "../core/Label";
import { EnrollmentStatusInput } from "../form/common";
import DropDownChoiceInput from "../form/inputs/DropDownChoiceInput";
import SwitchInput from "../form/inputs/SwitchInput";
import TextInput from "../form/inputs/TextInput";
import { Shuttle } from "../types";
import ContentData from "./ContentData";
import UserData from "./UserData";
import NumberInput from "../form/inputs/NumberInput";

export default class EnrollmentData {

    public id!: number;

    public uuid!: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="externalId" />
        },
        inputProps: {
            maxLength: 128
        }
    })
    public externalId?: string;

    public user!: UserData;

    public content!: ContentData;

    @Input({
        component: DropDownChoiceInput.of<Shuttle.Completion>({
            options: ['NotStarted', 'Incomplete', 'Completed', 'Passed', 'Failed']
        }),
        meta: {
            title: <Label k="completion" />
        }
    })
    public completion: Shuttle.Completion = 'NotStarted';

    @Input({
        component: EnrollmentStatusInput,
        meta: {
            title: <Label k="status" />
        }
    })
    public status!: 'Active' | 'Expired' | 'Finished' | 'Deleted' | 'Archived';

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="transcript" />
        }
    })
    public transcript?: boolean;

    public createDate!: string;

    public firstVisitDate?: string;

    public lastVisitDate?: string;

    public startDate?: string;

    public endDate?: string;

    public timeSpent?: number;

    public participantId?: string;

    @Input({
        component: NumberInput,
        meta: {
            title: 'Score',
        },
        inputProps: {
            min: 0,
            max: 100,
        },
    })
    public score?: number;

    public progress?: number;

    public alternative?: boolean;

    public completionDate?: string;

    public completionTimeSpent?: number;

    public completedBy?: string;

    public uniqueTimeViewed?: number;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="enrollment_update_issue_awards" />
        }
    })
    public issueAwards?: boolean;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="enrollment_update_send_email" />
        }
    })
    public sendEmail?: boolean;

}
