import { AxiosError } from "axios";
import { Shuttle } from "../types";

export default class TypeUtil {

    public static tuple = <T extends any[]>(...values: T) => values;

    public static isServerValidationError(error: any): error is AxiosError<Shuttle.ResponseData<Shuttle.Violation[]>> {
        return (error?.response?.data?.result) === 'validation';
    }

}
