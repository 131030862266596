import { Shuttle } from "../../types";
import Icon from '@mui/icons-material/BookmarkAdd'
import { useContext } from "react";
import { ConstraintsContext, ContainerNavContext, ContentContext, SettingsContext } from "../../contexts";
import { getAlternative } from "../../player/utils";
import Label from "../../core/Label";
import { Input } from "react-ts-form";
import SwitchInput from "../../form/inputs/SwitchInput";
import { BookmarkList } from "../../launcher/BookmarkList";
import bestLocaleMatch from "../../i18n/util";

class ContentBookmarkConfig {

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="editable" />
        },
    })
    public editable?: boolean;

    @Input({
        component: SwitchInput,
        meta: {
            title: "Show All",
        },
    })
    public showAll?: boolean;

}

export const ContentBookmarkElement: Shuttle.LayoutElementType<ContentBookmarkConfig> = {
    name: 'layout_content_bookmarks',
    Icon,
    ConfigClass: ContentBookmarkConfig,
    Component: ({ config: { editable = false, showAll }, edit }) => {
        const [settings] = useContext(SettingsContext);
        const container = useContext(ContainerNavContext);
        const contentNode = useContext(ContentContext);
        const constraints = useContext(ConstraintsContext);
        const content = contentNode && getAlternative(constraints, contentNode);
        const title = bestLocaleMatch(content?.title, settings.locale);

        if (!content) return null;

        return (
            <BookmarkList
                contentId={showAll ? undefined : content.id}
                data={() => ({
                    activePath: container?.activePath ?? '',
                })}
                editable={editable && !edit}
                fileName={`${title} Notes.pdf`}
                max={1}
            />
        );
    },
}
