import { Alert } from "@mui/material";

import Layout from "../core/Layout";
import Label from "../core/Label";

export default function NotFound() {
    return (
        <Layout>
            <Alert severity="info">
                <Label k="notfound" />
            </Alert>
        </Layout>
    );
}
