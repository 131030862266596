import { ReactNode, useContext, useState } from "react";
import { BookmarksContext } from "../contexts";
import { Box, Button, Grid, Paper, TextField, Typography } from "@mui/material";
import { Shuttle } from "../types";
import Label from "../core/Label";
import AddIcon from "@mui/icons-material/Add";
import Download from "@mui/icons-material/Download";

interface BookmarkListProps {
    bookmarkTitle?: (bookmark: Shuttle.BookmarkData) => ReactNode
    contentId?: number;
    data?: (prevData: any) => any;
    editable?: boolean;
    fileName?: string;
    max?: number;
    sort?: (bookmarks: Shuttle.BookmarkData[]) => Shuttle.BookmarkData[];
}

export function BookmarkList({
    bookmarkTitle = () => <Label k="section_note" />,
    contentId,
    data,
    editable = false,
    fileName,
    max = 0,
    sort,
}: BookmarkListProps) {
    let { bookmarks, downloadPdf, remove, save } = useContext(BookmarksContext);
    const [editing, setEditing] = useState<null | Shuttle.BookmarkData>(null);
    if (contentId) {
        bookmarks = bookmarks.filter(b => b.contentId === contentId);
    }
    if (sort) {
        bookmarks = sort(bookmarks);
    }
    if (editing?.id === 0) {
        bookmarks = [
            ...bookmarks,
            editing,
        ]
    }

    return (
        <>
            {
                bookmarks.map(b => (
                    <Paper
                        key={b.id}
                        sx={{
                            p: 2,
                            my: 1,
                        }}
                        variant="outlined"
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} md>
                                <Typography variant="h6">
                                    {bookmarkTitle(b)}
                                </Typography>
                            </Grid>
                            {
                                editable && editing?.id !== b.id && (
                                    <Grid item xs={12} md="auto">
                                        <Button onClick={() => setEditing(b)}>
                                            <Label k="edit" />
                                        </Button>
                                        <Button onClick={() => remove(b.id)}>
                                            <Label k="delete" />
                                        </Button>
                                    </Grid>
                                )
                            }
                        </Grid>
                        {
                            editing?.id === b.id
                                ? (
                                    <>
                                        <TextField
                                            value={editing.notes}
                                            onChange={e => setEditing({ ...editing, notes: e.target.value })}
                                            multiline
                                            fullWidth
                                            autoFocus
                                        />
                                        <Box sx={{ mt: 1 }}>
                                            <Button 
                                                onClick={() => {
                                                    setEditing(null)
                                                    const prevData = editing.data ?? {};
                                                    save({
                                                        ...editing,
                                                        data: {
                                                            ...prevData,
                                                            ...data?.(prevData) ?? {},
                                                        },
                                                    })
                                                }}
                                            >
                                                <Label k="save" />
                                            </Button>
                                            <Button onClick={() => setEditing(null)}>
                                                <Label k="cancel" />
                                            </Button>
                                        </Box>
                                    </>
                                ) : (
                                    <Typography variant="body2">
                                        {b.notes}
                                    </Typography>
                                )
                        }
                    </Paper>
                ))
            }
            {
                editable && !!contentId && (max <= 0 || bookmarks.length < max) && (
                    <Button
                        color="primary"
                        variant="text"
                        startIcon={<AddIcon />}
                        disabled={!contentId}
                        onClick={() => setEditing({
                            id: 0,
                            contentId: contentId!,
                            notes: '',
                            data: {},
                        })}
                    >
                        <Label k="add_section_note" />
                    </Button>
                )
            }
            {
                downloadPdf && (
                    <Button
                        color="primary"
                        variant="text"
                        onClick={() => downloadPdf?.(contentId ? [contentId] : undefined, fileName)}
                        startIcon={<Download />}
                    >
                        <Label k="download" />
                    </Button>
                )
            }
        </>
    );
}
