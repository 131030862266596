import { anyEditor, createEntitySection, createFormAction } from "../../core/admin/AdminSection";
import ConditionData from "../../model/ConditionData";
import ConditionService from "../../services/ConditionService";


export default createEntitySection<ConditionData>({
    model: ConditionData,
    ...ConditionService,
    getId: c => c.id,
    label: 'name',
    columns: [
        {
            key: 'name',
            sort: 'name'
        },
        {
            key: 'externalId',
            sort: 'externalId',
        }
    ],
    editEnabled: ({ appContext }) => anyEditor(appContext),
    actions: [
        createFormAction({
            key: 'create',
            clazz: ConditionData,
            enabled: (_, appContext) => anyEditor(appContext),
            onSubmit: async data => {
                await ConditionService.create(data);
                return true;
            }
        })
    ],
})
