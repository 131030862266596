import { Suspense, useState } from "react";
import { Box, Button } from "@mui/material";
import { IInputProps } from "react-ts-form";
import { CodeEditor, RichTextEditor } from "../../lazy";
import Label from "../../core/Label";

interface RichTextInputProps extends IInputProps<string> {

}

export default function RichTextInput(props: RichTextInputProps) {
    const [editSource, setEditSource] = useState(false);

    return (
        <Suspense fallback={null}>
            <Box>
                <Button size="small" onClick={() => setEditSource(!editSource)}>
                    <Label k={editSource ? 'edit_rich_text' : 'edit_raw'} />
                </Button>
            </Box>
            {
                editSource ? (
                    <CodeEditor 
                        value={props.value}
                        onChange={props.onChange}
                        language="html"
                    />
                ) : (
                    <RichTextEditor 
                        value={props.value}
                        onChange={props.onChange}
                        disabled={props.disabled}
                        id={props.id}
                    />
                )
            }
        </Suspense>
    );
}
