import { useContext, useState } from "react";
import { TreeItem, TreeView } from "@mui/lab";
import { Shuttle } from "../types";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import EnrollmentData from "../model/EnrollmentData";
import { Box, Stack, Typography } from "@mui/material";
import StringUtil from "../util/StringUtil";
import { SettingsContext } from "../contexts";
import bestLocaleMatch from "../i18n/util";

interface ContentTreePreviewProps {
    content: Shuttle.ContentNode;
    enrollments?: EnrollmentData[];
}

export default function ContentTreePreview({ content, enrollments }: ContentTreePreviewProps) {

    const [expanded, setExpanded] = useState([] as string[]);
    const [settings] = useContext(SettingsContext)

    const renderNode = (node: Shuttle.ContentNode) => {

        const e = enrollments?.find(x => x.content.id === node.content.id);
        return (
            <TreeItem
                key={node.id}
                nodeId={node.id + ''}
                label={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ flexGrow: 1 }}>
                            <Typography>
                                {bestLocaleMatch(node.content.title, node.content.locales?.[0] ?? settings.locale)}
                                {node.required && (<Box component="span" sx={{ color: theme => theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.main }}>*</Box>)}
                            </Typography>
                        </div>
                        {
                            enrollments && (
                                <Stack direction="row" spacing={.5}>
                                    {
                                        e && (
                                            <span>
                                                {'(' + StringUtil.formatHMS(e.timeSpent || 0) + ')'}
                                            </span>
                                        )
                                    }
                                    <span>
                                        {
                                            e ? ((e.progress || 0) < 100 ? `${e.progress}%` : e.completion) : '--'
                                        }
                                    </span>
                                </Stack>
                            )
                        }
                    </div>
                }
                sx={{ color: enrollments ? (
                        e ? (
                            e.completion === 'Failed' ? 'error.main' 
                                : (e.completion === 'Completed' || e.completion === 'Passed')
                                    ? 'success.main'
                                    : undefined
                            ) : 'text.disabled'
                    ) : undefined
                }}
            >
                {node.content.children?.map(renderNode)}
            </TreeItem>
        );
    }

    return (
        <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            expanded={expanded}
            onNodeToggle={(_, ids) => setExpanded(ids)}
        >
            {renderNode(content)}
        </TreeView>
    );
}
