import { Input } from "react-ts-form";

import { ContentTypeNameMultiInput, TagNameMultiInput } from "../form/common";
import SearchParams from "./SearchParams";
import Label from "../core/Label";

export default class ContentSearchParams extends SearchParams {

    @Input({
        component: TagNameMultiInput,
        meta: {
            title: <Label k="tags" />
        }
    })
    public tags?: string[];

    @Input({
        component: ContentTypeNameMultiInput,
        meta: {
            title: <Label k="type" />
        }
    })
    public types?: string[];

}
