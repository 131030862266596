import { Alert } from "@mui/material";
import { Suspense, useCallback } from "react";
import { RouteComponentProps } from "react-router-dom";

import useResolver from "../../hooks/useResolver";
import { Player } from "../../lazy";
import LaunchService from "../../services/LaunchService";
import UrlUtil from "../../util/UrlUtil";

/**
 * creates a learning session for a logged in user and plays the content
 */
export function LaunchView({ match: { params: { uuid } }, location: { search } }: RouteComponentProps<{ uuid: string; }>) {

    const { data, error, loading } = useResolver(useCallback(async () => {
        return LaunchService.get().launchContent(uuid, UrlUtil.parseQueryString(search));
    }, [uuid, search]));

    if ((error as any)?.response.data) {
        return (
            <Alert severity="warning">
                {(error as any)?.response.data}
            </Alert>
        );
    }

    if (loading || !data) return null;

    return (
        <Suspense fallback={null}>
            <Player sessionId={data.sessionId} internalCloseButton />
        </Suspense>
    );
}

export function EnrollmentLaunchView({ match: { params: { enrollmentId } } }: RouteComponentProps<{ enrollmentId: string }>) {

    const { data, error, loading } = useResolver(useCallback(async () => await LaunchService.get().launchEnrollment(enrollmentId), [enrollmentId]));

    if ((error as any)?.response.data) {
        return (
            <Alert severity="warning">
                {(error as any)?.response.data}
            </Alert>
        );
    }

    if (loading || !data) return null;

    return (
        <Suspense fallback={null}>
            <Player sessionId={data.sessionId} internalCloseButton />
        </Suspense>
    );
}
