import dateFormat from "date-fns/locale/tr";
import { trTR } from "@mui/material/locale";
import dictionary from "./tr_TR.json";

import { ILocale } from "../Locale";

const tr_TR: ILocale = {
    rtl: false, // right to left
    dictionary,
    dateFormat,
    muiLocale: trTR
};

export default tr_TR;
//turkish