import { Fragment, useCallback, useContext, useReducer, createContext, Dispatch, useMemo, useState, ReactNode } from "react";
import { Alert, Typography, Button, Grid, Box, Pagination, Divider, useMediaQuery, useTheme, Collapse } from "@mui/material";
import { Input } from "react-ts-form";
import ChildrenListIcon from "@mui/icons-material/List";
import FiltersIcon from '@mui/icons-material/FilterList';
import Label from "../../core/Label";
import SwitchInput from "../../form/inputs/SwitchInput";
import TextInput from "../../form/inputs/TextInput";
import DropDownChoiceInput from "../../form/inputs/DropDownChoiceInput";
import { DefaultChoiceRender } from "../../form/IOptionsProps";
import { Shuttle } from "../../types";
import { LayoutRegion, setElementData } from "../../core/ui/Layout";
import NumberInput from "../../form/inputs/NumberInput";
import { TagNameMultiInput } from "../../form/common";
import RadioChoiceInput from "../../form/inputs/RadioChoiceInput";
import CheckBoxInput from "../../form/inputs/CheckBoxInput";
import { BookmarksContext, ConstraintsContext, ContentContext, SessionContext, SettingsContext } from "../../contexts";
import ArrayUtil from "../../util/ArrayUtil";
import { getAlternative } from "../../player/utils";
import bestLocaleMatch from "../../i18n/util";
import { filterContentChildren } from "./utils";

interface IChildrenListContext {
    params: Shuttle.MenuSearchParams;
    paramsDispatch: Dispatch<ParamsAction>;
    filtersEditable?: boolean;
    loading?: boolean;
}

export const ChildrenListContext = createContext<null | IChildrenListContext>(null);

class ContentChildrenListElementConfig {

    // default params

    @Input({
        component: NumberInput,
        meta: {
            title: <Label k="items_per_page" />
        },
        inputProps: {
            min: 0,
        }
    })
    public itemsPerPage: number = 10;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="targets" />
        },
        array: {
            addComponent: ({ onAdd }) => (
                <Button onClick={() => onAdd([""])}>
                    <Label k="add" />
                </Button>
            ),
            sort: true,
            remove: true,
        }
    })
    public targets?: string[];

    @Input({
        component: TagNameMultiInput,
        meta: {
            title: <Label k="tags" />
        }
    })
    public tags?: string[];

    @Input({
        component: SwitchInput,
        meta: {
            title: 'Required Items Only'
        }
    })
    public requiredOnly?: boolean;

    @Input({
        component: SwitchInput,
        meta: {
            title: 'Incomplete Items Only'
        }
    })
    public incompleteOnly?: boolean;

    @Input({
        component: SwitchInput,
        meta: {
            title: 'Next Only',
        },
    })
    public nextOnly?: boolean;

    @Input({
        component: SwitchInput,
        meta: {
            title: "Bookmarked Only",
        },
    })
    public bookmarkedOnly?: boolean;

    @Input({
        component: DropDownChoiceInput,
        meta: {
            title: <Label k="sort.by" />
        },
        inputProps: {
            options: ['index', 'title']
        }
    })
    public sortBy?: string;

    @Input({
        component: SwitchInput,
        meta: {
            title: "Flatten",
        },
    })
    public flatten?: boolean;

    // controls

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="show_paging" />
        }
    })
    public showPaging?: boolean;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="show_sort" />
        }
    })
    public showSort?: boolean;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="show_filters" />
        }
    })
    public showFilters?: boolean;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="multi_select_tags" />
        }
    })
    public multiSelectTags?: boolean;

    // layout

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="show_loading_state" />
        }
    })
    public showLoadingState?: boolean;

    @Input({
        component: NumberInput,
        meta: {
            title: <Label k="itemsPerRow" />
        },
        inputProps: {
            min: 1,
            max: 4
        }
    })
    public itemsPerRow: number = 1;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k='fill.space' />
        }
    })
    public fillSpace?: boolean;

    @Input({
        component: NumberInput,
        meta: {
            title: <Label k="spacing" />
        },
        inputProps: {
            min: 0,
            max: 10,
        }
    })
    public spacing: number = 2;

    @Input({
        component: NumberInput,
        meta: {
            title: <Label k="item_spacing" />
        },
        inputProps: {
            min: 0,
            max: 10,
        }
    })
    public itemSpacing: number = 0;

    public elements?: string[];

}

type ParamsAction =
    | { type: 'RESET'; }
    | { type: 'TOGGLE_TAG'; tag: string; }
    | { type: 'SET_PAGE'; page: number; }
    | { type: 'SET_ITEMS_PER_PAGE'; itemsPerPage: number; }
    | { type: 'SET_REQUIRED_ONLY'; requiredOnly: boolean; }
    | { type: 'SET_SEARCH'; search: string; }
    | { type: 'SET_SORT'; sortBy: string; };

function paramsInit(config: ContentChildrenListElementConfig) {
    const params: Shuttle.MenuSearchParams = {};

    params.page = 0;
    params.itemsPerPage = typeof config.itemsPerPage === 'number' ? Math.max(0, Math.min(100, config.itemsPerPage)) : 10;
    if (config.tags?.length) params.tags = config.tags;
    if (config.requiredOnly) params.requiredOnly = true;
    if (config.incompleteOnly) params.incompleteOnly = true;
    if (config.nextOnly) params.nextOnly = true;
    if (config.bookmarkedOnly) params.bookmarkedOnly = true;
    if (config.sortBy) params.sortBy = [config.sortBy];
    if (config.flatten) params.flatten = true;

    return params;
}

interface ContentChildrenListProps {
    content: Shuttle.ContentNodeContent;
    contentNode: Shuttle.ContentNode;
    config: ContentChildrenListElementConfig;
    constraints: Shuttle.Constraints;
    elementKey: string;
}

function ContentChildrenList({ config, content, contentNode, constraints, }: ContentChildrenListProps) {
    const { session } = useContext(SessionContext);
    
    const reducer = useCallback((state: Shuttle.MenuSearchParams, action: ParamsAction) => {
        switch (action.type) {
            case "RESET":
                return paramsInit(config);
            case "TOGGLE_TAG":
                return {
                    ...state,
                    page: 0,
                    tags: state.tags?.includes(action.tag) 
                        ? state.tags.filter(t => t !== action.tag ) 
                        : (
                            config.multiSelectTags
                                ? [...state.tags || [], action.tag]
                                : [action.tag]
                        )
                };
            case "SET_PAGE":
                return {
                    ...state, 
                    page: Math.max(0, action.page)
                };
            case "SET_ITEMS_PER_PAGE":
                return {
                    ...state,
                    page: 0,
                    itemsPerPage: Math.min(100, Math.max(0, action.itemsPerPage))
                };
            case "SET_SORT":
                return {
                    ...state,
                    page: 0,
                    sortBy: [action.sortBy]
                };
            case "SET_SEARCH":
                return {
                    ...state,
                    page: 0,
                    search: action.search
                };
            case "SET_REQUIRED_ONLY":
                return {
                    ...state,
                    page: 0,
                    requiredOnly: action.requiredOnly
                }
            default:
                return state;
        }
    }, [config]);

    const theme = useTheme();
    const [{ locale }] = useContext(SettingsContext)
    const desktop = useMediaQuery(theme.breakpoints.up('md'));
    const [filtersOpen, setFiltersOpen] = useState(false);
    const { bookmarks } = useContext(BookmarksContext)
    const [params, paramsDispatch] = useReducer(reducer, config, paramsInit);

    const { count, end, list, requiredCount, page, start, tags, total } = useMemo(() => {

        let { contents: list, tags, requiredCount } = filterContentChildren({
            ...params,
            targets: config.targets,
            content,
            constraints,
            tracking: session?.tracking ?? {},
            bookmarks,
            locale,
        })

        const total = list.length;

        if (params.sortBy) {
            for (let sort of params.sortBy) {
                let desc = false;
                if (sort.charAt(0) === '-') {
                    desc = true;
                    sort = sort.substring(1);
                }
                if (sort === 'title') {
                    ArrayUtil.sortByProperty(list, ({ content }) => bestLocaleMatch(content.title, locale) ?? '', desc);
                }
                if (sort === 'index' && desc) {
                    list.reverse();
                }
            }
        }

        let page = Math.max(0, params.page || 0);
        let itemsPerPage = Math.max(0, params.itemsPerPage || 0);
        if (itemsPerPage === 0) { // show all
            page = 0;
            itemsPerPage = total;
        }
        const count = itemsPerPage > 0 ? Math.ceil(total / itemsPerPage) : 0
        const start = page * itemsPerPage;
        const end = Math.min(total, start + itemsPerPage);
        list = page <= count ? list.slice(start, end) : [];

        return { list, total, tags, requiredCount, page, count, start, end };
    }, [content, config, constraints, params, locale, bookmarks, session]);

    const listContext = useMemo(() => ({ params, paramsDispatch, filtersEditable: config.showFilters }), [config, params, paramsDispatch]);

    const gridItemSize = config.itemsPerRow === 4 ? 3 : config.itemsPerRow === 3 ? 4 : config.itemsPerRow === 2 ? 6 : 12;
    const gridSpacing = typeof config.spacing === 'number' ? Math.min(10, Math.max(0, config.spacing || 0)) : 2;
    
    const refine = config.showFilters && (
        <Fragment>
            <Box mb={2}>
                <TextInput
                    value={params.search || ""} 
                    onChange={search => paramsDispatch({ type: 'SET_SEARCH', search })}
                    placeholderKey="search"
                    variant="outlined"
                />
            </Box>
            {
                Boolean(tags?.length) && (
                    <Fragment>
                        <Divider />
                        <Box my={2}>
                            {
                                config.multiSelectTags ? (
                                    tags.map(tag => (
                                        <Box key={tag.name} mx={1.5}>
                                            <CheckBoxInput
                                                value={!!params.tags?.includes(tag.name)}
                                                onChange={() => paramsDispatch({ type: 'TOGGLE_TAG', tag: tag.name })}
                                            >
                                                {bestLocaleMatch({ en: tag.name, ...tag.displayName ?? {} }, locale)}
                                            </CheckBoxInput>
                                        </Box>
                                    ))
                                ) : (
                                    <RadioChoiceInput 
                                        value={tags.find(t => t.name === params.tags?.[0])}
                                        options={tags}
                                        onChange={tag => tag && paramsDispatch({ type: 'TOGGLE_TAG', tag: tag.name })}
                                        choiceRenderer={{
                                            getKey: o => o?.name ?? '',
                                            getLabel: o => bestLocaleMatch({ en: o?.name, ...o?.displayName ?? {} }, locale),
                                            equals: (x, y) => x?.name === y?.name,
                                        }}
                                    />
                                )
                            }
                        </Box>
                    </Fragment>
                )
            }
            {
                requiredCount > 0 && (
                    <Fragment>
                        <Divider />
                        <Box my={2} mx={1.5}>
                            <CheckBoxInput 
                                value={!!params.requiredOnly} 
                                onChange={requiredOnly => paramsDispatch({ type: 'SET_REQUIRED_ONLY', requiredOnly })}
                            >
                                <Label k="required" />
                            </CheckBoxInput>
                        </Box>
                    </Fragment>
                )
            }
        </Fragment>
    );

    return (
        <ChildrenListContext.Provider value={listContext}>
            <Grid container spacing={2}>
                {
                    refine && (
                        <Grid item xs={12} md={3}>
                            {
                                desktop ? refine : (
                                    <Fragment>
                                        <Box pb={2}>
                                            <Button startIcon={<FiltersIcon />} onClick={() => setFiltersOpen(!filtersOpen)}>
                                                <Label k="toggle.filters" />
                                            </Button>
                                        </Box>
                                        <Collapse in={filtersOpen}>
                                            {refine}
                                        </Collapse>
                                    </Fragment>
                                )
                            }
                        </Grid>
                    )
                }
                <Grid item xs={12} md={config.showFilters ? 9 : 12}>
                    {
                        Boolean(config.showPaging || config.showSort) && (
                            <Grid container spacing={gridSpacing} sx={{ alignItems: 'center', pb: 2 }}>
                                <Grid item xs={12} md>
                                    {
                                        Boolean(config.showPaging) && (
                                            <Typography color="textSecondary">
                                                {
                                                    total > list.length ? (
                                                        <Label 
                                                            k="paging.info" 
                                                            args={{ start: start + 1, end, total }}
                                                        />
                                                    ) : (
                                                        <Label k="paging.count" args={{ total }} />
                                                    )
                                                }
                                            </Typography>
                                        )
                                    }
                                </Grid>
                                {
                                    config.showSort && (
                                        <Grid item xs={12} md={3}>
                                            <DropDownChoiceInput
                                                choiceRenderer={{...DefaultChoiceRender, getLabel:(key) => <Label k={key} /> }}
                                                onChange={sortBy => {
                                                    if (sortBy) {
                                                        paramsDispatch({ type: 'SET_SORT', sortBy });
                                                    }
                                                }}
                                                placeholder={<Label k="sort.by" />}
                                                value={params.sortBy?.[0]}
                                                options={['title', 'index']}
                                                small
                                            />
                                        </Grid>
                                    )
                                }
                            </Grid>
                        )
                    }
                    <Grid container spacing={gridSpacing}>
                        {
                            list.length ? list.map(child => (
                                <Grid item xs={12} md={gridItemSize} key={child.content.id}>
                                    <ContentContext.Provider value={child.contentNode}>
                                        <LayoutRegion 
                                            keys={config.elements || []} 
                                            fillSpace={config.fillSpace}
                                            spacing={config.itemSpacing}
                                        />
                                    </ContentContext.Provider>
                                </Grid>
                            )) : Boolean(content.children.length > 0 && config.showLoadingState) && (
                                <Grid item xs={12}>
                                    <Alert severity="info" sx={{ my: 2 }}>
                                        <Label k="no_results" />
                                    </Alert>
                                </Grid>
                            )
                        }
                    </Grid>
                    {
                        Boolean(config.showPaging) && count > 1 && (
                            <Pagination 
                                count={count}
                                page={page + 1}
                                onChange={(_, page) => paramsDispatch({ type: 'SET_PAGE', page: (page - 1) })}
                                sx={{ pt: 2 }}
                            />
                        )
                    }
                </Grid>
            </Grid>
        </ChildrenListContext.Provider>
    );
}

export const ContentChildrenListElement: Shuttle.LayoutElementType<ContentChildrenListElementConfig> = {
    name: "layout.content.children.list",
    Icon: ChildrenListIcon,
    ConfigClass: ContentChildrenListElementConfig,
    Component({ config, edit, elementKey }) {
        const contentNode = useContext(ContentContext);
        const constraints = useContext(ConstraintsContext);

        if (edit) {
            const gridItemSize = config.itemsPerRow === 4 ? 3 : config.itemsPerRow === 3 ? 4 : config.itemsPerRow === 2 ? 6 : 12;

            const items: ReactNode[] = [];

            let i = 0;
            while (i < (typeof config.itemsPerPage === 'number' ? Math.max(1, config.itemsPerPage) : 1)) {
                items.push(
                    <Grid key={i++} item xs={12} md={gridItemSize}>
                        <LayoutRegion 
                            keys={config.elements || []} 
                            fillSpace={config.fillSpace}
                            spacing={config.itemSpacing}
                        />
                    </Grid>
                );
            }

            return (
                <Grid container spacing={config.spacing}>
                    {items}
                </Grid>
            );
        }

        if (!contentNode) return null;

        const content = getAlternative(constraints, contentNode);

        if (!content?.children.length) {
            return null;
        }

        return (
            <ContentChildrenList
                config={config}
                content={content}
                contentNode={contentNode}
                constraints={constraints}
                elementKey={elementKey}
            />
        );
    },
    getRegions(config, key) {
        return [
            {
                title: "Grid Item",
                key: 'item',
                keys: config.elements || [],
                setKeys: (layout, elements) => setElementData(layout, key, {...config, elements}),
            }
        ];
    },
};
