import { Input } from "react-ts-form";

import Label from "../../core/Label";
import NumberInput from "../../form/inputs/NumberInput";
import QuestionData from "./QuestionData";
import DurationInput from "../../form/inputs/DurationInput";

export default class ContentQuestionData {

    @Input({ clazz: QuestionData, })
    public question!: QuestionData;

    @Input({
        component: NumberInput,
        meta: {
            title: <Label k="displayOrder" />
        }
    })
    public displayOrder!: number;

    @Input({
        component: NumberInput,
        meta: {
            title: <Label k="page" />
        }
    })
    public pageIndex!: number;

    @Input({
        component: DurationInput,
        meta: {
            title: <Label k="displayTime" />
        },
    })
    public displayTime?: number;

}
