
import { Input } from "react-ts-form";
import DropDownChoiceInput from "../form/inputs/DropDownChoiceInput";
import TextInput from "../form/inputs/TextInput";
import FileInput from "../form/inputs/FileInput";
import SwitchInput from "../form/inputs/SwitchInput";
import DateTimeInput from "../form/inputs/DateTimeInput";
import NumberInput from "../form/inputs/NumberInput";
import Label from "../core/Label";
import LangStringInput from "../form/inputs/LangStringInput";
import { Shuttle } from "../types";
import FileData from "./FileData";
import ContentData from "../model/ContentData";
import { Consumer } from "../model/Consumer";
import { ContentInput, ConsumerInput, AwardTypeChoiceInput } from "../form/common";
import Rule from "./Rule";
import CodeInput from "../form/inputs/CodeInput";
import EnrollmentData from "./EnrollmentData";
import UserData from "./UserData";
import StringUtil from "../util/StringUtil";

export function getAwardValue(award:Award):string {
    if (award.type.type !== 'Credit') return '';

    if (award.valueExtractor === 'Value') {
        return  ' (' + award.value + ')';
    }

    if (award.valueExtractor === 'Duration') {
        return  ' (' + StringUtil.formatHMS(award.content.duration!) + ')';        
    }

    return '';
}

const AwardTypeInput = DropDownChoiceInput.of<'Credit' | 'Certificate' | 'Badge'>({
    options: ['Credit', 'Certificate', 'Badge']
});

const AwardValueExtratorInput = DropDownChoiceInput.of<'Value' | 'UniqueTimeViewed' | 'TimeSpent' | 'Score' | 'Duration'>({
    options: ['Value', 'UniqueTimeViewed', 'TimeSpent', 'Score', 'Duration']
});

const AwardValidityStartTypeInput = DropDownChoiceInput.of<'Issue' | 'Claim'>({
    options: ['Issue', 'Claim']
});

export class AwardConfig {
    @Input( {
        clazz: Rule,
        meta: {
            title: <Label k='rule' />,
        },
    })    
    public rule?: Rule;

    @Input((_, { root }) => {
        if (root.type === 'Certificate' || root.type?.type === 'Certificate') {
            return {
                component: CodeInput,
                meta: {
                    title: <Label k ='template' />,
                },
            }
        }
        return {};
    })    
    public template?: string;

    @Input({
        component: SwitchInput,
        meta: {
            title: 'Determine Value On Claim',
            description: 'Only used for awards that require manual claim.'
        },
    })
    public determineValueOnClaim?: boolean;
}

export class AwardTypeConfig extends AwardConfig  {
}

export class AwardType {
    public id!: number;

    public uuid!: string;

    @Input((_, { parent }) => ({
        component: AwardTypeInput,
        meta: {
            title: <Label k="type" />,
            required: true,
            disabled: !!parent.id
        }
    }))
    public type!: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="name" />,
            required: true
        },
    })
    public name!: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="externalId" />

        },
        inputProps: {
            maxLength: 128
        },
    })
    public externalId?: string;

    @Input({
        component: LangStringInput,
        meta: {
            title: <Label k="displayName" />
        },
    })
    public displayName!: Shuttle.LangString;

    @Input({
        component: LangStringInput,
        meta: {
            title: <Label k="description" />
        },
        inputProps: {
            type: 'richText' as const,
        },
    })
    public description?: Shuttle.LangString;

    @Input({
        component: FileInput,
        meta: {
            title: <Label k="icon" />
        },
        inputProps: {
            accept: 'image/*',
        },
    })
    public icon?: FileData;
    
    @Input((_, { parent }) => {
        if (parent.type !== 'Credit') {
            return {};
        }
        
        return {
            component: AwardValueExtratorInput,
            meta: {
                title: <Label k="extractor" />,
                required: true
            }
        };
    })
    public valueExtractor!: string;

    @Input({
        clazz: AwardTypeConfig
    })
    public config?: AwardTypeConfig;
    
}

export class Award {
    public id!: number;

    public uuid!: string;

    allowSetType:boolean = false;
    allowChangeContent:boolean = true;

    @Input((_, { parent }) =>{

        return {
            component: AwardTypeChoiceInput,
            meta: {
                title: <Label k="type" />,
                required: true,
                disabled: !!parent.id || !parent.allowSetType,
            },
        }            
    })
    public type!: AwardType;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="award.autoClaim" />,
        },
    })
    public autoClaim!: boolean;

    @Input({
        component: DateTimeInput,
        meta: {
            title: <Label k='startDate' />
        },
        inputProps: { time: true },
    })
    public startDate?: string;

    @Input({
        component: DateTimeInput,
        meta: {
            title: <Label k='endDate' />
        },
        inputProps: { time: true },
    })
    public endDate?: string;

    @Input({
        component: DateTimeInput,
        meta: {
            title: <Label k='award.expirationDate' />
        },
        inputProps: { time: true },
    })
    public expirationDate?: string;

    @Input({
        component: NumberInput,
        meta: {
            title: <Label k="award.validityLength" />
        },
        inputProps: {
            float: false,
            min: 0,
        },
    })
    public validityLength?: number;


    @Input({
        component: AwardValidityStartTypeInput,
        meta: {
            title: <Label k="award.validityStartType" />,
            required: true
        },
    })
    public validityStartType: string = 'Claim';

    @Input({
        component: NumberInput,
        meta: {
            title: <Label k="value" />
        },
        inputProps: {
            float: true,
            min: 0,
        },
    })
    public value?: number;

    @Input((_, { parent }) =>{
        if (parent.autoClaim) {
            return {};
        }

        return {
            component: DateTimeInput,
            meta: {
                title: <Label k='award.claimExpirationDate' />
            },
            inputProps: { time: true }
        }
    })
    public claimExpirationDate?: string;

    @Input((_, { parent }) => {
        if (parent.autoClaim) {
            return {};
        }
    
        return {
            component: NumberInput,
            meta: {
                title: <Label k="award.claimValidityLength" />
            },
            inputProps: {
                float: false,
                min: 0,
            },
        }
    })
    public claimValidityLength?: number;

    @Input((_, { parent }) =>{
        return {
            component: ContentInput,
            meta: {
                title: <Label k="content" />,
                required: true,
                disabled: !parent.allowChangeContent,
            },
        }
    })
    public content!: ContentData;
    

    @Input({
        component: ConsumerInput,
        meta: {
            title: <Label k="consumer" />,
        },
    })
    public consumer?: Consumer;

    @Input((_, { parent }) => {
        if (!parent.type || parent.type.type !== 'Credit') {
            return {};
        }
        
        return {
            component: AwardValueExtratorInput,
            meta: {
                title: <Label k="extractor" />,
                required: true
            }
        };
    })
    public valueExtractor!: string;
    
    @Input({
        clazz: AwardConfig
    })
    public config?: AwardConfig;
}

export class AwardIssued {
    public id!: number;
    public uuid!: string;

    public award!:Award;
    public user!:UserData;
    public enrollment?:EnrollmentData;

    public issueDate!:string;
    public claimDate?:string;
    public value?:number;
    public artifact?:FileData;
    public artifactUrl?:string;
}

