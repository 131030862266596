import { Input } from "react-ts-form";
import { Grid, GridSpacing, GridSize, Button } from "@mui/material";

import GridIcon from "@mui/icons-material/ViewCompact";

import Label from "../core/Label";
import DropDownChoiceInput from "../form/inputs/DropDownChoiceInput";
import NumberInput from "../form/inputs/NumberInput";
import { GridSizeInput } from "../form/common";
import { Shuttle } from "../types";
import { LayoutRegion, setElementData } from "../core/ui/Layout";
import StringUtil from "../util/StringUtil";
import { CSSProperties, ReactNode, useContext, useMemo } from "react";
import SwitchInput from "../form/inputs/SwitchInput";
import { ConditionBuilderInput } from "../form/inputs/ConditionBuilderInput";
import { ConstraintsContext, ContentContext, SessionContext } from "../contexts";
import evaluateCondition from "../conditions/evaluateCondition";
import { getAlternative } from "../player/utils";

class GridColumn {

    public key!: string;

    public elements?: string[];

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k='fill.space' />
        }
    })
    public fillSpace?: boolean;

    @Input({
        component: NumberInput,
        meta: {
            title: <Label k="spacing" />
        },
        inputProps: {
            min: 0,
            max: 10,
        }
    })
    public spacing?: number;
    
    @Input({
        component: GridSizeInput,
        meta: {
            title: <Label k="breakpoint.xs" />
        }
    })
    public xs?: GridSize | 'hidden';

    @Input({
        component: GridSizeInput,
        meta: {
            title: <Label k="breakpoint.sm" />
        }
    })
    public sm?: GridSize | 'hidden';

    @Input({
        component: GridSizeInput,
        meta: {
            title: <Label k="breakpoint.md" />
        }
    })
    public md?: GridSize | 'hidden';

    @Input({
        component: GridSizeInput,
        meta: {
            title: <Label k="breakpoint.lg" />
        }
    })
    public lg?: GridSize | 'hidden';

    @Input({
        component: ConditionBuilderInput,
        meta: {
            title: <Label k="visible_condition" />
        },
        inputProps: {
            nullable: true,
        }
    })
    public visibleCondition?: Shuttle.ConditionNode;

}

class GridElementConfig {

    @Input({
        component: DropDownChoiceInput,
        meta: {
            title: <Label k="spacing" />
        },
        inputProps: {
            options: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
        }
    })
    public spacing: GridSpacing = 2;

    @Input({
        component: DropDownChoiceInput.of<CSSProperties['alignItems']>({
            options: ['stretch', 'center', 'flex-start', 'flex-end']
        }),
        meta: {
            title: <Label k="alignment" />
        }
    })
    public alignItems?: CSSProperties['alignItems'];

    @Input({
        clazz: GridColumn,
        array: {
            addComponent: ({onAdd}) => (
                <Button onClick={() => onAdd([{key: StringUtil.uuid()}])}>
                    <Label k="add" />
                </Button>
            ),
            sort: true,
            remove: true,
        }
    })
    public columns: GridColumn[] = [];

}

function GridComponent({ config, edit }: Shuttle.LayoutElementProps<GridElementConfig>) {

    const contentNode = useContext(ContentContext);
    const constraints = useContext(ConstraintsContext);
    const { session } = useContext(SessionContext);

    const columns = useMemo(() => (config.columns || []).reduce((list, { key, elements, spacing, fillSpace, xs, sm, md, lg, visibleCondition }, index) => {

        if (edit || !visibleCondition || (contentNode && constraints && session && evaluateCondition(visibleCondition, {
            contentNode,
            content: getAlternative(constraints, contentNode),
            root: session.root,
            tracking: session.tracking,
            state: session.state,
        }))) {
            list.push(
                <Grid 
                    key={key || index}
                    item 
                    xs={xs === 'hidden' ? false : xs}
                    sm={sm === 'hidden' ? false : sm}
                    md={md === 'hidden' ? false : md}
                    lg={lg === 'hidden' ? false : lg}
                    sx={{
                        display: ([['xs', xs], ['sm', sm], ['md', md], ['lg', lg]] as const).reduce((a, [k, v]) => {
                            a[k] = v === 'hidden' ? 'none' : (v ?? 'block');
                            return a;
                        }, {} as any)
                    }}
                >
                    <LayoutRegion 
                        keys={elements || []} 
                        spacing={Math.max(0, Math.min(10, spacing || 0))}
                        fillSpace={fillSpace}
                    />
                </Grid>
            );
        }

        return list;
    }, [] as ReactNode[]), [edit, config, contentNode, constraints, session]);

    return (
        <Grid container spacing={config.spacing} alignItems={config.alignItems}>
            {columns}
        </Grid>
    );
}

export const GridElement: Shuttle.LayoutElementType<GridElementConfig> = {
    name: "layout.grid",
    Icon: GridIcon,
    ConfigClass: GridElementConfig,
    Component: GridComponent,
    getRegions(config, elementKey) {
        return config.columns?.map(({ elements, key, }, index, arr) => ({
            title: 'column ' + (index + 1),
            key,
            keys: elements || [],
            setKeys: (layout, elements) => setElementData(layout, elementKey, {
                ...config,
                columns: arr.map((c, i) => i === index ? { ...c, elements } : c),
            })
        })) || [];
    }
};
