import { Box, Popover, Typography } from "@mui/material";
import { MouseEvent, PropsWithChildren, useContext, useState } from "react";
import { GlossaryContext } from "../../contexts";
import RunningText from "./RunningText";

export default function GlossaryTermText({ children }: PropsWithChildren<{}>) {

    const { terms } = useContext(GlossaryContext);

    const [anchor, setAnchor] = useState<HTMLElement | null>(null);
    
    const handleClick = (e: MouseEvent<HTMLDivElement>) => {
        if ((e.target as any).hasAttribute('data-term')) {
            setAnchor(e.target as HTMLElement);
        }
    }

    const anchorTerm = anchor && (anchor.getAttribute('data-term') || anchor.innerText);
    const term = anchorTerm ? terms.find(t => t.name.toLowerCase() === anchorTerm.toLowerCase()) : undefined;
    
    return (
        <div onClick={handleClick}>
            {children}
            <Popover anchorEl={anchor} anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }} open={Boolean(term)} onClose={() => setAnchor(null)}>
                {
                    term && (
                        <Box sx={{ p: 1, maxWidth: '300px' }}>
                            <Typography variant="subtitle1">
                                {term.name}    
                            </Typography>
                            <RunningText html={term.definition} />
                        </Box>
                    )
                }
            </Popover>
        </div>
    );
}
