import { useCallback, useState } from "react";
import * as React from "react";
import { IInputProps, IInputTemplateProps } from "react-ts-form";
import { Alert, Button, Box, Checkbox, List, ListItem, ListItemIcon, ListItemText, Chip, ListItemSecondaryAction, Grid, Divider, Radio, ListItemButton } from "@mui/material";

import ClearIcon from "@mui/icons-material/Clear";

import { DefaultChoiceRender, ILazyOptionsProps } from "../IOptionsProps";
import Label from "../../core/Label";
import SearchParams from "../../model/SearchParams";
import { Class } from "../../util/Class";
import ModalDialog from "../../core/ui/ModalDialog";
import useResolver from "../../hooks/useResolver";
import ArrayItemToolbar from "../../core/ui/ArrayItemToolbar";
import DecoratorInput from "../DecoratorInput";
import * as templates from "../templates";
import LabelledIconButton from "../../core/ui/LabelledIconButton";
import Paging from "../../core/ui/Paging";

interface IListMultipleChoiceInputProps<T, U extends SearchParams = SearchParams> extends IInputProps<T[]>, ILazyOptionsProps<T, U> {
    paramsClass?: Class<U>; // generate search filter form
    params?: Partial<U>;
    entityRemoveToken?: boolean;
    singleChoice?: boolean;
}

function InputTemplate(props: IInputTemplateProps) {
    return (
        <Grid item md="auto">
            <templates.InputTemplate {...props} />
        </Grid>
    );
}

const inlineTemplates = {...templates, InputTemplate};

export default function ListMultipleChoiceInput<T, U extends SearchParams = SearchParams>(props: IListMultipleChoiceInputProps<T, U>) {
    const { entityRemoveToken, options, optionEnabled } = props;
    const [value, setValue] = useState<T[] | null>(null);
    const [sort, setSort] = useState(false);
    const initialParams = () => ({
        ...(props.paramsClass ? new props.paramsClass() : new SearchParams()),
        ...props.params,
    }) as U;
    const [params, setParams] = useState<U>(initialParams);
    const open = !!value;
    const { data, error, loading } = useResolver(useCallback(async () => {
        return (open && typeof options === 'function') ? await options(params) : { items: [], paging: { total: 0 } };
    }, [open, params, options]));
    
    const itemsPerPage = Math.max(1, params.itemsPerPage || 10);
    const total = (typeof options === 'function' ? data?.paging.total : options?.length) || 0;
    const list = (typeof options === 'function' ? data?.items : options) || [];
    const choiceRenderer = props.choiceRenderer || DefaultChoiceRender;

    return (
        <Box>
            <Button onClick={() => setValue(props.value || [])} disabled={props.disabled}>
                <Label k="select.placeholder" />
            </Button>
            {
                props.value && (
                    <Box display="flex" flexWrap="wrap" mx={-0.5}>
                        {
                            props.value.map(val => (
                                <Box key={choiceRenderer.getKey(val)} mx={0.5}>
                                    {
                                        entityRemoveToken && (val as any)?.id === 0 ? (
                                            <Chip disabled label={<Label k="removed" />} />
                                        ) : (
                                            <Chip 
                                                label={choiceRenderer.getLabel(val)}
                                                onDelete={() => {
                                                    if (entityRemoveToken && props.singleChoice) {
                                                        props.onChange([{ id: 0 } as any]);
                                                    } else {
                                                        props.onChange(props.value.filter(v => !choiceRenderer.equals(val, v)));
                                                    }
                                                }}
                                                disabled={props.disabled}
                                            />
                                        )
                                    }
                                </Box>
                            ))
                        }
                    </Box>
                )
            }
            <ModalDialog
                actions={
                    <React.Fragment>
                        {
                            (sort || (value && value.length > 1)) && (
                                <Button onClick={() => setSort(!sort)}>
                                    {sort ? <Label k="browse" /> : <Label k="sort" />}
                                </Button>
                            )
                        }
                        <Button onClick={() => setValue(null)}>
                            <Label k="cancel" />
                        </Button>
                        <Button 
                            color="primary" 
                            onClick={() => {
                                setValue(null);
                                props.onChange(value || []);
                            }}
                        >
                            <Label k="submit" />
                        </Button>
                    </React.Fragment>
                }
                dividers
                maxWidth={props.paramsClass ? "md" : "sm"}
                noPadding
                onClose={() => setValue(null)}
                open={open}
                scroll="body"
                title={props.title}
            >
                {
                    sort ? (
                        <List dense>
                            {
                                value?.length ? (
                                    value.map((v, index, arr) => {
    
                                        const key = choiceRenderer.getKey(v);
    
                                        return (
                                            <ListItem key={key}>
                                                <ListItemText primary={choiceRenderer.getLabel(v)} secondary={choiceRenderer.getSubtitle?.(v)} />
                                                <ListItemSecondaryAction>
                                                    <ArrayItemToolbar value={arr} onChange={setValue} index={index} />
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        );
                                    })
                                ) : (
                                    <ListItem disabled>
                                        <Label k="results.empty" />
                                    </ListItem>
                                )
                            }
                        </List>
                    ) : (
                        <React.Fragment>
                            {
                                typeof options === 'function' && (
                                    <Box p={2}>
                                        <Grid container spacing={1} alignItems="center">
                                            <DecoratorInput
                                                clazz={(props.paramsClass || SearchParams) as Class<U>}
                                                value={params}
                                                onChange={next => setParams({...next, page: 0})}
                                                templates={inlineTemplates}
                                            />
                                            <Grid>
                                                <LabelledIconButton
                                                    icon={ClearIcon}
                                                    k="clear"
                                                    onClick={() => setParams(initialParams)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                )
                            }
                            {
                                (error?.message) && (
                                    <Alert severity="error">
                                        {error.message}
                                    </Alert>
                                )
                            }
                            <Divider />
                            <List>
                                {
                                    (!list.length && !loading) ? (
                                        <ListItem disabled>
                                            <Label k="results.empty" />
                                        </ListItem>
                                    ) : list.map(o => {

                                        const selected = !!(value?.find(v => choiceRenderer.equals(v, o)));
                                        const key = choiceRenderer.getKey(o);
                                        const id = props.id + '_' + key;
            
                                        return (
                                            <ListItemButton
                                                key={key}
                                                onClick={() => {
                                                    if (props.singleChoice) {
                                                        setValue(selected ? [] : [o]);
                                                    } else if (selected) {
                                                        setValue((value || []).filter(v => !choiceRenderer.equals(v, o)));
                                                    } else {
                                                        setValue([...(value || []), o]);
                                                    }
                                                }}
                                                dense
                                                disabled={!!optionEnabled?.(o)}
                                            >
                                                <ListItemIcon>
                                                    {
                                                        React.createElement(props.singleChoice ? Radio : Checkbox, {
                                                            edge: 'start',
                                                            checked: selected,
                                                            tabIndex: -1,
                                                            disableRipple: true,
                                                            'aria-labelledby': id,
                                                            color: 'secondary'
                                                        })
                                                    }
                                                </ListItemIcon>
                                                <ListItemText 
                                                    id={id}
                                                    primary={choiceRenderer.getLabel(o)}
                                                    secondary={choiceRenderer.getSubtitle?.(o)}
                                                />
                                            </ListItemButton>
                                        );
                                    })
                                }
                            </List>
                            {
                                (Math.ceil(total / itemsPerPage) > 1) && (
                                    <React.Fragment>
                                        <Divider />
                                        <Box p={2}>
                                            <Paging<U> value={params} onChange={setParams} total={total} />
                                        </Box>
                                    </React.Fragment>
                                )
                            }
                        </React.Fragment>
                    )
                }
            </ModalDialog>
        </Box>
    );
}

ListMultipleChoiceInput.ofType = function<T, U extends SearchParams = SearchParams>() {
    return ListMultipleChoiceInput as React.ComponentType<IListMultipleChoiceInputProps<T, U>>;
}

ListMultipleChoiceInput.forOptions = function<T, U extends SearchParams = SearchParams>(
    options: IListMultipleChoiceInputProps<T, U>['options'],
    choiceRenderer?: IListMultipleChoiceInputProps<T, U>['choiceRenderer']
) {
    return (props: Omit<IListMultipleChoiceInputProps<T, U>, "options" | "choiceRenderer">) => (
        <ListMultipleChoiceInput options={options} choiceRenderer={choiceRenderer} {...props} />
    );
}

ListMultipleChoiceInput.of = function<T, U extends SearchParams = SearchParams>(settings: Omit<IListMultipleChoiceInputProps<T, U>, keyof IInputProps<T>>) {
    return (props: IInputProps<T[]> & Partial<Omit<IListMultipleChoiceInputProps<T, U>, keyof IInputProps<T>>>) => (
        <ListMultipleChoiceInput {...settings} {...props} />
    );
};
