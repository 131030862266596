import ThemeData from "../model/ThemeData";
import createEntityService from "./createEntityService";
import UploadService from "./UploadService";

async function saveFiles(data: ThemeData) {
    if (data.favicon?.file) {
        data = {...data, favicon: await UploadService.get().upload(data.favicon.file)};
    }
    if (data.logo?.file) {
        data = {...data, logo: await UploadService.get().upload(data.logo.file)};
    }
    if (data.logoInverse?.file) {
        data = {...data, logoInverse: await UploadService.get().upload(data.logoInverse.file)};
    }
    if (data.pictureDefault?.file) {
        data = {...data, pictureDefault: await UploadService.get().upload(data.pictureDefault.file)};
    }
    if (data.posterDefault?.file) {
        data = {...data, posterDefault: await UploadService.get().upload(data.posterDefault.file)};
    }
    return data;
}

export default createEntityService<ThemeData>({ 
    path: '/themes',
    beforeCreate: saveFiles,
    beforeUpdate: saveFiles,
});
