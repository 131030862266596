import { Input } from "react-ts-form";
import Label from "../core/Label";
import { ConditionBuilderInput } from "../form/inputs/ConditionBuilderInput";
import TextInput from "../form/inputs/TextInput";

export default class ConditionData {

    public id!: number;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="name" />,
            required: true
        }
    })
    public name!: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="externalId" />
        },
        inputProps: {
            maxLength: 128
        }
    })
    public externalId?: string;

    @Input({
        component: ConditionBuilderInput,
    })
    public config!: any;

}
