
/**
 * add all static label definitions here.
 * set "rich" property to true to enable support for non-plain text formats.
 * set "type" property if the default value for the label is not plain text.
 */
const dictionary = {

    // languages
    "de": "Deutsch",
    "en": "English",
    "es": "Español",
    "fr": "Française",
    "it": "Italiana",
    "ja": "日本語",
    "ko": "한국인",
    "pl": "Polski",
    "pt": "Português",
    "ru": "Русский",
    "th": "แบบไทย",
    "tr": "Türkçe",
    "zh": "中文",

    // Pages
    "my_courses": "My Courses",

    "awards": "Awards",
    "id": "ID",
    "uuid": "UUID",
    "alias": "Alias",
    "are_you_there": "Are You There?",
    "Active": "Active",
    "alt": "Alt Text",
    "api_url": "API URL",
    "action": "Action",
    "Archived": "Archived",
    "Completed": "Completed",
    "Deleted": "Deleted",
    "Expired": "Expired",
    "FAILED": "Failed",
    "Failed": "Failed",
    "Finished": "Finished",
    "Incomplete": "Incomplete",
    "NotNull.message": "Required",
    "NotStarted": "Not Started",
    "PASSED": "Passed",
    "Passed": "Passed",
    "QUIZ": "Quiz",
    "SURVEY": "Survey",
    "Unique.message": "Must be unique",
    "_default": "Default",
    "acknowledge": "Acknowledge",
    "actions": "Actions",
    "activity_publishing": "Activity Publishing",
    "add": "Add",
    "added": "Added",
    "add.condition": "Add Condition",
    "add.column": "Add Column",
    "add.existing": "Add Existing",
    "add.extractor": "Add Extractor",
    "add_section_note": "Add Note",
    "add.new": "Add New",
    "add.option": "Add Option",
    "add.property": "Add Property",
    "admin": "Admin",
    "admin.customize": "Customize",
    "admin.dashboards": "Dashboards",
    "admin.manage": "Manage",
    "admin.section.aliases": "Aliases",
    "admin.section.alternatives": "Alternatives",
    "admin.section.analytics": "Analytics",
    "admin.section.announcements": "Announcements",
    "admin.section.api.keys": "API Keys",
    "admin.section.auth.providers": "Authentication",
    "admin.section.behaviors": "Behaviors",
    "admin.section.collectors": "Collectors",
    "admin.section.conditions": "Conditions",
    "admin.section.consumers": "Consumers",
    "admin.section.content.providers": "Content Providers",
    "admin.section.contents": "Contents",
    "admin.section.contents.caption": "Search, create, and edit contents",
    "admin.section.contents.children": "Contents",
    "admin.section.contents.children.caption": "Associate contents that can be used in various way depending on content display settings",
    "admin.section.contents.enrollments": "Enrollments",
    "admin.section.contents.enrollments.caption": "Enroll users into this content",
    "admin.section.contributors": "Contributors",
    "admin.section.custom.pages": "Custom Pages",
    "admin.section.dashboard": "Dashboard",
    "admin.section.glossaries": "Glossaries",
    "admin.section.glossary.terms": "Terms",
    "admin.section.groups": "Groups",
    "admin.section.identity.verification": "Identity Verification",
    "admin.section.localization": "Localization",
    "admin.section.localization.caption": "Override site-level display text",
    "admin.section.messages": "Messages",
    "admin.section.messages.caption": "Create messages that will display to client admins",
    "admin.section.variables": "Variables",
    "admin.section.quiz.modes": "Quiz Modes",
    "admin.section.quiz.questions": "Questions",
    "admin.section.quiz.attempts": "Quiz Attempts",
    "admin.section.quizzes": "Quizzes",
    "admin.section.runtime": "Runtime",
    "admin.section.systems": "Systems",
    "admin.section.systems.caption": "Configure integrations with external systems",
    "admin.section.tags": "Tags",
    "admin.section.tags.caption": "Use tags to organize and filter content.",
    "admin.section.tasks": "Tasks",
    "admin.section.templates": "Templates",
    "admin.section.tenant.settings": "Settings",
    "admin.section.tenants": "Tenants",
    "admin.section.tenants.caption": "Tenants Setup",
    "admin.section.themes": "Themes",
    "admin.section.themes.caption": "Create color schemes for contents",
    "admin.section.users": "Users",
    "admin.section.users.caption": "Search and manage user accounts",
    "admin.section.webhooks": "Webhooks",
    "admin.section.webhooks.history": "History",
    "admin.section.award.types": "Award Types",
    "admin.section.awards": "Awards",
    "admin.section.awards.issued": "Awards Issued",
    "agenda": "Agenda",
    "agenda.past.emtpy": "There are no past events available.",
    "agenda.upcoming.empty": "There are no upcoming events available.",
    "aiccsettings": "AICC Settings",
    "all": "All",
    "allowPrevious": "Allow Previous",
    "allowSkip": "Allow Skip",
    "allow_review_attempts": "Allow Review Attempts",
    "allow_question_review": "Allow Question Review",
    "alignment": "Alignment",
    "alternative": "Alternative",
    "alternatives": "Alternatives",
    "alternative_of": "Alternative of: {title}",
    "answer": "Answer",
    "appearance": "Appearance",
    "ar_SA": "Arabic",
    "are_you_still_watching": "Are you still watching?",
    "authentication": "Authentication",
    "auth_code": "Access Code",
    "auth_code_instructions": "A 6-digit verification code was sent to your email. Enter it here to continue.",
    "auth_code_invalid_user": "No corresponding account found.",
    "auth_code_invalid_code": "Access code invalid.",
    "auto": "Auto",
    "auto_scroll": "Auto Scroll",
    "autoStart": "Auto Start First Attempt",
    "avatar": "Avatar",
    "available_when_launched": "Available When Launched",
    "not_available": "Not Available",
    "back": "Back",
    "backgroundDark": "Background (Dark)",
    "backgroundLight": "Background (Light)",
    "background_image": "Background Image",
    "background_image_dark": "Background Image (Dark Mode)",
    "basePath": "Base Path",
    "behavior_max_time": "Max Time",
    "behavior_visibility_timeout": "Visibility Timeout",
    "behavior_copy_prevention": "Copy Prevention",
    "behaviors": "Behaviors",
    "bio": "Bio",
    "bookmarks": "Bookmarks",
    "breakpoint.lg": "Large Screens",
    "breakpoint.md": "Medium Screens and Up",
    "breakpoint.sm": "Small Screens and Up",
    "breakpoint.xs": "Extra Small Screens and Up",
    "browse": "Browse",
    "button": "Button",
    "cancel": "Cancel",
    "callback_url": "Callback URL",
    "capitalize": "Capitalize",
    "cas_login_url": "Login URL",
    "cas_service_validate_url": "Service Validate URL",
    "cas_id_attr": "ID Attribute",
    "cas_first_name_attr": "First Name Attribute",
    "cas_last_name_attr": "Last Name Attribute",
    "cas_email_attr": "Email Attribute",
    "caseSensitive": "Case Sensitive",
    "check_answer": "Check Answer",
    "chapters": "Chapters",
    "choose": "Choose",
    "choose_mode": "Choose Mode",
    "choosemeeting": "Choose Meeting",
    "clear": "Clear",
    "clear_cache": "Clear Cache",
    "client.id": "Client ID",
    "client.secret": "Client Secret",
    "close": "Close",
    "closed_message": "Closed Message",
    "collector": "Collector",
    "color": "Color",
    "color_dark": "Color (Dark)",
    "columns": "Columns",
    "completions": "Completions",
    "completeOnLaunch": "Complete on Launch",
    "completedMessage": "Completed Message",
    "completion": "Completion",
    "completionTimeSpent": "Time to Complete",
    "completionDate": "Completion Date",
    "condition.wrapper": "Wrapper",
    "condition.boolean": "Boolean",
    "condition.number": "Number",
    "condition.string": "String",
    "condition_content_completed": "Content Completed",
    "condition_content_has_children": "Content Has Children",
    "condition_content_menu": "Content Has Menu Enabled",
    "condition_content_required": "Content Required",
    "condition_content_min_time_spent": "Content Has a Min Time Spent Requirement",
    "condition_content_min_time_met": "Content's Min Time Requirement Has Been Met",
    "configure_study_pace": "Configure Study Pace",
    "confirm": "Confirm",
    "confirm_password": "Confirm Password",
    "consumer": "Consumer",
    "consumer.origin": "Origin",
    "consumer.origin.add": "Add Origin",
    "consumer.origin.regex": "Regex",
    "consumer.origins": "Origins",
    "consumer.user-match-strategy": "Locate Existing User by",
    "container": "Container",
    "container.owntracking.switch": "Enable Local Content Tracking",
    "content": "Content",
    "content.child.settings": "Settings",
    "content.create": "Create Content",
    "contentId": "Content ID",
    "contents": "Contents",
    "contributor": "Contributor",
    "contributors": "Contributors",
    "continue": "Continue",
    "copied.to.clipboard": "Copied to Clipboard",
    "copy": "Copy",
    "copy.link": "Copy Link",
    "correct": "Correct",
    "count": "Count",
    "create": "Create",
    "createDate": "Create Date",
    "createdBy": "Created By",
    "create_an_account": "Create an Account",
    "dark": "Dark",
    "de_DE": "German",
    "default": "Default",
    "default_": "Default",
    "definition": "Defintion",
    "delete": "Delete",
    "description": "Description",
    "designations": "Designations",
    "dimension": "Dimension",
    "enable_glossary_links": "Enable Glossary Links",
    "discard.changes": "Discard Changes",
    "displayEndDate": "Display End Date",
    "displayName": "Display Name",
    "displayOrder": "Display Order",
    "displayTime": "Display Time",
    "displayStartDate": "Display Start Date",
    "divider": "Divider",
    "download": "Download",
    "download.aicc": "Download AICC",
    "download.scorm": "Download SCORM",
    "duration": "Duration",
    "edit": "Edit",
    "editable": "Editable",
    "edit.column": "Edit Column",
    "edit_raw": "Edit Raw",
    "edit_rich_text": "Edit Rich Text",
    "elevation": "Elevation",
    "email": "Email",
    "embed_code": "Embed Code",
    "enabled": "Enabled",
    "enabled_condition": "Enabled Condition",
    "visible_condition": "Visible Condition",
    "endDate": "End Date",
    "endTime": "End Time",
    "expiryDate": "Expiration Date",
    "bulk_enroll": "Bulk Enroll",
    "enroll.user": "Enroll User",
    "enrollments": "Enrollments",
    "error": "Error",
    "error.content.invalid": "Invalid Content",
    "error.content.menu.inline": "Menu not supported for inline content launch",
    "error.content.notfound": "Content Not Found",
    "error.invalid.layout.element": "Invalid Layout Element",
    "es_ES": "Spanish (Spain)",
    "es_MX": "Spanish (Mexico)",
    "exclude_from_navigation": "Exclude From Navigation",
    "exclude_time_spent": "Exclude Time Spent",
    "existing": "Existing",
    "exit": "Exit",
    "exit_impersonation": "Exit Impersonation",
    "expand": "Expand",
    "expand_section": "Expand Section",
    "expand_button_label": "Expand Button Label",
    "leaf_section": "Leaf Section",
    "expand.menu": "Expand Menu",
    "externalId": "External ID",
    "extractor": "Extractor",
    "extractors": "Extractors",
    "failedMessage": "Failed Message",
    "favicon": "Favicon",
    "feedback": "Feedback",
    "feedbackCorrect": "Correct Feedback",
    "feedbackIncorrect": "Incorrect Feedback",
    "fill.space": "Fill Available Space",
    "filter.results": "Filter Results",
    "filters": "Filters",
    "first": "First",
    "firstName": "First Name",
    "firstVisitDate": "First Visited",
    "flashcard.prompt": "Did you know the correct answer?",
    "fontFamily": "Font Family",
    "fontSize": "Font Size",
    "fontWeight": "Font Weight",
    "format.blockquote": "Block Quote",
    "format.bold": "Bold",
    "format.heading": "Heading {level}",
    "format.italic": "Italic",
    "format.link": "Add Link",
    "format.link.off": "Remove Link",
    "format.normal": "Normal",
    "format.ol": "Ordered List",
    "format.ul": "Unordered List",
    "format.underline": "Underline",
    "fr_FR": "French",
    "freeform": "Freeform",
    "fullScreen": "Full Screen",
    "general": "General",
    "global": "Global",
    "glossary": "Glossary",
    "grid": "Grid",
    "html": "HTML",
    "h5p.zip": "H5P Zip Archive",
    "h5psettings": "H5P Settings",
    "handler": "Handler",
    "header_color": "Header Color",
    "headers": "Headers",
    "hidden": "Hidden",
    "hide_prerequisites_list": "Hide Prerequisites List",
    "home": "Home",
    "horizontal": "Horizontal",
    "icon": "Icon",
    "id_ID": "Indonesian",
    "impersonate": "Impersonate",
    "import": "Import",
    "include.archived": "Include Archived",
    "include_events": "Include Events",
    "incorrect": "Incorrect",
    "index": "Index",
    "info": "Info",
    "insert_element": "Insert Element",
    "intent": "Intent",
    "interactions": "Interactions",
    "internal": "Internal",
    "it_IT": "Italian",
    "itemsPerRow": "Items Per Row",
    "items_per_page": "Items Per Page",
    "item_spacing": "Item Layout Spacing",
    "ja_JP": "Japanese",
    "json": "JSON",
    "key": "Key",
    "ko_KR": "Korean",
    "language": "Language",
    "last": "Last",
    "lastLoginDate": "Last Login Date",
    "lastModified": "Last Modified",
    "lastName": "Last Name",
    "lastAccessDate": "Last Accessed",
    "lastActiveDate": "Last Active Date",
    "lastVisitDate": "Last Visited",
    "launch": "Launch",
    "launch.resume": "Resume",
    "launch.review": "Review",
    "launch.link": "Launch Link",
    "launch.link.help": "auto-detects launch protocol based on available parameters.",
    "launch.new.window": "Open",
    "launch.new.window.launched": "Content launched in a new Window/Tab",
    "launch.new.window.switch": "Launch in new Window/Tab",
    "launch.uri": "Launch URL",
    "launcher.aicc": "AICC",
    "launcher.container": "Container",
    "launcher.crowdwisdom": "Crowd Wisdom",
    "launcher.h5p": "H5P",
    "launcher.lti.tool": "LTI Tool",
    "launcher.multimedia": "Multimedia",
    "launcher.page": "Page",
    "launcher.quiz": "Quiz",
    "launcher.remote": "Remote",
    "launcher.scorm": "SCROM",
    "launcher.shuttle": "Shuttle",
    "launcher.tincan": "TinCan (xAPI)",
    "launcher.web": "Web",
    "launcher.zoom": "Zoom",
    "layout": "Layout",
    "layout.add.element": "Add Layout Element",
    "layout_box": "Box",
    "layout_card": "Card",
    "layout.content.children.list": "Content Children",
    "layout_content_actions": "Content Actions",
    "layout_content_announcements": "Content Announcements",
    "layout_content_bookmarks": "Content Bookmarks",
    "layout_content_contributors": "Content Contributors",
    "layout_content_glossary": "Content Glossary",
    "layout_content_menu": "Content Menu",
    "layout_content_menu_children": "Content Menu Children",
    "layout_content_progress": "Content Progress",
    "layout_content_tags": "Content Tags",
    "layout_content_awards": "Content Awards",
    "layout_embed": "Embed",
    "layout_expand": "Expand",
    "layout_external_link": "External Link",
    "layout_modal": "Modal",
    "layout_spacer": "Spacer",
    "layout_tabs": "Tabs",
    "layout_video": "Video",
    "layout.grid": "Grid",
    "layout.image": "Image",
    "layout.launch.inline": "Inline Content Launch",
    "layout.text": "Text",
    "launch_button_label": "Launch Button Label",
    "leaf": "Leaf",
    "light": "Light",
    "list": "List",
    "locale": "Locale",
    "location": "Location",
    "locked": "Locked",
    "locked_message": "Locked Message",
    "login": "Login",
    "logo": "Logo",
    "logo.inverse": "Logo (Dark Mode)",
    "logout": "Logout",
    "lowercase": "Lowercase",
    "lti-cp.link.id": "Resource Link ID",
    "lti-tc.outcomes-ext": "Outcomes Extension",
    "lti-tp.custom-key": "Name",
    "lti-tp.custom-value": "Value",
    "lti-tp.custom.property": "Custom Properties",
    "lti-tp.custom.property.add": "Add",
    "lti-tp.mark-complete-on-score-over-zero": "Mark Complete On Score Over Zero",
    "lti-tp.score-as-progress": "Score as Progress",
    "lti-tp.share.user-email": "Email",
    "lti-tp.share.user-family-name": "Family name",
    "lti-tp.share.user-full-name": "Full name",
    "lti-tp.share.user-given-name": "Given name",
    "lti-tp.share.user-id": "ID",
    "lti-tp.share.user-image": "Image",
    "lti-tp.share.user-sourced-id": "Sourced ID",
    "lti-tp.url": "URL",
    "lti-tp.version": "LTI Version",
    "lti.consumer-key": "Consumer Key",
    "lti.shared-secret": "Shared Secret",
    "ltisettings": "LTI Settings",
    "ltiusersettings": "Shared User Information",
    "match_info": "Match {current} of {total}",
    "maxAttemptAllowed": "Max Attempts Allowed",
    "maxQuestionAttempts": "Max Question Attempts",
    "max_length": "Max Length",
    "meetingId": "Meeting ID",
    "menu": "Menu",
    "method": "Method",
    "minTimeSpent": "Min Time Spent",
    "mode": "Mode",
    "mode.auto": "Auto",
    "mode.dark": "Dark",
    "mode.edit": "Edit Mode",
    "mode.edit.description": "Your progress will not tracked, sequencing rules will be disabled, and hidden items will be visible.",
    "mode.light": "Light",
    "mode.normal": "Normal Mode",
    "mode.browse": "Preview Mode",
    "mode.browse.description": "Your progress will not be tracked.",
    "mode.review": "Review Mode",
    "mode.review.description": "You have already completed this content.",
    "move.down": "Move Down",
    "move.up": "Move Up",
    "multi_select_tags": "Multi Select Tags",
    "name": "Name",
    "navigation": "Navigation",
    "new": "New",
    "next": "Next",
    "no": "No",
    "no_modes_available": "No modes available",
    "no_results": "No Results",
    "not_supported": "Not Supported",
    "notes": "Notes",
    "none": "(None)",
    "notfound": "Not Found",
    "oidc_authorization_location": "Authorization Location",
    "oidc_client_id": "Client ID",
    "oidc_secret": "Secret",
    "oidc_token_location": "Token Location",
    "open": "Open",
    "operator": "Operator",
    "optional": "Optional",
    "options": "Options",
    "or": "or",
    "order": "Order",
    "organization": "Organization",
    "outlined": "Outlined",
    "padding": "Padding",
    "padding_x": "Padding X",
    "padding_y": "Padding Y",
    "page": "Page",
    "paging.count": "{total} Items",
    "paging.info": "{start} - {end} of {total}",
    "paper": "Paper",
    "participantId": "Participant ID",
    "passScore": "Passing Score",
    "passedMessage": "Passed/Completed Message",
    "password": "Password",
    "password_mismatch": "Password Mismatch",
    "past": "Past",
    "pause": "Pause",
    "persisted": "Persisted",
    "phone": "Phone",
    "picture": "Picture",
    "pl_PL": "Polish",
    "play": "Play",
    "points": "Points",
    "poster": "Poster",
    "preferences": "Preferences",
    "prerequisites_message": "Prerequisites Message",
    "preview": "Preview",
    "preview_content_tree": "Preview Content Tree",
    "previous": "Previous",
    "primary": "Primary",
    "principal": "Principal",
    "production": "Production",
    "progress": "Progress",
    "properties": "Properties",
    "property_name": "Property Name",
    "pt_PT": "Portuguese",
    "publicId": "Public ID",
    "question.type.FLASHCARD": "Flash Card",
    "question.type.MULTIPLE_CHOICE": "Multiple Choice",
    "question.type.MULTIPLE_RESPONSE": "Multiple Response",
    "question.type.TEXT": "Text",
    "question.types": "Question Types",
    "questionCount": "Question Count",
    "questions": "Questions",
    "question_index": "Question {number} of {count}",
    "question_statistics_answer": "{percent}% of participants selected this answer.",
    "question_statistics_correct": "{percent}% of participants answered this question correctly.",
    "question_statistics_skipped": "{percent}% of participants skipped this question.",
    "question_review_description": "You have answered {answered} of {count} questions.",
    "quiz": "Quiz",
    "quiz.retry": "Try Again",
    "quiz.review": "Review",
    "quiz.start": "Start",
    "redirect": "Redirect",
    "refresh": "Refresh",
    "registry.launcher": "Launcher",
    "registry.layout.element": "Layout Element",
    "registry.root": "Root",
    "relative_period": "Time Period",
    "remediation": "Remediation",
    "remove": "Remove",
    "removed": "Removed",
    "replay.ten.seconds": "Back 10 Seconds",
    "required": "Required",
    "required_prerequisites": "Please complete prerequisite(s) in order to access this item.",
    "reset": "Reset",
    "resource": "Resource",
    "responsive": "Responsive",
    "result": "Result",
    "results.empty": "No Results",
    "resume": "Resume",
    "richText.placeholder": "Type something...",
    "role": "Role",
    "roles": "Roles",
    "rounded": "Rounded",
    "ru_RU": "Russian",
    "rules": "Rules",
    "saml_idp_cert": "IdP Cert",
    "saml_sp_cert": "SP Cert",
    "saml_login_url": "Login URL",
    "saml_first_name_attr": "First Name Attribute",
    "saml_last_name_attr": "Last Name Attribute",
    "saml_display_name_attr": "Display Name Attribute",
    "saml_email_attr": "Email Attribute",
    "saml_sp_initiated": "SP Initiated",
    "saml_sp_post": "Use Post Request",
    "saml_want_authn_request_signed": "Sign Authn Requests",
    "saml_destination": "Destination",
    "save": "Save",
    "saved": "Saved",
    "schema": "Schema",
    "scope": "Scope",
    "score": "Score",
    "scored": "Scored",
    "scorm.upload": "File (imsmanifest.xml or zip/pif)",
    "scormupload": "SCORM Manifest or Package",
    "script": "Script",
    "search": "Search",
    "seatLimit": "Seat Limit",
    "secondary": "Secondary",
    "section_note": "Section Note",
    "select.placeholder": "Choose an option...",
    "selected": "Selected",
    "sequenceIndex": "Sequence Index",
    "sequenceOrder": "Sequence Order",
    "sequential": "Sequential",
    "sessions": "Sessions",
    "session_closed": "Session Closed",
    "settings": "Settings",
    "settings.colorScheme": "Color Scheme",
    "settings.textSize": "Text Size",
    "show.answer": "Show Answer",
    "show_paging": "Show Paging",
    "show_feedback": "Show Feedback",
    "show_filters": "Show Filters",
    "show_incorrect_only": "Show Incorrect Only",
    "show_label": "Show Label",
    "show_loading_state": "Show Loading State",
    "show_sort": "Show Sort",
    "shuffleOptions": "Shuffle Options",
    "shuffleQuestions": "Shuffle Questions",
    "shuttlesettings": "Shuttle Settings",
    "sign_in_with_google": "Sign In With Google",
    "sign_in": "Sign In",
    "sign_up": "Sign Up",
    "sign_out": "Sign Out",
    "singlePage": "Single Page",
    "skipped": "Skipped",
    "size": "Size",
    "sort": "Sort",
    "sort.by": "Sort By",
    "sort.name.asc": "Name (A-Z)",
    "sort.name.desc": "Name (Z-A)",
    "spacing": "Spacing",
    "speakers": "Speakers",
    "square": "Square",
    "sr.skip.content": "Skip To Main Content",
    "sr.skip.sidebar": "Skip to Sidebar",
    "sso": "SSO",
    "start": "Start",
    "startDate": "Start Date",
    "startTime": "Start Time",
    "status": "Status",
    "stem": "Stem",
    "study_pace": "Study Pace",
    "study_time": "Study Time",
    "style": "Style",
    "subTitle": "Subtitle",
    "submit": "Submit",
    "success": "Success",
    "sum_children_time_spent": "Sum Children Time Spent",
    "summary": "Summary",
    "system": "System",
    "systemEnrollmentId": "System Enrollment ID",
    "systemUserId": "System User ID",
    "tags": "Tags",
    "target": "Target",
    "targets": "Targets",
    "template": "Template",
    "text": "Text",
    "text.plain": "Plain Text",
    "text.rich": "Rich Text",
    "textPrimary": "Text Primary",
    "textSecondary": "Text Secondary",
    "textTransform": "Text Transform",
    "th_TH": "Thai",
    "theme": "Theme",
    "theme.current": "Current Theme",
    "theme.set": "Set Theme",
    "thickness": "Thickness",
    "timeSpent": "Time Spent",
    "timeZone": "Time Zone",
    "time_window": "Time Window",
    "tincan.activity.id": "Activity ID",
    "tincan.upload": "File (tincan.xml or zip)",
    "tincanupload": "XAPI TinCan",
    "title": "Title",
    "toggle_color_scheme": "Toggle Color Scheme",
    "toggle_section": "Toggle Section",
    "toggle.drawer": "Toggle Drawer",
    "toggle.filters": "Toggle Filters",
    "toggle.fullscreen": "Toggle Fullscreen",
    "toggle.mute": "Toggle Mute",
    "toggle.view.grid": "Toggle Grid View",
    "toggle.view.list": "Toggle List View",
    "top_level_only": "Top Level Only",
    "tr_TR": "Turkish",
    "trigger": "Trigger",
    "transcript": "Transcript",
    "translations": "Translations",
    "type": "Type",
    "ui_schema": "UI Schema",
    "uniqueTimeViewed": "Unique Playback Time",
    "unreliablyObserveActivity": "Observe Activity (not supported for cross domain content)",
    "upcoming": "Upcoming",
    "upload_file": "Upload File",
    "uppercase": "Uppercase",
    "url": "URL",
    "user": "User",
    "users": "Users",
    "use_content_picture": "Use Content Picture",
    "use_user_external_id": "Use User External ID",
    "user.id": "User ID",
    "username": "Username",
    "value": "Value",
    "variables": "Variables",
    "vertical": "Vertical",
    "view": "View",
    "warning": "Warning",
    "warningDuration": "Warning Duration",
    "watch": "Watch",
    "watch_x_to_complete": "Watch at least {duration} to complete",
    "watch_entire_video_to_complete": "Watch entire video to complete",
    "webinar": "Webinar",
    "weight": "Weight",
    "window.confirm": "Are you sure?",
    "x_api": "xAPI",
    "yes": "Yes",
    "zh_CN": "Chinese (Simplified)",
    "zh_TW": "Chinese (Traditional)",
    "zoomsettings": "ZOOM Settings",
    "previous-siblings": "Previous Siblings",
    "months": "Months",
    "weeks": "Weeks",
    "days": "Days",
    "rule": "Rule",
    "username_already_taken": "Username already associated with another account.",
    "email_already_taken": "Email already associated with another account.",
    "password_instructions": "Must be at least 5 characters long.",
    "registration_not_allowed": "Registration is not allowed.",
    "award.autoClaim": "Auto Claim",
    "award.expirationDate" : "Expiration Date",
    "award.validityLength" : "Validity Length (days)",
    "award.claimExpirationDate" : "Claim Expiration Date",
    "award.validityStartType" : "Validity Start Type",
    "award.claimValidityLength" : "Claim Validity Length (days)",
    "award.issueDate": "Issue Date",
    "award.claimDate": "Claim Date",
    "invalid_session": "Invalid Session",
    "claim_selected": "Claim Selected",
    "certificates": "Certificates",
    "issued": "Issued",
    "not_earned": "Not Earned",
    "completion_progress": "Progress: {progress}%",
    min_time_instructions: `This course requires that you spend at least {time}.`,
    access_code_email_prompt_instructions: "Enter your email to receive the access code. You'll receive the access code in email and then need to return and enter it here.",
    access_code_username_prompt_instructions: "Enter your username to receive the access code. You'll receive the access code in email and then need to return and enter it here.",
    access_code_response_instructions: "Please enter the access code sent to you via email.",
    enrollment_update_issue_awards: "Issue Awards",
    enrollment_update_send_email: "Send Completion Email",
    search_enabled_only: "Enabled Only",
};

export default dictionary;
