import * as React from "react";

import useString from "../hooks/useString";
import { ValidLabelKey, ValidLabelArg } from "./localization/ValidLabelKey";

interface ILabelProps {
    args?: { [name: string]: ValidLabelArg; };
    className?: string;
    k: ValidLabelKey;
    style?: React.CSSProperties;
    children?: (text: string) => React.ReactElement;
    html?: boolean;
}

export default function Label({ args, children, className, html, k, style }: ILabelProps) {
    const text = useString(k, args);

    if (typeof children === 'function') {
        return children(text);
    }

    if (html) {
        return (
            <div className={className} style={style} dangerouslySetInnerHTML={{__html: text}} />
        );
    }

    return (
        <span className={className} style={style}>
            {text}
        </span>
    );
}
