import { createElement, Fragment, useContext } from "react";
import { List, ListItem, ListItemText, ListItemIcon, Divider, Box, Alert } from "@mui/material";
import { Switch, RouteComponentProps, useHistory } from "react-router-dom";

import TenantsIcon from "@mui/icons-material/Business";
import RuntimeIcon from "@mui/icons-material/Storage";

import Layout from "../core/Layout";
import { processSections, SectionWrapper } from "../core/admin/AdminSection";
import UserToolbar from "../core/UserToolbar";
import { FeedbackProvider } from "../core/FeedbackContext";
import Tenants from "./super-admin/Tenants";
import Runtime from "./super-admin/Runtime";
import { AppContext } from "../core/AppContext";
import { AuthService } from "../services/AuthService";

const sections: Array<SectionWrapper<void>> = [
    {
        path: '/tenants',
        label: 'admin.section.tenants',
        icon: TenantsIcon,
        section: Tenants,
    },
    {
        path: '/runtime',
        label: 'admin.section.runtime',
        icon: RuntimeIcon,
        section: Runtime,
    },
];

export default function SuperAdmin(props: RouteComponentProps) {
    const [appContext] = useContext(AppContext);
    const history = useHistory();

    if (!appContext.user) {
        history.push('/login?r=' + encodeURIComponent(AuthService.trimFrontendPath(appContext.tenant, window.location.pathname)));
        return null;
    }

    if (!appContext.user.roles?.includes('ROLE_SUPER_ADMIN')) {
        return (
            <Layout>
                <Alert severity="info">
                    {`You don't have permission to access this section.`}
                </Alert>
            </Layout>
        );
    }

    const { routes, tabs } = processSections<void>({ appContext, context: undefined, sections, pathPrefix: '/super-admin' });
    return (
        <FeedbackProvider>
            <Layout 
                drawer={
                    <Fragment>
                        <ListItem>
                            <ListItemText primary={appContext.tenant.name} secondary={appContext.tenant.publicId} />
                        </ListItem>
                        <Divider />
                        <List>
                            {
                                tabs.map(({ icon, path, title }) => {
                                    const selected = props.location.pathname.startsWith(path);
                                    return (
                                        <ListItem 
                                            dense
                                            button
                                            key={path}
                                            onClick={() => props.history.push(path)}
                                            selected={selected}
                                            color={selected ? 'primary' : undefined}
                                        >
                                            <ListItemIcon>
                                                {icon && createElement(icon, { color: selected ? 'primary' : undefined })}
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={title}
                                                primaryTypographyProps={{
                                                    color: selected ? 'primary' : undefined,
                                                }}
                                            />
                                        </ListItem>
                                    );
                                })
                            }
                        </List>
                    </Fragment>
                }
                rightElement={<UserToolbar />}
                fullscreen
                onClickLogo={() => history.push('/')}
            >
                <Box sx={{ p: 2 }}>
                    <Switch>
                        {routes}
                    </Switch>
                </Box>
            </Layout>
        </FeedbackProvider>
    );
}
