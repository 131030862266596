import { anyEditor, createEntitySection, createFormAction } from "../../core/admin/AdminSection";
import ChipToggleList from "../../form/inputs/ChipToggleList";
import QuizModeData, { QuizModeSearchParams } from "../../model/quiz/QuizModeData";
import QuizModeService from "../../services/QuizModeService";

export default createEntitySection<QuizModeData, QuizModeSearchParams>({
    
    model: QuizModeData,
    params: QuizModeSearchParams,

    getId: o => o.id,
    label: 'name',

    ...QuizModeService,

    columns: [
        {
            key: 'name',
            sort: 'name',
        },
        {
            key: 'externalId',
            sort: 'externalId'
        },
        {
            key: 'type',
            sort: 'type',
            renderCell: ({ data, params, setParams }) => (
                <ChipToggleList
                    options={[data.type]}
                    onChange={([type]) => setParams({ ...params, page: 0, type })}
                    size="small"
                    value={params.type ? [params.type] : []}
                />
            ),
        },
    ],

    editEnabled: ({ appContext }) => anyEditor(appContext),

    actions: [
        createFormAction({
            key: 'create',
            clazz: QuizModeData,
            onSubmit: async mode => {
                await QuizModeService.create(mode);
                return true;
            },
            enabled: (_, appContext) => anyEditor(appContext),
        }),
    ]

});
