import { Input } from "react-ts-form";

import Label from "../core/Label";
import FileInput from "../form/inputs/FileInput";
import RichTextInput from "../form/inputs/RichTextInput";
import TextInput from "../form/inputs/TextInput";
import FileData from "./FileData";

export default class ContributorData {

    public id!: number;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="name" />,
            required: true
        }
    })
    public name!: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="externalId" />
        },
        inputProps: {
            maxLength: 128
        }
    })
    public externalId?: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="designations" />
        }
    })
    public designations?: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="organization" />
        }
    })
    public organization?: string;

    @Input({
        component: RichTextInput,
        meta: {
            title: <Label k="bio" />
        }
    })
    public bio?: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="email" />
        }
    })
    public email?: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="phone" />
        }
    })
    public phone?: string;

    @Input({
        component: FileInput,
        meta: {
            title: <Label k="avatar" />
        }
    })
    public avatar?: FileData;

}
