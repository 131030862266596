import { TextareaAutosize } from "@mui/material";
import { IInputProps } from "react-ts-form";

interface TextAreaInputProps extends IInputProps<string> {

}

export default function TextAreaInput({ disabled, id, required, onChange, value = "" }: TextAreaInputProps) {
    
    return (
        <TextareaAutosize 
            minRows={3}
            disabled={disabled}
            id={id}
            required={required}
            onChange={e => onChange(e.target.value)} 
            value={value}
            style={{
                minWidth: '100%',
                maxWidth: '100%',
            }}
        />
    );
}
