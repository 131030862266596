import { useState, useEffect, useCallback } from "react";

export interface IResolverState<T> {
    data: T | null;
    error?: Error;
    loading: boolean;
}

/**
 * calls @param resolver whenever the reference changes and tracks loading state.
 */
export default function useResolver<T>(resolver: () => Promise<T>) {

    const [state, setState] = useState<IResolverState<T>>({data: null, loading: false});

    const reload = useCallback(async () => {
        setState(prev => ({...prev, error: undefined, loading: true}));
        try {
            const data = await resolver();
            setState({ data, error: undefined, loading: false });
        } catch (error: any) {
            console.error(error);
            setState({data: null, error, loading: false});
        }
    }, [resolver]);

    useEffect(() => {
        reload()
    }, [reload]);

    return {...state, reload, setState};
}
