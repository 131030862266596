import Axios from "axios";
import SearchParams from "../model/SearchParams";
import SearchResult from "../model/SearchResult";
import { Shuttle } from "../types";

export const ConsumerUIService = {

    searchMyEnrollments: async (params: SearchParams) => Axios
        .get<SearchResult<Shuttle.EnrollmentWithCourse>>('/m/enrollments', { params })
        .then(r => r.data),

};
