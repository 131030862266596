import { Input } from "react-ts-form";

import Label from "../core/Label";
import TextInput from "../form/inputs/TextInput";

export default class GlossaryData {

    public id!: number;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="name" />,
            required: true
        }
    })
    public name!: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="externalId" />
        },
        inputProps: {
            maxLength: 128
        }
    })
    public externalId?: string;

}
