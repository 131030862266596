import { Alert, CircularProgress, Skeleton, Stack } from "@mui/material";
import { createElement, ReactNode, useContext, useEffect, useRef, useState } from "react";
import { ConstraintsContext, ContentContext, SessionContext, SessionEntryContext, SettingsContext, TrackingContext } from "../contexts";
import Layout from "../core/Layout";
import { Layout as UiLayout } from "../core/ui/Layout";
import { ThemeProvider } from "../core/ThemeProvider";
import { behaviorRegistry, launcherRegistry } from "../registries";
import { Shuttle } from "../types";
import { api } from "./api";
import useTracking from "./useTracking";
import LockedIcon from "@mui/icons-material/Lock";
import LockedMessage from "./LockedMessage";
import { getAlternative, isLocked } from "./utils";
import GlossaryProvider from "../core/GlossaryProvider";
import BrowserUtil from "../util/BrowserUtil";
import LabelledIconButton from "../core/ui/LabelledIconButton";
import LogOutIcon from '@mui/icons-material/ExitToApp';
import CloseIcon from '@mui/icons-material/Close';
import { useHistory } from "react-router-dom";
import DropDownChoiceInput from "../form/inputs/DropDownChoiceInput";
import { LabelKeyChoiceRenderer } from "../form/IOptionsProps";

interface PlayContentProps {
    contentNode: Shuttle.ContentNode;
    defaultDimension?: string;
    defaultLockedMessage?: ReactNode;
    defaultPrerequisitesMessage?: ReactNode;
    hidePrerequisitesList?: boolean;
    inline?: boolean;
    params?: any; //memoize
    popup?: boolean;
    controls?: ReactNode[];
    internalCloseButton?: boolean;
}

export default function PlayContent({
    contentNode,
    defaultLockedMessage,
    defaultPrerequisitesMessage,
    hidePrerequisitesList,
    inline,
    ...rest
}: PlayContentProps) {

    const ref = useRef<HTMLDivElement>(null);
    const [active, setActive] = useState(!inline);

    useEffect(() => {
        const { current } = ref;
        if (!active && current) {
            const w = current.ownerDocument?.defaultView;
            if (w) {
                const handler = () => {
                    if (BrowserUtil.isElementInViewport(current)) {
                        setActive(true);
                        w.removeEventListener('scroll', handler);
                        w.removeEventListener('resize', handler);
                    }
                }
                handler();
                w.addEventListener('scroll', handler);
                w.addEventListener('resize', handler);
                return () => {
                    w.removeEventListener('scroll', handler);
                    w.removeEventListener('resize', handler);
                }
            }
        }
    }, [active]);

    const { closeSession, session } = useContext(SessionContext);
    const constraints = useContext(ConstraintsContext);

    if (!session) {
        throw new Error('rendered outside of session context!');
    }

    const content = getAlternative(constraints, contentNode);

    const launcher = launcherRegistry.get(content.launcher);
    
    if (!launcher) {
        return (
            <Alert severity="error">
                {`invalid launcher: ${content.launcher}`}
            </Alert>
        );
    }

    if (isLocked(constraints, contentNode)) {
        return (
            <Alert severity="info" icon={<LockedIcon />}>
                <LockedMessage 
                    contentNode={contentNode}
                    defaultLockedMessage={defaultLockedMessage}
                    defaultPrerequisitesMessage={defaultPrerequisitesMessage}
                    hidePrerequisitesList={hidePrerequisitesList}
                />
            </Alert>
        );
    }

    if (!active) {
        return <Skeleton variant="rectangular" ref={ref} />
    }
    
    return (
        <PlayWrapper 
            closeSession={closeSession}
            content={content} 
            contentNode={contentNode}
            session={session}
            launcher={launcher}
            inline={inline}
            {...rest}
            key={content.path}
        />
    );
}

interface PlayWrapperProps {
    closeSession: () => void;
    content: Shuttle.ContentNodeContent;
    contentNode: Shuttle.ContentNode;
    controls?: ReactNode[];
    defaultDimension?: string;
    inline?: boolean;
    launcher: Shuttle.Launcher;
    params?: any; //memoize
    popup?: boolean;
    session: Shuttle.PlayEntryPointData;
    internalCloseButton?: boolean;
}

function PlayWrapper({
    closeSession,
    content,
    contentNode,
    defaultDimension,
    inline,
    launcher,
    params,
    popup,
    session: {
        sessionId,
        logoutUrl,
    },
    controls,
    internalCloseButton,
}: PlayWrapperProps) {
    const { setSession } = useContext(SessionContext);
    const [{ locale }] = useContext(SettingsContext);
    const [entry, setEntry] = useState<null | Shuttle.PlaySessionEntryData>(null);
    const history = useHistory();
    const ref = useRef<HTMLDivElement>(null);

    controls = controls ? [...controls] : [];

    // "root" level
    if (!content.path.includes('/c')) {
        let languages = content.locales;
        if (!languages?.length && content !== contentNode.content) {
            languages = contentNode.content.locales;
        }
        if (languages?.length > 1) {
            controls.push(
                <DropDownChoiceInput
                    key='locale-picker'
                    options={languages}
                    onChange={locale => setSession(prev => ({
                        ...prev,
                        state: {
                            ...prev.state ?? {},
                            locale: [locale],
                        },
                    }))}
                    value={locale}
                    small
                    choiceRenderer={LabelKeyChoiceRenderer}
                />
            )
        }
    }

    useEffect(() => {

        if (sessionId) {
            api.play.path(sessionId, content.path, params).then(setEntry).catch(err => console.error(err));
        } else {
            setEntry(null);
        }

    }, [sessionId, content, params]);

    const { behaviors } = entry ?? {}

    const track = useTracking((launcher.tracking && entry?.entryId) || undefined, ref);

    if (!entry || (launcher.tracking && !track.tracking)) {
        return <CircularProgress />;
    }

    if (behaviors?.length) {
        let i = 0;
        for (const { handler, config, state = {} } of behaviors) {
            const ToolbarComponent = behaviorRegistry.get(handler)?.ToolbarComponent;
            if (ToolbarComponent) {
                if (!controls) controls = []
                controls.push(
                    createElement(ToolbarComponent, {
                        key: `${i++}-${handler}`,
                        config,
                        closeSession,
                        locale,
                        state,
                    })
                );
            }
        }
    }

    if (contentNode.id === 0) {
        if (internalCloseButton) {
            controls.push(
                <LabelledIconButton
                    key="exit"
                    k="exit"
                    icon={CloseIcon}
                    onClick={() => {
                        const frames = (ref.current?.ownerDocument ?? document).querySelectorAll('iframe');
                        frames.forEach(f => {
                            f.src = 'about:blank';
                        });
                        setTimeout(() => history.push('/'), 100);
                    }}
                />
            )
        } else if (logoutUrl) {
            controls.push(
                <LabelledIconButton
                    key="logout"
                    k="logout"
                    icon={LogOutIcon}
                    {...{ href: logoutUrl } as any}
                    target={BrowserUtil.inFrame() ? "_top" : undefined}
                    edge="end"
                />
            );
        }
    }

    const entryPoint = !content.path.includes('c'); //not a content child

    const toolbar = controls?.length ? (
        <Stack direction="row" spacing={1} flexShrink={0} flexGrow={0}>
            {controls}
        </Stack>
    ) : undefined

    const footer: ReactNode = !!(entry.theme?.footer) && (
        <UiLayout layout={entry.theme.footer.config} />
    );

    let display = createElement(launcher.Component, {
        content,
        contentNode,
        defaultDimension, 
        entryPoint,
        footer,
        inline,
        popup,
        session: entry,
        toolbar,
        track,
    });

    if (toolbar && !launcher.disableDefaultToolbar) {
        display = (
            <Layout rightElement={toolbar} fullscreen>
                {display}
                {footer}
            </Layout>
        );
    }

    if (entry.terms?.length) {
        display = (
            <GlossaryProvider terms={entry.terms}>
                {display}
            </GlossaryProvider>
        )
    }

    if (entry.theme) {
        display = (
            <ThemeProvider theme={entry.theme}>
                {display}
            </ThemeProvider>
        );
    }

    return (
        <SessionEntryContext.Provider value={entry}>
            <ContentContext.Provider value={contentNode}>
                <TrackingContext.Provider value={track}>
                    <div data-simpatico-content={contentNode.id.toString()} />
                    {display}
                    <div ref={ref} />
                    {
                        entry.behaviors?.reduce((a, { handler, config, state }, index) => {
                            const type = behaviorRegistry.get(handler);
                            if (type && type.BodyComponent) {
                                a.push(createElement(type.BodyComponent, {
                                    config,
                                    state,
                                    locale,
                                    closeSession,
                                    key: handler + '-' + index
                                }))
                            }
                            return a;
                        }, [] as ReactNode[])
                    }
                </TrackingContext.Provider>
            </ContentContext.Provider>
        </SessionEntryContext.Provider>
    );
}
