import { useCallback, useState } from "react";
import useResolver from "../hooks/useResolver";
import EnrollmentService from "../services/EnrollmentService";
import LabelledIconButton from "./ui/LabelledIconButton";
import TreeIcon from "@mui/icons-material/AccountTree";
import ModalDialog from "./ui/ModalDialog";
import Label from "./Label";
import ContentTreePreview from "./ContentTreePreview";
import { Box, Button } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

interface EnrollmentTreePreviewProps {
    enrollmentId: number;
}

export default function EnrollmentTreePreview({ enrollmentId }: EnrollmentTreePreviewProps) {

    const { data, loading, reload } = useResolver(useCallback(() => EnrollmentService.previewTree(enrollmentId), [enrollmentId]));

    if (!data) {
        return (
            <div>
                loading...
            </div>
        );
    }
    
    return (
        <>
            <Box sx={{ textAlign: 'right', mb: 1 }}>
                <Button 
                    startIcon={<RefreshIcon />} 
                    onClick={reload} 
                    color="secondary" 
                    variant="contained" 
                    size="small"
                    disabled={loading}
                >
                    <Label k="refresh" />
                </Button>
            </Box>
            <ContentTreePreview content={data.content} enrollments={data.enrollments} />
        </>
    );
}

EnrollmentTreePreview.DialogButton = function DialogButton(props: EnrollmentTreePreviewProps) {
    const [open, setOpen] = useState(false);

    return (
        <>
            <LabelledIconButton 
                icon={TreeIcon}
                k="preview_content_tree"
                onClick={() => setOpen(true)}
            />
            <ModalDialog
                open={open}
                onClose={() => setOpen(false)}
                title={<Label k="preview_content_tree" />}
            >
                <EnrollmentTreePreview {...props} />
            </ModalDialog>
        </>
    );
}
