import { useContext, useMemo } from "react";
import { SettingsContext } from "../contexts";

import { ValidLabelKey, ValidLabelArg } from "../core/localization/ValidLabelKey";
import { getBestLocale } from "../i18n/loaders";
import dictionary from "../i18n/dictionary";

export default function useString(key?: ValidLabelKey, args?: { [name: string]: ValidLabelArg; }) {

    const [{ locale }] = useContext(SettingsContext)

    return useMemo(() => {

        const localeDictionary = getBestLocale(locale).dictionary ?? {}

        let text = (key && (localeDictionary[key] ?? dictionary[key])) ?? key ?? "";

        if (text && args && Object.keys(args).length) {
            text = text.replace(new RegExp("{(" + Object.keys(args).join('|') + ")}", "g"), match => {
                const name = match.slice(1, match.length - 1);
                const value = args[name];
                if (typeof value === 'number') {
                    return value.toString();
                }
                if (typeof value === 'string') {
                    return value;
                }
                return "";
            });
        }

        return text;
    }, [locale, key, args]);
}
