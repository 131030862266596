import { Input } from "react-ts-form";
import QuizModeData from "./QuizModeData";
import NumberInput from "../../form/inputs/NumberInput";
import Label from "../../core/Label";

export default class ContentQuizModeData {

    public quizMode!: QuizModeData;

    @Input({
        component: NumberInput,
        meta: {
            title: <Label k="displayOrder" />,
        },
    })
    public displayOrder = 0;

}
