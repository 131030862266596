import { FormEvent, useContext, useState } from "react";
import Collector from "../model/Collector";
import { Button, Checkbox, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import bestLocaleMatch from "../i18n/util";
import { SettingsContext } from "../contexts";
import StringUtil from "../util/StringUtil";
import useId from "../hooks/useId";
import Label from "./Label";
import { IInputProps } from "react-ts-form";
import RunningText from "./ui/RunningText";

interface CollectorFormProps {
    initialValues?: Record<string, unknown>;
    onSubmit: (data: Record<string, unknown>) => void;
    promptText?: Collector['promptText'];
    variables: Collector['properties'];
}

export function CollectorForm({
    initialValues,
    onSubmit,
    promptText,
    variables,
}: CollectorFormProps) {

    const [{ locale }] = useContext(SettingsContext);
    const [values, setValues] = useState(initialValues ?? {});
    const id = useId();

    const title = bestLocaleMatch(promptText, locale);
    
    return (
        <form
            onSubmit={(e: FormEvent<HTMLFormElement>) => {
                e.preventDefault();
                onSubmit(values);
            }}
        >
            {
                !!title && (
                    <RunningText html={title} />
                )
            }
            <CollectorFields
                id={id}
                onChange={setValues}
                title={title}
                value={values}
                variables={variables}
            />
            <Button
                variant="contained"
                fullWidth
                type="submit"
                color="primary"
                disabled={variables.some(p => p.required && p.editable && values[p.name] === undefined)}
            >
                <Label k="continue" />
            </Button>
        </form>
    );
}

interface CollectorFieldsProps extends IInputProps<Record<string, unknown>> {
    variables: Collector['properties'];
}

export function CollectorFields({
    disabled,
    id,
    onChange,
    value: values,
    variables,
}: CollectorFieldsProps) {
    const [{ locale }] = useContext(SettingsContext);
    if (!values) values = {};
    return (
        <>
            {
                variables.filter(p => p.editable).map((p, i) => {

                    const key = p.name + '-' + i;

                    const label = bestLocaleMatch(p.displayName, locale) ?? StringUtil.snakeToTitle(p.name);
                    
                    const description = bestLocaleMatch(p.description, locale);

                    let value = values[p.name]

                    if (p.options?.length) {
                        if (p.multiple) {
                            if (value && !Array.isArray(value)) {
                                value = [value];
                            }
                        }
                        return (
                            <FormControl key={key} fullWidth required={p.required} sx={{ my: 1 }}>
                                <InputLabel id={id + key + '-label'} required={p.required}>
                                    {label}
                                </InputLabel>
                                <Select
                                    labelId={id + key + '-label'}
                                    id={id + key}
                                    label={label}
                                    required={p.required}
                                    multiple={p.multiple}
                                    value={value ?? ''}
                                    onChange={e => {
                                        const { target: { value } } = e;
                                        const selected = p.multiple && typeof value === 'string' ? value.split(',').filter(s => p.options?.find(o => o.value === s)) : value
                                        onChange({
                                            ...values,
                                            [p.name]: selected,
                                        })
                                    }}
                                    autoComplete="off"
                                    variant="outlined"
                                    disabled={disabled}
                                >
                                    {
                                        p.options.map((o, i2) => (
                                            <MenuItem key={o.value + '-' + i2} value={o.value}>
                                                {bestLocaleMatch(o.displayName, locale) ?? o.value}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        );
                    }

                    if (p.type === 'boolean') {
                        return (
                            <FormControl key={key} sx={{ my: 1 }} fullWidth disabled={disabled}>
                                <FormControlLabel
                                    label={label}
                                    aria-required={p.required}
                                    disabled={disabled}
                                    control={
                                        <Checkbox
                                            checked={Boolean(value)}
                                            required={p.required}
                                            onChange={e => onChange({
                                                ...values,
                                                [p.name]: e.target.checked
                                            })}
                                            disabled={disabled}
                                        />
                                    }
                                />
                                {
                                    description && (
                                        <FormHelperText>
                                            {description}
                                        </FormHelperText>
                                    )
                                }
                            </FormControl>
                        );
                    }

                    return (
                        <TextField
                            key={key}
                            type={p.type === 'number' ? 'number' : 'text'}
                            label={label}
                            helperText={description}
                            required={p.required}
                            fullWidth
                            value={value ?? ''}
                            onChange={e => onChange({
                                ...values,
                                [p.name]: p.type === 'number' ? Number(e.target.value) : e.target.value,
                            })}
                            sx={{ my: 1 }}
                            disabled={disabled}
                        />
                    );
                })
            }
        </>
    );
}
