import { useState } from "react";
import { IInputProps } from "react-ts-form";
import { TextField, TextFieldProps, InputAdornment, IconButton } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


interface IPasswordInputProps extends IInputProps<string> {
    newPassword?: boolean;
    fullWidth?: boolean;
    variant?: TextFieldProps['variant'];
}

export default function PasswordInput(props: IPasswordInputProps) {
    const [show, setShow] = useState(false);

    return (
        <TextField
            autoComplete={props.newPassword ? 'new-password' : undefined}
            fullWidth={props.fullWidth !== undefined ? props.fullWidth : true}
            disabled={props.disabled}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShow(!show)}
                            onMouseDown={e => e.preventDefault()}
                            edge="end"
                        >
                            {show ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            id={props.id}
            required={props.required}
            onChange={e => props.onChange(e.target.value)}
            type={show ? 'text' : 'password'}
            value={props.value || ""} 
            variant={props.variant}
        />
    );
}
