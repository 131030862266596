import { Shuttle } from "../../types";
import H5PIcon from "@mui/icons-material/AbcSharp";
import Embed from "../Embed";
import { Fieldsets, Input } from "react-ts-form";

import FileData from "../../model/FileData";
import FileInput from "../../form/inputs/FileInput";
import Label from "../../core/Label";

@Fieldsets({
    h5psettings: <Label k="h5psettings" />
})
class H5PSettings {

    @Input({
        component: FileInput,
        meta: {
            title: <Label k="h5p.zip" />
        },
        fieldset: "h5psettings",
    })
    public resource?: FileData;

}

export const H5PLauncher: Shuttle.Launcher = {
    name: 'launcher.h5p',
    Icon: H5PIcon,
    Component: Embed,
    ConfigClass: H5PSettings,
};
