import { Input } from "react-ts-form";
import TextInput from "../form/inputs/TextInput";
import Label from "../core/Label";

export class LoginData {

    @Input((_, { context }) => ({
        component: TextInput,
        meta: {
            title: context.emailAsUsername ? <Label k="email" /> : <Label k="username" />,
            required: true,
        },
        inputProps: {
            maxLength: 64,
        },
    }))
    public username?: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="password" />,
            required: true,
        },
        inputProps: {
            maxLength: 64,
            type: 'password',
        },
    })
    public password!: string;

}
