import { Checkbox, CheckboxProps, FormControlLabel } from "@mui/material";
import * as React from "react";
import { IInputProps } from "react-ts-form";

interface ICheckBoxInputProps extends IInputProps<boolean> {
    color?: CheckboxProps['color'];
    size?: CheckboxProps['size'];
}

export default function CheckBoxInput(props: React.PropsWithChildren<ICheckBoxInputProps>) {

    const input = (
        <Checkbox 
            checked={!!props.value}
            disabled={props.disabled}
            id={props.id}
            onChange={e => props.onChange(e.target.checked)}
            required={props.required}
        />
    );

    if (props.children) {
        return (
            <FormControlLabel 
                control={input}
                label={<>{props.children}</>}
                labelPlacement="end"
            />
        );
    }

    return input;
}
