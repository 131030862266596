import { Input } from "react-ts-form";
import TextInput from "../form/inputs/TextInput";
import SwitchInput from "../form/inputs/SwitchInput";
import Rule from "./Rule";
import UserData from "./UserData";
import DropDownChoiceInput from "../form/inputs/DropDownChoiceInput";
import { Consumer } from "./Consumer";
import { ConsumerInput } from "../form/common";

export class Group {

    public id!: number;

    @Input({
        component: TextInput,
        meta: {
            title: 'Name',
            required: true,
        }
    })
    public name!: string;

    @Input({
        component: TextInput,
        meta: {
            title: 'External ID',
        },
    })
    public externalId?: string;

    @Input({
        component: SwitchInput,
        meta: {
            title: 'Internal',
            description: 'Enable this if you do not want consumer integration APIs, SSO, or course launch to be able to add users to this group.',
        },
    })
    public internal?: boolean;

    @Input({
        component: SwitchInput,
        meta: {
            title: 'Dynamic',
            description: 'Build user list dynamically based on a configurable rule. Once enabled, you will not be able to manually add users.'
        },
    })
    public dynamic?: boolean;

    @Input((_, { parent }) => {
        if (parent.dynamic) {
            return {
                clazz: Rule,
                meta: {
                    title: 'Rule',
                },
            };
        }
        return {};
    })
    public rule?: Rule

    @Input(((_, { parent }) => ({
        component: ConsumerInput,
        meta: {
            title: 'Consumer',
            required: true,
            disabled: !!parent.id,
        },
    })))
    public consumer!: Consumer;

    public createDate!: string;
    public userCount!: number;

}

export class UserGroup {

    public user!: UserData;

    public group!: Group;

    @Input({
        component: DropDownChoiceInput,
        meta: {
            title: 'Role',
            required: true,
        },
        inputProps: {
            options: ['LEARNER', 'INSTRUCTOR'],
        },
    })
    public role: 'LEARNER' | 'INSTRUCTOR' = 'LEARNER';

    @Input({
        component: DropDownChoiceInput,
        meta: {
            title: 'Method',
            required: true,
            description: `STATIC will stay in this group until explicitly removed, 
            DYNAMIC will be removed when the user no longer matches the rule,
            and FIXED will not be modified by either process.`
        },
        inputProps: {
            options: ['FIXED', 'STATIC', 'DYNAMIC'],
        },
    })
    public method: 'STATIC' | 'DYNAMIC' | 'FIXED' = 'FIXED';

    public createDate!: string;

}
