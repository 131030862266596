import { IInputProps, Input } from "react-ts-form";

import TextInput from "../form/inputs/TextInput";
import SwitchInput from "../form/inputs/SwitchInput";
import FileInput from "../form/inputs/FileInput";
import Label from "../core/Label";
import PasswordInput from "../form/inputs/PasswordInput";
import { ConsumerInput, LocaleInput, RoleMultiInput, TimeZoneInput } from "../form/common";
import FileData from "./FileData";
import { Consumer } from "./Consumer";
import { useMemo } from "react";
import { Box } from "@mui/material";
import { AuthProvider } from "./AuthProvider";

function RawJson({ value }: IInputProps<any>) {
    const formatted = useMemo(() => JSON.stringify(value, null, 2), [value]);
    return (
        <Box
            sx={{
                p: 2,
                whiteSpace: 'pre',
                border: theme => `1px solid ${theme.palette.divider}`,
                display: 'block',
                borderRadius: 4,
            }}
            component="code"
        >
            {formatted}
        </Box>
    )
}

export default class UserData {

    public id!: number;

    @Input((_, { parent }) => ({
        component: ConsumerInput,
        meta: {
            title: 'Consumer',
            disabled: !!parent.id,
        },
    }))
    public consumer!: Consumer;

    public authProvider?: AuthProvider;
    
    @Input({
        component: TextInput,
        meta: { 
            title: <Label k="firstName" />, 
            required: true
        }
    })
    public firstName!: string;
    
    @Input({
        component: TextInput,
        meta: { 
            title: <Label k="lastName" />, 
            required: true 
        }
    })
    public lastName!: string;

    @Input({
        component: TextInput,
        meta: { 
            title: <Label k="displayName" />
        }
    })
    public displayName?: string;

    public readonly fullName?: string;
    
    @Input({
        component: TextInput,
        meta: { 
            title: <Label k="email" />
        }
    })
    public email!: string;

    @Input({
        component: TextInput,
        meta: { 
            title: 'Phone #',
        },
    })
    public phone?: string;
    
    @Input({
        component: SwitchInput,
        meta: { 
            title: <Label k="internal" />
        }
    })
    public internal?: boolean;
    
    @Input({
        component: TextInput,
        meta: { 
            title: <Label k="externalId" />
        },
        inputProps: {
            maxLength: 128
        }
    })
    public externalId?: string;

    @Input({
        component: FileInput,
        meta: {
            title: <Label k="avatar" />
        },
        inputProps: {
            accept: "img/*"
        }
    })
    public avatar?: FileData;
    
    public lastLoginDate?: string;
    public verification?: 'PENDING' | 'SUCCESS' | 'FAILURE';
    public abilities?: any; // TODO fix typing compatibility wity casl library

    @Input({
        component: LocaleInput,
        meta: {
            title: <Label k="locale" />
        }
    })
    public locale?: string;

    @Input({
        component: TimeZoneInput,
        meta: {
            title: <Label k="timeZone" />
        }
    })
    public timeZone?: string;

    @Input({
        component: RoleMultiInput,
        meta: {
            title: <Label k="roles" />
        }
    })
    public roles?: string[];

    @Input({
        component: RawJson,
        meta: {
            title: 'Properties',
            disabled: true,
        },
    })
    public properties?: Record<string, unknown>

    @Input({
        component: TextInput,
        meta: {
            title: <Label k='username' />
        }
    })
    public username?: string;

    @Input({
        component: PasswordInput,
        meta: {
            title: <Label k="password" />
        },
        inputProps: {
            newPassword: true
        }
    })
    public password?: string;

    @Input({
        component: PasswordInput,
        meta: {
            title: <Label k="password" />
        },
        inputProps: {
            newPassword: true
        }
    })
    public passwordConfirm?: string;

    public lastActiveDate?: string;

}
