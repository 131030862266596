import { anyEditor, createEntitySection, createFormAction } from "../../core/admin/AdminSection";
import SwitchInput from "../../form/inputs/SwitchInput";
import Collector from "../../model/Collector";
import CollectorService from "../../services/CollectorService";

export default createEntitySection<Collector>({

    model: Collector,

    getId: o => o.id,
    label: 'name',

    ...CollectorService,

    columns: [
        {
            key: 'name',
            sort: 'name'
        },
        {
            key: 'externalId',
            sort: 'externalId',
        },
        {
            key: '_default',
            sort: '_default',
            renderCell: ({ appContext, data, reload }) => (
                <SwitchInput 
                    value={Boolean(data._default)}
                    onChange={_default => CollectorService.update({...data, _default}).then(reload)}
                    disabled={!anyEditor(appContext)}
                />
            )
        }
    ],
    editEnabled: ({ appContext }) => anyEditor(appContext),
    actions: [
        createFormAction({
            key: 'create',
            clazz: Collector,
            enabled: (_, appContext) => anyEditor(appContext),
            onSubmit: async data => {
                await CollectorService.create(data);
                return true;
            }
        }),
    ],

});
