import { Input } from "react-ts-form";
import Label from "../core/Label";
import DropDownChoiceInput from "../form/inputs/DropDownChoiceInput";
import { conditionRegistry } from "../registries";
import { Shuttle } from "../types";

type WrapperOperator = 'AND' | 'OR' | 'XOR' | 'NONE';

class WrapperCondition {
    
    @Input({
        component: DropDownChoiceInput.of<WrapperOperator>({
            options: ['AND', 'OR', 'XOR', 'NONE']
        }),
        meta: {
            title: <Label k="operator" />
        }
    })
    public operator!: WrapperOperator;

}

const wrapperCondition: Shuttle.Condition<WrapperCondition> = {
    name: 'condition.wrapper',
    ConfigClass: WrapperCondition,
    allowsChildren: true,
    evaluate: (condition, input) => {
        const count = condition.children?.length || 0;
        const matched = condition.children?.filter(c => conditionRegistry.get(c.type)?.evaluate(c, input)).length || 0;

        const operator = condition.config?.operator || 'AND';

        switch (operator) {
            case 'AND':
                return count === matched;
            case 'OR':
                return matched > 0;
            case 'XOR':
                return matched === 1;
            case 'NONE':
                return matched === 0;
            default:
                return false;
        }
    },
};

export default wrapperCondition;
