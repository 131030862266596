import { useState, useEffect } from "react";

export default function useObjectURL(file: File | null) {
    const [url, setUrl] = useState<undefined | string>(undefined);
    useEffect(() => {
        let objectUrl: string;
        if (file) {
            objectUrl = URL.createObjectURL(file);
            setUrl(objectUrl);
        }
        return () => { 
            if (objectUrl) {
                URL.revokeObjectURL(objectUrl);
            }
        }
    }, [file]);
    return url;
}
