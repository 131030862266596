import { CSSProperties, Fragment, useRef, useState } from "react";
import { Tabs, TabsProps, Tab, Box } from "@mui/material";

import IControlledProps from "../../model/IControlledProps";
import BrowserUtil from "../../util/BrowserUtil";

export interface ITab {
    key: string;
    title: React.ReactNode;
    content: React.ReactNode | (() => React.ReactNode);
    href?: string;
    target?: string;
    disabled?: boolean;
}

interface ITabViewProps extends IControlledProps<string> {
    // tabs to show. if less than 2, only render first tab's content?
    tabs: ITab[];

    // for accessibility
    idPrefix?: string;

    style?: CSSProperties;
    className?: string;

    panelStyle?: CSSProperties;

    // design stuff
    indicatorColor?: TabsProps["indicatorColor"];
    orientation?: TabsProps["orientation"];
    textColor?: TabsProps["textColor"];
}


export default function TabView({ className, idPrefix, indicatorColor, onChange, orientation, panelStyle, style, tabs = [], textColor, value }: ITabViewProps) {

    const ref = useRef<HTMLDivElement>(null);

    if (!tabs.length) {
        return null;
    }

    if (tabs.length === 1) {
        const first = tabs[0];
        if (first.disabled) {
            return null;
        }
        return (
            <Fragment>
                {typeof first.content === 'function' ? first.content() : first.content}
            </Fragment>
        );
    }

    style = {
        ...style || {},
        display: 'flex',
        flexDirection: orientation === 'vertical' ? 'row' : 'column',
        minWidth: '100%',
        width: '1px',
    }

    return (
        <div className={className} style={style} ref={ref}>
            <Tabs 
                textColor={textColor} 
                indicatorColor={indicatorColor}
                value={value}
                onChange={async (_: any, value: string) => {
                    if (ref.current) {
                        await BrowserUtil.closeContentFrames(ref.current)
                    }
                    const tab = tabs.find(t => t.key === value);
                    if (tab?.href) {
                        return; // don't navigate if this is an external link
                    }
                    onChange(value);
                }}
                orientation={orientation}
                sx={orientation === 'vertical' ? { flexShrink: 0 } : { width: '100%' }}
                variant="scrollable"
            >
                {
                    tabs.map(tab => {
                        if (tab.href) {
                            return (
                                <Tab
                                    key={tab.key}
                                    component="a"
                                    href={tab.href}
                                    target={tab.target ?? '_blank'}
                                    id={idPrefix ? `${idPrefix}_tab_${tab.key}` : undefined}
                                    label={tab.title}
                                    disabled={tab.disabled}
                                />
                            );
                        }
                        return (
                            <Tab
                                key={tab.key}
                                value={tab.key}
                                label={tab.title}
                                id={idPrefix ? `${idPrefix}_tab_${tab.key}` : undefined}
                                aria-controls={idPrefix ? `${idPrefix}_tabpanel_${tab.key}` : undefined}
                                disabled={tab.disabled}
                            />
                        );
                    })
                }
            </Tabs>
            <Box py={orientation === 'vertical' ? 0 : 2} px={orientation === 'vertical' ? 2 : 0} flexGrow={1}>
                {
                    tabs.map(tab => (
                        <div
                            key={tab.key}
                            hidden={tab.key !== value}
                            id={idPrefix ? `${idPrefix}_tabpanel_${tab.key}` : undefined}
                            aria-labelledby={idPrefix ? `${idPrefix}_tab_${tab.key}` : undefined}
                            style={panelStyle}
                        >
                            {
                                tab.key === value && !tab.disabled && (
                                    typeof tab.content === 'function' ? tab.content() : tab.content
                                )
                            }
                        </div>
                    ))
                }
            </Box>
        </div>
    );
}

TabView.Auto = function Auto(props: Omit<ITabViewProps, keyof IControlledProps<string>>) {
    const [selected, setSelected] = useState((props.tabs?.find(t => !t.disabled)?.key) || '0');
    return <TabView {...props} value={selected} onChange={setSelected} />;
}
