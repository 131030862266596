import { Suspense } from "react";
import { IInputProps } from "react-ts-form";
import { CodeEditor } from "../../lazy";
import { Shuttle } from "../../types";

interface CodeInputProps extends IInputProps<string> {
    height?: number | string;
    language?: string;
    onReady?: (control: Shuttle.CodeEditorControl) => void;
    wrap?: boolean;
}

export default function CodeInput({ disabled, height, language, onChange, onReady, value, wrap }: CodeInputProps) {

    return (
        <Suspense fallback="loading...">
            <CodeEditor 
                disabled={disabled}
                height={height}
                language={language}
                onChange={onChange}
                onReady={onReady}
                value={value}
                wrap={wrap}
            />
        </Suspense>
    );
}
