import { useContext } from "react";
import { Input } from "react-ts-form";

import { createComponentSection } from "../../core/admin/AdminSection";
import Label from "../../core/Label";
import { LocaleMultiInput, ThemeInput, TimeZoneInput } from "../../form/common";
import DecoratorForm from "../../form/DecoratorForm";
import TenantInfo from "../../model/TenantInfo";
import ThemeData from "../../model/ThemeData";
import TenantService from "../../services/TenantService";
import { AppContext } from "../../core/AppContext";

class TenantEditData extends TenantInfo {

    @Input({
        component: ThemeInput,
        meta: {
            title: <Label k="theme" />
        }
    })
    public theme?: ThemeData;

    @Input({
        component: LocaleMultiInput,
        meta: {
            title: <Label k="locale" />
        }
    })
    public locale!: string[];

    @Input({
        component: TimeZoneInput,
        meta: {
            title: <Label k="timeZone" />
        }
    })
    public timeZone!: string;

}

function TenantSettings() {
    const [appContext, setAppContext] = useContext(AppContext);

    return (
        <DecoratorForm
            clazz={TenantEditData}
            initialValue={appContext.tenant}
            onSubmit={
                async data => (await TenantService.saveSettings(data)).data
            }
            afterSubmit={
                tenant => setAppContext(prev => ({...prev, tenant}))
            }
        />
    );
}

export default createComponentSection({ component: TenantSettings, });
