import { Alert, AlertProps, Snackbar } from "@mui/material";
import { useCallback, useContext, useMemo, useState } from "react";
import * as React from "react";

import ArrayUtil from "../util/ArrayUtil";

interface IFeedbackMessage {
    intent?: AlertProps['severity'];
    content: React.ReactNode | ((close: () => void) => React.ReactNode);
}

interface IFeedbackContext {
    push(...messages: IFeedbackMessage[]): void;
}

const FeedbackContext = React.createContext<IFeedbackContext>({push() {}});

export function useFeedbackContext() {
    return useContext(FeedbackContext);
}

interface IFeedbackProviderProps {
}

export function FeedbackProvider(props: React.PropsWithChildren<IFeedbackProviderProps>) {
    const [queue, setQueue] = useState<IFeedbackMessage[]>([]);

    const push = useCallback(async (...messages: IFeedbackMessage[]) => {
        setQueue(prev => [...messages, ...prev]);
    }, []);

    const ctx = useMemo(() => ({ push }), [push]);

    const close = useCallback(() => setQueue(prev => prev.length ? prev.slice(0, prev.length - 1) : []), []);

    const current = ArrayUtil.last(queue);

    return (
        <FeedbackContext.Provider value={ctx}>
            {props.children}
            <Snackbar anchorOrigin={{horizontal: 'left', vertical: 'bottom'}} open={!!current} onClose={close} autoHideDuration={4000}>
                <Alert onClose={close} severity={current?.intent} variant="filled">
                    {
                        current && (
                            typeof current.content === 'function' ? current.content(close) : current.content
                        )
                    }
                </Alert>
            </Snackbar>
        </FeedbackContext.Provider>
    );
}
