import ContributorData from "../model/ContributorData";
import createEntityService from "./createEntityService";
import UploadService from "./UploadService";

async function beforeSave(data: ContributorData) {
    if (data.avatar?.file) {
        data = {...data, avatar: await UploadService.get().upload(data.avatar.file)};
    }
    return data;
}

export default createEntityService<ContributorData>({ 
    path: '/contributors',
    beforeCreate: beforeSave,
    beforeUpdate: beforeSave,
});
