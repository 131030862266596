import { createContext, Dispatch, SetStateAction } from "react";
import { Shuttle } from "./types";
import Constants from "./util/Constants";

export interface SessionContextValue {
    session: null | Shuttle.PlayEntryPointData;
    setSession: Dispatch<SetStateAction<Shuttle.PlayEntryPointData>>;
    closeSession: () => void;
}

export const SessionContext = createContext<SessionContextValue>({
    session: null,
    setSession: () => console.warn('used outside of session'),
    closeSession: () => console.warn('used outside of session'),
});

export const SessionEntryContext = createContext<null | Shuttle.PlaySessionEntryData>(null);

export const ConstraintsContext = createContext<Shuttle.Constraints>({
    alternatives: {},
    hidden: [],
    locked: {},
});

export const ContentContext = createContext<null | Shuttle.ContentNode>(null);

export interface ContainerNav {
    activePath: string;
    setActivePath: (activePath: string) => void;
}

export const ContainerNavContext = createContext<null | ContainerNav>(null);

export const TrackingContext = createContext<Shuttle.TrackingContextValue>({ 
    tracking: {},
    commit: data => {
        console.error('tracking not initialized! payload: ', data);
    },
    events: events => {
        console.error('tracking not initialized! payload: ', events);
    },
});

export type SettingsContextValue = readonly [Shuttle.Settings, Dispatch<SetStateAction<Shuttle.Settings>>]

export const SettingsContext = createContext<SettingsContextValue>([
    {
        mode: 'auto',
        locale: 'en',
        timeZone: 'America/Chicago',
    },
    () => {
        console.error('tried to update settings outside of stateful context');
    }
]);

export const GlossaryContext = createContext<Shuttle.GlossaryContextValue>({
    terms: [],
})

export interface BookmarksContextValue {
    bookmarks: Shuttle.BookmarkData[]
    save: (bookmark: Shuttle.BookmarkData) => void
    remove: (bookmarkId: number) => void
    downloadPdf?: (contentIds?: number[], fileName?: string) => void
}

export const BookmarksContext = createContext<BookmarksContextValue>({
    bookmarks: [],
    save: Constants.NO_OP_FUNC,
    remove: Constants.NO_OP_FUNC,
    downloadPdf: Constants.NO_OP_FUNC,
})

export const VIDEO_PLAYERS: videojs.VideoJsPlayer[] = [];
