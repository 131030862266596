import { useState, useEffect } from "react";
import * as React from "react";
import { AppBar, Box, Link, Toolbar, IconButton, Drawer, Theme, useMediaQuery, Divider } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { visuallyHidden } from '@mui/utils';

import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";

import Label from "./Label";
import useId from "../hooks/useId";
import LabelledIconButton from "./ui/LabelledIconButton";

interface ILayoutProps {
    drawer?: React.ReactNode;
    rightElement?: React.ReactNode;
    size?: 'Normal' | 'Small' | 'Large' | 'Fluid';
    noPadding?: boolean;
    fullscreen?: boolean; // 100vh flex column for main content. allows you to position children vertically with flex-grow
    onClickLogo?: () => void;
}

const drawerWidth = 300;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' && prop !== 'matches' && prop !== 'fullscreen' && prop !== 'drawer' })<{
  open?: boolean; matches?: boolean; fullscreen?: boolean; drawer?: boolean;
}>(({ theme, open, matches, fullscreen, drawer }) => ({
  flexGrow: 1,
  padding: fullscreen ? 0 : theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  minHeight: '100vh',
  maxWidth: '100%',
  marginLeft: drawer && matches ? `-${drawerWidth}px` : 0,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
  ...(fullscreen && {
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column',
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function Layout(props: React.PropsWithChildren<ILayoutProps>) {
    const hasDrawer = !!props.drawer;
    const theme = useTheme();
    const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
    const [open, setOpen] = useState(matches && hasDrawer);
    const anchor = useId();
    const sideAnchor = anchor + "_sidebar";

    const { onClickLogo } = props;

    // auto expand or hide sidebar when resizing the window
    useEffect(() => setOpen(matches && hasDrawer), [setOpen, matches, hasDrawer]);

    return (
      <Box sx={{ display: 'flex' }}>
        <Link sx={visuallyHidden} href={`#${anchor}`}>
          <Label k="sr.skip.content" />
        </Link>
        {
          hasDrawer && (
            <Link sx={visuallyHidden} href={`#${sideAnchor}`}>
              <Label k="sr.skip.sidebar" />
            </Link>
          )
        }
        <AppBar position="fixed" color={theme.headerColor || 'default'} variant="outlined" elevation={0} sx={{ zIndex: matches ? theme.zIndex.drawer + 1 : undefined }}>
          <Toolbar>
            {
                hasDrawer && (
                  <Label k="toggle.drawer">
                    {
                      (toggleLabel) => (
                        <IconButton
                          color="inherit"
                          aria-label={toggleLabel}
                          onClick={() => setOpen(!open)}
                          edge="start"
                        >
                            <MenuIcon />
                        </IconButton>
                      )
                    }
                  </Label>
                )
            }
            <Box
              {
                ...onClickLogo ? {
                  onClick: () => onClickLogo(),
                  role: 'button',
                } : {}
              }
              sx={{
                alignSelf: 'stretch',
                cursor: onClickLogo ? 'pointer' : 'default',
                backgroundImage: `url('${theme.logoUrl}')`,
                backgroundPosition: 'center left',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                margin: theme.spacing(1, 0),
                width: '210px'
              }}
            />
            <div style={{flexGrow: 1}} />
            {props.rightElement}
          </Toolbar>
        </AppBar>
        {
          props.drawer && (
            <Drawer 
              anchor="left"
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                  width: drawerWidth,
                  boxSizing: 'border-box',
                },
              }}
              id={sideAnchor}
              open={open} 
              onClose={() => setOpen(false)}
              variant={matches ? 'persistent' : 'temporary'}
          >
            <DrawerHeader>
              {
                !matches && (
                  <LabelledIconButton 
                    k="close"
                    icon={CloseIcon}
                    onClick={() => setOpen(false)}
                  />
                )
              }
            </DrawerHeader>
            <Divider />
            {props.drawer}
          </Drawer>
          )
        }
        <Main open={open} matches={matches} fullscreen={props.fullscreen} drawer={!!props.drawer}>
          <DrawerHeader />
          <div
            style={{
              margin: '0 auto',
              maxWidth: (
                props.fullscreen || props.size === 'Fluid' ? '100%' :
                props.size === 'Large' ? '1440px' :
                props.size === 'Small' ? '980px' :
                '1200px'
              ),
              flexGrow: 1,
              display: 'flex',
              alignItems: 'stretch',
              flexDirection: 'column',
              width: '100%',
            }}
            id={anchor}
          >
            {props.children}
          </div>
        </Main>
      </Box>
    );
}
