import { useContext, useMemo } from "react";
import * as React from "react";

interface IAncestorProviderProps<T> {
    context: React.Context<T[]>;
    value: T;
}

export default function AncestorProvider<T>({ children, context, value }: React.PropsWithChildren<IAncestorProviderProps<T>>) {
    const ancestorValues = useContext(context);
    const next = useMemo(() => [...(ancestorValues || []), value], [ancestorValues, value]);
    return (
        <context.Provider value={next}>
            {children}
        </context.Provider>
    );
}
