import { Shuttle } from "../../types";
import AiccIcon from "@mui/icons-material/AirplanemodeActive";
import Embed from "../Embed";
import { Fieldsets, Input } from "react-ts-form";
import TextInput from "../../form/inputs/TextInput";
import Label from "../../core/Label";

@Fieldsets({
    aiccsettings: <Label k="aiccsettings" />
})
class AiccConfig {

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="launch.uri" />,
            required: true
        },
        fieldset: "aiccsettings"
    })
    public uri?: string;

}

export const AiccLauncher: Shuttle.Launcher = {
    name: 'launcher.aicc',
    Icon: AiccIcon,
    Component: Embed,
    ConfigClass: AiccConfig
};
