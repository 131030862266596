import { RouteChildrenProps } from "react-router-dom";
import { BookmarksContext, ConstraintsContext, SessionContext, SettingsContext } from "../contexts";
import { useEffect, useMemo, useState } from "react";
import { ListenerContext, PopupController, resolvePath } from "./utils";
import UrlUtil from "../util/UrlUtil";
import StringUtil from "../util/StringUtil";
import PlayContent from "./PlayContent";
import { Alert } from "@mui/material";

export default function PlayPopup({ location }: RouteChildrenProps) {

    const [state, setState] = useState<null | ListenerContext>(null);

    const closeConnection = useMemo(() => PopupController.subscribeToOpener(setState), []);

    useEffect(() => {
        window.addEventListener('beforeunload', closeConnection);
        return () => {
            window.removeEventListener('beforeunload', closeConnection);
            closeConnection();
        }
    }, [closeConnection]);

    // subscribe to all state changes from opener window
    useEffect(() => PopupController.subscribeToOpener(setState), []);

    if (!state) {
        return (
            <>
                loading...
            </>
        );
    }
    
    // extract target content path from query params
    const query = UrlUtil.parseQueryString(location.search);
    const path = StringUtil.safeGetFirst(query['path']);

    // find target content node
    const contentPath = resolvePath(state.session.session!.root, path);
    const target = contentPath?.length ? contentPath[contentPath.length - 1] : null;

    return (
        <SettingsContext.Provider value={state.settings}>
            <SessionContext.Provider value={state.session}>
                <ConstraintsContext.Provider value={state.constraints}>
                    <BookmarksContext.Provider value={state.bookmarks}>
                        {
                            target ? (
                                <PlayContent contentNode={target.contentNode} />
                            ) : (
                                <Alert severity="error">
                                    Invalid Path
                                </Alert>
                            )
                        }
                    </BookmarksContext.Provider>
                </ConstraintsContext.Provider>
            </SessionContext.Provider>
        </SettingsContext.Provider>
    );
}
