import { PropsWithChildren, useMemo } from "react";
import { GlossaryContext } from "../contexts";
import { Shuttle } from "../types";
import StringUtil from "../util/StringUtil";

export default function GlossaryProvider({ children, terms }: PropsWithChildren<{
    terms: Shuttle.GlossaryContextValue['terms'];
}>) {
    const value = useMemo(() => ({
        terms,
        regex: terms.length 
            ? new RegExp('(' + terms.map(({ name }) => '\\b' + StringUtil.escapeRegExp(name) + '\\b').join('|') + ')', 'gi') 
            : undefined,
    }), [terms]);
    return (
        <GlossaryContext.Provider value={value}>
            {children}
        </GlossaryContext.Provider>
    );
}
