import { Box, CircularProgress, CircularProgressProps } from "@mui/material";
import { PropsWithChildren } from "react";

// wrap mui progress circle to have a background track
export default function ProgressCircle({ children, color, size = 24, sx = {}, value = 0, ...rest }: PropsWithChildren<CircularProgressProps>) {
    return (
        <Box
            sx={{
                position: 'relative',
                width: size,
                height: size,
                ...sx,
            }}
        >
            <CircularProgress
                {...rest}
                size={size}
                sx={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    color: theme => theme.palette.grey[theme.palette.mode === 'dark' ? 800 : 200],
                    
                }}
                value={100}
                aria-hidden
            />
            <CircularProgress
                {...rest}
                size={size}
                sx={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                }}
                color={color}
                value={value}
            />
            {children}
        </Box>
    );
}
