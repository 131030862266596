import { adminOnly, createEntitySection, createFormAction } from "../../core/admin/AdminSection";
import SwitchInput from "../../form/inputs/SwitchInput";
import ContentProvider from "../../model/ContentProvider";
import ContentProviderService from "../../services/ContentProviderService";

export default createEntitySection({
    model: ContentProvider,
    getId: o => o.id,
    label: o => o.name,
    ...ContentProviderService,
    columns: [
        {
            key: 'name',
            sort: 'name',
        },
        {
            key: 'externalId',
            sort: 'externalId',
        },
        {
            key: 'type',
            sort: 'type.name',
            renderCell: ({ data }) => data.type.name,
        },
        {
            key: 'enabled',
            sort: 'enabled',
            renderCell({appContext, data, reload}) {
                return (
                    <SwitchInput 
                        value={!!data.enabled}
                        onChange={
                            enabled => ContentProviderService.update({...data, enabled}).then(reload)
                        }
                        disabled={!adminOnly(appContext)}
                    />
                );
            }
        },
    ],
    editEnabled: ({ appContext }) => adminOnly(appContext),
    actions: [
        createFormAction({
            key: 'create',
            clazz: ContentProvider,
            onSubmit: async data => {
                await ContentProviderService.create(data);
                return true;
            },
            enabled: (_, appContext) => adminOnly(appContext),
        }),
    ],
});
