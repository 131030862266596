import { Dialog, DialogContent } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Input } from "react-ts-form";
import Label from "../core/Label";
import DurationInput from "../form/inputs/DurationInput";
import LangStringInput from "../form/inputs/LangStringInput";
import SwitchInput from "../form/inputs/SwitchInput";
import bestLocaleMatch from "../i18n/util";
import LayoutString from "../layout/LayoutString";
import { Shuttle } from "../types";

class VisibilityTimeoutConfig {

    @Input({
        component: DurationInput,
        meta: {
            title: <Label k="duration" />,
            required: true,
        }
    })
    public duration?: number; //seconds

    @Input({
        component: LangStringInput,
        meta: {
            title: <Label k="text" />
        }
    })
    public message?: Shuttle.LangString

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="unreliablyObserveActivity" />
        }
    })
    public unreliablyObserveActivity?: boolean;

    @Input({
        component: DurationInput,
        meta: {
            title: <Label k="warningDuration" />
        }
    })
    public warningDuration?: number; //seconds

    @Input({
        component: LangStringInput,
        meta: {
            title: <Label k="warning" />
        },
    })
    public warningMessage?: Shuttle.LangString

}

const VisibilityTimeoutBehavior: Shuttle.BehaviorType<VisibilityTimeoutConfig> = {
    name: 'behavior_visibility_timeout',
    ConfigClass: VisibilityTimeoutConfig,

    BodyComponent({ 
        config, 
        closeSession, 
        locale 
    }) {

        const [open, setOpen] = useState(false);

        const timer = useRef<any>(0);
        const warningTimer = useRef<any>(0);

        useEffect(() => {
            let {
                duration = 0,
                message,
                unreliablyObserveActivity,
                warningDuration = 0,
            } = config;
            if (duration && !isNaN(duration) && duration > 0) {

                const close = () => {
                    closeSession((message && (
                        <span>
                            {bestLocaleMatch(message ?? {}, locale) || message}
                        </span>
                    )) || undefined)
                }

                const startTimeout = () => {
                    if (timer.current) clearTimeout(timer.current);
                    if (warningTimer.current) clearTimeout(warningTimer.current);
                    setOpen(false);
                    const timerMs = duration * 1000;
                    const warningMs = (isNaN(warningDuration) || warningDuration < 0 ? 0 : warningDuration) * 1000;
                    timer.current = setTimeout(close, timerMs);
                    if (timerMs > warningMs && unreliablyObserveActivity) {
                        warningTimer.current = setTimeout(() => setOpen(true), timerMs - warningMs);
                    }
                }

                if (unreliablyObserveActivity) {
                    startTimeout();
                    const events = ['click', 'mousemove', 'scroll'];
                    events.forEach(event => window.addEventListener(event, startTimeout));
                    return () => {
                        events.forEach(event => window.removeEventListener(event, startTimeout));
                    }
                }

                document.addEventListener('visibilitychange', startTimeout);
                return () => {
                    document.removeEventListener('visibilitychange', startTimeout);
                }
            }
        }, [config, closeSession, locale]);

        useEffect(() => {
            clearTimeout(timer.current);
            clearTimeout(warningTimer.current);
        }, []);

        if (config.unreliablyObserveActivity) {
            return (
                <Dialog open={open}>
                    <DialogContent>
                        <LayoutString text={config.warningMessage} />
                    </DialogContent>
                </Dialog>
            );
        }

        return null;
    }
};

export default VisibilityTimeoutBehavior;
