import * as React from "react";

import { ValidLabelKey, ValidLabelArg, isValidLabelKey } from "../core/localization/ValidLabelKey";
import Label from "../core/Label";
import SearchResult from "../model/SearchResult";
import { FilteredKeys } from "../util/types";
import SearchParams from "../model/SearchParams";
import Registry from "../util/Registry";

interface IBaseOptionsProps<T> {
    choiceRenderer?: IChoiceRenderer<T>;
    optionEnabled?: (o: T) => boolean;
}

/**
 * for components that need to populate their
 * options list immediately, e.g. a Radio Group.
 */
export interface IOptionsProps<T> extends IBaseOptionsProps<T> {
    options: T[];
}

/**
 * for components that could lazy load their options list,
 * e.g. a Popover menu (doesn't show the options until you interact with it).
 */
export interface ILazyOptionsProps<T, U extends SearchParams = SearchParams> extends IBaseOptionsProps<T> {
    options: T[] | ((params: U) => Promise<SearchResult<T>>);
}

/**
 * determines how to compare and display values
 * in options-based form components.
 */
export interface IChoiceRenderer<T> {
    getKey(o: T): React.Key;
    getLabel(o: T): React.ReactNode;
    getSubtitle?: (o: T) => React.ReactNode;
    equals(left: T, right: T): boolean;
}

/**
 * fallback choice renderer that uses reference equivalence.
 */
export const DefaultChoiceRender: IChoiceRenderer<any> = {
    getKey(o) {
        return o + "";
    },
    getLabel(o) {
        return o;
    },
    equals: Object.is
};

export const LabelKeyChoiceRenderer: IChoiceRenderer<ValidLabelKey | string> = {
    ...DefaultChoiceRender,
    getLabel(k) {
        if (isValidLabelKey(k)) {
            return React.createElement(Label, { k });
        }
        return k;
    }
};

export class PropertyChoiceRender<T extends object, K extends FilteredKeys<T, string | number> = any> implements IChoiceRenderer<T> {
    
    public constructor(
        private property: K
    ) {}

    public getKey(o: T) {
        return o[this.property] as unknown as (string | number); // ??
    }

    public getLabel(o: T) {
        return o?.[this.property];
    }

    public equals(left: T, right: T) {
        return left?.[this.property] === right?.[this.property];
    }
}

export const DefaultLabeledChoiceRenderer: IChoiceRenderer<{ label: ValidLabelKey; value: string | number; args?: { [name: string]: ValidLabelArg; } } & any> = {
    getKey({value}) {
        return value;
    },
    getLabel(o) {
        return o && React.createElement(Label, { k: o.label, args: o.args });
    },
    equals: Object.is
};

export class RegistryKeyChoiceRenderer implements IChoiceRenderer<string> {

    public constructor(
        private registry: Registry<any>
    ) {}

    public getKey(o: string) {
        return o;
    }

    public getLabel(o: string): React.ReactNode {
        if (this.registry.has(o)) {
            return React.createElement(Label, { k: this.registry.get(o).name });
        }
        return o;
    }

    public equals = Object.is;

}
