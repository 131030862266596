
export default function bestLocaleMatch<V>(
    obj: { [locale: string]: V } | undefined, 
    locale: string,
) {
    if (!obj) return null
    locale = locale.toLowerCase()
    return obj[locale]
        ?? obj[locale.substring(0, 2)]
        ?? obj[Object.keys(obj).find(k => k.startsWith(locale)) ?? '']
        ?? obj.en_US
        ?? obj.en
        ?? obj.x
        ?? null
}
