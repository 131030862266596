import { Shuttle } from "../../types";
import { Box, Chip } from "@mui/material";
import TagIcon from "@mui/icons-material/LocalOffer";
import { useContext } from "react";
import { ChildrenListContext } from "./ContentChildrenListElement";
import { ConstraintsContext, ContentContext, SettingsContext } from "../../contexts";
import { getAlternative } from "../../player/utils";
import bestLocaleMatch from "../../i18n/util";

class ContentTagsConfig {

}

const ContentTagsElement: Shuttle.LayoutElementType = {
    name: "layout_content_tags",
    Icon: TagIcon,
    ConfigClass: ContentTagsConfig,
    Component() {

        const [{ locale }] = useContext(SettingsContext);

        const contentNode = useContext(ContentContext);
        const constraints = useContext(ConstraintsContext);
        const listContext = useContext(ChildrenListContext);

        if (!contentNode) return null;

        const content = getAlternative(constraints, contentNode);

        if (!content?.tags?.length) return null;

        return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', m: -0.5 }}>
                {
                    content.tags.map(tag => (
                        <Chip 
                            key={tag.name}
                            label={bestLocaleMatch({ en: tag.name, ...tag.displayName ?? {} }, locale)}
                            onClick={listContext ? () => listContext.paramsDispatch({ type: 'TOGGLE_TAG', tag: tag.name }) : undefined}
                            color={listContext?.params.tags?.includes(tag.name) ? 'primary' : 'default'}
                            size='small'
                            disabled={listContext?.loading}
                            sx={{ m: .5 }}
                        />
                    ))
                }
            </Box>
        );
    }
};

export default ContentTagsElement;
