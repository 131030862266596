import { Shuttle } from "../../types";
import Icon from "@mui/icons-material/ReadMore";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Paper, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { GlossaryContext, SettingsContext } from "../../contexts";
import TextInput from "../../form/inputs/TextInput";
import Label from "../../core/Label";
import StringUtil from "../../util/StringUtil";
import RunningText from "../../core/ui/RunningText";
import { Input } from "react-ts-form";
import SwitchInput from "../../form/inputs/SwitchInput";
import ExpandMore from "@mui/icons-material/ExpandMore";

class ConfigClass {

    @Input({
        component: SwitchInput,
        meta: {
            title: 'Make terms collapsible',
            description: 'useful if you have long definitions for each term.',
        },
    })
    public collapsible?: boolean;

}

export const ContentGlossaryElement: Shuttle.LayoutElementType<ConfigClass> = {
    name: 'layout_content_glossary',
    Icon,
    ConfigClass,
    Component: ({ config }) => {

        const [settings] = useContext(SettingsContext)

        const [search, setSearch] = useState("");

        let { terms } = useContext(GlossaryContext);

        terms = terms.filter(({ locale }) => !locale || settings.locale.startsWith(locale))

        if (search) {
            terms = terms.filter(({ name, alternatives }) => (name + ' | ' + (alternatives || '')).toLowerCase().includes(search.toLowerCase()));
        }

        const searchRegExp = search ? new RegExp(StringUtil.escapeRegExp(search), 'gi') : null;

        return (
            <>
                <Box sx={{ mb: 1 }}>
                    <TextInput value={search} onChange={setSearch} placeholderKey="search" autoFocus />
                </Box>
                <Box sx={{ '& .match': { color: 'red' } }}>
                    {
                        terms.length ? terms.map(({ id, name, definition }) => {
                            const title = <span dangerouslySetInnerHTML={{__html: searchRegExp ? name.replace(searchRegExp, '<span class="match">$&</span>') : name}} />;
                            const body = <RunningText html={definition} />;
                            if (config.collapsible) {
                                return (
                                    <Accordion key={id}>
                                        <AccordionSummary expandIcon={<ExpandMore />}>
                                            {title}
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {body}
                                        </AccordionDetails>
                                    </Accordion>
                                );
                            }
                            return (
                                <Paper sx={{ my: 1, p: 1 }} key={id}>
                                    <Typography variant="h6">
                                        {title}
                                    </Typography>
                                    {body}
                                </Paper>
                            );
                        }) : (
                            <Alert severity="info">
                                <Label k="no_results" />
                            </Alert>
                        )
                    }
                </Box>
            </>
        );
    },
};
