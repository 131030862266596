import { Button } from "@mui/material";
import { Input } from "react-ts-form";
import DropDownChoiceInput from "../form/inputs/DropDownChoiceInput";
import TextInput from "../form/inputs/TextInput";
import { Shuttle } from "../types";

const operators = {
    BOOLEAN: ['EQ', 'NEQ'],
    NUMBER: ['LT', 'LE', 'EQ', 'GE', 'GT'],
    STRING: ['EQ', 'STARTS_WITH', 'ENDS_WITH', 'CONTAINS', 'EMPTY', 'NOT_EMPTY', 'IN'],
    DATE: ['LT', 'LE', 'EQ', 'GE', 'GT'],
    WRAPPER: ['AND', 'OR', 'XOR', 'NONE'],
}

export default class Rule implements Shuttle.Rule {

    @Input({
        component: DropDownChoiceInput,
        meta: {
            title: 'Type',
        },
        inputProps: {
            options: ['BOOLEAN', 'NUMBER', 'STRING', 'DATE', 'WRAPPER'],
        },
    })
    public type: Shuttle.Rule['type'] = 'WRAPPER';

    @Input((_, { parent }) => {
        const type: undefined | Shuttle.Rule['type'] = parent?.type;
        if (!type || type === 'WRAPPER') return {};
        return {
            component: TextInput,
            meta: {
                title: 'Path',
                description: 'JSON path to target value. use $.type to access event type discriminator, $.payload.<field> to access type-specific fields.',
            },
        };
    })
    public path?: string;

    @Input((_, { parent }) => {
        const type: undefined | Shuttle.Rule['type'] = parent?.type;
        if (!type) return {};
        return {
            component: DropDownChoiceInput,
            meta: {
                title: 'Operator',
                required: true,
            },
            inputProps: {
                options: operators[type],
            },
        }
    })
    public operator?: Shuttle.Rule['operator'];

    @Input((_, { parent }) => {
        const type: undefined | Shuttle.Rule['type'] = parent?.type;
        if (!type || type === 'WRAPPER' || type === 'BOOLEAN') return {};
        return {
            component: TextInput,
            meta: {
                title: 'Value',
            },
        };
    })
    public value?: string;

    @Input((_, { parent }) => {
        const type: undefined | Shuttle.Rule['type'] = parent?.type;
        if (type !== 'WRAPPER') return {};
        return {
            clazz: Rule,
            array: {
                remove: true,
                addComponent: ({ onAdd }) => (
                    <Button
                        variant="contained"
                        size="small"
                        color="secondary"
                        onClick={() => onAdd([
                            {
                                type: 'WRAPPER',
                            },
                        ])}
                    >
                        Add Rule
                    </Button>
                )
            }
        }
    })
    public children?: Rule[];

}