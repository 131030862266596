import { Input } from "react-ts-form";
import TextInput from "../form/inputs/TextInput";
import SwitchInput from "../form/inputs/SwitchInput";
import DropDownChoiceInput from "../form/inputs/DropDownChoiceInput";
import Label from "../core/Label";
import TextAreaInput from "../form/inputs/TextAreaInput";
import { Button } from "@mui/material";

class NameValue {
 
    @Input({
        component: TextInput,
        meta: {
            title: "Name",
            required: true,
        }
    })
    public name!: string;

    @Input({
        component: TextInput,
        meta: {
            title: "Value",
            required: true,
        }
    })
    public value!: string;

}

class SamlAuth {

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="url" />,
            required: true,
        }
    })
    public loginUrl?: string;

    @Input({
        component: TextAreaInput,
        meta: {
            title: <Label k="saml_idp_cert" />,
            //required: true,
        }
    })
    public idpCert?: string;

    @Input((_, { parent }) => {
        if (parent.spCert) {
            return {
                component: TextAreaInput,
                meta: {
                    title: <Label k="saml_sp_cert" />,
                    disabled: true,
                }
            }
        }
        return {};
    })
    public spCert?: string;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="saml_sp_initiated" />
        }
    })
    public spInitiated?: boolean;

    @Input((_, { parent }) => {

        if (!parent.spInitiated) {
            return {};
        }

        return {
            component: SwitchInput,
            meta: {
                title: <Label k="saml_sp_post" />
            }
        };
    })
    public spPost?: boolean;

    @Input((_, { parent }) => {

        if (!parent.spInitiated) {
            return {};
        }

        return {
            component: SwitchInput,
            meta: {
                title: <Label k="saml_want_authn_request_signed" />
            }
        };
    })
    public wantAuthnRequestsSigned?: boolean;

    @Input((_, { parent }) => {

        if (!parent.spInitiated) {
            return {};
        }

        return {
            component: TextInput,
            meta: {
                title: <Label k="saml_destination" />
            }
        };
    })
    public destination?: string;

    @Input({
        component: TextInput,
        meta: {
            title: 'Logout Redirect',
        },
    })
    public logoutRedirect?: string;

    @Input({
        component: TextInput,
        meta: {
            title: 'ID Attribute',
        }
    })
    public idAttr?: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="saml_first_name_attr" />
        }
    })
    public firstNameAttr?: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="saml_last_name_attr" />
        }
    })
    public lastNameAttr?: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="saml_email_attr" />
        }
    })
    public emailAttr?: string;

}

class OidcAuth {

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="oidc_authorization_location" />
        }
    })
    public authorizationLocation?: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="oidc_token_location" />
        }
    })
    public tokenLocation?: string;

    @Input({
        component: TextInput,
        meta: {
            title: 'Logout Redirect',
        },
    })
    public logoutRedirect?: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="oidc_client_id" />
        }
    })
    public clientId?: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="oidc_secret" />
        }
    })
    public secret?: string;

}

class CasAuth {

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="cas_login_url" />,
            required: true,
        }
    })
    public loginUrl!: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="cas_service_validate_url" />,
            required: true,
        }
    })
    public serviceValidateUrl!: string;

    @Input({
        component: TextInput,
        meta: {
            title: 'Logout Redirect',
        },
    })
    public logoutRedirect?: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="cas_id_attr" />,
        }
    })
    public idAttr?: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="cas_first_name_attr" />
        }
    })
    public firstNameAttr?: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="cas_last_name_attr" />
        }
    })
    public lastNameAttr?: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="cas_email_attr" />
        }
    })
    public emailAttr?: string;

}

class EncryptedParameterAuth {

    
    @Input({
        component: TextInput,
        meta: {
            title: "Login URL",
            required: true,
        }
    })
    public loginUrl!: string;

    @Input({
        component: DropDownChoiceInput.of({
            options: ['AES_256', 'RIJNDAEL_128'],
        }),
        meta: {
            title: "Mode",
            required: true,
        }
    })
    public mode!: string;

    @Input({
        component: TextInput,
        meta: {
            title: "Data Param Name",
            description: '(default is "d")',
        },
    })
    public dataParam!: string;

    @Input({
        component: TextInput,
        meta: {
            title: "State Param Name",
            description: '(default is "s")',
        },
    })
    public stateParam!: string;

    @Input({
        component: TextInput,
        meta: {
            title: "Timestamp Field Name",
            description: 'if specified, this field in the payload will be checked to make sure it is set to within 30 seconds of time of access.',
        },
    })
    public timestampName!: string;

    @Input({
        component: TextInput,
        meta: {
            title: "Course ID Field Name",
            description: 'if specified, user will be auto-redirected to this course.',
        },
    })
    public courseIdName!: string;

    @Input({
        clazz: NameValue,
        array: {
            sort: true,
            remove: true,
            addComponent: ({ onAdd }) => (
                <Button size="small" color="secondary" onClick={() => onAdd([{ name: 'firstName', value: '' }])}>
                    Add Extractor
                </Button>
            )
        },
        meta: {
            title: "Extractors"
        },
    })
    public extractors!: string;

    @Input({
        component: TextInput,
        meta: {
            title: "Secret Key",
        }
    })
    public secret!: string;

    @Input({
        component: TextInput,
        meta: {
            title: "IV",
        }
    })
    public iv?: string;

    @Input({
        component: TextInput,
        meta: {
            title: "Logout URL",
        }
    })
    public logoutUrl?: string;

}

const types: Record<string, any> = {
    cas: CasAuth,
    saml: SamlAuth,
    oidc: OidcAuth,
    enc: EncryptedParameterAuth,
};

export class AuthProvider {

    public id!: number;
    public uuid!: string;
    public createDate!: string;
    public updateDate!: string;

    @Input({
        component: DropDownChoiceInput,
        meta: {
            title: 'Type',
            required: true,
        },
        inputProps: {
            options: Object.keys(types).sort(),
        },
    })
    public type!: string;

    @Input({
        component: TextInput,
        meta: {
            title: 'Name',
            required: true,
        },
        inputProps: {
            maxLength: 255,
        }
    })
    public name!: string;

    @Input({
        component: TextInput,
        meta: {
            title: 'External ID',
        },
        inputProps: {
            maxLength: 128,
        }
    })
    public externalId?: string;

    @Input({
        component: SwitchInput,
        meta: {
            title: 'Enabled',
        }
    })
    public enabled?: boolean;

    @Input((_, { parent }) => ({
        clazz: types[parent.type],
    }))
    public config?: any = {};

}
