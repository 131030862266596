import { Fragment, useState } from "react";
import { Table, TableHead, TableRow, TableCell, TableBody, Box, Collapse, TableSortLabel, TablePagination, TableFooter, useTheme, TableContainer } from "@mui/material";
import { TablePaginationActionsProps } from "@mui/material/TablePagination/TablePaginationActions";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import IColumn from "../../model/IColumn";
import SearchParams from "../../model/SearchParams";
import StringUtil from "../../util/StringUtil";
import LabelledIconButton from "./LabelledIconButton";

interface ITableViewProps<T, P extends SearchParams> {
    columns: Array<IColumn<T>>;
    data: T[];
    total?: number;
    value?: P;
    onChange?: (params: P) => void;
    getKey(item: T, index: number): React.Key;
    expand?: (item: T, index: number, arr: T[]) => React.ReactNode;
    caption?: React.ReactNode;
    dense?: boolean;
}

function TablePaginationActions({ count, onPageChange, page, rowsPerPage }: TablePaginationActionsProps) {
    const theme = useTheme();

    const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box flexShrink={0} ml={2.5}>
            <LabelledIconButton 
                disabled={page === 0}
                onClick={handleFirstPageButtonClick}
                k="first"
                icon={theme.direction === 'rtl' ? LastPageIcon : FirstPageIcon}
            />
            <LabelledIconButton 
                disabled={page === 0}
                onClick={handleBackButtonClick}
                k="previous"
                icon={theme.direction === 'rtl' ? KeyboardArrowRight : KeyboardArrowLeft}
            />
            <LabelledIconButton 
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                k="next"
                icon={theme.direction === 'rtl' ? KeyboardArrowLeft : KeyboardArrowRight}
            />
            <LabelledIconButton 
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                k="last"
                icon={theme.direction === 'rtl' ? FirstPageIcon : LastPageIcon}
            />
        </Box>
    );
}

export default function TableView<T, P extends SearchParams>(props: React.PropsWithChildren<ITableViewProps<T, P>>) {
    const [expanded, setExpanded] = useState<null | React.Key>(null);
    const { value, onChange } = props;

    const cols: IColumn<T>[] = props.expand ? [
        { 
            key: '__expand', 
            name: null, 
            padding: "checkbox",
            renderCell(item, index) {
                const key = props.getKey(item, index);
                return (
                    <LabelledIconButton 
                        k="expand"
                        icon={key === expanded ? KeyboardArrowDownIcon : KeyboardArrowUpIcon}
                        onClick={() => setExpanded(key === expanded ? null : key)}
                    />
                );
            }
        },
        ...props.columns
    ] : props.columns;

    return (
        <Fragment>
            <TableContainer>
                <Table size={props.dense ? "small" : "medium"}>
                    {
                        props.caption && (
                            <caption>
                                {props.caption}
                            </caption>
                        )
                    }
                    <TableHead>
                        <TableRow>
                            {
                                cols.map(col => {
                                    let label = col.name;
                                    
                                    if (onChange && value && col.sort) {
                            
                                        let active = false;
                                        let desc = false;
                                        if (props.value?.sortBy?.length) {
                                            for (const sort of props.value.sortBy) {
                                                if (sort === col.sort) {
                                                    active = true;
                                                    break;
                                                }
                                                if (sort === ("-" + col.sort)) {
                                                    active = true;
                                                    desc = true;
                                                    break;
                                                }
                                            }
                                        }
                            
                                        const dir = active ? (desc ? 'desc' : 'asc') : false
                            
                                        return (
                                            <TableCell 
                                                key={col.key}
                                                sortDirection={dir}
                                                align={col.align}
                                            >
                                                <TableSortLabel 
                                                    active={active}
                                                    direction={dir || 'asc'}
                                                    onClick={
                                                        () => onChange({
                                                            ...value,
                                                            sortBy: [
                                                                (desc ? "" : "-") + col.sort
                                                            ],
                                                            page: 0
                                                        })
                                                    }
                                                >
                                                    {label}
                                                </TableSortLabel>
                                            </TableCell>
                                        );
                                    }
                            
                                    return (
                                        <TableCell key={col.key} align={col.align}>
                                            {label}
                                        </TableCell>
                                    );
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            props.data?.length ? props.data.map((item, index, arr) => {
                                const key = props.getKey(item, index);
                                return (
                                    <Fragment key={key}>
                                        <TableRow selected={expanded === key}>
                                            {
                                                cols.map(col => (
                                                    <TableCell key={col.key} align={col.align} padding={col.padding}>
                                                        {col.renderCell(item, index, arr)}
                                                    </TableCell>
                                                ))
                                            }
                                        </TableRow>
                                        {
                                            props.expand && (
                                                <TableRow>
                                                    <TableCell 
                                                        style={{ paddingBottom: 0, paddingTop: 0 }} 
                                                        colSpan={cols.length}
                                                    >
                                                        <Collapse in={expanded === key} unmountOnExit={true}>
                                                            <Box margin={2}>
                                                                {props.expand(item, index, arr)}
                                                            </Box>
                                                        </Collapse>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }
                                    </Fragment>
                                );
                            }) : props.children && (
                                <TableRow>
                                    <TableCell colSpan={cols.length}>
                                        {props.children}
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                    <TableFooter>
                        {
                            !!(value && onChange) && (
                                <TableRow>
                                    <TablePagination 
                                        colSpan={cols.length}
                                        rowsPerPageOptions={[10, 25, 50]}
                                        count={typeof props.total == 'number' ? props.total : -1}
                                        rowsPerPage={value.itemsPerPage}
                                        page={Math.min(Math.max(0, value.page || 0), value.itemsPerPage && props.total ? Math.ceil(props.total / value.itemsPerPage) : 0)}
                                        onPageChange={(_, page) => onChange({...value, page})}
                                        onRowsPerPageChange={
                                            e => onChange({
                                                ...value, 
                                                page: 0, 
                                                itemsPerPage: StringUtil.toInteger(e.target.value, 10)
                                            })
                                        }
                                        ActionsComponent={TablePaginationActions}
                                    />
                                </TableRow>
                            )
                        }
                    </TableFooter>
                </Table>
            </TableContainer>
        </Fragment>
    );
}
