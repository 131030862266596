import { Input } from "react-ts-form";

import Label from "../core/Label";
import { ContentContributorTypeInput } from "../form/common";
import NumberInput from "../form/inputs/NumberInput";
import RichTextInput from "../form/inputs/RichTextInput";
import SwitchInput from "../form/inputs/SwitchInput";
import ContributorData from "./ContributorData";

export default class ContentContributorData {

    public contributor!: ContributorData;

    @Input({
        component: ContentContributorTypeInput,
        meta: {
            title: <Label k="type" />,
            required: true
        }
    })
    public type: 'General' | 'Author' | 'Coauthor' | 'Illustrator' | 'Speaker' | 'Presenter' = 'General';

    @Input({
        component: NumberInput,
        meta: {
            title: <Label k="index" />,
            required: true
        }
    })
    public index: number = 0;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="principal" />
        }
    })
    public principal: boolean = false;

    @Input({
        component: RichTextInput,
        meta: {
            title: <Label k="description" />
        }
    })
    public description: string = "";

}
