import * as React from "react";
import { Box } from "@mui/material";

import DecoratorForm, { IDecoratorFormProps } from "./DecoratorForm";
import AnchorPopover from "../core/ui/AnchorPopover";

interface IDecoratorFormPopoverProps<T extends object> extends IDecoratorFormProps<T> {
    children: (setAnchor: (element: HTMLElement) => void) => React.ReactElement;
}

export default function DecoratorFormPopover<T extends object>({ children, ...rest }: IDecoratorFormPopoverProps<T>) {
    return (
        <AnchorPopover 
            content={
                <Box padding={1}>
                    <DecoratorForm {...rest} />
                </Box>
            }
        >
            {children}
        </AnchorPopover>
    );
}
