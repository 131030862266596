import Axios from "axios";
import { Shuttle } from "../types";
import BrowserUtil from "../util/BrowserUtil";
import createQueue from "../util/AxiosQueue";

export const client = createQueue(Axios.create({}));

function formatCommit(data: Shuttle.TrackingCommitData) {
    return {
        ...data,
        state: data.state && typeof data.state === 'object' ? JSON.stringify(data.state) : data.state,
    };
}

export const api = {

    play: {

        entryPoint: async (sessionId: string) => client.post<Shuttle.PlayEntryPointData>('/play/' + sessionId, {}).then(r => r.data),

        path: async (sessionId: string, path: string, params?: any) => client.post<Shuttle.PlaySessionEntryData>('/play/' + sessionId + '/entries', { path: path ? path : "", params }).then(r => r.data),

        sequencing: async (sessionId: string) => client.get<string[]>('/play/' + sessionId + '/sequencing').then(r => r.data),

        setState: async (sessionId: string, values: any) => client.put<Shuttle.PlayEntryPointData>('/play/' + sessionId + '/state', values).then(r => r.data),

        close: async (sessionId: string) => client.post<void>('/play/' + sessionId + '/close', {}).then(r => r.data),

        tracking: async (sessionId: string) => client.get<Shuttle.TrackingMap>('/play/' + sessionId + '/tracking').then(r => r.data),

        bookmarks: {

            save: async (sessionId: string, bookmark: Shuttle.BookmarkData) => await client
                .post<Shuttle.BookmarkData>('/play/' + sessionId + '/bookmarks', bookmark)
                .then(r => r.data),

            remove: async (sessionId: string, bookmarkId: number) => await client
                .delete<void>('/play/' + sessionId + '/bookmarks/' + bookmarkId)
                .then(r => r.data),

            downloadPdf: async (sessionId: string, contentIds?: number[], fileName = 'notes.pdf') => {
                const r = await client.get(`/play/${sessionId}/bookmarks/pdf`, { 
                    params: {
                        contentIds,
                    },
                    responseType: 'blob',
                });
                await BrowserUtil.download(r.data, fileName, 'application/pdf');
            },

        },

        awards: {
            list: async (sessionId: string) => await client
                .get<Shuttle.PlayEntryPointData['issuedAwards']>(`/play/${sessionId}/awards/issued`)
                .then(r => r.data),
            claim: async (sessionId: string, awardIds: string[]) => await client
                .post<Shuttle.PlayEntryPointData['issuedAwards']>(`/play/${sessionId}/awards/claim`, awardIds)
                .then(r => r.data),
        },

        announcements: {
            acknowledge: async (sessionId: string, announcementId: number) => await client
                .put<void>(`/play/${sessionId}/announcements/${announcementId}`, {})
                .then(r => r.data),
        },

    },

    track: {

        init: async (sessionId: string) => client.get<Shuttle.TrackingData>('/track/init/' + sessionId).then(r => {
            let state = r.data.state;
            if (state) {
                try {
                    state = JSON.parse(state)
                } catch {}
            }
            return {
                ...r.data,
                state,
            };
        }),

        commit: async (sessionId: string, data: Shuttle.TrackingCommitData) => client.post<void>('/track/commit/' + sessionId, formatCommit(data)).then(r => r.data),

        finish: async (sessionId: string, data?: Shuttle.TrackingCommitData) => client.post<void>('/track/finish/' + sessionId, data ? formatCommit(data) : {}).then(r => r.data),

        event: async (sessionId: string, events: Shuttle.TrackingEventData[]) => client.post<void>('/track/event/' + sessionId, events).then(r => r.data),

    },

    quiz: {

        init: async (sessionId: string) => await client
            .get<Shuttle.QuizInitData>(`/track/quiz/${sessionId}/init`)
            .then(r => r.data),

        createAttempt: async (
            sessionId: string,
            modeId: number
        ) => await client
            .post<Shuttle.QuizInitData>(`/track/quiz/${sessionId}/attempts`, { modeId })
            .then(r => r.data),

        bookmarkAttempt: async (
            sessionId: string,
            attemptId: number,
            page: number
        ) => await client
            .put<void>(`/track/quiz/${sessionId}/attempts/${attemptId}/bookmark`, { page })
            .then(r => r.data),

        submitResponses: async (
            sessionId: string,
            attemptId: number,
            questionAttempts: Shuttle.QuizAttemptQuestionData<any, any>[]
        ) => await client
            .post<Shuttle.QuizAttemptQuestionData<any, any>[]>(`/track/quiz/${sessionId}/attempts/${attemptId}/responses`, questionAttempts)
            .then(r => r.data),

        submitAttempt: async (
            sessionId: string,
            attemptId: number
        ) => await client
            .put<Shuttle.QuizInitData>(`/track/quiz/${sessionId}/attempts/${attemptId}/submit`, {})
            .then(r => r.data),

    },

};
