import { Typography } from "@mui/material";
import { anyEditor, createEntitySection, createFormAction } from "../../core/admin/AdminSection";
import SwitchInput from "../../form/inputs/SwitchInput";
import TagData from "../../model/TagData";
import TagService from "../../services/TagService";

export default createEntitySection({

    model: TagData,

    getId: o => o.id,
    label: 'name',
    
    ...TagService,

    columns: [
        {
            key: 'name',
            sort: 'name',
            renderCell: ({ data }) => (
                <>
                    <Typography fontWeight={700}>
                        {data.name}
                    </Typography>
                    {
                        !!(data.displayName && Object.keys(data.displayName).length) && (
                            <Typography
                                variant="subtitle2"
                                color="text.secondary"
                                textOverflow="ellipsis"
                                display="block"
                                maxWidth={200}
                            >
                                {Object.entries(data.displayName).map(([lang, val]) => `${lang}: ${val}`).join(', ')}
                            </Typography>
                        )
                    }
                </>
            ),
        },
        {
            key: 'externalId',
            sort: 'externalId',
        },
        {
            key: 'internal',
            sort: 'internal',
            renderCell({data, reload}) {
                return (
                    <SwitchInput 
                        value={!!data.internal}
                        onChange={
                            internal => TagService.update({ ...data, internal }).then(reload)
                        }
                    />
                );
            }
        },
        {
            key: 'count',
        }
    ],

    editEnabled: ({ appContext }) => anyEditor(appContext),

    actions: [
        createFormAction({
            key: 'create',
            clazz: TagData,
            onSubmit: async tag => {
                await TagService.create(tag);
                return true;
            },
            enabled: (_, appContext) => anyEditor(appContext),
        }),
    ]

});
