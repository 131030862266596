import {
    createContext,
    createElement,
    Dispatch,
    ReactNodeArray,
    SetStateAction,
    useContext,
    useMemo,
} from "react";
import { Alert, Stack, StackProps } from "@mui/material";

import { Shuttle } from "../../types";
import AncestorProvider from "../AncestorProvider";
import { SxProps } from "@mui/system";
import { layoutElementRegistry } from "../../registries";
import evaluateCondition from "../../conditions/evaluateCondition";
import { ConstraintsContext, ContentContext, SessionContext } from "../../contexts";
import { getAlternative } from "../../player/utils";

interface LayoutContextValue {
    layout: Shuttle.LayoutConfig;
}

export const LayoutContext = createContext<null | LayoutContextValue>(null);

interface LayoutEditContextValue {
    selected: string[];
    setSelected: Dispatch<SetStateAction<string[]>>;
    setLayout(layout: Shuttle.LayoutConfig): void;
}

export const LayoutEditContext = createContext<null | LayoutEditContextValue>(null);

interface LayoutRegionProps {
    keys: string[];
    direction?: 'row' | 'column';
    fillSpace?: boolean;
    alignItems?: StackProps['alignItems'];
    spacing?: number;
    sx?: SxProps;
}

const MAX_RECURSIVE_DEPTH = 10;

export const ElementAncestorContext = createContext<string[]>([]);

export function setElementData<T = any>(layout: Shuttle.LayoutConfig, key: string, data: T) {
    return {
        ...layout,
        elements: {
            ...(layout.elements || {}),
            [key]: {
                ...layout.elements[key],
                data
            }
        }
    };
}

export function LayoutRegion({ direction = 'column', alignItems, fillSpace, keys = [], spacing, sx }: LayoutRegionProps) {
    const ctx = useContext(LayoutContext);
    const edit = useContext(LayoutEditContext);
    const ancestors = useContext(ElementAncestorContext);
    const { session } = useContext(SessionContext);
    const contentNode = useContext(ContentContext);
    const constraints = useContext(ConstraintsContext);

    if (!ctx) {
        return null; // cannot render outside of layout context
    }

    sx = {
        ...sx ?? {},
        height: fillSpace ? '100%' : 'auto',
        "& > .__editing": { boxShadow: 'inset 0px 0px 0px 2px blue, 0px 0px 7px 1px blue' },
        "& > *": fillSpace ? { flexGrow: 1 }: {},
    };

    return (
        <Stack direction={direction} alignItems={alignItems} spacing={spacing} sx={sx}>
            {
                keys.reduce((list, key, index) => {

                    const reactKey = key + '-' + index;

                    const className = edit?.selected?.includes(key) ? '__editing' : '';

                    if (ancestors.filter(k => k === key).length >= MAX_RECURSIVE_DEPTH) { // limit recursive rendering

                        if (edit) {

                            list.push(
                                <Alert key={reactKey} severity="error" className={className}>
                                    {`too many circular references (max ${MAX_RECURSIVE_DEPTH})`}
                                </Alert>
                            );

                        }

                    } else {

                        //find config
                        const target = ctx.layout.elements?.[key];

                        if (target) {

                            //find layout element type
                            const type = layoutElementRegistry.get(target.id);

                            if (type) {

                                if (edit || !target.condition || (session && contentNode && evaluateCondition(target.condition, { 
                                    state: session.state, 
                                    tracking: session.tracking, 
                                    root: session.root, 
                                    contentNode,
                                    content: getAlternative(constraints, contentNode),
                                }))) {

                                    list.push(
                                        <AncestorProvider key={reactKey} context={ElementAncestorContext} value={key}>
                                            <div 
                                                className={className} 
                                                data-element-key={key} 
                                                onDoubleClick={edit ? e => {
                                                    e.stopPropagation();
                                                    edit.setSelected([key]);
                                                } : undefined}
                                            >
                                                {
                                                    createElement(type.Component, {
                                                        edit: Boolean(edit),
                                                        elementKey: key,
                                                        config: target.data,
                                                        setData: next => edit?.setLayout(setElementData(ctx.layout, key, next)),
                                                        direction,
                                                    })
                                                }
                                            </div>
                                        </AncestorProvider>
                                    );

                                }

                            } else {

                                if (edit) {

                                    list.push(
                                        <Alert key={reactKey} severity="warning" className={className}>
                                            {`invalid element type: ${target.id}`}
                                        </Alert>
                                    );

                                }

                            }

                        } else {

                            if (edit) {
                                
                                list.push(
                                    <Alert key={reactKey} severity="warning" className={className}>
                                        {`invalid key: ${key}`}
                                    </Alert>
                                );

                            }

                        }

                    }

                    return list;
                }, [] as ReactNodeArray)
            }
        </Stack>
    );
}

export function Layout({ layout }: Shuttle.LayoutProps) {
    const ctx = useMemo(() => ({ layout: layout || {} }), [layout]);
    return (
        <LayoutContext.Provider value={ctx}>
            <LayoutRegion keys={ctx.layout.root || []} />
        </LayoutContext.Provider>
    );
}
