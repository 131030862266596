import { Avatar } from "@mui/material";
import { anyEditor, createEntitySection, createFormAction } from "../../core/admin/AdminSection";
import ContributorData from "../../model/ContributorData";
import ContributorService from "../../services/ContributorService";

export default createEntitySection<ContributorData>({

    model: ContributorData,

    getId: o => o.id,
    label: 'name',

    ...ContributorService,
 
    columns: [
        {
            key: 'avatar',
            renderCell: ({data}) => (
                <Avatar alt={data.name} src={data.avatar?.url}>
                    {data.name.charAt(0)}
                </Avatar>
            ),
            padding: 'checkbox'
        },
        {
            key: 'name',
            sort: 'name'
        },
        {
            key: 'externalId',
            sort: 'externalId',
        }
    ],

    editEnabled: ({ appContext }) => anyEditor(appContext),

    actions: [
        createFormAction({
            key: 'create',
            clazz: ContributorData,
            onSubmit: async data => {
                await ContributorService.create(data);
                return true;
            },
            enabled: (_, appContext) => anyEditor(appContext),
        })
    ]

});
