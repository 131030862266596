import { Shuttle } from "../../types";
import { Button, ButtonProps, Stack, Tooltip } from "@mui/material";
import ActionsIcon from '@mui/icons-material/MoreHoriz';
import { useContext } from "react";
import Label from "../../core/Label";
import { Input } from "react-ts-form";
import RadioChoiceInput from "../../form/inputs/RadioChoiceInput";
import useString from "../../hooks/useString";
import { ConstraintsContext, ContainerNavContext, ContentContext, SessionContext } from "../../contexts";
import LayoutString from "../LayoutString";
import LockedMessage from "../../player/LockedMessage";
import SwitchInput from "../../form/inputs/SwitchInput";
import { getAlternative, isLocked } from "../../player/utils";
import LangStringInput from "../../form/inputs/LangStringInput";
import OpenInNew from "@mui/icons-material/OpenInNew";
import { AppContext } from "../../core/AppContext";
import { launcherRegistry } from "../../registries";
import Embed from "../../launcher/Embed";
import { api } from "../../player/api";

class ContentActionsConfig {

    @Input({
        component: RadioChoiceInput.of<ButtonProps['variant']>({
            options: ['text', 'outlined', 'contained']
        }),
        meta: {
            title: <Label k="style" />
        }
    })
    public buttonVariant?: ButtonProps['variant'];

    @Input({
        component: RadioChoiceInput.of<ButtonProps['size']>({
            options: ['small', 'medium', 'large']
        }),
        meta: {
            title: <Label k="size" />
        }
    })
    public buttonSize?: 'small' | 'medium' | 'large';

    @Input({
        component: LangStringInput,
        meta: {
            title: <Label k="launch_button_label" />
        },
    })
    public launchButtonLabel?: Shuttle.LangString

    @Input({
        component: LangStringInput,
        meta: {
            title: <Label k="locked_message" />
        },
    })
    public lockedMessage?: Shuttle.LangString

    @Input({
        component: LangStringInput,
        meta: {
            title: <Label k="prerequisites_message" />
        },
        inputProps: {
            languages: ['en', 'es'],
        },
    })
    public prerequisitesMessage?: Shuttle.LangString

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="hide_prerequisites_list" />
        }
    })
    public hidePrerequisitesList?: boolean;

}

const ContentActionsElement: Shuttle.LayoutElementType<ContentActionsConfig> = {
    name: 'layout_content_actions',
    Icon: ActionsIcon,
    ConfigClass: ContentActionsConfig,
    Component({ config: { 
        buttonSize, 
        buttonVariant, 
        hidePrerequisitesList,
        launchButtonLabel = {},
        lockedMessage = {},
        prerequisitesMessage = {},
    } }) {
        const [appContext] = useContext(AppContext);
        const { session } = useContext(SessionContext);
        const contentNode = useContext(ContentContext);
        const constraints = useContext(ConstraintsContext);
        const navCtx = useContext(ContainerNavContext);
        const defaultLabel = useString('launch');
        const resumeLabel = useString('launch.resume');
        const reviewLabel = useString('launch.review');

        if (!contentNode || !navCtx?.setActivePath) return null;

        const content = getAlternative(constraints, contentNode);

        const completion = session?.tracking[content.uuid]?.completion;

        const disabled = isLocked(constraints, contentNode);

        let launch = (
            <Button 
                disabled={disabled}
                onClick={async () => {
                    if (session && content.newWindow) {
                        const [width, height] = (content.dimension || '800x600').split('x');
                        const features = `popup,height=${height},width=${width}`;
                        if (launcherRegistry.get(content.launcher)?.Component === Embed) {
                            const { uri } = await api.play.path(session.sessionId, content.path);
                            window.open(uri, 'content_' + content.uuid, features);
                        } else {
                            window.open(
                                (appContext.tenant.basePath ?? '') + '/play/popup?path=' + encodeURIComponent(content.path),
                                'content_' + content.uuid,
                                features
                            );
                        }
                        return;
                    }
                    navCtx.setActivePath(content.path);
                }}
                size={buttonSize}
                endIcon={content.newWindow ? <OpenInNew /> : undefined}
                variant={buttonVariant}
            >
                {
                    launchButtonLabel.en
                        ? <LayoutString text={launchButtonLabel} />
                        : (completion === 'Completed' || completion === 'Passed' || completion === 'Failed')
                            ? reviewLabel
                            : (completion === 'Incomplete')
                                ? resumeLabel
                                : defaultLabel
                }
            </Button>
        );

        if (disabled) {
            launch = (
                <Tooltip 
                    title={
                        <LockedMessage 
                            contentNode={contentNode}
                            defaultLockedMessage={lockedMessage.en && <LayoutString text={lockedMessage} />} 
                            defaultPrerequisitesMessage={prerequisitesMessage.en && <LayoutString text={prerequisitesMessage} />}
                            hidePrerequisitesList={hidePrerequisitesList}
                        />
                    }
                >
                    <div>
                        {launch}
                    </div>
                </Tooltip>
            );
        }

        return (
            <Stack spacing={1} flexWrap="wrap" alignItems="flex-start">
                {launch}
            </Stack>
        );
    }
};

export default ContentActionsElement;
