import { useContext, useEffect, useRef, useState } from "react";
import { Shuttle } from "../types";
import LangStringInput from "../form/inputs/LangStringInput";
import { Input } from "react-ts-form";
import DurationInput from "../form/inputs/DurationInput";
import { TrackingContext, VIDEO_PLAYERS } from "../contexts";
import { Button, Dialog, DialogActions, DialogContent, Snackbar, Typography } from "@mui/material";
import LayoutString from "../layout/LayoutString";
import Label from "../core/Label";
import StringUtil from "../util/StringUtil";

class AreYouThereConfig {

    @Input({
        component: DurationInput,
        meta: {
            title: 'Show popup every',
            required: true,
        },
    })
    public timeout = 0;

    @Input({
        component: DurationInput,
        meta: {
            title: 'Show warning before pause',
        },
    })
    public warningThreshold = 0;

    @Input({
        component: LangStringInput,
        meta: {
            title: "Message",
        },
        inputProps: {
            type: 'richText',
        },
    })
    public message?: Shuttle.LangString;

}

interface AreYouThereState {
    m: number[]
}

export const AreYouThereBehavior: Shuttle.BehaviorType<AreYouThereConfig, AreYouThereState> = {
    name: "are_you_there",
    ConfigClass: AreYouThereConfig,
    BodyComponent: ({
        config: {
            message,
            timeout = 0,
            warningThreshold = 0,
        },
    }) => {

        const tracking = useContext(TrackingContext);

        timeout = Math.max(0, timeout);
        warningThreshold = Math.max(0, Math.min(timeout, warningThreshold));
    
        const [remaining, setRemaining] = useState(timeout);
        const interval = useRef<any>(undefined);

        useEffect(() => {
            if (remaining > 0) {
                if (!interval.current) {
                    interval.current = setInterval(() => {
                        setRemaining(prev => Math.max(0, prev - 1));
                    }, 1000);
                }
            } else {
                if (interval.current) {
                    clearInterval(interval.current);
                }
                VIDEO_PLAYERS.forEach(p => p.pause());
            }
        }, [remaining]);

        useEffect(() => () => {
            clearInterval(interval.current);
        }, []);

        const text = (
            <LayoutString text={message} html />
        );

        const confirmButton = (
            <Button
                onClick={() => {
                    tracking.events({
                        action: 'confirm-watching',
                        timestamp: Date.now(),
                    });
                    setRemaining(timeout);
                }}
            >
                <Label k="confirm" />
            </Button>
        );

        return (
            <>
                {
                    warningThreshold > 0 && (
                        <Snackbar
                            open={remaining > 0 && remaining <= warningThreshold}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            message={text}
                            action={
                                <>
                                    <Typography variant="body2" color="inherit" component="span">
                                            {StringUtil.formatDuration(remaining)}
                                    </Typography>
                                    {confirmButton}
                                </>
                            }
                        />
                    )
                }
                <Dialog open={remaining === 0} maxWidth="xs" fullWidth>
                    <DialogContent>
                        {text}
                    </DialogContent>
                    <DialogActions>
                        {confirmButton}
                    </DialogActions>
                </Dialog>
            </>
        )
    },
}
