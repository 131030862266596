import dateFormat from "date-fns/locale/it";
import { itIT } from "@mui/material/locale";
import dictionary from "./it_IT.json";

import { ILocale } from "../Locale";

const it_IT: ILocale = {
    rtl: false, // right to left
    dictionary,
    dateFormat,
    muiLocale: itIT
};

export default it_IT;
//italian