import { Avatar } from "@mui/material";
import { DateLabel } from "../../core/DateLabel";
import { adminOnly, createEntitySection, createFormAction } from "../../core/admin/AdminSection";
import SwitchInput from "../../form/inputs/SwitchInput";
import { Group, UserGroup } from "../../model/Group";
import GroupService from "../../services/GroupService";
import UserData from "../../model/UserData";
import { Input } from "react-ts-form";
import { UserInput } from "../../form/common";

class AddUserGroup extends UserGroup {

    @Input((_, { parent }) => ({
        component: UserInput,
        meta: {
            title: 'User',
            required: true,
        },
        inputProps: {
            params: {
                consumerId: parent.group.consumer.id,
            },
        },
    }))
    public user!: UserData;

}

export const groupUsers = {
    path: '/users',
    label: 'admin.section.users' as const,
    section: createEntitySection<UserGroup, any, Group>({
        model: UserGroup,
        getId: o => o.user.id,
        label: o => o.user.fullName!,
        search: async (params, group) => await GroupService.searchUsers(group.id, params),
        get: async (userId, group) => await GroupService.getUser(group.id, userId),
        update: async ({ user, group, ...data }) => await GroupService.addUser(group.id, user.id, data),
        delete: async ({ group, user }) => await GroupService.removeUser(group.id, user.id),
        columns: [
            {
                key: 'avatar',
                renderCell: ({data}) => (
                    <Avatar alt={`${data.user.firstName} ${data.user.lastName}`} src={data.user.avatar?.url}>
                        {`${data.user.firstName?.charAt(0)}${data.user.lastName?.charAt(0)}` }
                    </Avatar>
                ),
            },
            {
                key: 'firstName',
                sort: 'user.firstName',
                renderCell: ({ data }) => data.user.firstName,
            },
            {
                key: 'lastName',
                sort: 'user.lastName',
                renderCell: ({ data }) => data.user.lastName,
            },
            {
                key: 'email',
                sort: 'user.email',
                renderCell: ({ data }) => data.user.email,
            },
            {
                key: 'phone',
                sort: 'user.phone',
                renderCell: ({ data }) => data.user.phone,
            },
            {
                key: 'lastActiveDate',
                sort: 'user.lastActiveDate',
                renderCell: ({ data }) => <DateLabel date={data.user.lastActiveDate} format="Pp" />
            },
            {
                key: 'method',
                sort: 'method',
            },
            {
                key: 'role',
                sort: 'role',
            },
            {
                key: 'added',
                sort: 'createDate',
                renderCell: ({ data }) => <DateLabel date={data.createDate} format="Pp" />
            },
        ],
        editEnabled: ({ appContext }) => adminOnly(appContext),
        actions: [
            createFormAction({
                key: 'add',
                getInitialData: group => {
                    const d = new UserGroup();
                    d.group = group;
                    return d;
                },
                clazz: AddUserGroup,
                onSubmit: async ({ user, group, ...rest }) => {
                    await GroupService.addUser(group.id, user.id, rest);
                    return true;
                },
                enabled: (_, appContext) => adminOnly(appContext),
            }),
        ],
    }),
};

export default createEntitySection<Group>({
    model: Group,
    getId: o => o.id,
    label: o => o.name,
    ...GroupService,
    columns: [
        {
            key: 'name',
            sort: 'name',
        },
        {
            key: 'consumer',
            sort: 'consumer.name',
            renderCell: ({ data }) => data.consumer.name,
        },
        {
            key: 'externalId',
            sort: 'externalId',
        },
        {
            key: 'internal',
            sort: 'internal',
            renderCell: ({ data, reload }) => (
                <SwitchInput
                    value={!!data.internal}
                    onChange={internal => GroupService.update({ ...data, internal }).then(reload)}
                />
            ),
        },
        {
            key: 'type',
            sort: 'dynamic',
            renderCell: ({ data }) => data.dynamic ? 'Dynamic' : 'Static',
        },
        {
            key: 'createDate',
            sort: 'createDate',
            renderCell: ({ data }) => <DateLabel date={data.createDate} />,
        },
        {
            key: 'users',
            sort: 'userCount',
            renderCell: ({ data }) => data.userCount ?? 0,
        },
    ],
    editEnabled: ({ appContext }) => adminOnly(appContext),
    actions: [
        createFormAction({
            key: 'create',
            clazz: Group,
            onSubmit: async group => {
                await GroupService.create(group)
                return true;
            },
            enabled: (_, appContext) => adminOnly(appContext),
        })
    ],
    sections: [
        groupUsers,
    ]
})
