import { v4 as generateUuid } from "uuid";
import Constants from "./Constants";

export default class StringUtil {

    private static counter = 0;

    public static toBoolean(str: string) {
        return Boolean(str && "ty1".includes(str.toLowerCase().charAt(0)));
    }

    public static toInteger(str: string, defaultValue: number) {
        let parsed = parseInt(str, 10);
        if (isNaN(parsed)) {
            parsed = defaultValue;
        }
        return parsed;
    }

    public static safeGetFirst(str: undefined | null | string | string[]) {
        if (Array.isArray(str)) {
            return str[0];
        }
        return str || "";
    }

    public static includesIgnoreCase(str: string, query: string) {
        return (str || "").toLowerCase().includes((query || "").toLowerCase());
    }

    public static uuid() {
        return generateUuid();
    }

    public static tempId() {
        return "_" + (this.counter++);
    }

    public static formatHMS(seconds: number,) {
        const hours = Math.floor(seconds / 3600);
        const mins  = Math.floor((seconds - (hours * 3600)) / 60);
        const secs  = seconds - (hours * 3600) - (mins * 60);
        const parts: string[] = [];
        if (hours > 0) parts.push(hours + 'h');
        if (mins > 0) parts.push(mins + 'm');
        if (secs > 0) parts.push(secs + 's');
        if (!parts.length) parts.push('0s');
        return parts.join(' ');
    }

    public static formatDuration(seconds: number) {
        const hours = Math.floor(seconds / 3600);
        const mins  = Math.floor((seconds - (hours * 3600)) / 60);
        const secs  = seconds - (hours * 3600) - (mins * 60);
        return (hours ? [hours, mins, secs] : [mins, secs]).map(n => n.toString().padStart(2, '0')).join(':');
    }

    public static formatVttTimestamp(seconds: number) {
        const hours = Math.floor(seconds / 3600);
        const mins  = Math.floor((seconds - (hours * 3600)) / 60);
        const secs  = seconds - (hours * 3600) - (mins * 60);
        return [hours, mins, secs].map(n => n.toString().padStart(2, '0')).join(':') + '.' + (seconds % 1).toFixed(3).substring(2);
    }

    public static formatVars(str: string, onVar: (name: string, defaultValue?: string) => string) {
        return str?.replace(Constants.REGEX_VARIABLES, match => {

            match = match.substring(1, match.length - 1); // remove { and }

            let defaultValue = '';
            const idx = match.indexOf('|');
            if (match.indexOf('|') > -1) {
                defaultValue = match.substring(idx + 1);
                match = match.substring(0, idx);
            }

            return onVar(match, defaultValue);
        })
    }

    public static parseDimension(dimension: string, defaultRatio: number = 9 / 16) {
        let ratio = defaultRatio
        if (dimension?.match(Constants.REGEX_DIMENSION)) {
            const [w, h] = dimension.split('x').map(s => StringUtil.toInteger(s, 0));
            if (w > 0 && h > 0) {
                ratio = h / w;
            }
        }
        return ratio;
    }

    public static escapeRegExp(expr: string) {
        return expr.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    public static highlightSearch(
        text: string,
        search: string,
        matchClassName = 'match',
    ) {
        if (!text || !search) return text ?? '';
        const rx = new RegExp(StringUtil.escapeRegExp(search), 'gi');
        return text.replace(rx, `<strong class="${matchClassName}">$&</strong>`);
    }

    public static snakeToTitle(snake: string) {
        return (snake ?? '').split('_').map(p => p.charAt(0).toUpperCase() + p.substring(1)).join(' ')
    }
}
