import { EMBED_FRAME_ATTR } from "../launcher/Embed";

export default class BrowserUtil {

    public static getPreferedColorScheme(): 'light' | 'dark' {
        return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
    }

    public static async copyToClipboard(str: string): Promise<boolean> {
        const el = document.createElement('textarea');
        el.style.position = 'fixed';
        el.style.top = '0';
        el.style.left = '0';
        el.value = str;
        document.body.appendChild(el);

        el.focus();
        el.select();
        el.setSelectionRange(0, 99999);

        let success = false;
        try {
            document.execCommand('copy');
            success = true;
        } catch (e) {
        } finally {
            document.body.removeChild(el);
        }
        return success;
    }

    public static inFrame() {
        try {
            return window !== window.top;
        } catch (_) {
            return true;
        }
    }

    public static async downloadFileFromResponse(res: Response, fileName: string) {
        const blob = await res.blob();
        const contentType = res.headers.get('content-type') ?? 'application/octet-stream';
        return await this.downloadFile(blob, contentType, fileName)
    }

    public static async downloadFile(data: BlobPart | string, type: string, fileName: string) {
        const blob = data instanceof Blob ? data : new Blob([data], { type });
        const urlObj = (() => {
            if (window.URL && (window.URL as any).createObjectURL) {
                return window.URL;
            }
            return window.webkitURL;
        })();
        const blobUrl = urlObj.createObjectURL(blob);
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = blobUrl;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();

        setTimeout(() => {
            document.body.removeChild(link);
            urlObj.revokeObjectURL(blobUrl);
        }, 200);
    }

    public static async download(data: any, fileName: string, mime?: string) {
        const blob = new Blob([data], { type: mime || 'application/octet-stream'});
        const urlObj = (() => {
            if (window.URL && (window.URL as any).createObjectURL) {
                return window.URL;
            }
            return window.webkitURL;
        })();
        const blobUrl = urlObj.createObjectURL(blob);
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = blobUrl;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();

        setTimeout(() => {
            document.body.removeChild(link);
            urlObj.revokeObjectURL(blobUrl);
        }, 200);
    }

    public static isFullscreenEnabled(doc = document) {
        return !!(
            doc.fullscreenElement 
            || (doc as any).webkitFullscreenElement 
            || (doc as any).mozFullScreenElement
        );
    }

    public static isElementFullscreen(element: HTMLElement) {
        const doc = element.ownerDocument;
        return (
            doc.fullscreenElement 
            || (doc as any).webkitFullscreenElement 
            || (doc as any).mozFullScreenElement
        ) === element;
    }

    public static isElementInViewport(element: HTMLElement) {
        const rect = element.getBoundingClientRect();
        const doc = element.ownerDocument;
        return BrowserUtil.isPointInViewport(rect.left, rect.top, doc) || BrowserUtil.isPointInViewport(rect.right, rect.bottom, doc);
    }

    private static isPointInViewport(x: number, y: number, doc: Document) {
        return (
            y >= 0 &&
            x >= 0 &&
            y <= (doc.defaultView!.innerHeight || doc.documentElement.clientHeight) &&
            x <= (doc.defaultView!.innerWidth || doc.documentElement.clientWidth)
        )
    }

    public static readFile(file: File) {
        return new Promise<string>((resolve, reject) => {

            const reader = new FileReader();
            reader.onload = e => {
                if (typeof e.target?.result === 'string') {
                    resolve(e.target.result);
                } else {
                    reject('failed to read file');
                }
            };
            reader.onerror = e => reject(e);
            reader.readAsText(file);

        });
    }

    public static dangerouslyInjectChildScripts(element: Element) {

        if (element.tagName === 'SCRIPT') {
            const script = document.createElement('script');
            script.text = element.innerHTML;
            let i = -1, attrs = element.attributes, attr;
            while(++i < attrs.length) {
                script.setAttribute((attr = attrs[i]).name, attr.value);
            }
            element.parentNode?.replaceChild(script, element);
            return;
        }
        
        let i = 0;
        while (i < element.children.length) {
            this.dangerouslyInjectChildScripts(element.children[i++]);
        }
    }

    public static scrollElementTo(element: HTMLElement, to: number, duration: number = 500) {
        if (duration <= 0) return;
        const distance = to - element.scrollTop;
        const perTick = distance / duration * 10;
        setTimeout(function() {
            element.scrollTop = element.scrollTop + perTick;
            if (element.scrollTop === to) return;
            BrowserUtil.scrollElementTo(element, to, duration - 10);
        }, 10);
    }

    public static getSearchParams(form: HTMLFormElement) {
        const fd = new FormData(form);
        const sp = new URLSearchParams();
        fd.forEach((value, name) => {
            sp.append(name, value.toString());
        })
        return sp;
    }

    public static async closeContentFrames(parent: HTMLElement) {
        const frames = parent.querySelectorAll<HTMLIFrameElement>('iframe[' + EMBED_FRAME_ATTR + ']');
        if (frames.length) {
            frames.forEach(f => f.src = 'about:blank');
            await new Promise(resolve => setTimeout(resolve, 200));
        }
    }

    /**
     * 
     * @param action callback to execute every iteration - return true to continue, return false to exit
     * @param intervalMs how frequently you want to try
     * @param limit max attempts
     * @returns Promise<boolean> - true if resolved successfully, false if it ran out of attempts
     */
    public static async poll(action: () => Promise<boolean>, intervalMs: number, limit: number) {
        while (limit) {
            const runAgain = await new Promise<boolean>(resolve => {
                setTimeout(async () => resolve(await action()), intervalMs);
            });
            if (runAgain) {
                limit--;
            } else {
                return true;
            }
        }
        return false;
    }

    public static randomNumber(min: number, max: number) {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }
}
