import { Input } from "react-ts-form";
import TextInput from "../form/inputs/TextInput";
import { Shuttle } from "../types";
import LangStringInput from "../form/inputs/LangStringInput";
import DateTimeInput from "../form/inputs/DateTimeInput";
import SwitchInput from "../form/inputs/SwitchInput";

export class Announcement {

    public id!: number;

    public uuid!: string;

    @Input({
        component: TextInput,
        meta: {
            title: 'Name',
            required: true,
        },
    })
    public name!: string;

    @Input({
        component: LangStringInput,
        meta: {
            title: 'Display Name',
        }
    })
    public displayName?: Shuttle.LangString;

    @Input({
        component: LangStringInput,
        meta: {
            title: 'Body',
        },
        inputProps: {
            type: 'richText',
        },
    })
    public description?: Shuttle.LangString;

    @Input({
        component: DateTimeInput,
        meta: {
            title: 'Start Date',
            description: 'Date when this announcement will start to appear to end users.'
        }
    })
    public startDate!: string;

    @Input({
        component: DateTimeInput,
        meta: {
            title: 'End Date',
            description: 'Date when this announcement will no longer show on featured announcement feeds.',
        }
    })
    public endDate?: string;

    @Input({
        component: DateTimeInput,
        meta: {
            title: 'Expiration Date',
            description: 'Date when this announcement will no longer show to end users anywhere.',
        }
    })
    public expiryDate?: string;

    @Input({
        component: SwitchInput,
        meta: {
            title: 'Required',
            description: 'User must acknowledge this announcement before continuing.',
        },
    })
    public required?: boolean;

    public createDate!: string;

    public acknowledged?: boolean;

}
