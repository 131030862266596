import Axios from "axios";
import { Group, UserGroup } from "../model/Group";
import SearchParams from "../model/SearchParams";
import createEntityService from "./createEntityService";
import ResponseData from "../model/ResponseData";
import SearchResult from "../model/SearchResult";

const GroupService = {
    ...createEntityService<Group>({
        path: '/groups',
        allowDelete: true,
    }),
    searchUsers: async (groupId: number | string, params: SearchParams) => await Axios
        .get<ResponseData<SearchResult<UserGroup>>>(`/groups/${groupId}/users`, { params })
        .then(r => r.data),
    getUser: async (groupId: number | string, userId: number | string) => await Axios
        .get<ResponseData<UserGroup>>(`/groups/${groupId}/users/${userId}`)
        .then(r => r.data),
    addUser: async (groupId: number | string, userId: number | string, data: Omit<UserGroup, 'user' | 'group'>) => await Axios
        .put<ResponseData<UserGroup>>(`/groups/${groupId}/users/${userId}`, data)
        .then(r => r.data),
    removeUser: async (groupId: number | string, userId: number | string) => await Axios
        .delete(`/groups/${groupId}/users/${userId}`)
        .then(r => r.data),
}

export default GroupService;
