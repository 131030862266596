import { useEffect, useRef } from "react";
import Constants from "../util/Constants";

export default function useInterval(interval: number, callback: () => void) {
    const intervalRef = useRef<any>(null);
    const callbackRef = useRef<() => void>(Constants.NO_OP_FUNC);
    callbackRef.current = callback;
    useEffect(() => {
        const clear = () => intervalRef.current && clearInterval(intervalRef.current);
        clear();
        if (interval < 1) return;
        const handler = () => {
            if (document.visibilityState === 'visible') {
                intervalRef.current = setInterval(() => callbackRef.current(), interval);
            } else {
                clear();
            }
        }
        handler();
        document.addEventListener('visibilitychange', handler);
        return () => {
            clear();
            document.removeEventListener('visibilitychange', handler);
        }
    }, [interval]);
}
