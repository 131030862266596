import { LocationDescriptorObject } from "history";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { AuthService } from "../services/AuthService";

export default function LogoutRedirect(props: { to?: LocationDescriptorObject<any>; }) {
    const history = useHistory();
    const { to } = props;

    useEffect(() => {
        AuthService.logout();
    }, [history, to]);

    return null;
}
