import { Shuttle } from "../../types";
import ScormIcon from "@mui/icons-material/Extension";
import Embed from "../Embed";
import { Fieldsets, Input } from "react-ts-form";

import FileData from "../../model/FileData";
import FileInput from "../../form/inputs/FileInput";
import TextField from "../../form/inputs/TextInput";
import Label from "../../core/Label";

@Fieldsets({
    scormupload: <Label k="scormupload" />
})
class ScormSettings {

    @Input({
        component: FileInput,
        meta: {
            title: <Label k="scorm.upload" />
        },
        inputProps: {
            accept: ".xml,.zip,.pif"
        },
        fieldset: "scormupload"
    })
    public resource?: FileData;

    @Input((_, { parent }) => {
        if (!parent.uri) {
            return {};
        }
        
        return {
            component: TextField,
            meta: {
                title: <Label k="launch.uri" />,
                disabled: true
            },
            fieldset: "scormupload"
        };
    })
    public uri?: string;    
}

export const ScormLauncher: Shuttle.Launcher = {
    name: 'launcher.scorm',
    Icon: ScormIcon,
    ConfigClass: ScormSettings,
    Component: Embed,
};
