import { BookmarksContextValue, SessionContextValue, SettingsContextValue } from "../contexts";
import { Shuttle } from "../types";

export function visitNodes(node: Shuttle.ContentNode, func: (node: Shuttle.ContentNode) => void) {
    func(node);
    node.content.children?.forEach(child => visitNodes(child, func));
    node.alternatives?.forEach(alt => alt.content.children?.forEach(child => visitNodes(child, func)));
}

export function getAllContents(node: Shuttle.ContentNode) {
    const list: Shuttle.ContentNodeContent[] = [];
    visitNodes(node, n => {
        list.push(n.content);
        n.alternatives?.forEach(a => list.push(a.content));
    });
    return list;
}

export function resolvePath(root: Shuttle.ContentNode, path: string) {
    let pair = {
        contentNode: root,
        content: root.content,
    };
    const result = [pair];
    for (const part of path.split('/').filter(p => p)) {
        if (part.startsWith('c')) {
            const next = pair.content.children?.find(child => (child.id + '') === part.substring(1));
            if (next) {
                result.push(pair = {
                    contentNode: next,
                    content: next.content,
                });
                continue;
            } else {
                return null;
            }
        }
        if (part.startsWith('a')) {
            const alt = pair.contentNode.alternatives?.find(alt => (alt.id + '') === part.substring(1));
            if (alt) {
                pair.content = alt.content;
                continue;
            } else {
                return null;
            }
        }
        return null;
    }
    return result;
}

export function getAlternative(constraints: Shuttle.Constraints, contentNode: Shuttle.ContentNode) {
    const idx = constraints.alternatives[contentNode.id + ''];
    return (typeof idx === 'number' && idx >= 0 && contentNode.alternatives?.[idx]?.content) || contentNode.content;
}

export function isHidden(constraints: Shuttle.Constraints, contentNode: Shuttle.ContentNode) {
    for (const path of constraints.hidden) {
        if (path === contentNode.content.path || contentNode.content.path.startsWith(path + '/')) {
            return true;
        }
    }
    return false;
}

export function isLocked(constraints: Shuttle.Constraints, contentNode: Shuttle.ContentNode) {
    for (const path of Object.keys(constraints.locked)) {
        if (path === contentNode.content.path || contentNode.content.path.startsWith(path + '/')) {
            return true;
        }
    }
    return false;
}

export interface ListenerContext {
    settings: SettingsContextValue;
    session: SessionContextValue;
    constraints: Shuttle.Constraints;
    bookmarks: BookmarksContextValue;
}

interface PopupListener {
    window: Window | WindowProxy;
    callback: (context: ListenerContext) => void;
}

const LISTENERS = '__LISTENERS';

export class PopupController {

    public static get() {
        return (window as any)[LISTENERS] as PopupController;
    }

    public static start(initialState: ListenerContext) {
        const prev = (window as any)[LISTENERS] as PopupController;
        if (prev) {
            prev.closeAll();
        }
        return (window as any)[LISTENERS] = new PopupController(initialState);
    }

    public static subscribeToOpener = (callback: PopupListener['callback']) => {
        const source = window.opener?.window;
        if (!source) {
            throw new Error('unable to access opener!');
        }
        const controller = source[LISTENERS] as PopupController;
        if (!controller) {
            throw new Error('opener is not waiting for listeners!');
        }
        return controller.subscribe({
            window,
            callback,
        });
    }

    private listeners: PopupListener[] = [];
    
    private constructor(
        private state: ListenerContext
    ) {}

    public subscribe(listener: PopupListener) {
        this.listeners.push(listener);
        listener.callback(this.state);
        return () => {
            const idx = this.listeners.indexOf(listener);
            if (idx !== -1) {
                this.listeners.splice(idx, 1);
            }
        }
    }

    public update(context: ListenerContext) {
        this.state = context;
        if (process.env.NODE_ENV === 'development') {
            console.log(`notifying ${this.listeners.length} popup subscribers`);
        }
        for (const { callback } of this.listeners) {
            try {
                callback(this.state);
            } catch {}
        }
    }

    public closeAll() {
        while (this.listeners.length) {
            const { window } = this.listeners.pop()!;
            try {
                window.close();
            } catch {}
        }
    }

}
