import { anyEditor, createEntitySection, createFormAction } from "../../core/admin/AdminSection";
import SwitchInput from "../../form/inputs/SwitchInput";
import BehaviorData, { behaviorTypeRenderer } from "../../model/BehaviorData";
import BehaviorService from "../../services/BehaviorService";

export default createEntitySection({

    model: BehaviorData,

    ...BehaviorService,

    getId: o => o.id,
    label: o => o.name,

    columns: [
        {
            key: 'name',
            sort: 'name'
        },
        {
            key: 'externalId',
            sort: 'externalId',
        },
        {
            key: 'handler',
            sort: 'handler',
            renderCell: ({data}) => behaviorTypeRenderer.getLabel(data.handler),
        },
        {
            key: 'enabled',
            sort: 'enabled',
            renderCell: ({appContext, data, reload}) => (
                <SwitchInput 
                    value={!!data.enabled}
                    onChange={
                        enabled => BehaviorService.update({...data, enabled}).then(reload)
                    }
                    disabled={!anyEditor(appContext)}
                />
            )
        }
    ],
    editEnabled: ({ appContext }) => anyEditor(appContext),
    actions: [
        createFormAction({
            key: 'create',
            clazz: BehaviorData,
            enabled: (_, appContext) => anyEditor(appContext),
            onSubmit: async data => {
                await BehaviorService.create(data);
                return true;
            }
        })
    ]

});
