import { Shuttle } from "../../types";

export default class EnrollmentQuizAttempt {
    public id!: number;
    public startTime!: string;
    public endTime!: string;
    public status!: Shuttle.QuizAttemptData['status'];
    public result!: Shuttle.QuizAttemptData['result'];
    public score!: number;
    public maxScore!: number;
}
