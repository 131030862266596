import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { api } from "./api";
import { Shuttle } from "../types";
import Axios from "axios";
import { MutableRefObject } from "react";

function prepareCommit(
    timestamps: MutableRefObject<[number, number?][]>,
    { bookmark, completion, progress, state, uniqueTimeViewed }: Shuttle.TrackingData,
) {
    const payload: Shuttle.TrackingCommitData = {
        bookmark,
        completion,
        progress,
        state,
        uniqueTimeViewed,
    }
    payload.sessionTimeSpent = Math.floor(timestamps.current.reduce((a, [start, end], i, arr) => {
        if (i === arr.length - 1 && !end) {
            end = Date.now();
        }
        return a + (end ? end - start : 0);
    }, 0) / 1000);
    return payload;
}

/**
 * handles tracking api lifecycle. does not initialize if no session id provided.
 */
export default function useTracking(entryId?: string, elementRef?: RefObject<HTMLElement>) {

    const timestamps = useRef<[number, number?][]>([]);
    if (!timestamps.current.length) {
        timestamps.current.push([Date.now()]);
    }

    const finished = useRef(false);
    const [tracking, setTracking] = useState<null | Shuttle.TrackingData>(null);
    const trackingRef = useRef(tracking)
    trackingRef.current = tracking

    useEffect(() => {
        if (!entryId) return;

        const win = elementRef?.current?.ownerDocument.defaultView ?? window;
        
        finished.current = false;

        api.track.init(entryId).then(setTracking);
        
        const handler = () => {
            if (finished.current) return;
            finished.current = true;
            const payload = prepareCommit(timestamps, trackingRef.current ?? {})
            let sent = false;
            // original window for this to avoid cross domain
            if ('navigator' in window) {
                sent = window.navigator.sendBeacon(
                    (Axios.defaults.baseURL || '') + '/track/finish/' + entryId, 
                    new Blob([JSON.stringify(payload)], {type: 'application/json'})
                );
            }
            if (!sent) {
                api.track.finish(entryId, payload);
            }
        }

        win.addEventListener('beforeunload', handler);
        return () => {
            if (!entryId || finished.current) return;
            finished.current = true;
            win.removeEventListener('beforeunload', handler);
            const payload = prepareCommit(timestamps, trackingRef.current ?? {})
            api.track.finish(entryId, payload);
        };
    }, [entryId, elementRef]);

    // update tracking data
    const commit = useCallback<Shuttle.TrackingContextValue['commit']>(value => {
        setTracking(prev => prev && ({
            ...prev,
            ...typeof value === 'function' ? value(prev) : value ?? {},
        }));
    }, []);

    // auto-commit whenever tracking data state is changed
    const firstRef = useRef(false);
    useEffect(() => {
        if (finished.current || !entryId || !tracking) return;
        if (!firstRef.current) {
            firstRef.current = true;
            return;
        }
        api.track.commit(entryId, prepareCommit(timestamps, tracking));
    }, [entryId, tracking]);

    const events = useCallback((events: Shuttle.TrackingEventData | Shuttle.TrackingEventData[]) => {
        if (!entryId) return;
        api.track.event(entryId, Array.isArray(events) ? events : [events]);
    }, [entryId]);

    useEffect(() => {
        const handler = () => {
            const latest = timestamps.current[timestamps.current.length - 1];
            if (!latest[1]) {
                latest[1] = Date.now();
            }
            if (document.visibilityState === 'visible') {
                timestamps.current.push([Date.now()]);
            } else {
                if (entryId && trackingRef.current && !finished.current) {
                    api.track.commit(entryId, prepareCommit(timestamps, trackingRef.current))
                }
            }
        }
        const doc = elementRef?.current?.ownerDocument ?? document;
        doc.addEventListener('visibilitychange', handler);
        return () => {
            doc.removeEventListener('visibilitychange', handler);
        }
    }, [entryId, elementRef]);

    return useMemo(() => ({ tracking, commit, events }), [tracking, commit, events]);
}
