import en from "./locales/en_US";
import es from "./locales/es_MX";
import zh from "./locales/zh_CN";
import fr from "./locales/fr_FR";
import ru from "./locales/ru_RU";
import tr from "./locales/tr_TR";
import de from "./locales/de_DE";
import it from "./locales/it_IT";
import ja from "./locales/ja_JP";
import th from "./locales/th_TH";
import ko from "./locales/ko_KR";
import pl from "./locales/pl_PL";
import pt from "./locales/pt_PT";
import bestLocaleMatch from "./util";

const locales = {
    de,
    en,
    es,
    fr,
    it,
    ja,
    ko,
    pl,
    pt,
    th,
    tr,
    ru,
    zh,
}

export function getLocaleChoices() {
    return Object.keys(locales).sort()
}

export function getBestLocale(locale: string) {
    return bestLocaleMatch(locales, locale) ?? en
}
