import { Shuttle } from "../types";
import ImageIcon from "@mui/icons-material/Image";
import { Input } from "react-ts-form";
import { useTheme } from "@mui/material";

import TextInput from "../form/inputs/TextInput";
import Label from "../core/Label";
import AssetUrlInput from "../form/inputs/AssetUrlInput";
import { useContext } from "react";
import StringUtil from "../util/StringUtil";
import SwitchInput from "../form/inputs/SwitchInput";
import { ConstraintsContext, ContentContext } from "../contexts";
import { getAlternative } from "../player/utils";

class ImageConfig {

    @Input({
        component: AssetUrlInput,
        meta: {
            title: <Label k="url" />
        }
    })
    public src!: string;
    
    @Input({
        component: TextInput,
        meta: {
            title: <Label k="alt" />
        }
    })
    public alt!: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="dimension" />,
            description: 'e.g. 16x9'
        }
    })
    public aspectRatio?: string;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="use_content_picture" />
        }
    })
    public contentPicture?: boolean;

    @Input({
        component: SwitchInput,
        meta: {
            title: "Rounded",
        },
    })
    public rounded?: boolean;

}

const ImageElement: Shuttle.LayoutElementType<ImageConfig> = {
    name: 'layout.image',
    Icon: ImageIcon,
    ConfigClass: ImageConfig,
    Component({ config: { alt, aspectRatio, contentPicture, rounded, src } }) {
        const theme = useTheme();
        const constraints = useContext(ConstraintsContext);
        const contentNode = useContext(ContentContext);

        if (contentPicture && contentNode) {
            const content = getAlternative(constraints, contentNode);
            if (content?.pictureUrl) {
                src = content.pictureUrl;
            }
        }

        if (!src) {
            src = theme.pictureUrl;
        }

        if (aspectRatio) {
            return (
                <div
                    style={{
                        position: 'relative',
                        height: 0,
                        paddingTop: (StringUtil.parseDimension(aspectRatio) * 100) + '%',
                        overflow: 'hidden',
                    }}
                >
                    <div
                        style={{
                            backgroundImage: `url('${src}')`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            borderRadius: rounded ? theme.spacing(1) : undefined,
                        }}
                    />
                </div>
            );
        }

        return (
            <img
                alt={alt}
                src={src || theme.pictureUrl}
                style={{
                    borderRadius: rounded ? theme.shape.borderRadius : undefined,
                    maxWidth: '100%',
                }}
            />
        );
    }
};

export default ImageElement;
