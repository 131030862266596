import { Alert, Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Chip, CircularProgress, Grid, LinearProgress, List, ListItemButton, ListItemText, Paper, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import Layout from "../core/Layout";
import useResolver from "../hooks/useResolver";
import { useCallback, useContext, useState } from "react";
import { ConsumerUIService } from "../services/ConsumerUIService";
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import { SettingsContext } from "../contexts";
import bestLocaleMatch from "../i18n/util";
import { useThemeContext } from "../core/ThemeProvider";
import UserToolbar from "../core/UserToolbar";
import RunningText from "../core/ui/RunningText";
import { Layout as UiLayout } from '../core/ui/Layout';
import { Link } from "react-router-dom";
import Paging from "../core/ui/Paging";
import Label from "../core/Label";
import AnchorPopover from "../core/ui/AnchorPopover";
import { DateLabel } from "../core/DateLabel";

export default function MyCourses() {

    const [settings] = useContext(SettingsContext);
    const { locale } = settings;
    const themeContext = useThemeContext();

    const [params, setParams] = useState({
        itemsPerPage: 12,
        page: 0,
        sortBy: ['-createDate'],
    })

    const [view, setView] = useState<'grid' | 'list'>('grid');

    const { data: enrollments } = useResolver(useCallback(async () => await ConsumerUIService.searchMyEnrollments(params), [params]));

    return (
        <Layout rightElement={<UserToolbar />} fullscreen>
            <Box sx={{ mx: 'auto', my: 8, maxWidth: 1200, width: '100%', px: 2 }}>
                <Grid container spacing={2} sx={{ mb: 3 }} alignItems="center">
                    <Grid item xs>
                        <Typography variant="h3" component="h1">
                            {'My Courses'}
                        </Typography>
                    </Grid>
                    <Grid item xs="auto">
                        <ToggleButtonGroup
                            value={view}
                            onChange={(_, view) => setView(prev => view ? view : prev)}
                            exclusive
                            size="small"
                        >
                            <ToggleButton value="grid">
                                <ViewModuleIcon />
                            </ToggleButton>
                            <ToggleButton value="list">
                                <ViewListIcon />
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    {
                        !enrollments ? (
                            <Grid item xs={12}>
                                <CircularProgress />
                            </Grid>
                        ) : !enrollments.items?.length ? (
                            <Grid item xs={12}>
                                <Alert severity="info">
                                    {'You are not enrolled in any courses'}
                                </Alert>
                            </Grid>
                        ) : enrollments.items?.map(e => {

                            const title = bestLocaleMatch(e.course.title, locale);
                            const subTitle = bestLocaleMatch(e.course.subTitle, locale);

                            const titles = (
                                <>
                                    <Typography fontWeight={700} gutterBottom={!subTitle}>
                                        {title}
                                    </Typography>
                                    {
                                        subTitle && (
                                            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                                {subTitle}
                                            </Typography>
                                        )
                                    }
                                    {
                                        !!e.course.awards?.length && (
                                            <Stack direction="row" flexWrap="wrap" sx={{ my: 1 }}>
                                                {
                                                    e.course.awards.filter(a => a.type === 'Credit').map(a => {
                                                        const issued = e.issuedAwards.find(i => i.award.id === a.id);
                                                        let value = issued?.value ?? a.value;
                                                        return (
                                                            <Chip
                                                                key={a.id}
                                                                disabled={!issued}
                                                                size="small"
                                                                color={issued?.claimDate ? 'success' : 'default'}
                                                                variant="outlined"
                                                                label={bestLocaleMatch(a.title, locale) + (typeof value === 'number' ? ': '+ value : '')}
                                                                sx={{ borderWidth: 2 }}
                                                            />
                                                        );
                                                    })
                                                }
                                            </Stack>
                                        )
                                    }
                                </>
                            );

                            const launchLabel = e.completion === 'NotStarted' ? 'Start' : e.completion === 'Incomplete' ? 'Continue' : 'Review';
                            const launchLink = `/launch/e/${e.id}`;

                            const completionText = (
                                <Typography
                                    color={
                                        e.completion === 'Completed' || e.completion === 'Passed'
                                            ? 'success.main'
                                            : e.completion === 'Failed'
                                            ? 'error.main'
                                            : e.completion === 'Incomplete'
                                            ? 'text.primary'
                                            : 'text.secondary'
                                    }
                                    variant="body2"
                                >
                                    <Label k={e.completion} />
                                    {
                                        typeof e.score === 'number' && ` (Score: ${e.score})`
                                    }
                                </Typography>
                            );

                            const certificatesEarned = e.issuedAwards.filter(a => a.award.type === 'Certificate');

                            const certificatesButton = !!(certificatesEarned.length) && (
                                <AnchorPopover
                                    content={
                                        <List>
                                            {
                                                certificatesEarned.map(c => (
                                                    <ListItemButton
                                                        component="a"
                                                        href={c.url!}
                                                        target={'cert-' + c.id}
                                                        disabled={!c.url || !c.claimDate}
                                                    >
                                                        <ListItemText
                                                            primary={bestLocaleMatch(c.award.title, locale)}
                                                            secondary={c.claimDate ? <DateLabel date={c.claimDate} /> : '--'}
                                                        />
                                                    </ListItemButton>
                                                ))
                                            }
                                        </List>
                                    }
                                >
                                    {
                                        setAnchor => (
                                            <Button
                                                onClick={e => setAnchor(e.currentTarget)}
                                                variant={view === 'list' ? 'contained' : 'text'}
                                                sx={view === 'list' ? { minWidth: 120 } : undefined}
                                            >
                                                <Label k="certificates" />
                                            </Button>
                                        )
                                    }
                                </AnchorPopover>
                            )

                            if (view === 'list') {
                                const description = bestLocaleMatch(e.course.description, locale);
                                return (
                                    <Grid key={e.id} item xs={12}>
                                        <Paper sx={{ p: 2 }}>
                                            <Grid container spacing={2} alignItems="stretch">
                                                <Grid item xs={12} md={3} lg={2}>
                                                    <CardActionArea component={Link} to={launchLink} sx={{ borderRadius: theme => theme.spacing(1) }}>
                                                        <CardMedia
                                                            image={e.course.pictureUrl || themeContext.theme.pictureDefault?.url}
                                                            sx={{
                                                                height: 0,
                                                                width: '100%',
                                                                paddingTop: '56.25%',
                                                                borderRadius: theme => theme.spacing(1),
                                                            }}
                                                        />
                                                    </CardActionArea>
                                                    {completionText}
                                                    <LinearProgress
                                                        color="success"
                                                        value={e.progress}
                                                        variant="determinate"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md>
                                                    {titles}
                                                    {
                                                        description && (
                                                            <RunningText html={description} />
                                                        )
                                                    }
                                                </Grid>
                                                <Grid item xs={12} md="auto" alignSelf="center">
                                                    <Stack direction="column" spacing={1}>
                                                        <Button
                                                            variant="contained"
                                                            sx={{ minWidth: 120 }}
                                                            component={Link}
                                                            to={launchLink}
                                                        >
                                                            {launchLabel}
                                                        </Button>
                                                        {certificatesButton}
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                )
                            }

                            return (
                                <Grid key={e.id} item xs={12} sm={6} md={4} lg={3}>
                                    <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                        <CardActionArea component={Link} to={launchLink}>
                                            <CardMedia
                                                component="img"
                                                src={e.course.pictureUrl || themeContext.theme.pictureDefault?.url}
                                                height={200}
                                            />
                                        </CardActionArea>
                                        <LinearProgress
                                            variant="determinate"
                                            color="success"
                                            value={e.progress}
                                        />
                                        <CardContent sx={{ flexGrow: 1 }}>
                                            {titles}
                                            {completionText}
                                        </CardContent>
                                        <CardActions>
                                            <Button
                                                component={Link}
                                                to={launchLink}
                                            >
                                                {launchLabel}
                                            </Button>
                                            {certificatesButton}
                                        </CardActions>
                                    </Card>
                                </Grid>
                            )
                        })
                    }
                </Grid>
                <Box sx={{ mt: 2 }}>
                    <Paging
                        total={enrollments?.paging.total ?? 0}
                        value={params}
                        onChange={setParams}
                    />
                </Box>
            </Box>
            {
                themeContext.theme.footer && (
                    <UiLayout layout={themeContext.theme.footer.config} />
                )
            }
        </Layout>
    );
}
