import { Input } from "react-ts-form";

import RadioChoiceInput from "../form/inputs/RadioChoiceInput";
import Label from "../core/Label";
import { Shuttle } from '../types';

/**
 * browser-level settings
 */
export default class Settings implements Shuttle.Settings {

    @Input({
        component: RadioChoiceInput.ofType<Shuttle.Mode>(),
        meta: {
            title: <Label k="settings.colorScheme" />
        },
        inputProps: {
            options: ["light", "dark", "auto"],
            choiceRenderer: {
                getKey(o) { return o; },
                getLabel(o) {
                    switch(o) {
                        case "dark":
                            return <Label k="mode.dark" />;
                        case "light":
                            return <Label k="mode.light" />;
                        case "auto":
                            return <Label k="mode.auto" />;
                        default:
                            return null;
                    }
                },
                equals: Object.is
            }
        }
    })
    public mode: Shuttle.Mode = "auto";

    public locale!: string;

    public timeZone!: string;

}
