import { IInputProps } from "react-ts-form";
import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { CSSProperties, ReactNode } from "react";

import { ValidLabelKey } from "../../core/localization/ValidLabelKey";
import useString from "../../hooks/useString";
import Debounced from "../Debounced";

interface ITextInputProps extends IInputProps<string> {
    autoComplete?: string;
    autoFocus?: boolean;
    endAdornment?: ReactNode;
    error?: boolean;
    fullWidth?: boolean;
    floatingLabel?: ReactNode;
    maxLength?: number;
    pattern?: string;
    placeholderKey?: ValidLabelKey; // used as fallback for aria-label if no id prop is provided
    type?: string;
    style?: CSSProperties;
    onKeyDown?: TextFieldProps['onKeyDown'];
    variant?: TextFieldProps["variant"];
}

export default function TextInput(props: ITextInputProps) {
    const placeholder = useString(props.placeholderKey);
    return (
        <TextField
            autoComplete={props.autoComplete}
            autoFocus={props.autoFocus}
            fullWidth={props.fullWidth !== undefined ? props.fullWidth : true}
            disabled={props.disabled}
            id={props.id}
            InputProps={{
                endAdornment: props.endAdornment && (
                    <InputAdornment position="end">
                        {props.endAdornment}
                    </InputAdornment>
                ),
                inputProps: {
                    maxLength: props.maxLength,
                    pattern: props.pattern,
                },
            }}
            error={props.error}
            required={props.required}
            onChange={e => props.onChange(e.target.value)}
            onKeyDown={props.onKeyDown}
            placeholder={placeholder}
            label={props.floatingLabel}
            size="small"
            style={props.style}
            type={props.type}
            value={props.value || ''}
            variant={props.variant}
        />
    );
}


TextInput.Debounced = (props: ITextInputProps) => <Debounced component={TextInput} {...props} />;
