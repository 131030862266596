import { Input } from "react-ts-form";

import Label from "../../core/Label";
import LangStringInput from "../../form/inputs/LangStringInput";
import { Shuttle } from "../../types";

export default class QuestionModel implements Shuttle.QuestionModel {

    @Input({
        component: LangStringInput,
        meta: {
            title: <Label k="stem" />,
            required: true,
        },
        inputProps: {
            type: 'richText',
        },
    })
    public stem: Shuttle.LangString = {}

}
