import { PaginationProps, Pagination } from "@mui/material";

import IControlledProps from "../../model/IControlledProps";
import SearchParams from "../../model/SearchParams";

interface IPagingProps<T extends SearchParams> extends IControlledProps<T> {
    total: number;
    color?: PaginationProps["color"];
    variant?: PaginationProps["variant"];
}

export default function Paging<T extends SearchParams = SearchParams>(props: IPagingProps<T>) {

    const page = typeof props.value.page === 'number' ? Math.max(props.value.page, 0) : 0;
    const count = (props.total > 0 && props.value.itemsPerPage > 0) ? Math.ceil(props.total / props.value.itemsPerPage) : 0;
    if (count < 2) {
        return null;
    }

    return (
        <Pagination 
            color={props.color}
            count={count}
            page={page + 1} // MUI uses 1-index
            onChange={(_, nextPage) => {
                props.onChange({...props.value, page: nextPage - 1});
                window.scrollTo({ top: 0, left: 0 });
            }}
            variant={props.variant}
        />
    );
}
