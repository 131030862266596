import { Shuttle } from "../types";
import VideoIcon from '@mui/icons-material/VideoCameraBack';
import { Suspense } from "react";
import { VideoPlayer } from "../lazy";
import AssetUrlInput from "../form/inputs/AssetUrlInput";
import { Input } from "react-ts-form";
import Label from "../core/Label";

class VideoConfig {

    @Input({
        component: AssetUrlInput,
        meta: {
            title: <Label k="url" />
        }
    })
    public src?: string;

    @Input({
        component: AssetUrlInput,
        meta: {
            title: <Label k="poster" />
        }
    })
    public poster?: string;

}

const VideoElement: Shuttle.LayoutElementType<VideoConfig> = {
    name: 'layout_video',
    Icon: VideoIcon,
    ConfigClass: VideoConfig,
    Component: ({ config: { poster, src } }) => (
        <Suspense fallback={null}>
            <VideoPlayer poster={poster} src={src} />
        </Suspense>
    ),
};

export default VideoElement;
