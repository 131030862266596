import { Input } from "react-ts-form";
import Label from "../../core/Label";
import NumberInput from "../../form/inputs/NumberInput";
import TextAreaInput from "../../form/inputs/TextAreaInput";
import QuestionModel from "../../model/quiz/QuestionModel";
import { IQuestionType } from "../QuestionType";

class TextQuestionModel extends QuestionModel {

    @Input({
        component: NumberInput,
        meta: {
            title: <Label k="max_length" />
        },
        inputProps: {
            min: 0,
        }
    })
    public maxLength?: number;

}

interface TextQuestionResponse {
    text: string;
}

const textQuestionType: IQuestionType<TextQuestionModel, TextQuestionResponse> = {
    name: 'question.type.text',
    clazz: TextQuestionModel,
    Component({ disabled, id, onChange, required, value }) {

        return (
            <TextAreaInput 
                value={value?.text}
                onChange={text => onChange({...value || {}, text})}
                required={required}
                disabled={disabled}
                id={id + '__text'}
            />
        );
    }
};

export default textQuestionType;
