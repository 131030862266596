import { CircularProgress, Collapse, Table, TableBody, TableCell, TableCellProps, TableHead, TableRow } from "@mui/material";
import { Key, ReactNode, useState } from "react";
import LabelledIconButton from "./LabelledIconButton";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Constants from "../../util/Constants";
import { DateLabel } from "../DateLabel";

export interface Col<T> {
    key: Key;
    name?: ReactNode;
    renderCell?: (item: T, index: number, arr: T[]) => React.ReactNode;
    align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
    padding?: TableCellProps['padding'];
    sort?: string;
    invisible?: boolean;
}

interface DataTableProps<T> {
    rows: T[];
    columns: Array<Col<T>>;
    getKey?: (row: T) => Key;
    expand?: (item: T, index: number, arr: T[]) => React.ReactNode;
    loading?: boolean;
    footer?: ReactNode;
    sort?: string;
    setSort?: (sort: string) => void;
    emptyMessage?: ReactNode;
}

export function DataTable<T>({
    columns,
    emptyMessage,
    expand,
    footer,
    getKey = o => (o as any).id,
    loading,
    rows,
}: DataTableProps<T>) {

    const [expanded, setExpanded] = useState<Key[]>([]);

    if (expand) {
        columns = [
            {
                key: '__expand',
                renderCell: item => {
                    const key = getKey(item);
                    const open = expanded.includes(key);
                    return (
                        <LabelledIconButton 
                            icon={open ? KeyboardArrowDownIcon : KeyboardArrowRightIcon}
                            k="expand"
                            onClick={() => setExpanded(prev => prev.includes(key) ? prev.filter(x => x !== key) : [...prev, key])}
                        />
                    )
                },
                padding: 'checkbox',
            },
            ...columns,
        ];
    }

    if (columns.find(c => c.invisible)) {
        columns = columns.filter(c => !c.invisible);
    }

    return (
        <Table>
            <TableHead>
                <TableRow>
                    {
                        columns.map(({ key, name, sort, align, padding }) => {
                            if (sort) {

                            }
                            return (
                                <TableCell key={key} align={align} padding={padding}>
                                    {name}
                                </TableCell>
                            )
                        })
                    }
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    loading
                        ? (
                            <TableRow>
                                <TableCell colSpan={columns.length}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        )
                        : !rows?.length
                        ? (
                            <TableRow>
                                <TableCell colSpan={columns.length}>
                                    {emptyMessage}
                                </TableCell>
                            </TableRow>
                        )
                        : rows.flatMap((item, index, arr) => {
                            const key = getKey(item);
                            const open = expanded.includes(key);
                            const row = (
                                <TableRow key={key}>
                                    {
                                        columns.map(({ key, renderCell, align, padding }) => {

                                            let value: ReactNode;
                                            if (renderCell) {
                                                value = renderCell(item, index, arr);
                                            } else {
                                                value = (item as any)[key];
                                                if (typeof value === 'string' && value.match(Constants.REGEX_ISO_DATE)) {
                                                    value = <DateLabel date={value} format="Ppp" />;
                                                }
                                            }
                                            
                                            return (
                                                <TableCell key={key} align={align} padding={padding}>
                                                    {value}
                                                </TableCell>
                                            )
                                        })
                                    }
                                </TableRow>
                            );
                            if (expand) {
                                return [
                                    row,
                                    <TableRow key={key + '__expand'}>
                                        <TableCell colSpan={columns.length} padding="none">
                                            <Collapse in={open}>
                                                {expand(item, index, arr)}
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                ];
                            }
                            return [row];
                        })
                }
            </TableBody>
            {footer}
        </Table>
    );
}
