import { Suspense } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Player } from "../../lazy";

export default function PlayView({ match: { params: { sessionId } } }: RouteComponentProps<{ sessionId: string; }>) {
    return (
        <Suspense fallback={null}>
            <Player sessionId={sessionId} />
        </Suspense>
    );
}
