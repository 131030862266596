import { IInputProps } from "react-ts-form";
import { Slider, TextField } from "@mui/material";
import { ReactNode } from "react";

interface INumberInputProps extends IInputProps<number> {
    positive?: boolean;
    min?: number;
    max?: number;
    step?: number;
    float?: boolean;
    floatingLabel?: ReactNode;
    slider?: boolean;
}

export default function NumberInput(props: INumberInputProps) {

    if (props.slider) {
        const min = props.min ?? 0;
        const max = Math.max(props.max ?? 100, min);
        const step = props.float ? props.step : 1;
        return (
            <Slider
                value={Math.min(max, Math.max(min, props.value ?? min))}
                onChange={(_, value) => props.onChange(Array.isArray(value) ? value[0] : value)}
                min={min}
                max={max}
                step={step}
                valueLabelDisplay="auto"
                marks={typeof step === 'number'}
                sx={{ mt: 3 }}
            />
        )
    }

    return (
        <TextField
            fullWidth
            disabled={props.disabled}
            id={props.id}
            required={props.required}
            onChange={e => {
                let next = props.float ? parseFloat(e.target.value) : parseInt(e.target.value, 10);
                const min = props.positive ? 0 : props.min;
                const max = props.max;
                if (min !== undefined && min > next) {
                    next = min;
                }
                if (max !== undefined && next > max) {
                    next = max;
                }
                props.onChange(next);
            }}
            label={props.floatingLabel}
            inputProps={{
                step: props.step
            }}
            type="number"
            value={props.value || 0} 
        />
    );
}
