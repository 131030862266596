import dateFormat from "date-fns/locale/pl";
import { plPL } from "@mui/material/locale";
import dictionary from "./pl_PL.json";

import { ILocale } from "../Locale";

const pl_PL: ILocale = {
    rtl: false, // right to left
    dictionary,
    dateFormat,
    muiLocale: plPL
};

export default pl_PL;
//polish