import dateFormat from "date-fns/locale/th";
import { idID } from "@mui/material/locale";
import dictionary from "./th_TH.json";

import { ILocale } from "../Locale";

const th_TH: ILocale = {
    rtl: false, // right to left
    dictionary,
    dateFormat,
    muiLocale: idID //using indonesia in the meantime
};

export default th_TH;
//thai