import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import axios from 'axios';

import App from './App';
import * as serviceWorker from './serviceWorker';

import { AuthService } from './services/AuthService';
import { QuestionTypeRegistry } from './quiz/QuestionType';
import { MultipleChoiceQuestionType } from './quiz/impl/MultipleChoiceQuestionType';
import { MultipleResponseQuestionType } from './quiz/impl/MultipleResponseQuestionType';
import { FlashCardQuestionType } from './quiz/impl/FlashCardQuestionType';
import { behaviorRegistry, conditionRegistry, launcherRegistry, layoutElementRegistry } from './registries';
import { ContainerLauncher } from './launcher/impl/Container';
import { H5PLauncher } from './launcher/impl/H5P';
import { ZoomLauncher } from './launcher/impl/Zoom';
import { ScormLauncher } from './launcher/impl/Scorm';
import { TinCanLauncher } from './launcher/impl/TinCan';
import { AiccLauncher } from './launcher/impl/Aicc';
import { WebLauncher } from './launcher/impl/Web';
import { QuizLauncher } from './launcher/impl/Quiz';
import { LtiToolLauncher } from './launcher/impl/LtiTool';
import { MediaLauncher } from './launcher/impl/Media';
import { CrowdWisdomLauncher } from './launcher/impl/CrowdWisdom';
import { PageLauncher } from './launcher/impl/Page';
import { TextElement } from './layout/TextElement';
import RichTextElement from './layout/RichTextElement';
import ImageElement from './layout/ImageElement';
import VideoElement from './layout/VideoElement';
import EmbedElement from './layout/EmbedElement';
import ExternalLinkElement from './layout/ExternalLinkElement';
import BoxElement from './layout/BoxElement';
import CardElement from './layout/CardElement';
import { GridElement } from './layout/GridElement';
import TabsElement from './layout/TabsElement';
import ExpandElement from './layout/ExpandElement';
import SpacerElement from './layout/SpacerElement';
import { ContentChildrenListElement } from './layout/content/ContentChildrenListElement';
import ContentTagsElement from './layout/content/ContentTagsElement';
import ContentProgressElement from './layout/content/ContentProgressElement';
import ContentActionsElement from './layout/content/ContentActionsElement';
import { InlineLaunchElement } from './layout/content/InlineLaunchElement';
import { ContentGlossaryElement } from './layout/content/ContentGlossaryElement';
import 'iframe-resizer/js/iframeResizer.contentWindow';
import MaxTimeBehavior from './behaviors/MaxTimeBehavior';
import VisibilityTimeoutBehavior from './behaviors/VisibilityTimeoutBehavior';
import wrapperCondition from './conditions/wrapperCondition';
import stateBooleanCondition from './conditions/stateBooleanCondition';
import stateNumberCondition from './conditions/stateNumberCondition';
import stateStringCondition from './conditions/stateStringCondition';
import contentCompletedCondition from './conditions/contentCompletedCondition';
import contentHasChildrenCondition from './conditions/contentHasChildrenCondition';
import contentRequiredCondition from './conditions/contentRequiredCondition';
import textQuestionType from './quiz/impl/TextQuestionType';
import AppProps from './util/AppProps';
import { client } from './player/api';
import { anonymousClient } from './core/clients';
import { contentHasMinTimeCondition } from './conditions/contentHasMinTimeCondition';
import { ContentBookmarkElement } from './layout/content/ContentBookmarkElement';
import { AreYouThereBehavior } from './behaviors/AreYouThereBehavior';
import { contentMenuCondition } from './conditions/contentMenuCondition';
import UserData from './model/UserData';
import { contentMinTimeMetCondition } from './conditions/contentMinTimeSpentCondition';
import { Shuttle } from './types';
import { ContentAwardsElement } from './layout/content/ContentAwardsElement';
import ContentAnnouncementsElement from './layout/content/ContentAnnouncementsElement';

import "video.js/dist/video-js.css";
import 'videojs-sprite-thumbnails'; // auto-assigned to the prototype of Player?
import CopyPreventionBehavior from './behaviors/CopyPreventionBehavior';

async function ShuttleApplication(target: HTMLElement) {

    // call static current tenant api to determine base path
    const tenant = await axios.get<Shuttle.TenantInfo>(AppProps.apiStaticBaseUrl + '/current-tenant', {
        headers: {
            'X-Shuttle-Host': window.location.host,
            'X-Shuttle-Base-Path': window.location.pathname,
        }
    }).then(r => r.data);

    document.title = tenant.displayName ?? tenant.name;

    // assign base path to axios client(s)
    [
        axios.defaults,
        client.defaults,
        anonymousClient.defaults,
    ].forEach(config => {
        config.baseURL = (tenant.basePath || '') + '/apiv1';
    });

    let user: null | UserData = null;
    try {
        const checked = await AuthService.check();
        user = checked.user ?? null;
    } catch (error) {
        console.info(error);
    }

    conditionRegistry
        .put('Wrapper', wrapperCondition)
        .put('Boolean', stateBooleanCondition)
        .put('Number', stateNumberCondition)
        .put('String', stateStringCondition)
        .put('content_completed', contentCompletedCondition)
        .put('content_has_children', contentHasChildrenCondition)
        .put('content_required', contentRequiredCondition)
        .put('content_min_time_spent', contentHasMinTimeCondition)
        .put('content_menu', contentMenuCondition)
        .put('content_min_time_met', contentMinTimeMetCondition);

    launcherRegistry
        .put('container', ContainerLauncher)
        .put('h5p', H5PLauncher)
        .put('zoom', ZoomLauncher)
        .put('scorm', ScormLauncher)
        .put('xapi.tincan', TinCanLauncher)
        .put('aicc', AiccLauncher)
        .put('web', WebLauncher)
        .put('quiz', QuizLauncher)
        .put('lti.tool', LtiToolLauncher)
        .put('multimedia', MediaLauncher)
        .put('CrowdWisdom', CrowdWisdomLauncher)
        .put('Page', PageLauncher);

    QuestionTypeRegistry
        .put('MULTIPLE_CHOICE', MultipleChoiceQuestionType)
        .put('MULTIPLE_RESPONSE', MultipleResponseQuestionType)
        .put('FLASHCARD', FlashCardQuestionType)
        .put('TEXT', textQuestionType);

    layoutElementRegistry
        .put('Text', TextElement)
        .put('RichText', RichTextElement)
        .put('Image', ImageElement)
        .put('Video', VideoElement)
        .put('Embed', EmbedElement)
        .put('ExternalLink', ExternalLinkElement)
        .put('Box', BoxElement)
        .put('Card', CardElement)
        .put('Grid', GridElement)
        .put('Tabs', TabsElement)
        .put('Expand', ExpandElement)
        .put('Spacer', SpacerElement)
        .put('ContentChildrenList', ContentChildrenListElement)
        .put('ContentTags', ContentTagsElement)
        .put('ContentProgress', ContentProgressElement)
        .put('ContentActions', ContentActionsElement)
        .put('ContentGlossary', ContentGlossaryElement)
        .put('ContentBookmark', ContentBookmarkElement)
        .put('ContentAwards', ContentAwardsElement)
        .put('ContentAnnouncements', ContentAnnouncementsElement)
        .put('InlineLaunch', InlineLaunchElement);

    behaviorRegistry
        .put('max_time', MaxTimeBehavior)
        .put('visibility_timeout', VisibilityTimeoutBehavior)
        .put('are_you_there', AreYouThereBehavior)
        .put('copy_prevention', CopyPreventionBehavior);

    ReactDOM.render(
        <Router basename={tenant.basePath || undefined}>
            <App tenant={tenant} user={user} />
        </Router>,
        target
    );
}

const root = document.getElementById('root');

if (!root) {
    throw new Error('invalid render target');
}

ShuttleApplication(root);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
