import { useState } from "react";
import * as React from "react";
import { Step, StepContent, StepLabel, Stepper, StepButton, Box, Button, Typography, CircularProgress } from "@mui/material";

import { Class } from "../util/Class";
import DecoratorInput, { IDecoratorInputProps } from "./DecoratorInput";
import Label from "../core/Label";

export type WizardStep<T extends Class<U> = Class<any>, U extends object = any, V = InstanceType<T>> = {
    clazz: T;
    subClazz?: IDecoratorInputProps<InstanceType<T>>['subClazz'];
    title: React.ReactNode;
    isCompleted(data?: V): boolean;
};

export function createWizardStep<T extends Class<U> = Class<any>, U extends object = any, V = InstanceType<T>>(step: WizardStep<T, U, V>) {
    return step;
}

interface IDecoratorWizardProps<T extends ReadonlyArray<WizardStep>> {
    steps: T;
    onSubmit(data: { [K in keyof T]: T[K] extends WizardStep<infer V> ? InstanceType<V> : never; }): Promise<void>;
    initialValue?: { [K in keyof T]: T[K] extends WizardStep<infer V> ? InstanceType<V> : never; };
    context?: object;
    nonLinear?: boolean;
    vertical?: boolean;
}

export function DecoratorWizard<T extends WizardStep[]>(props: IDecoratorWizardProps<T>) {

    type ValueTypes = { [K in keyof T]: T[K] extends WizardStep<infer V> ? InstanceType<V> : never; };

    const [activeStep, setActiveStep] = useState(0);
    const [data, setData] = useState<ValueTypes>(props.initialValue || [] as any);
    const [submitting, setSubmitting] = useState(false);

    const getChangeHandler = (index: number) => (value: any) => setData(props.steps.map((step, i) => index === i ? value : data[i] || new step.clazz()) as ValueTypes);

    const toolbar = (
        <Box padding={2}>
            <Button disabled={activeStep < 1} onClick={() => setActiveStep(activeStep - 1)}>
                <Label k="back" />
            </Button>
            {
                activeStep === props.steps.length - 1 ? (
                    <Button 
                        variant="contained" 
                        color="primary" 
                        disabled={submitting || props.steps.some((step, index) => !step.isCompleted(data?.[index]))}
                        onClick={
                            async () => {
                                setSubmitting(true);
                                try {
                                    await props.onSubmit(data);
                                    setSubmitting(false);
                                } catch (e) {
                                    console.error(e);
                                    setSubmitting(false);
                                }
                            }
                        }
                    >
                        {
                            submitting ? <CircularProgress size={22} /> : <Label k="submit" />
                        }
                    </Button>
                ) : (
                    <Button 
                        variant="contained" 
                        color="primary"
                        onClick={() => setActiveStep(activeStep + 1)}
                    >
                        <Label k="next" />
                    </Button>
                )
            }
        </Box>
    );

    return (
        <React.Fragment>
            <Stepper 
                orientation={props.vertical ? "vertical" : "horizontal"}
                activeStep={activeStep}
                nonLinear={props.nonLinear}
            >
                {
                    props.steps.map((step, index) => {
                        const completed = step.isCompleted(data?.[index]);

                        let content = (
                            <StepLabel key="label" error={activeStep > index && !completed}>
                                {step.title}
                            </StepLabel>
                        );

                        if (props.nonLinear) {
                            content = (
                                <StepButton onClick={() => setActiveStep(index)}>
                                    {content}
                                </StepButton>
                            );
                        }

                        return (
                            <Step key={index + '-' + step.clazz.name}>
                                {content}
                                {
                                    props.vertical && activeStep === index && (
                                        <StepContent>
                                            <DecoratorInput 
                                                clazz={step.clazz} 
                                                subClazz={step.subClazz}
                                                value={data[index]}
                                                onChange={getChangeHandler(index)}
                                                context={props.context}
                                            />
                                            {toolbar}
                                        </StepContent>
                                    )
                                }
                            </Step>
                        );
                    })
                }
            </Stepper>
            {
                !props.vertical && (
                    <React.Fragment>
                        <Box padding={2}>
                            <Typography variant="h5" component="div">
                                {props.steps[activeStep].title}
                            </Typography>
                            <DecoratorInput 
                                clazz={props.steps[activeStep].clazz} 
                                subClazz={props.steps[activeStep].subClazz}
                                value={data[activeStep]}
                                onChange={getChangeHandler(activeStep)}
                                context={props.context}
                            />
                        </Box>
                        {toolbar}
                    </React.Fragment>
                )
            }
        </React.Fragment>
    );
}
