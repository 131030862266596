import ContentTypeService from "../services/ContentTypeService";
import { PropertyChoiceRender, LabelKeyChoiceRenderer, DefaultChoiceRender } from "./IOptionsProps";
import ContentTypeData from "../model/ContentTypeData";
import DropDownChoiceInput from "./inputs/DropDownChoiceInput";
import ContentData from "../model/ContentData";
import ContentService from "../services/ContentService";
import { ValidLabelKey } from "../core/localization/ValidLabelKey";
import { getLocaleChoices } from "../i18n/loaders";
import Label from "../core/Label";
import { Grid, GridSize } from "@mui/material";
import ListMultipleChoiceInput from "./inputs/ListMultipleChoiceInput";
import TagData from "../model/TagData";
import TagService from "../services/TagService";
import ThemeData from "../model/ThemeData";
import ThemeService from "../services/ThemeService";
import timezones from "../i18n/timezones";
import ListSingleChoiceInput from "./inputs/ListSingleChoiceInput";
import QuizQuestionService from "../services/QuizQuestionService";
import ContributorService from "../services/ContributorService";
import TemplateService from "../services/TemplateService";
import UserService from "../services/UserService";
import ConditionData from "../model/ConditionData";
import ConditionService from "../services/ConditionService";
import TemplateData from "../model/TemplateData";
import GlossaryData from "../model/GlossaryData";
import GlossaryService from "../services/GlossaryService";
import Collector from "../model/Collector";
import CollectorService from "../services/CollectorService";
import BehaviorData from "../model/BehaviorData";
import BehaviorService from "../services/BehaviorService";
import ConsumerService from "../services/ConsumerService";
import { Group } from "../model/Group";
import GroupService from "../services/GroupService";
import UserData from "../model/UserData";
import { Consumer } from "../model/Consumer";
import * as templates from './templates';
import { IFormMeta, IInputTemplateProps } from "react-ts-form";
import { Webhook } from "../model/Webhook";
import { WebhookService } from "../services/WebhookService";
import { AuthProvider } from "../model/AuthProvider";
import AuthProviderService from "../services/AuthProviderService";
import ConsumerContentData from "../model/ConsumerContentData";
import SearchParams from "../model/SearchParams";
import { AwardType } from "../model/Award";
import AwardTypeService from "../services/AwardTypeService";

/**
 * commonly used input components, typically wrappers for other generic
 * input components with some domain-specific default props.
 */

export const ContentTypeInput = ListSingleChoiceInput.forOptions<ContentTypeData>(
    ContentTypeService.get().search,
    new PropertyChoiceRender("name")
);

export const ContentTypeNameMultiInput = ListMultipleChoiceInput.forOptions<string>(
    params => ContentTypeService.get().search(params).then(res => ({paging: res.paging, items: res.items?.map(t => t.name)})),
    DefaultChoiceRender
);

export const ContentInput = ListSingleChoiceInput.forOptions<ContentData>(
    async params => ContentService.search(params).then(res => res.data),
    {
        getKey(content) {
            return content.id
        },
        getLabel: content => content?.name,
        equals(left, right) {
            return Object.is(left?.uuid, right?.uuid);
        },
        getSubtitle(content) {
            return content.type.name;
        }
    }
);

export const ContentMultiInput = ListMultipleChoiceInput.of<ContentData>({
    options: async params => ContentService.search(params).then(r => r.data),
    choiceRenderer: {
        getKey(content) {
            return content.id
        },
        getLabel: content => content?.name,
        equals(left, right) {
            return Object.is(left?.uuid, right?.uuid);
        },
        getSubtitle(content) {
            return content.type.name;
        }
    },
})

export const LocaleInput = DropDownChoiceInput.of<string>({
    options: getLocaleChoices() as ValidLabelKey[],
    choiceRenderer: LabelKeyChoiceRenderer,
});

export const LocaleMultiInput = ListMultipleChoiceInput.of<string>({
    options: getLocaleChoices() as ValidLabelKey[],
    choiceRenderer: LabelKeyChoiceRenderer,
    params: {
        itemsPerPage: 100,
    },
});

export const TimeZoneInput = DropDownChoiceInput.of<string>({
    options: timezones,
});

export const EnrollmentStatusInput = DropDownChoiceInput.of<'Active' | 'Expired' | 'Finished' | 'Deleted' | 'Archived'>({
    options: ['Active', 'Expired', 'Finished', 'Deleted', 'Archived']
});

export const GridSizeInput = DropDownChoiceInput.of<GridSize | true | 'hidden'>({
    options: ['auto', true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 'hidden'],
    choiceRenderer: {
        getKey(o) {
            return "" + o;
        },
        getLabel(o) {
            if (o === 'auto') {
                return <Label k="auto" />;
            }
            if (o === true) {
                return <Label k="fill.space" />
            }
            return o;
        },
        equals: Object.is
    }
});

export const LayoutSizeInput = DropDownChoiceInput.of<'Normal' | 'Small' | 'Large' | 'Fluid'>({
    options: ['Normal', 'Small', 'Large', 'Fluid'],
    choiceRenderer: DefaultChoiceRender
});

export const TagMultiInput = ListMultipleChoiceInput.forOptions<TagData>(
    async params => TagService.search(params).then(r => r.data),
    new PropertyChoiceRender('name')
);

export const TagNameMultiInput = ListMultipleChoiceInput.forOptions<string>(
    async params => TagService.search(params).then(res => ({paging: res.data.paging, items: res.data.items?.map(t => t.name)})),
    DefaultChoiceRender
);

export const ThemeInput = ListSingleChoiceInput.of<ThemeData>({
    options: async params => ThemeService.search(params).then(r => r.data),
    choiceRenderer: {
        getKey: t => t.id,
        getLabel: t => t?.name,
        equals: (left, right) => left?.id === right?.id
    },
    entityRemoveToken: true,
});

export const QuestionsMultiInput = ListMultipleChoiceInput.forOptions(
    async params => QuizQuestionService.search(params).then(r => r.data),
    {
        getKey: t => t.id,
        getLabel: t => t?.name,
        equals: (left, right) => left?.id === right?.id
    }
);

export const ContributorInput = ListSingleChoiceInput.forOptions(
    async params => ContributorService.search(params).then(r => r.data),
    {
        getKey: t => t.id,
        getLabel: t => t?.name,
        equals: (left, right) => left?.id === right?.id
    }
);

export const ContentContributorTypeInput = DropDownChoiceInput.of<'General' | 'Author' | 'Coauthor' | 'Illustrator' | 'Speaker' | 'Presenter'>({
    options: ['General', 'Author', 'Coauthor', 'Illustrator', 'Presenter', 'Speaker']
});

export const ContributorMultiInput = ListMultipleChoiceInput.forOptions(
    async params => ContributorService.search(params).then(r => r.data),
    {
        getKey: t => t.id,
        getLabel: t => t?.name,
        equals: (left, right) => left?.id === right?.id
    }
);

export const TemplateInput = ListSingleChoiceInput.of<TemplateData>({
    options: async params => TemplateService.search(params).then(r => r.data),
    choiceRenderer: {
        getKey: t => t.id,
        getLabel: t => t?.name,
        equals: (left, right) => left?.id === right?.id
    },
    entityRemoveToken: true,
});

export const RoleMultiInput = ListMultipleChoiceInput.forOptions(
    [
        'ROLE_USER',
        'ROLE_REVIEWER',
        'ROLE_SUPPORT',
        'ROLE_AUTHOR',
        'ROLE_ADMIN',
    ],
    DefaultChoiceRender,
);

export const UserInput = ListSingleChoiceInput.forOptions<UserData, any>(
    params => UserService.search({...params, sortBy: ["firstName", "lastName"]}).then(r => r.data),
    {
        getKey(user) {
            return user.id;
        },
        getLabel(user) {
            return user ? `${user.firstName} ${user.lastName} (${user.externalId || 'no external ID'})` : '';
        },
        equals(left, right) {
            return !!(left && right && left.id === right.id);
        }
    }
);

export const UserMultiInput = ListMultipleChoiceInput.of<UserData, any>({
    options: params => UserService.search({...params, sortBy: ["firstName", "lastName"]}).then(r => r.data),
    choiceRenderer: {
        getKey(user) {
            return user.id;
        },
        getLabel(user) {
            return user ? `${user.firstName} ${user.lastName} (${user.externalId || 'no external ID'})` : '';
        },
        equals(left, right) {
            return !!(left && right && left.id === right.id);
        }
    },
})

export const FontInput = DropDownChoiceInput.of({
    options: ['Open_Sans', 'Roboto', 'Montserrat', 'Nunito', 'Quicksand', 'IBM_Plex_Sans', 'Inter', 'Poppins']
});

export const ColorRefInput = DropDownChoiceInput.of({
    options: [
        'initial',
        'text.primary',
        'text.secondary',
        'divider',
        ...[
            'primary', 'secondary', 'info', 'success', 'warning', 'error'
        ].reduce((a, c) => {

            a.push(...['main', 'light', 'dark', 'contrastText'].map(s => [c, s].join('.')));

            return a;
        }, [] as string[])
    ],
});

export const ConditionInput = ListSingleChoiceInput.of<ConditionData>({
    options: params => ConditionService.search(params).then(r => r.data),
    choiceRenderer: {
        getKey: o => o.id,
        getLabel: o => o.name,
        equals: (left, right) => left.id === right.id,
    },
    entityRemoveToken: true,
});

export const GlossaryInput = ListSingleChoiceInput.of<GlossaryData>({
    options: params => GlossaryService.search(params).then(r => r.data),
    choiceRenderer: {
        getKey: o => o.id,
        getLabel: o => o.name,
        equals: (left, right) => left.id === right.id,
    },
    entityRemoveToken: true,
});

export const CollectorInput = ListSingleChoiceInput.of<Collector>({
    options: params => CollectorService.search(params).then(r => r.data),
    choiceRenderer: {
        getKey: o => o.id,
        getLabel: o => o.name,
        equals: (left, right) => left.id === right.id,
    },
    entityRemoveToken: true,
});

export const ConsumerInput = ListSingleChoiceInput.of<any>({
    options: params => ConsumerService.search(params).then(r => r.data),
    choiceRenderer: {
        getKey: o => o.id,
        getLabel: o => o.name,
        equals: (left, right) => left.id === right.id,
    },
});

export const ConsumerMultiInput = ListMultipleChoiceInput.of<Consumer>({
    options: params => ConsumerService.search(params).then(r => r.data),
    choiceRenderer: {
        getKey: o => o.id,
        getLabel: o => o.name,
        equals: (left, right) => left.id === right.id,
    },
})

export const BehaviorMultiInput = ListMultipleChoiceInput.of<BehaviorData>({
    options: params => BehaviorService.search(params).then(r => r.data),
    choiceRenderer: {
        getKey: o => o.id,
        getLabel: o => o.name,
        equals: (left, right) => left.id === right.id,
    },
});

export const GroupInput = ListSingleChoiceInput.of<Group, any>({
    options: params => GroupService.search(params).then(r => r.data),
    choiceRenderer: {
        getKey: o => o.id,
        getLabel: o => o.name,
        equals: (left, right) => left.id === right.id,
    },
});

export const WebhookInput = ListSingleChoiceInput.of<Webhook, any>({
    options: params => WebhookService.search(params).then(r => r.data),
    choiceRenderer: {
        getKey: o => o.id,
        getLabel: o => o.name,
        equals: (left, right) => left.id === right.id,
    },
});

export const AuthProviderInput = ListSingleChoiceInput.of<AuthProvider>({
    options: async params => await AuthProviderService.search(params).then(r => r.data),
    choiceRenderer: {
        getKey: o => o.id,
        getLabel: o => o.name,
        equals: (left, right) => left.id === right.id,
    },
})

export const ConsumerContentMultiInput = ListMultipleChoiceInput.of<ConsumerContentData, SearchParams & { consumerId?: number }>({
    options: async ({ consumerId, ...params }) => {
        if (consumerId) {
            return await ConsumerService.searchContents(consumerId, params).then(r => r.data);
        }
        return { items: [], paging: { total: 0 } };
    },
    choiceRenderer: {
        getKey: o => o.content.id,
        getLabel: o => o.content.name,
        equals: (left, right) => left.content.id === right.content.id,
    },
})

export const horizontalTemplates: IFormMeta = {
    ...templates,
    InputTemplate: (props: IInputTemplateProps) => (
        <Grid item xs="auto">
            <templates.InputTemplate {...props} />
        </Grid>
    ),
};

export const AwardTypeChoiceInput = ListSingleChoiceInput.of<AwardType>({
    options: async params => await AwardTypeService.search(params).then(r => r.data),
    choiceRenderer: {
        getKey: o => o.id,
        getLabel: o => o.name,
        equals: (left, right) => left.id === right.id,
    },
});

