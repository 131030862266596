import { Input } from "react-ts-form";
import Label from "../core/Label";
import DropDownChoiceInput from "../form/inputs/DropDownChoiceInput";
import SwitchInput from "../form/inputs/SwitchInput";
import TextInput from "../form/inputs/TextInput";
import { resolvePath } from "../player/utils";
import { Shuttle } from "../types";

class ContentCompletionCondition {

    @Input({
        component: DropDownChoiceInput,
        meta: {
            title: <Label k="scope" />,
            required: true,
        },
        inputProps: {
            options: ['self', 'siblings']
        }
    })
    public scope: 'self' | 'siblings' = 'self';

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="target" />,
        },
        exclude: (_, { parent }) => parent.scope !== 'siblings',
    })
    public target!: string;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="previous-siblings" />,
        },
        exclude: (_, { parent }) => parent.scope !== 'siblings',
    })
    public previous!: boolean;
}

const contentCompletedCondition: Shuttle.Condition<ContentCompletionCondition> = {
    name: 'condition_content_completed',
    ConfigClass: ContentCompletionCondition,
    evaluate: ({ config: { scope, target, previous } }, { contentNode, root, tracking }) => {

        const contents: Shuttle.ContentNodeContent[] = [];

        if (scope === 'siblings') {

            const path = resolvePath(root, contentNode.content.path);

            if (path && path.length > 1) {
                const parent = path[path.length - 2].content;
                parent.children.forEach(child => {
                    if (child.id !== contentNode.id && (!target || target === child.target) && (!previous || child.index < contentNode.index)) {
                        contents.push(child.content);
                    }
                })
            }

        } else {
            contents.push(contentNode.content);
        }

        return contents.every(({ uuid }) => tracking[uuid]?.completion === 'Completed' || tracking[uuid]?.completion === 'Passed');
    }
};

export default contentCompletedCondition;
