import * as React from "react";
import { Dialog, DialogTitle, Typography, DialogContent, DialogActions, IconButton, DialogProps, Divider, useTheme } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

interface IDialogProps {
    title?: React.ReactNode;
    actions?: React.ReactNode;
    dividers?: boolean;
    maxWidth?: DialogProps["maxWidth"];
    noPadding?: boolean;
    open: boolean;
    onClose?: () => void;
    scroll?: DialogProps["scroll"];
}

export default function ModalDialog(props: React.PropsWithChildren<IDialogProps>) {
    const theme = useTheme();

    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth={props.maxWidth || "md"} scroll={props.scroll}>
            {
                props.title && (
                    <DialogTitle sx={{ margin: 0, padding: theme.spacing(2) }}>
                        <Typography variant="h6" component="div">
                            {props.title}
                        </Typography>
                        {
                            props.onClose && (
                                <IconButton
                                    onClick={props.onClose}
                                    sx={{
                                        color: theme.palette.grey[500],
                                        position: 'absolute',
                                        right: theme.spacing(1),
                                        top: theme.spacing(1)
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            )
                        }
                    </DialogTitle>
                )
            }
            {
                props.noPadding ? (
                    <React.Fragment>
                        {props.dividers && <Divider />}
                        {props.children}
                        {props.dividers && <Divider />}
                    </React.Fragment>
                ) : (
                    <DialogContent dividers={props.dividers}>
                        {props.children}
                    </DialogContent>
                )
            }
            {
                props.actions && (
                    <DialogActions>
                        {props.actions}
                    </DialogActions>
                )
            }
        </Dialog>
    );
}
