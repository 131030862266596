import { Shuttle } from "../types";
import EmbedIcon from "@mui/icons-material/Code";
import SwitchInput from "../form/inputs/SwitchInput";
import Label from "../core/Label";
import { Input } from "react-ts-form";
import TextInput from "../form/inputs/TextInput";
import iFrameResize from 'iframe-resizer/js/iframeResizer';
import { CSSProperties } from "react";
import StringUtil from "../util/StringUtil";
import useLayoutString from "./useLayoutString";

class EmbedElementConfig {

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="url" />,
            required: true,
        }
    })
    public url?: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="title" />,
            required: true,
        }
    })
    public title!: string;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="responsive" />
        }
    })
    public responsive?: boolean;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="dimension" />
        },
    })
    public dimension!: string;

}

const EmbedElement: Shuttle.LayoutElementType<EmbedElementConfig> = {
    name: 'layout_embed',
    Icon: EmbedIcon,
    ConfigClass: EmbedElementConfig,
    Component({ config: { dimension, responsive, title, url } }) {

        url = useLayoutString({ text: { x: url ?? '' } });

        if (!url) return null;

        const style: CSSProperties = {
            width: '1px',
            minWidth: '100%',
            border: 0,
            backgroundColor: 'transparent',
        };

        if (!responsive) {
            style.position = 'absolute';
            style.height = '100%';
        }

        return (
            <div 
                style={responsive ? undefined : {
                    height: 0,
                    position: 'relative',
                    overflow: 'hidden',
                    paddingTop: (StringUtil.parseDimension(dimension) * 100) + '%'
                }}
            >
                <iframe 
                    src={url}
                    onLoad={responsive ? e => iFrameResize({}, e.currentTarget) : undefined}
                    style={style}
                    title={title}
                />
            </div>
        );
    }
};

export default EmbedElement;
