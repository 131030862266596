import { Link } from "@mui/material";
import { ReactNode, useContext } from "react";
import { ConstraintsContext, ContainerNavContext, SettingsContext } from "../contexts";
import Label from "../core/Label";
import bestLocaleMatch from "../i18n/util";
import LayoutString from "../layout/LayoutString";
import { Shuttle } from "../types";
import { getAlternative, isLocked } from "./utils";

interface LockedMessageProps {
    contentNode: Shuttle.ContentNode;
    defaultLockedMessage?: ReactNode;
    defaultPrerequisitesMessage?: ReactNode;
    hidePrerequisitesList?: boolean;
}

export default function LockedMessage({ contentNode, defaultLockedMessage, defaultPrerequisitesMessage, hidePrerequisitesList }: LockedMessageProps) {

    const constraints = useContext(ConstraintsContext);
    const [settings] = useContext(SettingsContext)
    const navCtx = useContext(ContainerNavContext);

    const reason = constraints.locked[contentNode.content.path]?.[0];

    if (reason) {

        if (reason.type === 'condition') {
            return (
                <div>
                    {
                        bestLocaleMatch(reason.condition.failedMessage ?? {}, settings.locale)
                        || defaultLockedMessage
                        || <Label k="locked" />
                    }
                </div>
            );
        }
        
        if (reason.type === 'sequencing') {
            return (
                <div>
                    {
                        defaultPrerequisitesMessage
                        || <Label k="required_prerequisites" />
                    }
                    {
                        !hidePrerequisitesList && (
                            <ol>
                                {
                                    reason.prerequisites.map(n => {

                                        const content = getAlternative(constraints, n);

                                        if (navCtx && !isLocked(constraints, n)) {
                                            
                                            return (
                                                <li key={content.path}>
                                                    <Link href="#" onClick={() => navCtx.setActivePath(content.path)} color="inherit">
                                                        <LayoutString text={content.title} />
                                                    </Link>
                                                </li>
                                            );
                                        }

                                        return (
                                            <li key={content.path}>
                                                <LayoutString text={content.title} />
                                            </li>
                                        );
                                    })
                                }
                            </ol>
                        )
                    }
                </div>
            );
        }

    }

    return null;
}
