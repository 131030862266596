import { Input } from "react-ts-form";
import TextInput from "../../form/inputs/TextInput";
import Label from "../../core/Label";
import { Shuttle } from "../../types";
import CwIcon from "@mui/icons-material/AcUnit";
import Embed from "../Embed";

class CrowdWisdomConfig {

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="contentId" />
        }
    })
    public contentId!: string;

}

export const CrowdWisdomLauncher: Shuttle.Launcher = {
    name: 'launcher.crowdwisdom',
    Icon: CwIcon,
    Component: Embed,
    hasSystem: true,
    ConfigClass: CrowdWisdomConfig,
};
