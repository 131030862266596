import { Shuttle } from "../../types";
import ContainerIcon from "@mui/icons-material/AccountTree";
import AppLayout from "../../core/Layout";
import { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { ConstraintsContext, ContainerNav, ContainerNavContext, SessionContext } from "../../contexts";
import { Alert, Box, Breadcrumbs, Button, Collapse, Divider, Grid, LinearProgress, Link, List, ListItemButton, ListItemIcon, ListItemText, Modal, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import Label from "../../core/Label";

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CompletedIcon from "@mui/icons-material/CheckCircleOutlined";
import FailedIcon from "@mui/icons-material/CancelOutlined";
import { Layout } from "../../core/ui/Layout";
import { Input } from "react-ts-form";
import SwitchInput from "../../form/inputs/SwitchInput";
import { LayoutInput } from "../../form/inputs/LayoutInput";
import { getAlternative, isHidden, isLocked } from "../../player/utils";
import PlayContent from "../../player/PlayContent";
import LayoutString from "../../layout/LayoutString";
import ProgressCircle from "../../core/ui/ProgressCircle";
import BrowserUtil from "../../util/BrowserUtil";
import StringUtil from "../../util/StringUtil";
import LangStringInput from "../../form/inputs/LangStringInput";
import DropDownChoiceInput from "../../form/inputs/DropDownChoiceInput";
import { Add } from "@mui/icons-material";
import TextInput from "../../form/inputs/TextInput";
import ModalDialog from "../../core/ui/ModalDialog";

class ToolbarItem {

    public id!: string;

    @Input({
        component: LangStringInput,
        meta: {
            title: 'Label',
        }
    })
    public label!: Shuttle.LangString;

    @Input({
        component: DropDownChoiceInput.of({
            options: ['link', 'dialog'],
        })
    })
    public type: 'link' | 'dialog' = 'link';

    @Input((_, { parent }) => (parent.type === 'link' ? {
        component: TextInput,
        meta: {
            title: 'URL',
        },
    } : {}))
    public url?: string;

    @Input((_, { parent }) => (parent.type === 'dialog' ? {
        component: LayoutInput,
        meta: {
            title: 'Layout',
        },
    } : {}))
    public layout?: Shuttle.LayoutConfig;

}

class ContainerConfig {

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="menu" />
        }
    })
    public menu!: boolean;

    @Input({
        component: LayoutInput,
        meta: {
            title: <Label k="layout" />
        },
    })
    public layout!: Shuttle.LayoutConfig;

    @Input({
        clazz: ToolbarItem,
        meta: {
            title: 'Toolbar',
        },
        array: {
            sort: true,
            remove: true,
            addComponent: ({ onAdd }) => (
                <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    startIcon={<Add />}
                    onClick={() => {
                        const item = new ToolbarItem();
                        item.id = StringUtil.uuid();
                        onAdd([item]);
                    }}
                >
                    Add
                </Button>
            )
        }
    })
    public toolbar?: ToolbarItem[];

}

export const ContainerLauncher: Shuttle.Launcher = {
    name: 'launcher.container',
    Icon: ContainerIcon,
    tracking: true,
    disableDefaultToolbar: true,
    ConfigClass: ContainerConfig,
    Component: (props) => {
        if (useContext(ContainerNavContext)) {
            return (
                <ContainerLayout session={props.session} />
            );
        }
        return (
            <ContainerComponent {...props} />
        );
    },
};

const trimPath = (basePath: string, path: string) => path.startsWith(basePath) ? path.substring(basePath.length) : path;

function ContainerComponent({ contentNode, entryPoint, footer, inline, session, toolbar, track: { commit, tracking } }: Shuttle.LauncherProps) {
    const muiTheme = useTheme();
    const constraints = useContext(ConstraintsContext);
    const basePath = contentNode.content.path;
    const activePath = (tracking?.bookmark || ''); // path starting at current content node, not entry point
    const ref = useRef<HTMLDivElement>(null);
    const [openToolbarItem, setOpenToolbarItem] = useState('');

    const config = session.config as ContainerConfig;

    const rootHasLayout = Boolean(config?.layout)
    const parsed = useMemo(() => parsePath(constraints, contentNode, activePath, rootHasLayout), [constraints, contentNode, activePath, rootHasLayout]);

    if (config.toolbar?.length) {
        toolbar = (
            <Stack direction="row" alignItems="center" spacing={1}>
                {
                    config.toolbar.map(item => {
                        if (item.type === 'dialog') {
                            return (
                                <Button variant="text" color="inherit" size="small" onClick={() => setOpenToolbarItem(item.id)}>
                                    <LayoutString text={item.label} />
                                </Button>
                            )
                        }
                        return (
                            <Button variant="text" color="inherit" size="small" component="a" target="_blank" href={item.url} disabled={!item.url}>
                                <LayoutString text={item.label} />
                            </Button>
                        );
                    })
                }
                {toolbar}
            </Stack>
        );
    }

    // anchor link auto scroll
    const targetId = parsed?.target?.contentNode.id.toString();
    useEffect(() => {
        if (targetId) {
            setTimeout(() => {
                const target = document.querySelector(`[data-simpatico-content='${targetId}']`);
                target?.scrollIntoView?.({
                    behavior: 'smooth',
                    block: 'center',
                });
            }, 500);
        }
    }, [targetId]);

    const resolvedPath = parsed?.current[parsed.current.length - 1]?.content.path ?? '';
    const resolvedPathRef = useRef(resolvedPath);
    resolvedPathRef.current = resolvedPath;

    const setActivePath = useCallback(async (activePath: string) => {
        const prev = trimPath(basePath, resolvedPathRef.current);
        const bookmark = trimPath(basePath, activePath);
        if (ref.current && prev !== bookmark) {
            // only redirect these if we're actually changing paths
            await BrowserUtil.closeContentFrames(ref.current);
        }
        commit({ bookmark });
        window.scrollTo(0, 0);
    }, [basePath, commit]);


    const nav = useMemo<ContainerNav>(() => ({
        activePath,
        setActivePath,
    }), [activePath, setActivePath]);

    if (!parsed) {
        return (
            <Alert
                action={
                    <Button color="inherit" size="small" onClick={() => setActivePath('')}>
                        <Label k="back" />
                    </Button>
                }
                severity="info"
            >
                <Label k="notfound" />
            </Alert>
        );
    }

    const { prev, current, next, menuRoot } = parsed;

    const prevContent = prev.length ? prev[prev.length - 1] : null;
    const nextContent = next.length ? next[next.length - 1] : null;

    const menu = menuRoot && (
        <ContentMenu 
            content={menuRoot.contentNode} 
            dense
            activePath={current[current.length - 1]?.content.path || ''}
            setActivePath={setActivePath}
        />
    );

    const toolbarItem = config.toolbar?.find(i => i.id === openToolbarItem);

    const body = (
        <ContainerNavContext.Provider value={nav}>
            {
                current.length > 1 && (
                    <Breadcrumbs maxItems={3} sx={{ p: 2 }}>
                        {
                            current.map((item, index, arr) => {

                                if (index === arr.length - 1) {
                                    return (
                                        <Typography key={item.content.path} color="text.primary">
                                            <LayoutString text={item.content.title} />
                                        </Typography>
                                    );
                                }

                                return (
                                    <Link 
                                        key={item.content.path}
                                        component="button" 
                                        underline="hover" 
                                        color="inherit"
                                        onClick={() => setActivePath(item.content.path)}
                                        variant="body1"
                                    >
                                        {
                                            index === 0
                                                ? <Label k="home" />
                                                : <LayoutString text={item.content.title} />
                                        }
                                    </Link>
                                );
                            })
                        }
                    </Breadcrumbs>
                )
            }
            {
                current.length > 1 ? <PlayContent contentNode={current[current.length - 1].contentNode} /> : <ContainerLayout session={session} />
            }
            {
                (prevContent || nextContent) && (
                    <Stack
                        component="nav"
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        p={1}
                        sx={{
                            backgroundColor: muiTheme.palette.grey[muiTheme.palette.mode === 'dark' ? 900 : 100],
                        }}
                    >
                        {
                            prevContent && (
                                <Button 
                                    variant="contained"
                                    disableElevation
                                    color="primary"
                                    size="small"
                                    disabled={isLocked(constraints, prevContent.contentNode)}
                                    onClick={() => setActivePath(prevContent.content.path)}
                                    sx={{ maxWidth: 250 }}
                                >
                                    <Typography variant="subtitle2" noWrap textOverflow="ellipsis">
                                        <Label k="previous" />
                                        {': '}
                                        <LayoutString text={prevContent.content.title} />
                                    </Typography>
                                </Button>
                            )
                        }
                        <div style={{ flexGrow: 1 }} />
                        {
                            nextContent && (
                                <Button 
                                    variant="contained"
                                    disableElevation
                                    color="primary"
                                    size="small"
                                    disabled={isLocked(constraints, nextContent.contentNode)}
                                    onClick={() => setActivePath(nextContent.content.path)}
                                    sx={{ maxWidth: 250 }}
                                >
                                    <Typography variant="subtitle2" noWrap textOverflow="ellipsis">
                                        <Label k="next" />
                                        {': '}
                                        <LayoutString text={nextContent.content.title} />
                                    </Typography>
                                </Button>
                            )
                        }
                    </Stack>
                )
            }
            <ModalDialog
                open={!!toolbarItem}
                onClose={() => setOpenToolbarItem('')}
                title={<LayoutString text={toolbarItem?.label} />}
            >
                {
                    !!toolbarItem?.layout?.root && (
                        <Layout layout={toolbarItem.layout} />
                    )
                }
            </ModalDialog>
        </ContainerNavContext.Provider>
    );

    if (!entryPoint || inline) {
        return (
            <Grid container spacing={2}>
                {
                    menu && (
                        <Grid item xs={12} lg={3}>
                            {menu}
                        </Grid>
                    )
                }
                <Grid item xs={12} lg={menu ? 9 : 12}>
                    {body}
                </Grid>
            </Grid>
        );
    }

    return (
        <div ref={ref}>
            <AppLayout drawer={menu} rightElement={toolbar} fullscreen onClickLogo={current.length > 1 ? () => setActivePath(current[0].content.path) : undefined}>
                {body}
                {footer}
            </AppLayout>
        </div>
    );
}

function parsePath(
    constraints: Shuttle.Constraints,
    contentNode: Shuttle.ContentNode,
    path: string,
    rootHasLayout?: boolean
) {

    let node = { contentNode, content: getAlternative(constraints, contentNode) };

    const current = [node];

    for (const part of path.split('/').filter(p => p?.startsWith('c')).map(p => p.substring(1))) {
        const child = node?.content.children?.find(n => n.id + '' === part);
        if (!child) {
            return null;
        }
        current.push(node = { contentNode: child, content: getAlternative(constraints, child) });
    }

    // auto-recurse to first non-menu node if root doesn't have a layout
    if (!(rootHasLayout && (!path || path === '/'))) {
        while (node?.content.menu && node.content.children.length) {
            const childNode = node.content.children[0];
            current.push(node = { contentNode: childNode, content: getAlternative(constraints, childNode) });
        }
    }

    // "anchor" links within pages
    let target: undefined | typeof current[number] = undefined;
    if (current.length > 2) {
        // check if parent of leaf is a page
        const parent = current[current.length - 2];
        if (parent.content.launcher === 'Page') {
            // if so, set anchor link target
            target = current.pop();
        }
    }

    let menuRoot: null | typeof node = null;
    let i = current.length - 2;
    while (i > -1) {
        const n = current[i--];
        if (!n.content.menu) break;
        menuRoot = n;
    }

    let parent: typeof node | undefined;
    let prev = [...current];
    let optNode = prev.pop();
    prevLoop: while (optNode && prev.length) {
        parent = prev[prev.length - 1];
        const id = optNode.contentNode.id;
        const filtered = parent.content.children.filter(cc => (cc.id === id || !isHidden(constraints, cc)));
        for (let i = 0; i < filtered.length; i++) {
            if (filtered[i].id === optNode.contentNode.id) {
                if (i > 0) {
                    const prevNode = filtered[i - 1];
                    if (prevNode.excludeFromNavigation) {
                        prev = [];
                        optNode = undefined;
                        break prevLoop;
                    }
                    prev.push(optNode = { 
                        contentNode: prevNode,
                        content: getAlternative(constraints, prevNode),
                    });
                    break prevLoop;
                }
                break;
            }
        }
        optNode = prev.pop();
    }
    while (optNode && optNode.contentNode !== contentNode && optNode.content.menu) {
        const filtered = optNode.content.children?.filter(cc => !isHidden(constraints, cc));
        if (!filtered?.length) {
            break;
        }
        const prevNode = filtered[filtered.length - 1];
        if (prevNode.excludeFromNavigation) {
            prev = [];
            optNode = undefined;
            break;
        }
        prev.push(optNode = {
            contentNode: prevNode,
            content: getAlternative(constraints, prevNode),
        });
    }

    let next = [...current];
    optNode = next.pop();
    nextLoop: while (optNode && next.length) {
        parent = next[next.length - 1];
        const id = optNode.contentNode.id;
        const filtered = parent.content.children.filter(cc => (cc.id === id || !isHidden(constraints, cc)));
        for (let i = 0; i < filtered.length; i++) {
            if (filtered[i].id === optNode.contentNode.id) {
                if (i < filtered.length - 1) {
                    const nextNode = filtered[i + 1];
                    if (nextNode.excludeFromNavigation) {
                        next = [];
                        optNode = undefined;
                        break nextLoop;
                    }
                    next.push(optNode = {
                        contentNode: nextNode,
                        content: getAlternative(constraints, nextNode),
                    });
                    break nextLoop;
                }
                break;
            }
        }
        optNode = next.pop();
    }
    while (optNode && optNode.contentNode !== contentNode && optNode.content.menu) {
        const filtered = optNode.content.children?.filter(cc => !isHidden(constraints, cc));
        if (!filtered?.length) break;
        const nextNode = filtered[0];
        if (nextNode.excludeFromNavigation) {
            next = [];
            optNode = undefined;
            break;
        }
        next.push(optNode = {
            contentNode: nextNode,
            content: getAlternative(constraints, nextNode),
        });
    }

    return { prev, current, next, menuRoot, target };
}

interface ContainerLayoutProps {
    session: Shuttle.LauncherProps['session'];
}

function ContainerLayout({ session: { config } }: ContainerLayoutProps) {
    return <Layout layout={(config as ContainerConfig)?.layout} />
}

interface ContentMenuProps {
    content: Shuttle.ContentNode;
    activePath: string;
    setActivePath: (activePath: string) => void;
    dense?: boolean;
}

interface RenderNodeArgs {
    contentNode: Shuttle.ContentNode;
    depth?: number;
}

function ContentMenu({ content, dense, activePath, setActivePath }: ContentMenuProps) {
    const { session } = useContext(SessionContext);
    const constraints = useContext(ConstraintsContext);
    const theme = useTheme();
    const darkMode = theme.palette.mode === 'dark';

    const [expanded, setExpanded] = useState([] as string[]);

    useEffect(() => {
        if (activePath) {
            setExpanded(prev => {
                const next = [...prev];

                if (activePath) {
                    const parts = activePath.split('/');
                    let i = 0;
                    while (i < parts.length) {
                        const key = parts.slice(0, i++).join('/');
                        if (!next.includes(key)) {
                            next.push(key);
                        }
                    }
                }

                return next;
            });
        }
    }, [activePath]);

    const renderNode = ({ contentNode, depth = 0 }: RenderNodeArgs) => {

        const content = getAlternative(constraints, contentNode);

        const { completion = 'NotStarted', progress = 0, timeSpent = 0 } = session?.tracking[content.uuid] ?? {};

        const children = content.menu && (
            <List dense={dense} disablePadding={depth > 0}>
                {
                    content.children.filter(child => !isHidden(constraints, child)).map(contentNode => renderNode({ contentNode, depth: depth + 1 }))
                }
            </List>
        );

        if (depth === 0) {
            return (
                <Fragment key={content.id}>
                    <Box p={2}>
                        <Typography component="h2" variant="body1" fontWeight={700} gutterBottom>
                            <LayoutString text={content.title} />
                        </Typography>
                        <LinearProgress variant="determinate" value={progress} />
                        <Stack direction="row" justifyContent="space-between" mt={.5}>
                            <Typography
                                variant="caption"
                                color={completion === 'Completed' || completion === 'Passed' ? 'success.main' : completion === 'Failed' ? 'error.main' : 'text.secondary'}
                            >
                                {
                                    completion === 'Incomplete' ? (
                                        <Label k="completion_progress" args={{ progress }} />
                                    ) : (
                                        <Label k={completion} />
                                    )
                                }
                            </Typography>
                            {
                                !!content.minTimeSpent && (
                                    <Tooltip title={<Label k="min_time_instructions" args={{ time: StringUtil.formatHMS(content.minTimeSpent) }} />}>
                                        <Typography
                                            variant="caption"
                                            color={timeSpent >= content.minTimeSpent ? 'success.main' : 'text.secondary'}
                                        >
                                            {`${StringUtil.formatHMS(timeSpent)} / ${StringUtil.formatHMS(content.minTimeSpent)}`}
                                        </Typography>
                                    </Tooltip>
                                )
                            }
                        </Stack>
                    </Box>
                    <Divider />
                    {
                        children || (
                            <Label k="no_results" />
                        )
                    }
                </Fragment>
            );
        }

        const active = content.path === activePath || content.path.endsWith('/' + activePath);
        const open = expanded.includes(content.path);

        const completionIcon = (
            <ListItemIcon sx={{ minWidth: 32 }}>
                {
                    contentNode.required && (
                        <Tooltip title={<Label k={completion} />}>
                            {
                                completion === 'Failed' ? (
                                    <FailedIcon
                                        color={active ? 'primary' : 'inherit'}
                                        fontSize="small"
                                    />
                                ) : completion === 'Passed' || completion === 'Completed' ? (
                                    <CompletedIcon 
                                        color={active ? 'primary' : 'inherit'}
                                        fontSize="small"
                                    />
                                ) : (
                                    <ProgressCircle
                                        variant="determinate"
                                        value={progress}
                                        size={16}
                                    />
                                )
                            }
                        </Tooltip>
                    )
                }
            </ListItemIcon>
        );

        if (children) {
            return (
                <Fragment key={content.id}>
                    <ListItemButton 
                        onClick={() => setExpanded(prev => prev.includes(content.path) ? prev.filter(p => p !== content.path) : [...prev, content.path])}
                        sx={{
                            pl: theme.spacing(depth * 2),
                        }}
                    >
                        {completionIcon}
                        <ListItemText primary={<LayoutString text={content.title} />} />
                        {
                            open ? <ExpandLessIcon /> : <ExpandMoreIcon />
                        }
                    </ListItemButton>
                    <Collapse in={open}>
                        <Divider />
                        <Box sx={{ backgroundColor: darkMode ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.05)' }}>
                            {children}
                        </Box>
                        <Divider />
                    </Collapse>
                </Fragment>
            );
        }

        const locked = isLocked(constraints, contentNode);

        return (
            <ListItemButton 
                key={content.id} 
                color={active ? 'primary' : undefined}
                disabled={locked}
                selected={active}
                onClick={() => setActivePath(content.path)}
                sx={{
                    pl: theme.spacing(depth * 2),
                }}
            >
                {completionIcon}
                <ListItemText primary={<LayoutString text={content.title} />} />
            </ListItemButton>
        );
    }

    return renderNode({ contentNode: content });
}
