import { useMemo } from "react";

import StringUtil from "../util/StringUtil";

/**
 * simple hook for generating component-scoped stable random id.
 */
export default function useId() {
    return useMemo(() => StringUtil.tempId(), []);
}
