import { Shuttle } from "./types";
import Registry from "./util/Registry";

export const layoutElementRegistry = new Registry<Shuttle.LayoutElementType>();

export const launcherRegistry = new Registry<Shuttle.Launcher>();

export const behaviorRegistry = new Registry<Shuttle.BehaviorType>();

export const conditionRegistry = new Registry<Shuttle.Condition>();
