import Axios from "axios";

import ContentData from "../model/ContentData";
import SearchResult from "../model/SearchResult";
import UploadService from "./UploadService";
import { Shuttle } from "../types";
import ContentChildSearchParams from "../model/ContentChildSearchParams";
import ContentChildData from "../model/ContentChildData";
import createEntityService from "./createEntityService";
import ResponseData from "../model/ResponseData";
import FileData from "../model/FileData";
import BrowserUtil from "../util/BrowserUtil";
import SearchParams from "../model/SearchParams";
import ContentContributorData from "../model/ContentContributorData";
import EnrollmentData from "../model/EnrollmentData";
import ContentAlternativeData from "../model/ContentAlternativeData";
import ConsumerContentData from "../model/ConsumerContentData";
import { launcherRegistry } from "../registries";
import ContentQuestionData from "../model/quiz/ContentQuestionData";
import ContentQuizModeData from "../model/quiz/ContentQuizModeData";
import { Award, AwardIssued } from "../model/Award";
import AwardSearchParams from "../model/AwardSearchParams";
import AwardIssuedSearchParams from "../model/AwardIssuedSearchParams";

function isFileData(o: any): o is FileData {
    return Boolean(o && typeof o === 'object' && o.file);
}

async function saveFiles(data: ContentData) {

    if (data.icon?.file) {
        data = { ...data, icon: await UploadService.get().upload(data.icon.file) };
    }

    if (data.picture?.file) {
        data = { ...data, picture: await UploadService.get().upload(data.picture.file) };
    }

    if (data.details) {
        const directUpload = Boolean(launcherRegistry.get(data.type.launcher)?.directUploadResource);
        for (const [key, value] of Object.entries(data.details)) {
            if (isFileData(value) && value.file) {
                if (key === 'resource' && directUpload) {
                    data = {...data, details: {...data.details, resource: undefined}, resourceUrl: await UploadService.get().directUpload("resource", value.file)};
                } else {
                    data = {...data, details: {...data.details, [key]: await UploadService.get().upload(value.file) }}
                }
            }
        }
    }

    return data;
}

const ContentService = {

    ...createEntityService<ContentData>({ 
        path: '/contents',
        beforeCreate: saveFiles,
        beforeUpdate: saveFiles,
    }),

    getChild: async (id: number, content: ContentData) => {
        return Axios.get<Shuttle.ResponseData<ContentChildData>>('/contents/' + content.id + '/children/' + id).then(res => res.data)
    },

    searchChildren: async (params: ContentChildSearchParams, content: ContentData) => {
        return Axios.get<Shuttle.ResponseData<SearchResult<ContentChildData>>>('/contents/' + content.id + '/children', { params }).then(res => res.data);
    },

    addChild: async ({ content, ...rest }: ContentChildData, parent: ContentData) => {
        return Axios.put<Shuttle.ResponseData<ContentChildData>>('/contents/' + parent.id + '/children/' + content.id, rest).then(res => res.data);
    },

    removeChild: async (data: ContentChildData, content: ContentData) => {
        return Axios.delete<Shuttle.ResponseData<void>>('/contents/' + content.id + '/children/' + data.content.id).then(res => res.data);
    },

    getAlternative: async (id: number, content: ContentData) => Axios.get<Shuttle.ResponseData<ContentAlternativeData>>('/contents/' + content.id + '/alternatives/' + id).then(r => r.data),

    searchAlternatives: async (params: SearchParams, content: ContentData) => Axios.get<Shuttle.ResponseData<SearchResult<ContentAlternativeData>>>('/contents/' + content.id + '/alternatives', { params }).then(r => r.data),

    addAlternative: async ({ alternative, ...rest }: ContentAlternativeData, content: ContentData) => Axios.put<Shuttle.ResponseData<ContentAlternativeData>>('/contents/' + content.id + '/alternatives/' + alternative.id, rest).then(r => r.data),

    removeAlternative: async ({ alternative }: ContentAlternativeData, content: ContentData) => Axios.delete<any>('/contents/' + content.id + '/alternatives/' + alternative.id).then(r => r.data),
 
    getContributor: async (id: number, content: ContentData) => {
        return Axios.get<Shuttle.ResponseData<ContentContributorData>>('/contents/' + content.id + '/contributors/' + id).then(r => r.data);
    },

    searchContributors: async (params: SearchParams, content: ContentData) => {
        return Axios.get<Shuttle.ResponseData<SearchResult<ContentContributorData>>>('/contents/' + content.id + '/contributors', { params }).then(r => r.data);
    },

    addContributor: async ({ contributor, ...rest }: ContentContributorData, content: ContentData) => {
        return Axios.put('/contents/' + content.id + '/contributors/' + contributor.id, rest).then(r => r.data);
    },

    removeContributor: async (data: ContentContributorData, content: ContentData) => {
        return Axios.delete<Shuttle.ResponseData<void>>('/contents/' + content.id + '/contributors/' + data.contributor.id).then(r => r.data);
    },

    searchEnrollments: async (params: SearchParams, content: ContentData) => {
        return Axios.get<Shuttle.ResponseData<SearchResult<EnrollmentData>>>('/contents/' + content.id + '/enrollments', { params }).then(r => r.data);
    },

    searchConsumers: async (params: SearchParams, content: ContentData) => {
        return Axios.get<Shuttle.ResponseData<SearchResult<ConsumerContentData>>>('/contents/' + content.id + '/consumers', { params }).then(r => r.data);
    },

    getConsumer: async (id: number, content: ContentData) => {
        return Axios.get<Shuttle.ResponseData<ConsumerContentData>>('/contents/' + content.id + '/consumers/' + id).then(r => r.data);
    },

    addConsumer: async ({ consumer, ...rest }: ConsumerContentData, content: ContentData) => {
        return Axios.put('/contents/' + content.id + '/consumers/' + consumer.id, rest).then(r => r.data);
    },

    removeConsumer: async (cc: ConsumerContentData, content: ContentData) => {
        return Axios.delete('/contents/' + content.id + '/consumers/' + cc.consumer.id).then(r => r.data);
    },

    searchQuestions: async (contentId: number | string, params: SearchParams) => await Axios
        .get<Shuttle.ResponseData<SearchResult<ContentQuestionData>>>(`/contents/${contentId}/questions`, { params })
        .then(r => r.data),

    getQuestion: async (contentId: number | string, questionId: number | string) => await Axios
        .get<Shuttle.ResponseData<ContentQuestionData>>(`/contents/${contentId}/questions/${questionId}`)
        .then(r => r.data),

    addQuestion: async (contentId: number | string, questionId: number | string, data: ContentQuestionData) => await Axios
        .put<Shuttle.ResponseData<ContentQuestionData>>(`/contents/${contentId}/questions/${questionId}`, data)
        .then(r => r.data),

    removeQuestion: async (contentId: number | string, questionId: number | string) => await Axios
        .delete(`/contents/${contentId}/questions/${questionId}`)
        .then(r => r.data),

    downloadQuestionResponses: async (params: any) => {
        const r = await Axios.get('/quiz-reporting/responses/download/csv', { responseType: 'blob', params });
        const dis = r.headers['content-disposition'];
        const idx = dis.indexOf('filename="');
        const name = idx > -1 ? dis.substring(idx + 10, dis.length - 1) : 'unknown';
        BrowserUtil.download(r.data, name, r.headers['content-type']);
    },

    searchQuizModes: async (contentId: number | string, params: SearchParams) => await Axios
        .get<Shuttle.ResponseData<SearchResult<ContentQuizModeData>>>(`/contents/${contentId}/quiz-modes`, { params })
        .then(r => r.data),

    getQuizMode: async (contentId: number | string, quizModeId: number | string) => await Axios
        .get<Shuttle.ResponseData<ContentQuizModeData>>(`/contents/${contentId}/quiz-modes/${quizModeId}`)
        .then(r => r.data),

    addQuizMode: async (contentId: number | string, quizModeId: number | string, data: ContentQuizModeData) => await Axios
        .put<Shuttle.ResponseData<ContentQuizModeData>>(`/contents/${contentId}/quiz-modes/${quizModeId}`, data)
        .then(r => r.data),

    removeQuizMode: async (contentId: number | string, quizModeId: number | string) => await Axios
        .delete(`/contents/${contentId}/quiz-modes/${quizModeId}`)
        .then(r => r.data),

    previewTree: async (id: number, params?: { [name: string]: string; }) => Axios.get<Shuttle.ContentNode>('/contents/' + id + '/preview-tree', { params }).then(r => r.data),

    syncRegistration: async (data: ContentData) => Axios.post<void>('/contents/' + data.id + '/sync-registration', {}).then(res => res.data),

    syncTracking: async (data: ContentData) => Axios.post<void>('/contents/' + data.id + '/sync-tracking', {}).then(res => res.data),

    syncIncompletes: async (contentId: number | string) => Axios.post<{ count: number; }>(`/contents/${contentId}/sync-incomplete`, {}).then(r => r.data),

    getAnalyticsEmbed: async (data: ContentData) => Axios.get<ResponseData<{ uri: string; }>>('/songbird/content/' + data.id).then(r => r.data),

    downloadScorm: async (data: ContentData, consumerId?: string) => {
        const r = await Axios.get('/contents/' + data.id + '/download/scorm', { responseType: 'blob', params: { consumerId } });
        const getName = (cd:string):string | null => {
            if (!cd) return null;
            const idx = cd.indexOf('filename="');
            if (idx === -1) return null;
            return cd.substring(idx + 10, cd.length - 1);
        };
        const fn = getName(r.headers['content-disposition']) || data.uuid + "-scorm.zip"; 
        console.log('download file:', fn);
        BrowserUtil.download(r.data, fn, r.headers['content-type']); 
    },

    downloadAicc: async (data: ContentData, consumerId?: string) => {
        const r = await Axios.get('/contents/' + data.id + '/download/aicc', { responseType: 'blob', params: { consumerId } });
        const getName = (cd:string):string | null => {
            if (!cd) return null;
            const idx = cd.indexOf('filename="');
            if (idx === -1) return null;
            return cd.substring(idx + 10, cd.length - 1);
        };
        const fn = getName(r.headers['content-disposition']) || data.uuid + "-aicc.zip"; 
        console.log('download file:', fn);
        BrowserUtil.download(r.data, fn, r.headers['content-type']);
    },

    searchAwards: async ({ consumer, ...params }: AwardSearchParams, content: ContentData) => Axios.get<Shuttle.ResponseData<SearchResult<Award>>>('/contents/' + content.id + '/awards', { params: { consumerId:consumer?.id, ...params, partial:true } }).then(r => r.data),
    searchAwardsIssued: async ({ consumer, ...params }: AwardIssuedSearchParams, content: ContentData) => Axios.get<Shuttle.ResponseData<SearchResult<AwardIssued>>>('/contents/' + content.id + '/awards-issued', { params: { consumerId:consumer?.id, ...params, partial:true } }).then(r => r.data),
};

export default ContentService;
