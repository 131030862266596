import { Link, RouteComponentProps } from "react-router-dom";
import Layout from "../core/Layout";
import UrlUtil from "../util/UrlUtil";
import { Alert, Box, Button, Link as MuiLink, Paper, Typography } from "@mui/material";
import Label from "../core/Label";
import DecoratorForm from "../form/DecoratorForm";
import { RegistrationData } from "../model/RegistrationData";
import StringUtil from "../util/StringUtil";
import { AuthService } from "../services/AuthService";
import { useContext } from "react";
import { AppContext } from "../core/AppContext";

export default function Register({ history, location }: RouteComponentProps<{ provider: string; }>) {
    const queryParams = UrlUtil.parseQueryString(location.search);
    let redirect = StringUtil.safeGetFirst(queryParams.r) || '/';
    if (redirect.startsWith(window.location.origin)) {
        redirect = redirect.substring(window.location.origin.length);
    }
    const [{ tenant }, setAppContext] = useContext(AppContext);
    return (
        <Layout>
            <Paper
                sx={{
                    maxWidth: 420,
                    mx: 'auto',
                    my: 3,
                    p: 2,
                    width: '100%',
                }}
            >
                {
                    tenant.registration === 'SSO' ? (
                        <Button
                            fullWidth
                            color="primary"
                            variant="contained"
                            href={AuthService.initiateUrl(redirect)}
                        >
                            <Label k="continue" />
                        </Button>
                    ) : tenant.registration === 'SELF' ? (
                        <>
                            <Typography variant="h5" component="h2" gutterBottom>
                                <Label k="create_an_account" />
                            </Typography>
                            <DecoratorForm
                                clazz={RegistrationData}
                                context={tenant}
                                onSubmit={async data => {
                                    const user = await AuthService.register(data);
                                    setAppContext(prev => ({ ...prev, user }));
                                    history.push(redirect);
                                    return data;
                                }}
                                submitButtonFullWidth
                            />
                            <Box sx={{ mt: 2, textAlign: 'right' }}>
                                <MuiLink component={Link} to={"/login?r=" + encodeURIComponent(AuthService.trimFrontendPath(tenant, redirect))}>
                                    <Label k="login" />
                                </MuiLink>
                            </Box>
                        </>
                    ) : (
                        <Alert severity="info">
                            <Label k="registration_not_allowed" />
                        </Alert>
                    )
                }
            </Paper>
        </Layout>
    );
}
