import { Link, RouteComponentProps } from "react-router-dom";
import { Alert, Box, Button, Divider, Link as MuiLink, Paper, Stack, TextField, Typography } from "@mui/material";
import Layout from "../core/Layout";
import UrlUtil from "../util/UrlUtil";
import Constants from "../util/Constants";
import StringUtil from "../util/StringUtil";
import Label from "../core/Label";
import DecoratorForm from "../form/DecoratorForm";
import { LoginData } from "../model/LoginData";
import { AuthService } from "../services/AuthService";
import { useContext, useState } from "react";
import { AppContext } from "../core/AppContext";
import { GoogleIcon } from "../core/icons";

export default function LoginView(props: RouteComponentProps) {
    const queryParams = UrlUtil.parseQueryString(props.location.search);
    let redirect = StringUtil.safeGetFirst(queryParams[Constants.PARAM_REDIRECT]) ?? '/';
    if (redirect.startsWith(window.location.origin)) {
        redirect = redirect.substring(window.location.origin.length);
    }
    const [{ tenant }, setAppContext] = useContext(AppContext);
    return (
        <Layout>
            <Paper
                sx={{
                    maxWidth: 420,
                    mx: 'auto',
                    my: 3,
                    p: 2,
                    width: '100%',
                }}
            >
                <Typography variant="h5" component="h2" gutterBottom>
                    <Label k="login" />
                </Typography>
                {
                    tenant.registration === 'SSO' ? (
                        <Button
                            fullWidth
                            color="primary"
                            variant="contained"
                            href={AuthService.initiateUrl(redirect)}
                        >
                            <Label k="continue" />
                        </Button>
                    ) : tenant.authCodeLogin ? (
                        <AuthCodeLogin {...props} redirect={redirect} />
                    ) : (
                        <>
                            {
                                tenant.registration === 'SELF' && (
                                    <>
                                        <Button
                                            fullWidth
                                            color="inherit"
                                            startIcon={<GoogleIcon />}
                                            href={AuthService.initiateSocialLoginUrl('google', redirect)}
                                        >
                                            <Label k="sign_in_with_google" />
                                        </Button>
                                        <Divider variant="fullWidth" sx={{ my: 2 }}>
                                            <Label k="or" />
                                        </Divider>
                                    </>
                                )
                            }
                            <DecoratorForm
                                clazz={LoginData}
                                context={tenant}
                                onSubmit={async data => {
                                    const user = await AuthService.login(data);
                                    setAppContext(prev => ({ ...prev, user }));
                                    props.history.push(redirect);
                                    return data;
                                }}
                                submitButtonFullWidth
                            />
                            {
                                tenant.registration === 'SELF' && (
                                    <Box
                                        sx={{
                                            mt: 2,
                                            textAlign: 'right',
                                        }}
                                    >
                                        <MuiLink component={Link} to={'/register?r=' + encodeURIComponent(AuthService.trimFrontendPath(tenant, redirect))}>
                                            <Label k="create_an_account" />
                                        </MuiLink>
                                    </Box>
                                )
                            }
                        </>
                    )
                }
            </Paper>
        </Layout>
    );
}

function AuthCodeLogin(props: RouteComponentProps & { redirect: string; }) {
    const [{ tenant }, setAppContext] = useContext(AppContext);
    const [codeUser, setCodeUser] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    if (codeUser) {
        return (
            <form
                onSubmit={async e => {
                    e.preventDefault();
                    const fd = new FormData(e.currentTarget);
                    const code = fd.get('code')?.toString();
                    if (code?.length !== 6) {
                        return;
                    }
                    setError(false);
                    setLoading(true);
                    try {
                        const user = await AuthService.authCodeLogin(code);
                        setLoading(false);
                        setAppContext(prev => ({ ...prev, user }));
                        props.history.push(props.redirect);
                    } catch {
                        setLoading(false);
                        setError(true);
                    }
                }}
            >
                {
                    error && (
                        <Alert severity="error">
                            <Label k="auth_code_invalid_code" />
                        </Alert>
                    )
                }
                <TextField
                    key="code"
                    name="code"
                    label={<Label k="auth_code" />}
                    helperText={<Label k="access_code_response_instructions" />}
                    required
                    error={error}
                    inputProps={{
                        minLength: 6,
                        maxLength: 6,
                        pattern: '[0-9]{6}',
                    }}
                    fullWidth
                    sx={{ mb: 2 }}
                />
                <Button
                    fullWidth
                    variant="contained"
                    type="submit"
                    disabled={loading}
                >
                    <Label k="submit" />
                </Button>
                <Divider sx={{ my: 1 }} />
                <Stack direction="row" justifyContent="space-between">
                    <div />
                    <Button
                        size="small"
                        onClick={() => setCodeUser('')}
                    >
                        <Label k="cancel" />
                    </Button>
                </Stack>
            </form>
        );
    }

    return (
        <form
            onSubmit={async e => {
                e.preventDefault();
                const fd = new FormData(e.currentTarget);
                const username = fd.get('username')?.toString();
                if (!username) {
                    return;
                }
                setLoading(true);
                try {
                    await AuthService.sendAuthCode(username);
                    setLoading(false);
                    setError(false);
                    setCodeUser(username);
                } catch (err) {
                    console.error(err);
                    setLoading(false);
                    setError(true);
                }
            }}
        >
            {
                error && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        <Label k="auth_code_invalid_user" />
                    </Alert>
                )
            }
            <Typography variant="body2" color="text.secondary" gutterBottom>
                <Label k={tenant.emailAsUsername ? 'access_code_email_prompt_instructions' : 'access_code_username_prompt_instructions'} />
            </Typography>
            <TextField
                key="username"
                fullWidth
                name="username"
                error={error}
                required
                label={<Label k={tenant.emailAsUsername ? 'email' : 'username'} />}
                sx={{ mb: 2 }}
            />
            <Button
                variant="contained"
                fullWidth
                type="submit"
                disabled={loading}
            >
                <Label k="continue" />
            </Button>
        </form>
    );
}
