import { useCallback, useMemo, useState } from "react";
import * as React from "react";
import { Alert, Button, CircularProgress } from "@mui/material";
import { IFormMeta } from "react-ts-form";

import { Class } from "../util/Class";
import DecoratorInput, { IDecoratorInputProps } from "./DecoratorInput";
import Label from "../core/Label";
import TypeUtil from "../util/TypeUtil";
import { ValidationContext } from "./ValidationContext";
import { AxiosError } from "axios";

export interface IDecoratorFormProps<T extends object> {
    clazz: Class<T>;
    subClazz?: IDecoratorInputProps<T>['subClazz'];
    context?: object;
    templates?: IFormMeta;
    initialValue?: T;
    onSubmit(value: T): Promise<T>;
    afterSubmit?: (newValue: T) => void;
    submitLabel?: React.ReactNode;
    submitButtonFullWidth?: boolean;
    disabled?: boolean;
}

export default function DecoratorForm<T extends object>(props: IDecoratorFormProps<T>) {
    const { onSubmit, afterSubmit, initialValue, submitLabel, disabled, ...rest } = props;
    const [{data, error, saving}, setState] = useState<{data: T; error: React.ReactNode; saving: boolean;}>(() => ({
        data: initialValue || new props.clazz(),
        error: null,
        saving: false,
    }));

    const handleSubmit = useCallback(async (e: React.FormEvent) => {
        let cancelled = false;
        e.preventDefault();
        e.stopPropagation();
        if (disabled) return;
        try {
            setState(prev => ({...prev, feedback: null, saving: true}));
            const next = await onSubmit(data);
            if (!cancelled) {
                setState({data: next || data, error: null, saving: false});
            }
        } catch (error) {

            const cast = error as AxiosError;

            setState(prev => ({
                ...prev,
                saving: false,
                error: (
                    <Alert severity="error">
                        {
                            cast.response ? (
                                `${cast.response.data?.message || (typeof cast.response.data === 'string' ? cast.response.data : 'error')}`
                            ) : cast.message
                        }
                    </Alert>
                )
            }));

            console.error(error);
        }
        return () => { cancelled = true; };
    }, [data, onSubmit, disabled]);

    const violations = useMemo(() => {
        return (
            TypeUtil.isServerValidationError(error) 
                && error.response?.data.data
        ) || [];
    }, [error]);

    return (
        <form onSubmit={handleSubmit}>
            {error}
            <ValidationContext.Provider value={violations}>
                <DecoratorInput {...rest} value={data} onChange={nextData => setState(prev => ({...prev, data: nextData}))} />
            </ValidationContext.Provider>
            <Button disabled={saving || disabled} type="submit" variant="contained" color="primary" fullWidth={props.submitButtonFullWidth}>
                {saving ? <CircularProgress size={24} /> : (submitLabel || <Label k="submit" />)}
            </Button>
        </form>
    )
}
