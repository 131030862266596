import { Shuttle } from "../types";

import ExpandIcon from "@mui/icons-material/Expand";
import { LayoutRegion, setElementData } from "../core/ui/Layout";
import Label from "../core/Label";
import { Input } from "react-ts-form";
import SwitchInput from "../form/inputs/SwitchInput";
import { createContext, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import NumberInput from "../form/inputs/NumberInput";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

class ExpandConfig {

    @Input({
        component: SwitchInput,
        meta: {
            title: 'Open expand panel by default',
        }
    })
    public defaultExpanded?: boolean;

    @Input({
        component: NumberInput,
        meta: {
            title: <Label k="spacing" />
        },
        inputProps: {
            min: 0,
            max: 10
        }
    })
    public spacing?: number;

    @Input({
        component: NumberInput,
        meta: {
            title: <Label k="item_spacing" />
        },
        inputProps: {
            min: 0,
            max: 10
        }
    })
    public expandSpacing?: number;

    @Input({
        component: SwitchInput,
        meta: {
            title: 'Paper',
            description: 'Change style to display as an elevated surface.',
        },
    })
    public paper?: boolean;

    public elements?: string[];

    public expandElements?: string[];

}

export const ExpandContext = createContext<null | [boolean, (next: boolean) => void]>(null);

const ExpandElement: Shuttle.LayoutElementType<ExpandConfig> = {
    name: 'layout_expand',
    Icon: ExpandIcon,
    ConfigClass: ExpandConfig,
    Component({ config }) {

        const [expanded, setExpanded] = useState(Boolean(config.defaultExpanded));

        return (
            <Accordion
                expanded={expanded}
                onChange={(_, expanded) => setExpanded(expanded)}
                variant={config.paper ? 'elevation' : 'outlined'}
                elevation={2}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <LayoutRegion keys={config.elements ?? []} spacing={config.spacing} sx={{ flexGrow: 1 }} />
                </AccordionSummary>
                <AccordionDetails>
                    <LayoutRegion keys={config.expandElements ?? []} spacing={config.expandSpacing} />
                </AccordionDetails>
            </Accordion>
        )
    },
    getRegions: (config, key) => [
        {
            title: <Label k="toggle_section" />,
            key: 'toggle',
            keys: config.elements || [],
            setKeys: (layout, elements) => setElementData(layout, key, {...config, elements}),
        },
        {
            title: <Label k="expand_section" />,
            key: 'expand',
            keys: config.expandElements || [],
            setKeys: (layout, expandElements) => setElementData(layout, key, {...config, expandElements}),
        }
    ],
};

export default ExpandElement;
