import { useContext } from "react";
import InlineLaunchIcon from "@mui/icons-material/PlayCircleFilled";
import { Shuttle } from "../../types";
import { Alert } from "@mui/material";
import Label from "../../core/Label";
import { Input } from "react-ts-form";
import TextInput from "../../form/inputs/TextInput";
import { ContentContext } from "../../contexts";
import LayoutString from "../LayoutString";
import SwitchInput from "../../form/inputs/SwitchInput";
import PlayContent from "../../player/PlayContent";
import LangStringInput from "../../form/inputs/LangStringInput";

class InlineLaunchElementConfig {

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="dimension" />,
            description: "'auto' or WxH (e.g. 16x9, 4x3)",
        },
    })
    public dimension!: string;

    @Input({
        component: LangStringInput,
        meta: {
            title: <Label k="locked_message" />
        }
    })
    public lockedMessage?: Shuttle.LangString

    @Input({
        component: LangStringInput,
        meta: {
            title: <Label k="prerequisites_message" />
        }
    })
    public prerequisitesMessage?: Shuttle.LangString

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="hide_prerequisites_list" />
        }
    })
    public hidePrerequisitesList?: boolean;

}

function InlineLaunchComponent({
    config: {
        dimension,
        lockedMessage = {},
        hidePrerequisitesList,
        prerequisitesMessage = {},
    },
    edit
}: Shuttle.LayoutElementProps<InlineLaunchElementConfig>) {
    const contentNode = useContext(ContentContext);

    if (edit) {
        return (
            <Alert severity="info">
                <Label k="available_when_launched" />
            </Alert>
        );
    }

    if (!contentNode) {
        return (
            <Alert severity="warning">
                <Label k="error" />
            </Alert>
        );
    }

    return (
        <PlayContent
            contentNode={contentNode}
            inline
            defaultDimension={dimension}
            defaultLockedMessage={lockedMessage.en && <LayoutString text={lockedMessage} />}
            defaultPrerequisitesMessage={prerequisitesMessage.en && <LayoutString text={prerequisitesMessage} />}
            hidePrerequisitesList={hidePrerequisitesList}
        />
    );
}

export const InlineLaunchElement: Shuttle.LayoutElementType<InlineLaunchElementConfig> = {
    name: "layout.launch.inline",
    Icon: InlineLaunchIcon,
    ConfigClass: InlineLaunchElementConfig,
    Component: InlineLaunchComponent,
};
