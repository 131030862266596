import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

interface Request {
    req:AxiosRequestConfig,
    resolve:(value: AxiosRequestConfig | PromiseLike<AxiosRequestConfig>) => void,
}

export default function createQueue(client:AxiosInstance):AxiosInstance {
    const handler = {
        queue: [] as Array<Request>,
        running: false,

        push: (req:Request) => {
            handler.queue.push(req);
            setTimeout(() => {
                if (!handler.running) {
                    handler.shift();
                }    
            }, 0);
        },

        shift: () => {
            if (handler.queue.length) {
                const req = handler.queue.shift();
                req?.resolve(req.req);
                handler.running = true;
            }
        },

        request: (req:AxiosRequestConfig) => {
            return new Promise<AxiosRequestConfig>(resolve => {
                handler.push({ req, resolve })
            });
        },

        response: (res:AxiosResponse<any>) => {
            handler.running = false;
            handler.shift();
            return res;
        },

    }
    client.interceptors.request.use(handler.request);
    client.interceptors.response.use(handler.response);
    return client;
}