import queryString, { ParseOptions } from "query-string";

export default class UrlUtil {

    private static options: ParseOptions = {
        arrayFormat: 'separator'
    };

    public static parseQueryString(qs: string) {
        return queryString.parse(qs, UrlUtil.options);
    }

    public static toQueryString(params: { [name: string]: any; }) {
        return queryString.stringify(params, UrlUtil.options);
    }
}
