import { Fragment, useState, useMemo } from "react";
import { IInputProps } from "react-ts-form";
import { SketchPicker } from "react-color";
import { Popover, Button, getLuminance } from "@mui/material";

interface IColorInputProps extends IInputProps<string> {
    placeholder?: string;
}

export default function ColorInput(props: IColorInputProps) {
    const [anchor, setAnchor] = useState<any | null>(null);
    const luminance = useMemo(() => props.value ? getLuminance(props.value) : 1, [props.value]);

    if (props.disabled) {
        return (
            <Button disabled style={{backgroundColor: props.value || "#fff"}}>
                {props.value || props.placeholder || "No Color Selected"}
            </Button>
        );
    }
    
    return (
        <Fragment>
            <Button 
                onClick={e => setAnchor(e.currentTarget)} 
                style={{
                    backgroundColor: props.value || "#fff", 
                    color: (luminance > .5 ? '#000' : '#fff')
                }}
            >
                {props.value || props.placeholder || "Choose a Color"}
            </Button>
            <Popover
                open={!!anchor}
                anchorEl={anchor}
                onClose={() => setAnchor(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <SketchPicker 
                    disableAlpha={true}
                    color={props.value || undefined}
                    onChange={color => props.onChange(color.hex)}
                />
            </Popover>
        </Fragment>
    );
}
