import Axios from "axios";
import GlossaryData from "../model/GlossaryData";
import GlossaryTermData from "../model/GlossaryTermData";
import ResponseData from "../model/ResponseData";
import SearchParams from "../model/SearchParams";
import SearchResult from "../model/SearchResult";
import createEntityService from "./createEntityService";

const GlossaryService = {

    ...createEntityService<GlossaryData>({ path: '/glossaries', }),

    searchTerms: async (params: SearchParams, glossary: GlossaryData) => Axios.get<ResponseData<SearchResult<GlossaryTermData>>>('/glossaries/' + glossary.id + '/terms', { params }).then(r => r.data),

    createTerm: async (data: GlossaryTermData, glossary: GlossaryData) => Axios.post<ResponseData<GlossaryTermData>>('/glossaries/' + glossary.id + '/terms', data).then(r => r.data),

    getTerm: async (id: number, glossary: GlossaryData) => Axios.get<ResponseData<GlossaryTermData>>('/glossaries/' + glossary.id + '/terms/' + id).then(r => r.data),

    updateTerm: async (data: GlossaryTermData, glossary: GlossaryData) => Axios.patch<ResponseData<GlossaryTermData>>('/glossaries/' + glossary.id + '/terms/' + data.id, data).then(r => r.data),

    deleteTerm: async (data: GlossaryTermData, glossary: GlossaryData) => Axios.delete<ResponseData<void>>('/glossaries/' + glossary.id + '/terms/' + data.id).then(r => r.data),

};

export default GlossaryService;
