import axios from "axios";

import UserData from "../model/UserData";
import { RegistrationData } from "../model/RegistrationData";
import { LoginData } from "../model/LoginData";
import { Shuttle } from "../types";

interface AuthCheck {
    user?: UserData;
}

export const AuthService = {

    trimFrontendPath(tenant: Shuttle.TenantInfo, redirect: string) {
        if (tenant.basePath && redirect.startsWith(tenant.basePath)) {
            redirect = redirect.substring(tenant.basePath.length);
        }
        return redirect;
    },

    initiateUrl(redirect?: string) {
        return window.location.origin
            + (axios.defaults.baseURL ?? '')
            + '/auth/initiate?r=' + encodeURIComponent(redirect ?? '/');
    },

    initiateSocialLoginUrl(provider: string, redirect?: string) {
        return window.location.origin
            + (axios.defaults.baseURL ?? '')
            + '/auth/initiate?s=' + encodeURIComponent(provider)
            + '&r=' + encodeURIComponent(redirect ?? '/');
    },

    async sendAuthCode(username: string) {
        return await axios.post('/auth/code', { username });
    },

    async authCodeLogin(code: string) {
        await axios.post('/auth/code/login', { code });
        return await this.getProfile();
    },

    async login(data: LoginData) {
        await axios.post('/auth/login', data);
        return await this.getProfile()
    },

    async register(data: RegistrationData) {
        await axios.post('/auth/register', data);
        return await this.getProfile();
    },

    async refresh() {
        await axios.get('/auth/r/refresh', { skipAuthRefresh: true } as any);
    },

    logout() {
        window.location.href = window.location.origin + (axios.defaults.baseURL ?? '') + '/auth/r/logout';
    },

    async getProfile() {
        return await axios.get<UserData>('/profile').then(r => r.data);
    },

    saveProfile: async ({ locale, properties, timeZone }: Partial<UserData>) => await axios
        .patch('/profile', {
            locale,
            properties,
            timeZone,
        })
        .then(r => r.data),

    verifyIdentity: async (next?: boolean) => await axios
        .put<{ token: string; }>('/profile/verify-identity', { next })
        .then(r => r.data.token),

    async check() {
        if (window.top && window.top !== window) {
            return {}
        }
        return await axios.get<AuthCheck>('/auth/r/check').then(r => r.data);
    },

    impersonateUrl(userId: number) {
        return window.location.origin + (axios.defaults.baseURL ?? '') + '/auth/r/impersonate/init/' + userId;
    },

    impersonateExit() {
        window.location.href = window.location.origin + (axios.defaults.baseURL ?? '') + '/auth/r/impersonate/exit';
    },

}
