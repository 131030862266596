import { IconButton, IconButtonProps, Tooltip } from "@mui/material";
import { createElement } from "react";

import { ValidLabelArg, ValidLabelKey } from "../localization/ValidLabelKey";
import useString from "../../hooks/useString";

interface ILabelIconButtonProps extends IconButtonProps {
    k: ValidLabelKey;
    args?: { [name: string]: ValidLabelArg; };
    icon: React.ComponentType;
}

export default function LabelledIconButton({ args, disabled, k, icon, ...props }: ILabelIconButtonProps) {
    const text = useString(k, args);

    const btn = (
        <IconButton {...props} disabled={disabled} aria-label={text}>
            {createElement(icon)}
        </IconButton>
    );

    if (disabled) {
        return btn;
    }

    return (
        <Tooltip title={text}>
            {btn}
        </Tooltip>
    );
}
