import { Input } from "react-ts-form";
import Label from "../core/Label";
import DropDownChoiceInput from "../form/inputs/DropDownChoiceInput";
import DurationInput from "../form/inputs/DurationInput";
import { LabelKeyChoiceRenderer } from "../form/IOptionsProps";
import { Shuttle } from "../types";
import TimeIcon from "@mui/icons-material/AccessTime";
import { Box, Tooltip } from "@mui/material";
import StringUtil from "../util/StringUtil";
import { useEffect, useState } from "react";
import useInterval from "../hooks/useInterval";
import { useFormattedDate } from "../hooks/useDate";
import LangStringInput from "../form/inputs/LangStringInput";
import bestLocaleMatch from "../i18n/util";

class MaxTimeConfig {

    @Input({
        component: DurationInput,
        meta: {
            title: <Label k="duration" />,
            required: true,
        }
    })
    public duration?: number; //seconds

    @Input({
        component: DropDownChoiceInput.of({
            options: ['calendary_day', 'relative'],
            choiceRenderer: LabelKeyChoiceRenderer,
        }),
        meta: {
            title: <Label k="type" />,
            required: true,
        }
    })
    public type?: 'calendar_day' | 'relative' = 'calendar_day'

    @Input({
        component: DurationInput,
        meta: {
            title: <Label k="relative_period" />
        },
        exclude: (_, { parent }) => parent.type !== 'relative',
    })
    public period?: number; //seconds

    @Input({
        component: LangStringInput,
        meta: {
            title: <Label k="text" />,
            description: 'variables: {remaining}, {end}'
        }
    })
    public message?: Shuttle.LangString

    @Input({
        component: LangStringInput,
        meta: {
            title: <Label k="closed_message" />,
            description: 'variables: {duration}, {end}'
        }
    })
    public closedMessage?: Shuttle.LangString

}

const MaxTimeBehavior: Shuttle.BehaviorType<MaxTimeConfig, { time: number; start: number; first: number; }> = {
    name: 'behavior_max_time',
    ConfigClass: MaxTimeConfig,
    ToolbarComponent({ config, closeSession, locale, state: { time = 0, start, first } }) {

        const [remaining, setRemaining] = useState((config.duration || 0) - time);

        const formattedEnd = useFormattedDate(config.type === 'relative' ? (first + ((config.period || 0) * 1000)) : (start + (24 * 60 * 60 * 1000)), 'Pp');

        useInterval(remaining > 0 ? 1000 : 0, () => setRemaining(remaining - 1));

        useEffect(() => {
            if ((config.duration || 0) > 0 && remaining <= 0) {
                closeSession(
                    (config.closedMessage && (
                        <span>
                            {
                                StringUtil.formatVars(bestLocaleMatch(config.closedMessage, locale) ?? '', name => {
                                    if (name === 'duration') {
                                        return StringUtil.formatHMS(config.duration || 0);
                                    }

                                    if (name === 'end') {
                                        return formattedEnd;
                                    }

                                    return '';
                                })
                            }
                        </span>
                    )) || undefined
                );
            }
        }, [remaining, config, locale, closeSession, formattedEnd]);

        const remainingStr = StringUtil.formatHMS(remaining > 60 ? remaining - (remaining % 60) : remaining);
        const durationStr = StringUtil.formatHMS(config.duration || 0);
        
        const label = StringUtil.formatVars(
            bestLocaleMatch(config.message ?? {}, locale) || '{remaining} / {duration}',
            name => {
                if (name === 'remaining') {
                    return remainingStr;
                }
                if (name === 'duration') {
                    return durationStr;
                }
                return '';
            }
        )

        return (
            <Tooltip title={label}>
                <Box sx={{ display: 'flex', alignItems: 'center', color: remaining < 60 ? 'error.main' : undefined }}>
                    <TimeIcon sx={{ mr: .5 }} />
                    <div>
                        {remainingStr}
                    </div>
                </Box>
            </Tooltip>
        );
    }
};

export default MaxTimeBehavior;
