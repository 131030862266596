import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { Route, Switch } from "react-router-dom";

import './App.css';
import { ThemeProvider } from './core/ThemeProvider';
import { Shuttle } from './types';
import StorageUtil from './util/StorageUtil';
import Constants from './util/Constants';
import Settings from './model/Settings';
import { AppContext } from './core/AppContext';
import LoginView from './views/Login';
import AdminView from './views/Admin';
import NotFound from './views/NotFound';
import UnauthorizedView from './views/Unauthorized';
import LogoutRedirect from './core/LogoutRedirect';
import PlayView from './views/play/Play';
import { EnrollmentLaunchView, LaunchView } from './views/play/Launch';
import SuperAdmin from './views/SuperAdmin';
import { SettingsContext } from './contexts';
import MyCourses from './views/MyCourses';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import Axios from 'axios';
import { AuthService } from './services/AuthService';
import Register from './views/Register';
import Layout from './core/Layout';
import { Box } from '@mui/material';
import { CollectorForm } from './core/CollectorFields';
import { anyStaff } from './core/admin/AdminSection';
import LabelledIconButton from './core/ui/LabelledIconButton';
import { LogoutIcon } from './core/icons';
import IdentityVerification from './core/IdentityVerification';
import PlayPopup from './player/PlayPopup';

interface IAppProps {
    tenant: Shuttle.TenantInfo;
    user: null | Shuttle.UserData;
}

function initialSettings() {
    const prev = StorageUtil.getJson(Constants.KEY_SETTINGS, Settings) ?? new Settings()
    if (!prev.mode) prev.mode = 'auto'
    if (!prev.locale) prev.locale = 'en'
    if (!prev.timeZone) prev.timeZone = 'America/Chicago'
    return prev
}

export default function App({ tenant, user }: IAppProps) {

    const [data, setData] = useState({ tenant, user });

    // attach this before rendering any child component that may need it
    const handle = useRef(0);
    if (!handle.current) {
        handle.current = createAuthRefreshInterceptor(
            Axios,
            () => AuthService.refresh().then(() => {
                AuthService.getProfile().then(user => setData(prev => ({ ...prev, user })));
                return Promise.resolve();
            }).catch(x => {
                console.error(x);
                window.location.href = `${tenant.basePath ?? ''}/login?r=${encodeURIComponent(AuthService.trimFrontendPath(tenant, window.location.pathname))}`;
                return Promise.reject();
            }),
        );
    }
    useEffect(() => () => Axios.interceptors.response.eject(handle.current), []);

    const [settings, setSettings] = useState(initialSettings)
    useEffect(() => {
        StorageUtil.putJson(Constants.KEY_SETTINGS, settings);
    }, [settings]);

    const needsVerification = !!(data.user && tenant.verificationEnabled && !user?.impersonated && data.user.verification !== 'SUCCESS');

    let content: ReactNode;
    if (data.user && !anyStaff(data) && data.tenant.collector?.variables?.some(v => {
        if (v.required && v.name && v.editable) {
            const value = data.user?.properties?.[v.name];
            return value === undefined || value === null;
        }
        return false;
    })) {
        content = (
            <Layout
                rightElement={
                    <LabelledIconButton
                        k="sign_out"
                        icon={LogoutIcon}
                        onClick={() => AuthService.logout()}
                    />
                }
            >
                <Box
                    sx={{
                        mx: 'auto',
                        my: 3,
                        maxWidth: 420,
                        width: '100%',
                    }}
                >
                    <CollectorForm
                        initialValues={data.user.properties}
                        promptText={data.tenant.collector.promptText}
                        variables={data.tenant.collector.variables}
                        onSubmit={async properties => {
                           const user = await AuthService.saveProfile({ properties });
                           setData(prev => ({ ...prev, user }));
                        }}
                    />
                </Box>
            </Layout>
        );
    } else if (needsVerification) {
        content = (
            <IdentityVerification />
        );
    } else {
        content = (
            <Switch>
                <Route path="/play/popup" exact component={PlayPopup} />
                <Route path="/play/:sessionId" component={PlayView} />
                <Route path="/launch/e/:enrollmentId" component={EnrollmentLaunchView} />
                <Route path="/launch/:uuid" component={LaunchView} />
                <Route path="/super-admin" component={SuperAdmin} />
                <Route path="/admin" component={AdminView} />
                <Route path="/register" component={Register} />
                <Route path="/login" component={LoginView} />
                <Route path="/logout" component={LogoutRedirect} />
                <Route path="/401" component={UnauthorizedView} />
                <Route path="/" exact component={MyCourses} />
                <Route component={NotFound} />
            </Switch>
        );
    }

    return (
        <AppContext.Provider value={useMemo(() => [data, setData], [data])}>
            <SettingsContext.Provider value={useMemo(() => [settings, setSettings], [settings])}>
                <ThemeProvider theme={data.tenant.theme}>
                    {content}
                </ThemeProvider>
            </SettingsContext.Provider>
        </AppContext.Provider>
    );
};
