import { Avatar } from "@mui/material";
import { Input } from "react-ts-form";

import { createEntitySection, createFormAction } from "../../core/admin/AdminSection";
import { DateLabel } from "../../core/DateLabel";
import Label from "../../core/Label";
import { LocaleMultiInput, TimeZoneInput } from "../../form/common";
import SwitchInput from "../../form/inputs/SwitchInput";
import TextInput from "../../form/inputs/TextInput";
import TenantInfo from "../../model/TenantInfo";
import UserData from "../../model/UserData";
import TenantService from "../../services/TenantService";
import { Shuttle } from "../../types";

class TenantCreateData implements Shuttle.TenantInfo {
    
    public id!: number;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="publicId" />,
            required: true,
        }
    })
    public publicId!: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="name" />,
            required: true,
        }
    })
    public name!: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="externalId" />
        }
    })
    public externalId!: string;

    @Input({
        component: LocaleMultiInput,
        meta: {
            title: <Label k="locale" />
        }
    })
    public locale!: string[];

    @Input({
        component: TimeZoneInput,
        meta: {
            title: <Label k="timeZone" />
        }
    })
    public timeZone!: string;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="enabled" />,
        }
    })
    public enabled: boolean = true;

}

class TenantUpdateData implements Shuttle.TenantInfo {

    public id!: number;

    public publicId!: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="name" />
        }
    })
    public name!: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="externalId" />
        }
    })
    public externalId!: string;

    @Input({
        component: LocaleMultiInput,
        meta: {
            title: <Label k="locale" />
        }
    })
    public locale!: string[];

    @Input({
        component: TimeZoneInput,
        meta: {
            title: <Label k="timeZone" />
        }
    })
    public timeZone!: string;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="enabled" />,
        }
    })
    public enabled: boolean = true;

}

class CreateAdmin {

    @Input({
        component: TextInput,
        meta: {
            title: 'First Name',
            required: true,
        },
    })
    public firstName!: string;
    
    @Input({
        component: TextInput,
        meta: {
            title: 'Last Name',
            required: true,
        },
    })
    public lastName!: string;

    @Input({
        component: TextInput,
        meta: {
            title: 'Email',
            required: true,
        },
    })
    public email!: string;

}

export default createEntitySection<TenantInfo>({

    model: TenantUpdateData,

    ...TenantService,

    getId: o => o.id,
    label: 'name',

    columns: [
        {
            key: 'publicId',
            sort: 'publicId',
            renderCell: ({data}) => data.publicId,
        },
        {
            key: 'name',
            sort: 'name',
            renderCell: ({data}) => data.name,
        },
        {
            key: 'enabled',
            sort: 'enabled',
            renderCell: ({data, reload}) => (
                <SwitchInput 
                    value={data.enabled} 
                    onChange={
                        async enabled => {
                            await TenantService.update({...data, enabled});
                            reload();
                        }
                    } 
                />
            )
        },
    ],

    actions: [
        createFormAction({
            key: 'create',
            clazz: TenantCreateData,
            onSubmit: async tenant => {
                await TenantService.create(tenant);
                return true;
            }
        })
    ],

    sections: [
        {
            path: '/users',
            label: 'admin.section.users',
            section: createEntitySection({

                model: UserData,
                
                getId: o => o.id,
                label: o => o.firstName + ' ' + o.lastName,

                get: TenantService.getUser,
                search: TenantService.searchUsers,
                update: TenantService.updateUser,

                columns: [
                    {
                        key: 'avatar',
                        renderCell({data}) {
                            return (
                                <Avatar alt={`${data.firstName} ${data.lastName}`} src={data.avatar?.url}>
                                    {`${data.firstName.charAt(0)}${data.lastName.charAt(0)}` }
                                </Avatar>
                            );
                        }
                    },
                    {
                        key: 'externalId',
                        sort: 'externalId',
                        renderCell({data}) {
                            return data.externalId;
                        }
                    },
                    {
                        key: "firstName",
                        sort: "firstName",
                        renderCell({data}) {
                            return data.firstName;
                        }
                    },
                    {
                        key: 'lastName',
                        sort: 'lastName',
                        renderCell({data}) {
                            return data.lastName;
                        }
                    },
                    {
                        key: 'lastLoginDate',
                        sort: 'lastLoginDate',
                        renderCell({data}) {
                            return data.lastLoginDate && (
                                <DateLabel date={data.lastLoginDate} format="P p" />
                            )
                        }
                    },
                ],

                actions: [
                    createFormAction({
                        key: 'create',
                        clazz: CreateAdmin,
                        onSubmit: async ({ firstName, lastName, email }, tenant) => {
                            const consumer = await TenantService.searchConsumers(tenant.id, { defaultOnly: true }).then(t => t.data.items?.[0])
                            await TenantService.createUser({
                                id: 0,
                                consumer,
                                firstName,
                                lastName,
                                email,
                                internal: true,
                                roles: ['ROLE_ADMIN'],
                            }, tenant);
                            return true;
                        }
                    })
                ]

            }),
        }
    ]

});
