import { CSSProperties } from "react";
import { Shuttle } from "../types";
import useLayoutString from "./useLayoutString";
import { Box } from "@mui/material";

interface LayoutStringProps {
    text: Shuttle.LangString | undefined
    html?: boolean;
    className?: string;
    skip?: boolean
    style?: CSSProperties;
}

export default function LayoutString({ className, html, skip = false, style, text }: LayoutStringProps) {

    const str = useLayoutString({ html, text, skip });

    if (html) {
        return (
            <Box
                sx={{
                    '& > :first-of-type': {
                        mt: 0,
                    },
                    '& > :last-of-type': {
                        mb: 0,
                    },
                }}
                className={className}
                style={style}
                dangerouslySetInnerHTML={{__html: str}}
            />
        );
    }

    return (
        <span className={className} style={style}>
            {str}
        </span>
    );
}
