import { Input } from "react-ts-form";
import TextIcon from "@mui/icons-material/TextFields";
import { Shuttle } from "../types";
import Label from "../core/Label";
import DropDownChoiceInput from "../form/inputs/DropDownChoiceInput";
import SwitchInput from "../form/inputs/SwitchInput";
import { Typography } from "@mui/material";
import { ColorRefInput } from "../form/common";
import LangStringInput from "../form/inputs/LangStringInput";
import * as icons from '../icons';
import { createElement } from "react";
import useLayoutString from "./useLayoutString";

class TextElementConfig {

    @Input({
        component: LangStringInput,
        meta: {
            title: <Label k="text" />
        },
    })
    public text: Shuttle.LangString = {}

    @Input({
        component: DropDownChoiceInput.ofType<TextElementConfig['type']>(),
        meta: {
            title: <Label k="type" />
        },
        inputProps: {
            options: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'subtitle1', 'subtitle2', 'body1', 'body2', 'caption', 'overline', 'inherit'],
        }
    })
    public type: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2' | 'caption' | 'overline' | 'inherit' = 'inherit';

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="spacing" />
        }
    })
    public gutterBottom?: boolean;

    @Input({
        component: ColorRefInput,
        meta: {
            title: <Label k="color" />
        }
    })
    public color?: string;

    @Input({
        component: ColorRefInput,
        meta: {
            title: <Label k="color_dark" />
        }
    })
    public colorDark?: string;

    @Input({
        component: DropDownChoiceInput.ofType<string>(),
        meta: {
            title: 'Icon',
        },
        inputProps: {
            options: Object.keys(icons),
        },
    })
    public icon?: keyof typeof icons;

}

export const TextElement: Shuttle.LayoutElementType<TextElementConfig> = {
    name: "layout.text",
    Icon: TextIcon,
    ConfigClass: TextElementConfig,
    Component: ({ config: { color, colorDark, icon, gutterBottom, text = {}, type }, edit }) => {
        const str = useLayoutString({
            text,
            skip: edit,
        });
        const selectedIcon = icon && icons[icon]
        return (
            <Typography 
                gutterBottom={gutterBottom} 
                variant={type}
                sx={{
                    color: theme => theme.palette.mode === 'dark' ? (colorDark || color) : color,
                }}
            >
                {
                    selectedIcon && createElement(selectedIcon, { 
                        fontSize: 'inherit',
                        sx: {
                            mr: str ? theme => theme.spacing(.5) : 0,
                            verticalAlign: 'middle',
                        },
                    })
                }
                {
                    str && (
                        <span style={{ verticalAlign: 'middle' }}>
                            {str}
                        </span>
                    )
                }
            </Typography>
        );
    },
};
