import { createContext, useMemo, useContext, useEffect } from "react";
import { createTheme, CssBaseline, Palette, ThemeOptions, ThemeProvider as Provider } from "@mui/material";
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

import LightLogo from "../assets/logo-light-mode.png";
import DarkLogo from "../assets/logo-dark-mode.png";
import PictureDefault from "../assets/lightspeed9.gif";

import ThemeData from "../model/ThemeData";
import BrowserUtil from "../util/BrowserUtil";
import { TypographyOptions } from "@mui/material/styles/createTypography";
import { SettingsContext } from "../contexts";
import { getBestLocale } from "../i18n/loaders";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

declare module '@mui/material/styles' {

    interface Theme {
        logoUrl: string;
        pictureUrl: string;
        headerColor?: 'default' | 'primary' | 'secondary';
    }

    interface ThemeOptions {
        logoUrl?: string;
        pictureUrl?: string;
        headerColor?: 'default' | 'primary' | 'secondary';
    }

}

const defaultBackgroundColor = '#fff';
const defaultBackgroundColorDark = '#121212';

const pictureDefaultUrl = PictureDefault;

const defaultTheme = new ThemeData();
defaultTheme.logo = { url: LightLogo };
defaultTheme.logoInverse = { url: DarkLogo };
defaultTheme.pictureDefault = { url: pictureDefaultUrl };
defaultTheme.properties = {
    primaryColor: '#bf533b',
    secondaryColor: '#07b2d9',
    defaultBackgroundColor,
    defaultBackgroundColorDark,
};

export interface IThemeContext {
    locale: string;
    mode: 'auto' | 'light' | 'dark';
    theme: ThemeData;
    timeZone: string;
}

const ThemeContext = createContext<IThemeContext>({
    locale: 'en_US',
    mode: 'auto',
    theme: defaultTheme,
    timeZone: 'America/New_York'
});

interface ThemeProviderProps {
    mode?: IThemeContext['mode'];
    theme?: ThemeData;
    stylesContainer?: HTMLElement;
}

export function useThemeContext() {
    return useContext(ThemeContext);
}

function isTypographyKey(key: string): key is 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' {
    return ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(key);
}

function getColor(palette: Palette, color?: string) {
    if (!color) return undefined;
    const [p1, p2] = color.split('.');
    color = (palette as any)[p1]?.[p2];
    return typeof color === 'string' ? color : undefined;
}

export function ThemeProvider({ children, stylesContainer, theme }: React.PropsWithChildren<ThemeProviderProps>) {

    const [{ locale, mode, timeZone }] = useContext(SettingsContext);

    const customCache = useMemo(() => stylesContainer && createCache({
        prepend: true,
        key: 'css',
        container: stylesContainer,
    }), [stylesContainer]);

    let ctx = useContext(ThemeContext);

    ctx = useMemo(() => {
        const loc = locale || ctx.locale
        return {
            locale: loc,
            mode: theme?.properties?.mode === 'light'
                ? 'light'
                : theme?.properties?.mode === 'dark'
                ? 'dark'
                : (mode || ctx.mode),
            theme: theme || ctx.theme,
            timeZone: timeZone || ctx.timeZone,
        }
    }, [ctx, locale, mode, theme, timeZone]);

    const muiTheme = useMemo(() => {

        const mode = (!ctx.mode || ctx.mode === 'auto') ? BrowserUtil.getPreferedColorScheme() : ctx.mode;

        const fonts = [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
        ];

        if (ctx.theme.properties?.fontFamily) {
            fonts.unshift(`'${ctx.theme.properties.fontFamily}'`);
        }

        const options: ThemeOptions = {
            palette: {
                mode,
                primary: {
                    main: ctx.theme.properties?.primaryColor || "#000",
                },
                secondary: {
                    main: ctx.theme.properties?.secondaryColor || "#000",
                },
                background: {
                    default: mode === 'dark' ? ctx.theme.properties?.defaultBackgroundColorDark || defaultBackgroundColorDark : ctx.theme.properties?.defaultBackgroundColor || defaultBackgroundColor,
                }
            },
            typography: palette => ({
                ...(ctx.theme.properties?.textStyles || []).reduce((a, { key, color, fontSize, ...styles }) => {
                    if (isTypographyKey(key)) {
                        a[key] = {
                            ...styles,
                            fontSize: fontSize ? fontSize + 'rem' : undefined,
                            color: getColor(palette, color),
                        };
                    }
                    return a;
                }, {} as TypographyOptions),
                fontFamily: fonts.join(','),
            }),
            logoUrl: mode === 'dark' ? (ctx.theme.logoInverse?.url || ctx.theme.logo?.url) : ctx.theme.logo?.url,
            pictureUrl: ctx.theme.pictureDefault?.url || pictureDefaultUrl,
            headerColor: ctx.theme.properties?.headerColor,
            components: {
                MuiLinearProgress: {
                    styleOverrides: {
                        root: ({ theme }) => ({
                            backgroundColor: theme.palette.grey[theme.palette.mode === 'dark' ? 800 : 200],
                        }),
                    },
                },
                MuiListItemButton: {
                    styleOverrides: {
                        disabled: {
                            opacity: 1,
                        }
                    }
                },
            }
        };

        return createTheme(options, getBestLocale(ctx.locale).muiLocale);
    }, [ctx]);

    const varsCss = useMemo(() => {

        return ":root{" + Object.entries(muiTheme.palette).reduce((css, [key, value]) => {

            if (key === 'common' || key === 'grey') {
                return css;
            }

            if (typeof value === 'string') {
                css += `--theme-${key}: ${value};`;
            }
            
            if (typeof value === 'object') {
                for (const [k, v] of Object.entries(value)) {
                    css += `--theme-${key}-${k}: ${v};`;
                }
            }

            return css;
        }, "") + "}";
    }, [muiTheme]);

    useEffect(() => {
        if (ctx.theme.favicon?.url) {
            let link = document.getElementById('__favicon') as HTMLLinkElement;
            if (!link) {
                link = document.createElement('link');
                link.rel = "icon";
                if (ctx.theme.favicon.type) {
                    link.type = ctx.theme.favicon.type;
                }
                document.head.appendChild(link);
            }
            link.href = ctx.theme.favicon.url;
        }
    }, [ctx]);

    let content = (
        <Provider theme={muiTheme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                {
                    !!(ctx.theme.styles?.length) && (
                        <style>
                            {ctx.theme.styles.join('\n')}
                        </style>
                    )
                }
                <style>
                    {varsCss}
                </style>
                <CssBaseline />
                {children}
            </LocalizationProvider>
        </Provider>
    );

    if (customCache) {
        content = (
            <CacheProvider value={customCache}>
                {content}
            </CacheProvider>
        );
    }

    return (
        <ThemeContext.Provider value={ctx}>
            {content}
        </ThemeContext.Provider>
    );
}
