import SearchParams from "./SearchParams";
import Label from "../core/Label";
import { Input } from "react-ts-form";
import SwitchInput from "../form/inputs/SwitchInput";

export default class ConsumerSearchParams extends SearchParams {
    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="search_enabled_only" />,
        },
    })    
    public enabledOnly?: boolean = true;
}