import { Input } from "react-ts-form";
import NumberInput from "../../form/inputs/NumberInput";
import DurationInput from "../../form/inputs/DurationInput";

export default class NumberData {
    @Input({ component: NumberInput })
    public value!: number;
}

export class SecondsData {
    @Input({
        component: DurationInput,
    })
    public value: number = 0;
}
