import { Shuttle } from "../types";

class ContentMenuCondition {

}

export const contentMenuCondition: Shuttle.Condition<ContentMenuCondition> = {
    name: "condition_content_menu",
    ConfigClass: ContentMenuCondition,
    evaluate: (_, { content }) => !!content.menu,
};
