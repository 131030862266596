import ThemeData from "./ThemeData";

export default class TenantInfo {
    public id!: number;
    public name!: string;
    public publicId!: string;
    public locale!: string[];
    public timeZone!: string;
    public enabled!: boolean;
    public theme?: ThemeData;
    public basePath?: string;
}
