import { Button } from "@mui/material";
import { Input } from "react-ts-form";
import Label from "../core/Label";
import SwitchInput from "../form/inputs/SwitchInput";
import TextInput from "../form/inputs/TextInput";
import AddIcon from "@mui/icons-material/Add";
import DropDownChoiceInput from "../form/inputs/DropDownChoiceInput";
import { ConditionBuilderInput } from "../form/inputs/ConditionBuilderInput";
import { Shuttle } from "../types";
import LangStringInput from "../form/inputs/LangStringInput";

class Extractor {

    @Input({
        component: DropDownChoiceInput.of<string>({
            options: [
                'QueryString',
                'HttpHeader',
                'CookieValue',
                'FormData',
                'ContentContentId',
                'ContentExternalId',
                'UserEmail',
                'UserId',
                'UserExternalId',
                'UserInternal',
                'UserLocale',
                'EnrollmentTimeSinceFirstVisit',
                'EnrollmentCreateDate'
            ]
        }),
        meta: {
            title: <Label k="type" />,
            required: true
        }
    })
    public type!: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="key" />
        }
    })
    public key!: string;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="caseSensitive" />
        }
    })
    public caseSensitive?: boolean;

    @Input({
        component: SwitchInput,
        meta: {
            title: "Skip if value is already set",
        },
    })
    public initOnly?: boolean;

}

class PropertyOption {

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="value" />
        }
    })
    public value!: string;

    @Input({
        component: LangStringInput,
        meta: {
            title: <Label k="displayName" />
        }
    })
    public displayName?: Shuttle.LangString

}

export class Property {

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="name" />,
            required: true,
        }
    })
    public name!: string;

    @Input({
        component: LangStringInput,
        meta: {
            title: <Label k="displayName" />
        }
    })
    public displayName?: Shuttle.LangString

    @Input({
        component: LangStringInput,
        meta: {
            title: <Label k="description" />
        }
    })
    public description?: Shuttle.LangString

    @Input({
        component: DropDownChoiceInput.of<string>({
            options: ['string', 'number', 'boolean']
        }),
        meta: {
            title: <Label k="type" />,
            required: true,
        }
    })
    public type!: string;

    @Input((_, { parent }) => {

        if (parent.type === 'string') {
            return {
                clazz: PropertyOption,
                meta: {
                    title: <Label k="options" />
                },
                array: {
                    sort: true,
                    remove: true,
                    addComponent: ({ onAdd }) => (
                        <Button 
                            size="small" 
                            color="secondary" 
                            startIcon={<AddIcon />}
                            onClick={() => onAdd([""])}
                        >
                            <Label k="add.option" />
                        </Button>
                    )
                }
            };
        }

        return {};
    })
    public options?: PropertyOption[];

    @Input({
        component: SwitchInput,
        meta: {
            title: 'Multiple Values',
        },
    })
    public multiple?: boolean;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="required" />
        }
    })
    public required?: boolean;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="editable" />
        }
    })
    public editable?: boolean;

    @Input((_, { parent }) => {
        if (parent.editable) {
            return { 
                component: ConditionBuilderInput,
                meta: {
                    title: <Label k="visible_condition" />
                },
                inputProps: {
                    nullable: true,
                }
            };
        }
        return {};
    })
    public condition?: Shuttle.ConditionNode;

    @Input({
        clazz: Extractor,
        meta: {
            title: <Label k="extractors" />
        },
        array: {
            addComponent: ({ onAdd }) => (
                <Button
                    size="small"
                    color="secondary"
                    onClick={() => onAdd([new Extractor()])}
                    startIcon={<AddIcon />}
                >
                    <Label k="add.extractor" />
                </Button>
            ),
            remove: true,
            sort: true
        }
    })
    public extractors?: Extractor[];

}

export default class Collector {

    public id!: number;

    public uuid!: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="name" />,
            required: true,
        },
    })
    public name!: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="externalId" />
        }
    })
    public externalId?: string;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="_default" />
        }
    })
    public _default: boolean = false;

    @Input({
        component: LangStringInput,
        meta: {
            title: 'Prompt Text',
        },
        inputProps: {
            type: 'richText',
        },
    })
    public promptText?: Shuttle.LangString;

    @Input({
        clazz: Property,
        meta: {
            title: <Label k="properties" />
        },
        array: {
            sort: true,
            remove: true,
            addComponent: ({ onAdd, values }) => (
                <Button 
                    size="small" 
                    color="secondary" 
                    startIcon={<AddIcon />}
                    onClick={() => onAdd([{ 
                        name: 'new_prop_' + ((values?.length || 0) + 1), 
                        type: 'string', 
                        required: false
                    }])}
                >
                    <Label k="add.property" />
                </Button>
            )
        }
    })
    public properties!: Property[];

}
