import { Input } from "react-ts-form";
import TextInput from "../form/inputs/TextInput";
import SearchParams from "./SearchParams";
import SwitchInput from "../form/inputs/SwitchInput";
import TextAreaInput from "../form/inputs/TextAreaInput";
import UserData from "./UserData";
import { Consumer } from "./Consumer";
import { ConsumerInput } from "../form/common";

export class ApiKey {

    public id!: number;

    @Input((uuid) => {
        if (!uuid) return {};
        return {
            component: TextInput,
            meta: {
                title: 'Key ID',
                disabled: true,
            },
        };
    })
    public uuid!: string;
    
    @Input({
        component: TextInput,
        meta: {
            title: 'Name',
            required: true,
        },
    })
    public name!: string;

    @Input({
        component: TextInput,
        meta: {
            title: 'External ID',
        },
    })
    public externalId!: string;

    @Input({
        component: SwitchInput,
        meta: {
            title: 'Enabled',
        },
    })
    public enabled!: boolean;

    @Input({
        component: TextAreaInput,
        meta: {
            title: 'IP Addresses Allowed',
            description: '(comma separated)',
        },
    })
    public ipsAllowed?: string;

    @Input({
        component: ConsumerInput,
        meta: {
            title: 'Consumer',
            description: 'If selected, this API Key will be scoped to a specific consumer only.'
        },
    })
    public consumer?: Consumer;

    public createDate!: string;
    public updateDate!: string;
    public lastAccessDate?: string;
    public user!: UserData;

    public secret?: string;

}

export interface ApiKeySearchParams extends SearchParams {
    userIds?: number[];
    consumerIds?: number[];
}
