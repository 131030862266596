import { useContext, useMemo } from "react";
import { formatDistance as _formatDistance, formatRelative as _formatRelative, isSameDay, parseISO, isValid } from "date-fns";
import { format, utcToZonedTime } from "date-fns-tz";
import { Shuttle } from "../types";
import { getBestLocale } from "../i18n/loaders";
import { SettingsContext } from "../contexts";

type DateArg = number | string | Date | null | undefined;

export function parseDate(date: DateArg, timeZone?: string) {
    if (date === undefined || date === null) {
        return undefined;
    }
    if (typeof date === 'number') {
        date = new Date(date);
    }
    if (typeof date === 'string') {
        date = parseISO(date);
    }
    if (!isValid(date)) {
        return undefined;
    }
    if (timeZone) {
        return utcToZonedTime(date, timeZone);
    }
    return date;
}

export function formatDate({ locale, timeZone }: Shuttle.Settings, date: DateArg, fmt: string = "P") {
    const dt = parseDate(date);
    return dt ? format(dt, fmt, {
        locale: getBestLocale(locale).dateFormat,
        timeZone: timeZone,
    }) : "";
}

export function formatDistance({ locale }: Shuttle.Settings, from: DateArg, to: DateArg) {
    const f = parseDate(from);
    const t = parseDate(to);
    return (t && f) ? _formatDistance(f, t, { locale: getBestLocale(locale).dateFormat }) : '';
}

export function formatRelative({ locale, timeZone }: Shuttle.Settings, date: DateArg) {
    const f = parseDate(date, timeZone);
    const t = parseDate(new Date().getUTCDate(), timeZone);
    return f && t ? _formatRelative(f, t, { locale: getBestLocale(locale).dateFormat }) : '';
}

export function formatDateRange({ locale: localeStr, timeZone }: Shuttle.Settings, start: DateArg, end: DateArg, timeOnly?: boolean) {
    const locale = getBestLocale(localeStr).dateFormat
    const d1 = parseDate(start, timeZone);
    const d2 = parseDate(end, timeZone);
    let str = "";
    if (!d1) {
        return str;
    }
    const sameDay = !!d2 && isSameDay(d1, d2);
    const isTimeOnly = timeOnly && (!d2 || sameDay);
    str += format(d1, isTimeOnly ? "p" : "Pp", { locale, timeZone });
    if (d2) {
        str += " - ";
        str += format(d2, sameDay || isTimeOnly ? "p" : "Pp", { locale, timeZone });
    }
    if (timeZone) {
        str += (" (" + format(utcToZonedTime(d1, timeZone), "O", { locale, timeZone }) + ")");
    }
    return str;
}

export function useFormattedDate(date: DateArg, fmt: string = "P") {
    const [settings] = useContext(SettingsContext);
    return useMemo(() => formatDate(settings, date, fmt), [settings, date, fmt]);
}

export function useFormattedDistance(from: DateArg, to: DateArg) {
    const [settings] = useContext(SettingsContext);
    return useMemo(() => formatDistance(settings, from, to), [settings, from, to]);
}

export function useFormatRelative(date: DateArg) {
    const [settings] = useContext(SettingsContext);
    return useMemo(() => formatRelative(settings, date), [settings, date]);
}

export function useFormattedDuration(seconds: number) {
    return useFormattedDistance(0, seconds);
}

export function useFormattedDateRange(start: DateArg, end: DateArg, timeOnly?: boolean) {
    const [settings] = useContext(SettingsContext);
    return useMemo(() => formatDateRange(settings, start, end, timeOnly), [settings, start, end, timeOnly]);
}
