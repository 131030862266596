import { Input } from "react-ts-form";
import Label from "../../core/Label";
import { TagNameMultiInput } from "../../form/common";
import SearchParams from "../SearchParams";

export default class QuestionSearchParams extends SearchParams {

    @Input({
        component: TagNameMultiInput,
        meta: {
            title: <Label k="tags" />
        }
    })
    public tags?: string[];

}
