import { TextField } from "@mui/material";
import { useState } from "react";
import { IInputProps } from "react-ts-form";

import StringUtil from "../../util/StringUtil";

interface IDurationInputProps extends IInputProps<number> { // value in seconds

}

function fromValue(seconds: number) {
    if (seconds === undefined || seconds === null) {
        seconds = 0;
    }
    return StringUtil.formatHMS(seconds);
}

const rx = /\d+[hms]/g

function toValue(fmt: string) {
    if (!fmt) {
        return 0;
    }
    return fmt.match(rx)?.reduce((a, c) => {
        const unit = c.charAt(c.length - 1)
        const value = parseInt(c.substring(0, c.length - 1))
        if (isNaN(value)) return a
        switch (unit) {
            case 'h':
                return a + (3600 * value)
            case 'm':
                return a + (60 * value)
            case 's':
                return a + value
            default:
                return a
        }
    }, 0) ?? 0
}

export default function DurationInput(props: IDurationInputProps) {
    const [state, setState] = useState(fromValue(props.value ?? 0));
    return (
        <TextField 
            helperText="e.g. 5m 30s"
            value={state}
            onChange={e => setState(e.target.value)}
            onBlur={() => {
                props.onChange(toValue(state))
            }}
        />
    );
}
