import Axios from "axios";
import { Webhook } from "../model/Webhook";
import createEntityService from "./createEntityService";
import { Shuttle } from "../types";

interface WebhookHistoryParams {
    beforeId?: number;
    consumerIds?: number[];
    userIds?: number[];
    rootContentIds?: number[];
    rootEnrollmentIds?: number[];
    types?: string[];
}

export const WebhookService = {
    ...createEntityService<Webhook>({
        path: '/webhooks',
    }),

    history: async (webhookId: number | string, params: WebhookHistoryParams) => await Axios
        .get<Shuttle.WebhookLog[]>(`/webhooks/${webhookId}/history`, { params })
        .then(r => r.data),

    eventPreview: async ({
        beforeId,
        rule,
        template,
    }: {
        beforeId?: number;
        rule?: Shuttle.Rule;
        template?: string;
    }) => await Axios
        .post<{
            id: number;
            uuid: string;
            createDate: string;
            eventDate: string;
            data: any;
            matches: boolean;
            preview?: string;
        }[]>('/webhooks/events-preview', {
            rule,
            template,
        }, {
            params: {
                beforeId,
            },
        }).then(r => r.data),

    reactivate: async (webhookId: number | string) => {
        await Axios.post(`/webhooks/${webhookId}/reactivate`, {});
    },
}
