import { useContext, useMemo } from "react";
import { ConstraintsContext, ContentContext, GlossaryContext, SessionContext, SettingsContext } from "../contexts";
import bestLocaleMatch from "../i18n/util";
import { Shuttle } from "../types";
import renderLayoutString from "./renderLayoutString";

interface UseLayoutStringArgs {
    text: Shuttle.LangString | undefined
    html?: boolean;
    enableGlossary?: boolean;
    skip?: boolean;
}

export default function useLayoutString({ html, text, enableGlossary = false, skip = false }: UseLayoutStringArgs) {
    const [settings] = useContext(SettingsContext);
    const { session } = useContext(SessionContext);
    const constraints = useContext(ConstraintsContext);
    const contentNode = useContext(ContentContext);
    const glossary = useContext(GlossaryContext);
    return useMemo(() => {
        if (skip) {
            return bestLocaleMatch(text ?? {}, settings.locale) ?? ''
        }
        return renderLayoutString({
            text: text ?? {},
            html, 
            glossary,
            settings,
            entryPoint: session, 
            constraints, 
            contentNode, 
            enableGlossary,
        })
    }, [skip, text, html, glossary, settings, session, constraints, contentNode, enableGlossary]);
}
