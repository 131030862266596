import * as React from "react";
import { IInputProps } from "react-ts-form";
import IControlledProps from "../../model/IControlledProps";
import SearchParams from "../../model/SearchParams";
import { Class } from "../../util/Class";
import { ILazyOptionsProps } from "../IOptionsProps";
import AdapterInput, { AsyncAdapter } from "./AdapterInput";
import ListMultipleChoiceInput from "./ListMultipleChoiceInput";

interface IListSingleChoiceInputProps<T, U extends SearchParams = SearchParams> extends IInputProps<T>, ILazyOptionsProps<T, U> {
    paramsClass?: Class<U>;
    params?: Partial<U>;
    entityRemoveToken?: boolean;
}

export default function ListSingleChoiceInput<T, U extends SearchParams = SearchParams>({ value, onChange, ...rest }: IListSingleChoiceInputProps<T, U>) {
    return (
        <ListMultipleChoiceInput 
            {...rest}
            singleChoice
            value={value ? [value] : []}
            onChange={next => onChange(next?.[0])}
        />
    );
}

ListSingleChoiceInput.ofType = function<T, U extends SearchParams = SearchParams>() {
    return ListSingleChoiceInput as (React.ComponentType<IListSingleChoiceInputProps<T, U>>);
}

ListSingleChoiceInput.forOptions = function<T, U extends SearchParams = SearchParams>(
    options: IListSingleChoiceInputProps<T, U>['options'],
    choiceRenderer: IListSingleChoiceInputProps<T, U>['choiceRenderer']
) {
    return (props: Omit<IListSingleChoiceInputProps<T, U>, "options" | "choiceRenderer">) => (
        <ListSingleChoiceInput options={options} choiceRenderer={choiceRenderer} {...props} />
    );
}

ListSingleChoiceInput.withAdapter = function<T, U, V extends SearchParams = SearchParams>(options: IListSingleChoiceInputProps<U, V>['options'], adapter: AsyncAdapter<T, U>, choiceRenderer?: IListSingleChoiceInputProps<U, V>["choiceRenderer"]) {
    return (props: IControlledProps<T> & Omit<IListSingleChoiceInputProps<U, V>, keyof (ILazyOptionsProps<U, V> & IControlledProps<U>)>) => (
        <AdapterInput
            {...props}
            component={ListSingleChoiceInput.ofType<U>()}
            options={options as any /* TODO fix this typing */}
            choiceRenderer={choiceRenderer}
            adapter={adapter}
        />
    );
}

ListSingleChoiceInput.of = function<T, U extends SearchParams = SearchParams>(settings: Omit<IListSingleChoiceInputProps<T, U>, keyof IInputProps<T>>) {
    return (props: IInputProps<T> & Partial<Omit<IListSingleChoiceInputProps<T, U>, keyof IInputProps<T>>>) => (
        <ListSingleChoiceInput {...settings} {...props} />
    );
};
