import { useState } from "react";
import * as React from "react";
import { Popover, Paper } from "@mui/material";

interface IAnchorPopoverProps {
    children: (setAnchor: (element: HTMLElement) => void) => React.ReactElement;
    content: React.ReactNode | (() => React.ReactNode);
}

export default function AnchorPopover(props: IAnchorPopoverProps) {
    const [anchor, setAnchor] = useState<HTMLElement | null>(null);

    return (
        <React.Fragment>
            {props.children(setAnchor)}
            <Popover 
                open={Boolean(anchor)} 
                anchorEl={anchor}
                onClose={() => setAnchor(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Paper>
                    {typeof props.content === 'function' ? props.content() : props.content}
                </Paper>
            </Popover>
        </React.Fragment>
    );
}
