import { createElement } from "react";
import { Input } from "react-ts-form";
import Label from "../core/Label";
import TextInput from "../form/inputs/TextInput";

export default class SearchParams {

    public page: number = 0;

    public itemsPerPage: number = 10;

    public sortBy: string[] = [];

    @Input({
        component: TextInput.Debounced,
        meta: {
            title: createElement(Label, { k: 'search' })
        }
    })
    public search?: string;

}
