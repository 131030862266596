import { Shuttle } from "../../types";
import WebIcon from "@mui/icons-material/Link";
import Embed from "../Embed";
import { Input } from "react-ts-form";

import Label from "../../core/Label";
import SwitchInput from "../../form/inputs/SwitchInput";
import RichTextInput from "../../form/inputs/RichTextInput";
import AssetUrlInput from "../../form/inputs/AssetUrlInput";

class WebConfig {

    @Input({
        component: AssetUrlInput,
        meta: {
            title: <Label k="url" />
        },
    })
    public url!: string;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="redirect" />
        }
    })
    public redirect?: boolean;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="completeOnLaunch" />
        }
    })
    public completeOnLaunch?: boolean;

    @Input({
        component: RichTextInput,
        meta: {
            title: <Label k="completedMessage" />
        }
    })
    public callbackMessage?: string;

}

export const WebLauncher: Shuttle.Launcher = {
    name: 'launcher.web',
    Icon: WebIcon,
    ConfigClass: WebConfig,
    Component: Embed,
    hasSystem: true,
};
