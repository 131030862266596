import { Shuttle } from "../types";
import { Box, Paper, Theme, useTheme } from "@mui/material";
import BoxIcon from "@mui/icons-material/AddBox";
import { LayoutRegion, setElementData } from "../core/ui/Layout";
import { Input } from "react-ts-form";
import SwitchInput from "../form/inputs/SwitchInput";
import Label from "../core/Label";
import NumberInput from "../form/inputs/NumberInput";
import AssetUrlInput from "../form/inputs/AssetUrlInput";
import { SxProps } from "@mui/system";
import { ColorRefInput } from "../form/common";
import { useThemeContext } from "../core/ThemeProvider";
import { CSSProperties } from "react";
import DropDownChoiceInput from "../form/inputs/DropDownChoiceInput";

class BoxElementConfig {

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="paper" />
        }
    })
    public paper?: boolean;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="square" />
        }
    })
    public square?: boolean;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="outlined" />
        }
    })
    public outlined?: boolean;

    @Input({
        component: NumberInput,
        meta: {
            title: <Label k="elevation" />
        },
        inputProps: {
            min: 0,
            max: 24
        }
    })
    public elevation: number = 0;

    @Input({
        component: NumberInput,
        meta: {
            title: <Label k="padding_x" />
        },
        inputProps: {
            min: 0,
            max: 20
        }
    })
    public px?: number;

    @Input({
        component: NumberInput,
        meta: {
            title: <Label k="padding_y" />
        },
        inputProps: {
            min: 0,
            max: 20
        }
    })
    public py?: number;

    @Input({
        component: ColorRefInput,
        meta: {
            title: <Label k="backgroundLight" />
        }
    })
    public backgroundColor?: string;

    @Input({
        component: ColorRefInput,
        meta: {
            title: <Label k="backgroundDark" />
        }
    })
    public backgroundColorDark?: string;

    @Input({
        component: ColorRefInput,
        meta: {
            title: "Color (Light Mode)",
        },
    })
    public color?: string;

    @Input({
        component: ColorRefInput,
        meta: {
            title: "Color (Dark Mode)",
        },
    })
    public colorDark?: string;

    @Input({
        component: ColorRefInput,
        meta: {
            title: "Border Color",
        },
    })
    public borderColor?: string;

    @Input({
        component: ColorRefInput,
        meta: {
            title: "Border Color (Dark Mode)",
        },
    })
    public borderColorDark?: string;

    @Input({
        component: NumberInput,
        meta: {
            title: 'Border Width',
        },
        inputProps: {
            min: 0,
        }
    })
    public borderWidth?: number;

    @Input({
        component: AssetUrlInput,
        meta: {
            title: <Label k="background_image" />
        },
        inputProps: {
            accept: "image/*"
        }
    })
    public backgroundImage?: string;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="horizontal" />
        }
    })
    public horizontal?: boolean;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="container" />
        }
    })
    public container?: boolean;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k='fill.space' />
        }
    })
    public fillSpace?: boolean;

    @Input({
        component: DropDownChoiceInput.of<CSSProperties['alignItems']>({
            options: ['stretch', 'center', 'flex-start', 'flex-end']
        }),
        meta: {
            title: <Label k="alignment" />
        }
    })
    public alignItems?: CSSProperties['alignItems'];

    @Input({
        component: NumberInput,
        meta: {
            title: <Label k="spacing" />
        },
        inputProps: {
            min: 0,
            max: 10
        }
    })
    public spacing?: number;
    
    public elements!: string[];

}

const BoxElement: Shuttle.LayoutElementType<BoxElementConfig> = {
    name: 'layout_box',
    Icon: BoxIcon,
    ConfigClass: BoxElementConfig,
    Component({ config }) {
        const theme = useTheme();
        const spacing = Math.min(10, Math.max(0, config.spacing || 0));
        const themeCtx = useThemeContext();

        const region = (
            <LayoutRegion 
                keys={config.elements || []} 
                direction={config.horizontal ? 'row' : 'column'} 
                fillSpace={config.fillSpace}
                alignItems={config.alignItems}
                spacing={spacing}
            />
        );

        let backgroundColor = theme.palette.mode === 'dark' ? config.backgroundColorDark : config.backgroundColor;
        if (backgroundColor === 'initial') {
           backgroundColor = undefined;
        }
        
        let color = theme.palette.mode === 'dark' ? config.colorDark : config.color;
        if (color === 'initial') {
            color = undefined;
        }

        const borderColor = theme.palette.mode === 'dark' ? config.borderColorDark : config.borderColor;

        const sx: SxProps<Theme> = {
            backgroundColor,
            borderColor,
            color,
            px: config.px,
            py: config.py,
            width: '100%',
        };

        if (typeof config.borderWidth === 'number') {
            sx.borderWidth = Math.max(0, config.borderWidth ?? 0);
        }

        if (!config.outlined && sx.borderWidth) {
            sx.borderStyle = 'solid';
        }

        if (config.backgroundImage) {
            const url = config.backgroundImage === '{{theme.posterUrl}}' ? themeCtx.theme.posterDefault?.url ?? '' : config.backgroundImage;
            if (url) {
                sx.backgroundImage = `url('${url}')`;
                sx.backgroundSize = 'cover';
                sx.backgroundPosition = 'center';
            }
        }

        if (config.container) {
            sx.margin = '0 auto';
            sx.maxWidth = {
                xs: '100%',
                sm: '600px',
                md: '900px',
                lg: '1200px',
            };
        }

        if (config.paper) {
            sx.overflow = 'hidden';
            return (
                <Paper 
                    elevation={config.elevation}
                    square={config.square}
                    sx={sx}
                    variant={config.outlined ? 'outlined' : 'elevation'}
                >
                    {region}
                </Paper>
            );
        }
        
        return (
            <Box sx={sx}>
                {region}
            </Box>
        );
    },
    getRegions(config, key) {
        return [
            {
                key: 'elements',
                keys: config.elements || [],
                setKeys: (layout, elements) => setElementData(layout, key, {...config, elements}),
            },
        ];
    }
};

export default BoxElement;
