import { createElement, Fragment, useContext } from "react";
import { ListItem, ListItemText, Divider, List, ListItemIcon, Box, Alert } from "@mui/material";
import { RouteComponentProps, Switch, useHistory } from "react-router-dom";

import ContentIcon from "@mui/icons-material/Widgets";
import UserIcon from "@mui/icons-material/People";
import TagIcon from "@mui/icons-material/LocalOffer";
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import SettingsIcon from "@mui/icons-material/Settings";
import ThemesIcon from "@mui/icons-material/Palette";
import QuizModesIcon from "@mui/icons-material/SettingsApplications";
import QuestionsIcon from "@mui/icons-material/CheckBox";
import ContributorsIcon from "@mui/icons-material/Group";
import GlossariesIcon from "@mui/icons-material/Spellcheck";
import TemplatesIcon from "@mui/icons-material/ViewQuilt";
import CollectorsIcon from "@mui/icons-material/DynamicForm";
import ConditionsIcon from "@mui/icons-material/Rule";
import BehaviorIcon from "@mui/icons-material/AutoFixHigh";
import WebhookIcon from '@mui/icons-material/Webhook';
import ConsumersIcon from '@mui/icons-material/Business';
import KeyIcon from '@mui/icons-material/Key';
import DnsIcon from '@mui/icons-material/Dns';
import GroupIcon from '@mui/icons-material/Groups';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import AwardIcon from '@mui/icons-material/EmojiEvents';

import Layout from "../core/Layout";
import { adminOnly, anyStaff, processSections, SectionWrapper } from "../core/admin/AdminSection";
import UserToolbar from "../core/UserToolbar";
import Tags from "./admin/Tags";
import { FeedbackProvider } from "../core/FeedbackContext";
import { AppContext } from "../core/AppContext";
import Users from "./admin/Users";
import Contents from "./admin/Contents";
import TenantSettings from "./admin/TenantSettings";
import Themes from "./admin/Themes";
import QuizModes from "./admin/QuizModes";
import Questions from "./admin/Questions";
import Contributors from "./admin/Contributors";
import Glossaries from "./admin/Glossaries";
import Templates from "./admin/Templates";
import Conditions from "./admin/Conditions";
import Collectors from "./admin/Collectors";
import Behaviors from "./admin/Behaviors";
import Webhooks from "./admin/Webhooks";
import Consumers from "./admin/Consumers";
import ContentProviders from "./admin/ContentProviders";
import ApiKeys from "./admin/ApiKeys";
import Aliases from "./admin/Aliases";
import Groups from "./admin/Groups";
import AuthProviders from "./admin/AuthProviders";
import { AuthService } from "../services/AuthService";
import AwardTypes from "./admin/AwardTypes";

const sections: Array<SectionWrapper<void>> = [
    {
        path: '/consumers',
        label: 'admin.section.consumers',
        icon: ConsumersIcon,
        section: Consumers,
    },
    {
        path: '/contents',
        label: 'admin.section.contents',
        icon: ContentIcon,
        section: Contents
    },
    {
        path: '/users',
        label: 'admin.section.users',
        icon: UserIcon,
        section: Users
    },
    {
        path: '/groups',
        label: 'admin.section.groups',
        icon: GroupIcon,
        section: Groups,
    },
    {
        path: '/award-types',
        label: 'admin.section.award.types',
        icon: AwardIcon,
        section: AwardTypes,
    },
    {
        path: '/quiz-modes',
        label: 'admin.section.quiz.modes',
        icon: QuizModesIcon,
        section: QuizModes,
    },
    {
        path: '/questions',
        label: 'admin.section.quiz.questions',
        icon: QuestionsIcon,
        section: Questions,
    },
    {
        path: '/tags',
        label: 'admin.section.tags',
        icon: TagIcon,
        section: Tags,
    },
    {
        path: '/contributors',
        label: 'admin.section.contributors',
        icon: ContributorsIcon,
        section: Contributors,
    },
    {
        path: '/glossaries',
        label: 'admin.section.glossaries',
        icon: GlossariesIcon,
        section: Glossaries
    },
    {
        path: '/behaviors',
        label: 'admin.section.behaviors',
        icon: BehaviorIcon,
        section: Behaviors,
    },
    {
        path: '/conditions',
        label: 'admin.section.conditions',
        icon: ConditionsIcon,
        section: Conditions,
    },
    {
        path: '/collectors',
        label: 'admin.section.collectors',
        icon: CollectorsIcon,
        section: Collectors,
    },
    {
        path: '/templates',
        label: 'admin.section.templates',
        icon: TemplatesIcon,
        section: Templates
    },
    {
        path: '/themes',
        label: 'admin.section.themes',
        icon: ThemesIcon,
        section: Themes
    },
    {
        path: '/content-providers',
        label: 'admin.section.content.providers',
        icon: IntegrationInstructionsIcon,
        section: ContentProviders,
    },
    {
        path: '/auth-providers',
        label: 'admin.section.auth.providers',
        icon: LockOpenIcon,
        section: AuthProviders,
    },
    {
        path: '/webhooks',
        label: 'admin.section.webhooks',
        icon: WebhookIcon,
        section: Webhooks,
    },
    {
        path: '/api-keys',
        label: 'admin.section.api.keys',
        icon: KeyIcon,
        section: ApiKeys,
        enabled: (_, appContext) => adminOnly(appContext),
    },
    {
        path: '/aliases',
        label: 'admin.section.aliases',
        icon: DnsIcon,
        section: Aliases,
    },
    {
        path: '/settings',
        label: 'admin.section.tenant.settings',
        icon: SettingsIcon,
        section: TenantSettings,
        enabled: (_, appContext) => adminOnly(appContext),
    },
];

export default function Admin(props: RouteComponentProps) {
    const [appContext] = useContext(AppContext);
    const history = useHistory();

    if (!appContext.user) {
        history.push('/login?r=' + encodeURIComponent(AuthService.trimFrontendPath(appContext.tenant, window.location.pathname)));
        return null;
    }

    if (!anyStaff(appContext)) {
        return (
            <Layout>
                <Alert severity="info">
                    {`You don't have permission to access this section. Please contact your administrator.`}
                </Alert>
            </Layout>
        );
    }

    const { routes, tabs } = processSections<void>({ appContext, context: undefined, sections, pathPrefix: '/admin' });

    return (
        <FeedbackProvider>
            <Layout 
                onClickLogo={() => history.push('/')}
                drawer={
                    <Fragment>
                        <ListItem>
                            <ListItemText primary={appContext.tenant.name} secondary={appContext.tenant.publicId} />
                        </ListItem>
                        <Divider />
                        <List>
                            {
                                tabs.map(({ icon, path, title }) => {
                                    const selected = props.location.pathname.startsWith(path);
                                    return (
                                        <ListItem 
                                            dense
                                            button
                                            key={path}
                                            onClick={() => props.history.push(path)}
                                            selected={selected}
                                            color={selected ? 'primary' : undefined}
                                        >
                                            <ListItemIcon>
                                                {icon && createElement(icon, { color: selected ? 'primary' : undefined })}
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={title}
                                                primaryTypographyProps={{
                                                    color: selected ? 'primary' : undefined,
                                                }}
                                            />
                                        </ListItem>
                                    );
                                })
                            }
                        </List>
                    </Fragment>
                }
                rightElement={<UserToolbar />}
                fullscreen
            >
                <Box sx={{ p: 2 }}>
                    <Switch>
                        {routes}
                    </Switch>
                </Box>
            </Layout>
        </FeedbackProvider>
    );
}
