import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useContext, useState } from "react";
import { IInputProps } from "react-ts-form";
import RichTextInput from "./RichTextInput";
import TextInput from "./TextInput";
import DoneIcon from '@mui/icons-material/Done';
import { AppContext } from "../../core/AppContext";

interface LangStringInputProps extends IInputProps<{ [locale: string]: string }> {
    type?: 'text' | 'richText'
    languages?: string[]
}

export default function LangStringInput({
    languages,
    onChange,
    type = 'text',
    value,
    ...rest
}: LangStringInputProps) {

    const [{ tenant }] = useContext(AppContext);

    languages = languages?.length ? languages : tenant.locale?.length ? tenant.locale : ['en'];

    const [lang, setLang] = useState(languages[0])
    
    return (
        <Box sx={{ display: 'flex', alignItem: 'flex-start' }}>
            <Box sx={{ mr: 1 }}>
                <ToggleButtonGroup
                    exclusive
                    value={lang}
                    onChange={(_, lang) => setLang(lang)}
                    orientation='vertical'
                    size='small'
                >
                    {
                        languages.map((lang, i, arr) => (
                            <ToggleButton 
                                key={lang + '-' + i}
                                value={lang}
                            >
                                {lang}
                                {
                                    !!(arr.length > 1 && value?.[lang]) && (
                                        <DoneIcon fontSize='small' sx={{ ml: .5 }} />
                                    )
                                }
                            </ToggleButton>
                        ))
                    }
                </ToggleButtonGroup>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
                {
                    type === 'richText'
                        ? (
                            <RichTextInput
                                {...rest}
                                value={value?.[lang] ?? ''}
                                onChange={str => onChange({ ...value ?? {}, [lang]: str })}
                                key={lang}
                            />
                        )
                        : (
                            <TextInput
                                {...rest}
                                value={value?.[lang] ?? ''}
                                onChange={str => onChange({ ...value ?? {}, [lang]: str })}
                                key={lang}
                                fullWidth
                            />
                        )
                }
            </Box>
        </Box>
    )
}
