import ThemeData from "../../model/ThemeData";
import ThemeService from "../../services/ThemeService";
import { anyEditor, createEntitySection, createFormAction } from "../../core/admin/AdminSection";

export default createEntitySection<ThemeData>({
    model: ThemeData,
    getId: t => t.id,
    label: 'name',
    ...ThemeService,
    columns: [
        {
            key: 'name',
            sort: 'name',
        },
        {
            key: 'externalId',
            sort: 'externalId',
        },
    ],
    editEnabled: ({ appContext }) => anyEditor(appContext),
    actions: [
        createFormAction({
            key: 'create',
            clazz: ThemeData,
            onSubmit: async theme => {
                await ThemeService.create(theme);
                return true;
            },
            enabled: (_, appContext) => anyEditor(appContext),
        }),
    ],
});
