import { useContext } from "react";
import * as React from "react";
import { IInputTemplateProps, IArrayItemTemplateProps, IFieldsetTemplate } from "react-ts-form";
import { FormHelperText, IconButton, Box, Typography, Divider, Grid } from "@mui/material";

import DeleteIcon from '@mui/icons-material/Delete';
import UpIcon from "@mui/icons-material/ArrowUpward";
import DownIcon from "@mui/icons-material/ArrowDownward";

import useString from "../hooks/useString";
import { ValidationContext } from "./ValidationContext";
import { isValidLabelKey } from "../core/localization/ValidLabelKey";
import Label from "../core/Label";

export function InputTemplate(props: IInputTemplateProps & { noMargin?: boolean; }) {
    const violations = useContext(ValidationContext);
    return (
        <Box mb={props.noMargin ? 0 : 2}>
            {
                props.title && (
                    <Typography component="label" display="block" htmlFor={props.labelFor} variant="caption">
                        {props.title}
                        {props.required && "*"}
                    </Typography>
                )
            }
            {props.children}
            {
                props.description && (
                    <FormHelperText required={props.required}>
                        {props.description}
                    </FormHelperText>
                )
            }
            {
                violations.reduce((a, c) => {
                    if (c.path.slice(c.path.indexOf('.') + 1) === props.path) {
                        a.push(
                            <FormHelperText key={c.key_} error={true}>
                                {
                                    isValidLabelKey(c.key_) ? <Label k={c.key_} args={c.attrs} /> : c.message
                                }
                            </FormHelperText>
                        );
                    }
                    return a;
                }, [] as React.ReactNodeArray)
            }
        </Box>
    );
}

export function ArrayItemTemplate(props: IArrayItemTemplateProps) {
    const removeLabel = useString('remove');
    const moveUpLabel = useString('move.up');
    const moveDownLabel = useString('move.down');

    return (
        <React.Fragment>
            {
                props.index > 0 && (
                    <Divider />
                )
            }
            <Box marginY={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md="auto">
                        {
                            (props.canRemove || props.canSortDown || props.canSortUp) && (
                                
                                <div>
                                    <IconButton 
                                        aria-label={moveUpLabel}
                                        disabled={!props.canSortUp} 
                                        onClick={props.canSortUp ? props.sortUp : undefined}
                                        size="small"
                                    >
                                        <UpIcon />
                                    </IconButton>
                                    <IconButton 
                                        aria-label={moveDownLabel} 
                                        disabled={!props.canSortDown}
                                        onClick={props.canSortDown ? props.sortDown : undefined}
                                        size="small"
                                    >
                                        <DownIcon />
                                    </IconButton>
                                    {
                                        props.canRemove && (
                                            <IconButton aria-label={removeLabel} onClick={props.remove} size="small">
                                                <DeleteIcon />
                                            </IconButton>
                                        )
                                    }
                                </div>
                            )
                        }
                    </Grid>
                    <Grid item xs={12} md>
                        {props.children}
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    );
}


export function FieldsetTemplate(props: IFieldsetTemplate) {
    return (
        <Box component="fieldset" my={2}>
            {
                props.title && (
                    <Typography component="legend">
                        {props.title}
                    </Typography>
                )
            }
            {props.children}
        </Box>
    );
}
