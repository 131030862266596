import { Shuttle } from "../../types";
import PageIcon from "@mui/icons-material/Pages";
import { Layout } from "../../core/ui/Layout";
import { useEffect, useRef } from "react";
import BrowserUtil from "../../util/BrowserUtil";
import { Input } from "react-ts-form";
import { LayoutInput } from "../../form/inputs/LayoutInput";

class PageConfig {

    @Input({
        component: LayoutInput,
    })
    public layout!: Shuttle.LayoutConfig;

}

export const PageLauncher: Shuttle.Launcher = {
    name: 'launcher.page',
    Icon: PageIcon,
    tracking: true,
    ConfigClass: PageConfig,
    Component: ({ session, track: { commit } }) => {

        const config = session.config as PageConfig;

        const ref = useRef<HTMLDivElement>(null);

        const scrollCommitted = useRef(false);

        useEffect(() => {
            const handler = () => {
                if (session.mode === 'Normal' && ref.current && scrollCommitted.current === false && BrowserUtil.isElementInViewport(ref.current)) {
                    commit({ completion: 'Completed' });
                    scrollCommitted.current = true;
                }
            }
            window.addEventListener('scroll', handler);
            handler();
            return () => {
                window.removeEventListener('scroll', handler);
            }
        }, [session, commit]);

        return (
            <>
                <Layout layout={config?.layout} />
                <div ref={ref} style={{ marginBottom: '30px' }} />
            </>
        );
    }
};
