import { formatDate, formatDateRange } from "../hooks/useDate";
import StringUtil from "../util/StringUtil";
import { Shuttle } from "../types";
import BrowserUtil from "../util/BrowserUtil";
import { getAlternative } from "../player/utils";
import bestLocaleMatch from "../i18n/util";

interface RenderLayoutStringArgs {
    text: Shuttle.LangString
    constraints: null | Shuttle.Constraints;
    contentNode: null | Shuttle.ContentNode;
    entryPoint: null | Shuttle.PlayEntryPointData;
    settings: Shuttle.Settings
    glossary: Shuttle.GlossaryContextValue;
    enableGlossary?: boolean;
    html?: boolean;
}

function getContentDuration(contentNode: Shuttle.ContentNode, constraints: Shuttle.Constraints) {
    const content = getAlternative(constraints, contentNode)
    if (content.duration) return content.duration
    if (!content.children?.length) return 0
    let d = 0
    for (const child of content.children) {
        d += getContentDuration(child, constraints)
    }
    return d
}

export default function renderLayoutString({ contentNode, constraints, enableGlossary, entryPoint, glossary, html, settings, text }: RenderLayoutStringArgs) {

    let str = bestLocaleMatch(text, settings.locale) ?? ''

    str = StringUtil.formatVars(str, (name, def) => {

        if (name.startsWith('state_')) {
            name = name.slice(6);
            return entryPoint?.state[name]?.[0] || def || "";
        }

        const content = contentNode && (constraints ? getAlternative(constraints, contentNode) : contentNode.content);
        const status = (content && entryPoint?.tracking[content.uuid]) || undefined;
        const score = status?.score;
        const minTimeSpent = content?.minTimeSpent || 0;

        switch (name) {
            case 'content.title':
                return bestLocaleMatch(content?.title ?? {}, settings.locale) || def || '';

            case 'content.subTitle':
                return bestLocaleMatch(content?.subTitle ?? {}, settings.locale) || def || '';

            case 'content.description':
                return (html && (bestLocaleMatch(content?.description ?? {}, settings.locale))) || def || '';

            case 'content.tags':
                return content?.tags?.map(t => bestLocaleMatch({ en: t.name, ...t.displayName ?? {} }, settings.locale)).join(', ') || def || '';

            case 'content.duration':
                const duration = (contentNode && constraints) ? getContentDuration(contentNode, constraints) : undefined
                return typeof duration === 'number' ? StringUtil.formatHMS(duration) : (def || '');

            case 'content.start':
                return (content?.start && formatDate(settings, content.start)) || def || '';

            case 'content.end':
                return (content?.end && formatDate(settings, content.end)) || def || '';

            case 'content.minTimeSpent':
                return StringUtil.formatHMS(minTimeSpent) || def || '';

            case 'content.startEndRange':
                return (content?.start && formatDateRange(settings, content.start, content?.end)) || def || '';

            case 'content.completion':
                return status?.completion || def || '';

            case 'content.score':
                if (score === null || score === undefined) {
                    return def || '';
                }
                return score + '%';

            case 'content.completionDate':
                return (status?.completionDate && formatDate(settings, status.completionDate)) ?? '';

            case 'content.score.rounded':
                if (score === null || score === undefined) {
                    return def || '';
                }
                return Math.round(score) + '%';

            case 'content.progress':
                return (status?.progress || 0) + '%';

            case 'content.timeSpent':
                return status?.timeSpent ? 
                    StringUtil.formatHMS(status.timeSpent < 60 ? status.timeSpent : status.timeSpent - (status.timeSpent % 60)
                ) : def || '';

            case 'content.childrenCount':
                return (content?.children?.length || 0) + '';

            case 'sessionId':
                return entryPoint?.sessionId || def || '';

            case 'theme.locale':
                return settings.locale || def || '';

            case 'theme.language':
                return settings.locale?.substring(0, 2) || def || '';

            case 'theme.timeZone':
                return settings.timeZone || def || '';

            case 'theme.mode':
                return settings.mode === 'dark' ? 'dark' : settings.mode === 'light' ? 'light' : BrowserUtil.getPreferedColorScheme();

            default:
                return def || '';
        }
    });

    if (enableGlossary && glossary?.regex && str && html) {
        str = str.replace(glossary.regex, '<span data-term>$&</span>');
    }

    return str
}
