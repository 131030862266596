import { Shuttle } from "../types";
import LinkIcon from "@mui/icons-material/Link";
import { Input } from "react-ts-form";
import TextInput from "../form/inputs/TextInput";
import Label from "../core/Label";
import { Button } from "@mui/material";
import useLayoutString from "./useLayoutString";
import LayoutString from "./LayoutString";
import LangStringInput from "../form/inputs/LangStringInput";
import * as icons from '../icons';
import DropDownChoiceInput from "../form/inputs/DropDownChoiceInput";
import { createElement } from "react";

class ExternalLinkConfig {

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="url" />
        }
    })
    public url?: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="target" />
        }
    })
    public target?: string;

    @Input({
        component: LangStringInput,
        meta: {
            title: <Label k="text" />
        }
    })
    public text: Shuttle.LangString = {}

    @Input({
        component: DropDownChoiceInput,
        meta: {
            title: "Icon",
        },
    })
    public icon?: keyof typeof icons

}

const ExternalLinkElement: Shuttle.LayoutElementType<ExternalLinkConfig> = {
    name: 'layout_external_link',
    Icon: LinkIcon,
    ConfigClass: ExternalLinkConfig,
    Component: ({ config: { icon, target, text, url }, edit }) => {

        url = useLayoutString({ text: { x: url ?? '' }, skip: edit });

        return (
            <Button
                component='a'
                disabled={!url}
                href={url || ''}
                target={target}
                startIcon={
                    icon && icons[icon] ? createElement(icons[icon]) : undefined
                }
            >
                <LayoutString text={text} />
            </Button>
        );
    }
};

export default ExternalLinkElement;
