import { adminOnly, createEntitySection, createFormAction } from "../../core/admin/AdminSection";
import SwitchInput from "../../form/inputs/SwitchInput";
import { AuthProvider } from "../../model/AuthProvider";
import AuthProviderService from "../../services/AuthProviderService";

export default createEntitySection<AuthProvider>({
    model: AuthProvider,
    getId: o => o.id,
    label: o => o.name,
    ...AuthProviderService,
    columns: [
        {
            key: 'name',
            sort: 'name',
        },
        {
            key: 'externalId',
            sort: 'externalId',
        },
        {
            key: 'type',
            sort: 'type',
        },
        {
            key: 'enabled',
            sort: 'enabled',
            renderCell: ({appContext, data, reload}) => (
                <SwitchInput 
                    value={!!data.enabled}
                    onChange={
                        enabled => AuthProviderService.update({ ...data, enabled }).then(reload)
                    }
                    disabled={!adminOnly(appContext)}
                />
            ),
        },
    ],
    editEnabled: ({ appContext }) => adminOnly(appContext),
    actions: [
        createFormAction({
            key: 'create',
            clazz: AuthProvider,
            enabled: (_, appContext) => adminOnly(appContext),
            onSubmit: async (data) => {
                await AuthProviderService.create(data);
                return true;
            },
        })
    ],
})
