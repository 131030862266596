import Check from "@mui/icons-material/Check";
import { adminOnly, createEntitySection, createFormAction } from "../../core/admin/AdminSection";
import { TenantAlias } from "../../model/TenantAlias";
import TenantAliasService from "../../services/TenantAliasService";

export default createEntitySection({
    model: TenantAlias,
    getId: o => o.id,
    label: o => o.alias + (o.basePath ?? ''),
    ...TenantAliasService,
    columns: [
        {
            key: 'alias',
            sort: 'alias',
        },
        {
            key: 'basePath',
            sort: 'basePath',
        },
        {
            key: 'externalId',
            sort: 'externalId',
        },
        {
            key: '_default',
            sort: '_default',
            renderCell: ({ data }) => data._default ? <Check /> : undefined
        },
        {
            key: 'consumer',
            renderCell: ({ data }) => data.consumer?.name,
        },
        {
            key: 'theme',
            renderCell: ({ data }) => data.theme?.name,
        },
    ],
    editEnabled: ({ appContext }) => adminOnly(appContext),
    actions: [
        createFormAction({
            key: 'create',
            clazz: TenantAlias,
            enabled: (_, appContext) => adminOnly(appContext),
            onSubmit: async data => {
                await TenantAliasService.create(data);
                return true;
            },
        })
    ]
})
