import { Input } from "react-ts-form";
import RichTextIcon from "@mui/icons-material/TextFormat";
import { Shuttle } from "../types";
import Label from "../core/Label";
import RunningText from "../core/ui/RunningText";
import useLayoutString from "./useLayoutString";
import GlossaryTextWrapper from "../core/ui/GlossaryTextWrapper";
import SwitchInput from "../form/inputs/SwitchInput";
import LangStringInput from "../form/inputs/LangStringInput";

class RichTextConfig {

    @Input({
        component: LangStringInput,
        meta: {
            title: <Label k="text" />
        },
        inputProps: {
            type: 'richText',
        }
    })
    public text: Shuttle.LangString = {}

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="enable_glossary_links" />
        }
    })
    public enableGlossary?: boolean;

}

const RichTextElement: Shuttle.LayoutElementType<RichTextConfig> = {
    name: 'text.rich',
    Icon: RichTextIcon,
    ConfigClass: RichTextConfig,
    Component: ({ config: { enableGlossary, text }, edit }) => (
        <GlossaryTextWrapper>
            <RunningText 
                dangerouslyInjectScripts={!edit}
                html={useLayoutString({
                    text,
                    html: true,
                    enableGlossary,
                    skip: !!edit
                })}
            />
        </GlossaryTextWrapper>
    ),
}

export default RichTextElement;
