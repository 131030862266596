import { Class } from "./Class";
import StringUtil from "./StringUtil";

export default class StorageUtil {

    public static getString(key: string): string | null {
        try {
            return localStorage.getItem(key);
        } catch (e) {
            return null;
        }
    }

    public static put(key: string, value: any) {
        try {
            localStorage.setItem(key, value);
            return true;
        } catch (e) {
            return false;
        }
    }

    public static getInt(key: string) {
        const str = StorageUtil.getString(key);
        return str ? StringUtil.toInteger(str, 0) : null;
    }

    public static getJson<T extends object = object>(key: string, clazz?: Class<T>): T | null {
        try {
            const json = localStorage.getItem(key);
            if (!json) {
                return null;
            }
            const parsed = JSON.parse(json);
            return clazz ? Object.assign(new clazz(), parsed) : parsed;
        } catch (e) {
            return null;
        }
    }

    public static putJson(key: string, value: object): boolean {
        try {
            localStorage.setItem(key, JSON.stringify(value));
            return true;
        } catch (e) {
            return false;
        }
    }

    public static remove(key: string): boolean {
        try {
            localStorage.removeItem(key);
            return true;
        } catch (e) {
            return false;
        }
    }
}
