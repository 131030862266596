import { Typography } from "@mui/material";
import { anyEditor, createEntitySection, createFormAction } from "../../core/admin/AdminSection";
import ChipToggleList from "../../form/inputs/ChipToggleList";
import QuestionData from "../../model/quiz/QuestionData";
import QuestionSearchParams from "../../model/quiz/QuestionSearchParams";
import QuizQuestionService from "../../services/QuizQuestionService";

export default createEntitySection<QuestionData, QuestionSearchParams>({

    model: QuestionData,
    params: QuestionSearchParams,

    getId: o => o.id,
    label: 'name',

    ...QuizQuestionService,

    columns: [
        {
            key: 'name',
            sort: 'name',
            renderCell: ({data, params, setParams}) => (
                <>
                    <Typography gutterBottom={!!data.tags?.length}>
                        {data.name}
                    </Typography>
                    {
                        !!data.tags?.length && (
                            <ChipToggleList 
                                options={data.tags.map(t => t.name)}
                                onChange={tags => setParams({...params, page: 0, tags})}
                                size="small"
                                value={params.tags || []}
                            />
                        )
                    }
                </>
            )
        },
        {
            key: 'externalId',
            sort: 'externalId',
        },
        {
            key: 'type',
            sort: 'type',
        },
    ],

    editEnabled: ({ appContext }) => anyEditor(appContext),

    actions: [
        createFormAction({
            key: 'create',
            clazz: QuestionData,
            onSubmit: async (data) => {
                await QuizQuestionService.create(data);
                return true;
            },
            enabled: (_, appContext) => anyEditor(appContext),
        })
    ]

});
