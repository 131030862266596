import { Input } from "react-ts-form";
import Label from "../core/Label";
import { ConditionInput } from "../form/common";
import NumberInput from "../form/inputs/NumberInput";
import ConditionData from "./ConditionData";
import ContentData from "./ContentData";

export default class ContentAlternativeData {
    
    public alternative!: ContentData;

    @Input({
        component: ConditionInput,
        meta: {
            title: <Label k="enabled_condition" />,
            required: true
        }
    })
    public condition!: ConditionData;

    @Input({
        component: NumberInput,
        meta: {
            title: <Label k="index" />
        }
    })
    public index: number = 0;

}
