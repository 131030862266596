
export default class Registry<T> {

    private registered: { [id: string]: T; } = {};

    public put = (id: string, obj: T) => {
        this.registered[id] = obj;
        return this;
    }

    public get = (id: string) => this.registered[id];

    public has = (id: string) => Object.keys(this.registered).includes(id);

    public keys = () => Object.keys(this.registered);

    public entries = () => Object.entries(this.registered);

}
