import { Input } from "react-ts-form";

import TextInput from "../form/inputs/TextInput";
import NumberInput from "../form/inputs/NumberInput";
import Label from "../core/Label";
import SwitchInput from "../form/inputs/SwitchInput";
import ContentData from "./ContentData";
import ConditionData from "./ConditionData";
import { ConditionInput } from "../form/common";
import { Shuttle } from "../types";
import LangStringInput from "../form/inputs/LangStringInput";

export default class ContentChildData {

    public content!: ContentData;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="target" />
        }
    })
    public target?: string;

    @Input({
        component: LangStringInput,
        meta: {
            title: <Label k="displayName" />
        },
    })
    public displayName?: Shuttle.LangString;

    @Input({
        component: NumberInput,
        meta: {
            title: <Label k="index" />
        }
    })
    public index!: number;

    @Input({
        component: NumberInput,
        meta: {
            title: <Label k="sequenceIndex" />
        }
    })
    public sequenceIndex!: number;

    @Input({
        component: NumberInput,
        meta: {
            title: <Label k="weight" />
        }
    })
    public weight?: number;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="required" />
        }
    })
    public required!: boolean;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="hidden" />
        }
    })
    public hidden!: boolean;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="exclude_time_spent" />
        }
    })
    public excludeTimeSpent!: boolean;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="exclude_from_navigation" />
        }
    })
    public excludeFromNavigation!: boolean;

    @Input({
        component: ConditionInput,
        meta: {
            title: <Label k="enabled_condition" />
        }
    })
    public enabledCondition?: ConditionData;

    @Input({
        component: ConditionInput,
        meta: {
            title: <Label k="visible_condition" />
        }
    })
    public visibleCondition?: ConditionData;

}
