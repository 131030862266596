import { FC, useMemo } from "react";
import { IInputProps } from "react-ts-form";
import { RadioProps, Radio, Box } from "@mui/material";

import { IOptionsProps, DefaultChoiceRender } from "../IOptionsProps";
import StringUtil from "../../util/StringUtil";

interface IRadioChoiceInputProps<T> extends IInputProps<T>, IOptionsProps<T> {
    color?: RadioProps["color"];
    vertical?: boolean;
}

export default function RadioChoiceInput<T>({ choiceRenderer = DefaultChoiceRender, color, disabled, id, onChange, options, value, vertical = true, }: IRadioChoiceInputProps<T>) {

    const name = useMemo(() => id || StringUtil.uuid(), [id]);

    if (!options?.length) return null;

    return (
        <div role="radiogroup">
            {
                options.map(o => (
                    <Box 
                        key={choiceRenderer.getKey(o)} 
                        alignItems="center"
                        display={vertical ? 'flex' : 'inline-flex'}
                        component="label"
                    >
                        <Radio 
                            checked={choiceRenderer.equals(o, value)} 
                            color={color}
                            name={name}
                            onChange={() => onChange(o)}
                            disabled={disabled}
                        />
                        {choiceRenderer.getLabel(o)}
                    </Box>
                ))
            }
        </div>
    );
}

RadioChoiceInput.ofType = function<T>() {
    return RadioChoiceInput as FC<IRadioChoiceInputProps<T>>;
}

RadioChoiceInput.of = function<T>(settings: Omit<IRadioChoiceInputProps<T>, keyof IInputProps<T>>) {
    return (props: IInputProps<T>) => <RadioChoiceInput {...settings} {...props} />;
}
