import { Button } from "@mui/material";
import { Input } from "react-ts-form";
import Label from "../core/Label";
import DropDownChoiceInput from "../form/inputs/DropDownChoiceInput";
import SwitchInput from "../form/inputs/SwitchInput";
import TextInput from "../form/inputs/TextInput";
import { Shuttle } from "../types";

type StringOperator = 'EQUALS' | 'STARTS_WITH' | 'ENDS_WITH' | 'CONTAINS';

class StringCondition {

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="property_name" />,
            required: true,
        }
    })
    public name!: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="value" />,
            required: true,
        },
        array: {
            remove: true,
            sort: true,
            addComponent({ onAdd }) {
                return (
                    <Button onClick={() => onAdd([''])}>
                        <Label k="add" />
                    </Button>
                );
            }
        }
    })
    public values!: string[];

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="caseSensitive" />
        }
    })
    public caseSensitive?: boolean;

    @Input({
        component: DropDownChoiceInput.of<StringOperator>({
            options: ['EQUALS', 'STARTS_WITH', 'ENDS_WITH', 'CONTAINS']
        }),
        meta: {
            title: <Label k="operator" />,
            required: true,
        }
    })
    public operator: StringOperator = 'EQUALS';

}

const stateStringCondition: Shuttle.Condition<StringCondition> = {
    name: 'condition.string',
    ConfigClass: StringCondition,
    evaluate: ({ config = {} as StringCondition }, { state }) => {

        const values = state[config.name];

        let expected = config.values || [];
        if (!config.caseSensitive) {
            expected = expected.map(e => (e || '').toLowerCase());
        }

        if (values?.length) {
            for (let value of values) {
                if (!value) {
                    continue;
                }
                if (!config.caseSensitive) {
                    value = value.toLowerCase();
                }
                for (const str of expected) {
                    if (!str) continue;

                    if (config.operator === 'CONTAINS' && value.includes(str)) {
                        return true;
                    }

                    if (config.operator === 'STARTS_WITH' && value.startsWith(str)) {
                        return true;
                    }

                    if (config.operator === 'ENDS_WITH' && value.endsWith(str)) {
                        return true;
                    }

                    if (config.operator === 'EQUALS' && value === str) {
                        return true;
                    }
                }
            }
        }

        return false;
    },
};

export default stateStringCondition;
