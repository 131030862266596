import { Box } from "@mui/material";
import { createEntitySection, createFormAction } from "../../core/admin/AdminSection";
import { AwardType, Award, AwardIssued, getAwardValue } from "../../model/Award";
import AwardSearchParams from "../../model/AwardSearchParams";
import AwardIssuedSearchParams from "../../model/AwardIssuedSearchParams";
import AwardTypeService from "../../services/AwardTypeService";
import AwardService from "../../services/AwardService";
import AwardIssuedService from "../../services/AwardIssuedService";
import Label from "../../core/Label";
import { DateLabel } from "../../core/DateLabel";
import { ValidLabelKey } from "../../core/localization/ValidLabelKey";
import LabelledIconButton from "../../core/ui/LabelledIconButton";
import CertIcon from "@mui/icons-material/MilitaryTech";

export const awardIssued = {
    path: '/issued',
    label: 'admin.section.awards.issued' as ValidLabelKey,
    section: createEntitySection<AwardIssued, AwardIssuedSearchParams, Award>({
        model: AwardIssued,
        params: AwardIssuedSearchParams,
        getId: o => o.id,
        label: o => o.uuid,
        ...AwardIssuedService,
        search: async({consumer, ...params}, award) => await AwardService.searchIssued(award.id, { consumerId:consumer?.id, ...params }),
        columns: [
            {
                key: 'id',
                renderCell: ({ data }) => data.uuid,
            },
            {
                key: 'consumer',
                renderCell: ({ data }) => data.user.consumer.name,
            },
            {
                key: 'firstName',
                sort: 'user.firstName',
                renderCell: ({ data }) => data.user.firstName,
            },
            {
                key: 'lastName',
                sort: 'user.lastName',
                renderCell: ({ data }) => data.user.lastName,
            },
            {
                key: 'award.issueDate',
                sort: 'issueDate',
                renderCell: ({ data }) => <DateLabel date={ data.issueDate } />,
            },
            {
                key: 'value',
                sort: 'value',
                renderCell: ({ data }) => data.value,
                enabled: ({ type }) => type.type !== 'Certificate',
            },
            {
                key: 'award.claimDate',
                sort: 'claimDate',
                renderCell: ({ data }) => <DateLabel date={ data.claimDate } />,
            },
            {
                key: 'actions',
                padding: 'checkbox',
                enabled: ({ type }) => type.type === 'Certificate',
                renderCell({data}) {
                    return data.artifactUrl?
                    (
                        <Box sx={{ display: 'flex' }}>
                            <LabelledIconButton 
                                k="open" 
                                icon={CertIcon}
                                onClick={
                                    () => window.open(data.artifactUrl, '_cert')
                                }
                            />
                        </Box>
                    ): null;
                }
            }                        
        ],
        actions: [

        ],
    }),
};

export const awardTypeAwards = {
    path: '/awards',
    label: 'admin.section.awards' as const,
    section: createEntitySection<Award, AwardSearchParams, AwardType>({
        model: Award,
        params: AwardSearchParams,
        getId: o => o.id,
        label: o => o.content.name,
        ...AwardService,
        search: async({consumer, ...params}, awardType) => await AwardTypeService.searchAwards(awardType.id, { consumerId:consumer?.id, ...params }),
        columns: [
            {
                key: 'content',
                sort: 'content.name',
                renderCell: ({ data }) => data.content.name,
            },
            {
                key: 'externalId',
                sort: 'content.externalId',
                renderCell: ({ data }) => data.content.externalId,
            },
            {
                key: 'consumer',
                renderCell: ({ data }) => data.consumer?.name,
            },
            {
                key: 'award.autoClaim',
                sort: 'autoClaim',
                renderCell: ({ data }) => data.autoClaim ? <Label k='yes' /> : <Label k = 'no' />,
            },
            {
                key: 'extractor',
                sort: 'extractor',
                renderCell: ({ data }) => data.valueExtractor + getAwardValue(data),
                enabled: ({ type }) => type === 'Credit',
            },
        ],
        actions: [
            createFormAction({
                key: 'create',
                getInitialData: awardType => {
                    const d = new Award();
                    d.autoClaim = false;
                    d.type = awardType;
                    d.valueExtractor = awardType.valueExtractor;
                    return d;
                },
                clazz: Award,
                onSubmit: async award => {
                    await AwardService.create(award);
                    return true;
                }
            }),
        ],
        sections: [
            awardIssued
        ]
    }),
};

export const awardTypeIssued = {
    path: '/issued',
    label: 'admin.section.awards.issued' as ValidLabelKey,
    section: createEntitySection<AwardIssued, AwardIssuedSearchParams, AwardType>({
        model: AwardIssued,
        params: AwardIssuedSearchParams,
        getId: o => o.id,
        label: o => o.uuid,
        ...AwardIssuedService,
        search: async({consumer, ...params}, awardType) => await AwardTypeService.searchIssued(awardType.id, { consumerId:consumer?.id, ...params }),
        columns: [
            {
                key: 'id',
                renderCell: ({ data }) => data.uuid,
            },
            {
                key: 'content',
                sort: 'enrollment.content.name',
                renderCell: ({ data }) => data.enrollment?.content.name,
            },
            {
                key: 'externalId',
                sort: 'enrollment.content.externalId',
                renderCell: ({ data }) => data.enrollment?.content.externalId,
            },
            {
                key: 'consumer',
                renderCell: ({ data }) => data.user.consumer.name,
            },
            {
                key: 'firstName',
                sort: 'user.firstName',
                renderCell: ({ data }) => data.user.firstName,
            },
            {
                key: 'lastName',
                sort: 'user.lastName',
                renderCell: ({ data }) => data.user.lastName,
            },
            {
                key: 'award.issueDate',
                sort: 'issueDate',
                renderCell: ({ data }) => <DateLabel date={ data.issueDate } />,
            },
            {
                key: 'value',
                sort: 'value',
                renderCell: ({ data }) => data.value,
                enabled: ({ type }) => type !== 'Certificate',
            },
            {
                key: 'award.claimDate',
                sort: 'claimDate',
                renderCell: ({ data }) => <DateLabel date={ data.claimDate } />,
            },
            {
                key: 'actions',
                padding: 'checkbox',
                enabled: ({ type }) => type === 'Certificate',
                renderCell({data}) {
                    return data.artifactUrl?
                    (
                        <Box sx={{ display: 'flex' }}>
                            <LabelledIconButton 
                                k="open" 
                                icon={CertIcon}
                                onClick={
                                    () => window.open(data.artifactUrl, '_cert')
                                }
                            />
                        </Box>
                    ): null;
                }
            }                        
        ],
        actions: [

        ],
    }),
};

export default createEntitySection<AwardType>({
    model: AwardType,
    getId: o => o.id,
    label: o => o.name,
    ...AwardTypeService,
    columns: [
        {
            key: 'name',
            sort: 'name',
        },
        {
            key: 'externalId',
            sort: 'externalId'
        },
        {
            key: 'type',
            sort: 'type'
        }
    ],
    actions: [
        createFormAction({
            key: 'create',
            clazz: AwardType,
            onSubmit: async awardType => {
                await AwardTypeService.create(awardType)
                return true;
            }
        })
    ],
    sections: [
        awardTypeAwards,
        awardTypeIssued,
    ]
})