import Axios from "axios";
import { Consumer } from "../model/Consumer";
import SearchParams from "../model/SearchParams";
import createEntityService from "./createEntityService";
import ConsumerContentData from "../model/ConsumerContentData";
import ResponseData from "../model/ResponseData";
import SearchResult from "../model/SearchResult";
import UserData from "../model/UserData";
import BrowserUtil from "../util/BrowserUtil";
import { Announcement } from "../model/Announcement";

interface ImportResult {
    totalUsers: number;
    totalEnrollments: number;
    newUsers: number;
    newEnrollments: number;
}

const ConsumerService = {

    ...createEntityService<Consumer>({
        path: '/consumers',
    }),

    searchContents: async (consumerId: number | string, params: SearchParams) => Axios
        .get<ResponseData<SearchResult<ConsumerContentData>>>(`/consumers/${consumerId}/contents`, { params })
        .then(r => r.data),

    getContent: async (consumerId: number | string, contentId: number | string) => Axios
        .get<ResponseData<ConsumerContentData>>(`/consumers/${consumerId}/contents/${contentId}`)
        .then(r => r.data),

    addContent: async (consumerId: number | string, contentId: number | string, data: Omit<ConsumerContentData, 'consumer' | 'content'>) => Axios
        .put<ResponseData<ConsumerContentData>>(`/consumers/${consumerId}/contents/${contentId}`, data)
        .then(r => r.data),

    removeContent: async (consumerId: number | string, contentId: number | string) => Axios
        .delete(`/consumers/${consumerId}/contents/${contentId}`)
        .then(r => r.data),

    searchUsers: async (consumerId: number | string, params: SearchParams) => Axios
        .get<ResponseData<SearchResult<UserData>>>(`/consumers/${consumerId}/users`, { params })
        .then(r => r.data),

    stats: async () => await Axios.get<{
        consumers: number;
        users: number;
        contents: number;
        enrollments: number;
        completions: number;
        statsDate: string;
    }>('/consumers/stats').then(r => r.data),

    importUsers: async (consumerId: number | string, file: File): Promise<{ success: true; result: ImportResult; } | { success: false; errors: string[]; }> => {
        const fd = new FormData();
        fd.set('file', file);
        fd.set('name', file.name);
        fd.set('size', file.size.toString());
        fd.set('type', file.type);
        return await Axios.post<ImportResult>(`/consumers/${consumerId}/users`, fd)
            .then(r => ({
                success: true,
                result: r.data,
            }) as const)
            .catch(err => ({
                success: false,
                errors: Array.isArray(err.response?.data) ? err.response.data : [],
            }) as const);
    },

    downloadUserImportTemplate: async (consumerId: number | string) => {
        const blob = await Axios.get(`/consumers/${consumerId}/users/import-template`, {
            responseType: 'blob',
        }).then(r => r.data);
        await BrowserUtil.downloadFile(blob, 'application/vnd.ms-excel', 'simpatico-user-registration.xlsx');
    },

    searchContentAnnouncements: async (consumerId: number, contentId: number, params: SearchParams) => {
        return await Axios.get<ResponseData<SearchResult<Announcement>>>(`/consumers/${consumerId}/contents/${contentId}/announcements`, {
            params,
        }).then(r => r.data);
    },

    createContentAnnouncement: async (consumerId: number, contentId: number, data: Partial<Announcement>) => {
        return await Axios.post<ResponseData<Announcement>>(`/consumers/${consumerId}/contents/${contentId}/announcements`, data).then(r => r.data);
    },

    getContentAnnouncement: async (consumerId: number, contentId: number, announcementId: number) => {
        return await Axios.get<ResponseData<Announcement>>(`/consumers/${consumerId}/contents/${contentId}/announcements/${announcementId}`).then(r => r.data);
    },

    updateContentAnnouncement: async (consumerId: number, contentId: number, announcementId: number, data: Partial<Announcement>) => {
        return await Axios.patch<ResponseData<Announcement>>(`/consumers/${consumerId}/contents/${contentId}/announcements/${announcementId}`, data).then(r => r.data);
    },

}

export default ConsumerService;
