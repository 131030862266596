import { IInputProps, Input } from "react-ts-form";
import { Box, Button, CircularProgress, FormControlLabel, Grid, Stack, Switch, Typography } from "@mui/material";

import useObjectURL from "../../hooks/useObjectURL";
import FileData from "../../model/FileData";
import { Suspense, useCallback, useEffect, useState } from "react";
import { VideoPlayer } from "../../lazy";
import ModalDialog from "../../core/ui/ModalDialog";
import useResolver from "../../hooks/useResolver";
import { FileService, FileTextTrackData } from "../../services/FileService";
import DropDownChoiceInput from "./DropDownChoiceInput";
import VerticalCenter from "../../util/VerticalCenter";
import CodeInput from "./CodeInput";
import { InteractiveTranscript } from "../../launcher/impl/InteractiveTranscript";
import { Shuttle } from "../../types";
import StringUtil from "../../util/StringUtil";
import SwitchInput from "./SwitchInput";
import DecoratorForm from "../DecoratorForm";

interface IFileInputProps extends IInputProps<FileData | null> {
    accept?: string; // image/*, etc
}

export default function FileInput(props: IFileInputProps) {
    let url = useObjectURL(props.value?.file || null);
    let type: string | undefined = undefined;
    if (props.value?.file) {
        type = props.value.file.type;
    } else if (props.value?.url) {
        url = props.value.url;
        type = props.value.type;
    }

    let preview: React.ReactNode = null;

    if (url) {
        if (type?.startsWith('image/')) {
            preview = (
                <div 
                    style={{
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                        backgroundImage: `url('${url}')`,
                        backgroundRepeat: 'no-repeat',
                        height: '200px',
                        maxWidth: '100%',
                        width: '300px'
                    }} 
                />
            );
        }
        if (type?.startsWith('video/')) {
            preview = (
                <Suspense fallback="...">
                    <VideoPlayer src={url} type={props.value?.file?.type || props.value?.type || undefined} />
                </Suspense>
            );
        }
    }

    const enableTranscriptEdit = Boolean(!props.value?.file && props.value?.id && (type?.startsWith('audio/') || type?.startsWith('video/')));
    const [transcriptOpen, setTranscriptOpen] = useState(false);

    return (
        <>
            <Stack direction="row" spacing={1} mb={2}>
                <input
                    type="file" 
                    accept={props.accept}
                    disabled={props.disabled}
                    id={props.id}
                    required={props.required}
                    onChange={e => {
                        const file = e.target?.files?.[0] || null;
                        props.onChange({file});
                    }}
                />
                {
                    enableTranscriptEdit && (
                        <>
                            <Button
                                size="small"
                                onClick={() => setTranscriptOpen(true)}
                            >
                                Edit Transcript
                            </Button>
                            <ModalDialog
                                open={transcriptOpen}
                                onClose={() => setTranscriptOpen(false)}
                                title="Edit Transcript"
                                maxWidth="xl"
                            >
                                <TranscriptEditor fileId={props.value!.id!} url={props.value!.url!} />
                            </ModalDialog>
                        </>
                    )
                }
            </Stack>
            {
                !!(props.value?.url && props.value.name) && (
                    <Typography component="code" display="block">
                        {props.value.name}
                    </Typography>
                )
            }
            {
                preview && (
                    <Box marginTop={1}>
                        {preview}
                    </Box>
                )
            }
        </>
    );
}

class TranscribeForm {

    @Input({
        component: DropDownChoiceInput,
        meta: {
            title: 'Kind',
            required: true,
        },
        inputProps: {
            options: ['captions', 'chapters'],
        },
    })
    public kind: 'captions' | 'chapters' = 'captions';

    @Input({
        component: DropDownChoiceInput.of({
            options: ['en', 'es', 'de', 'fr', 'it', 'pl', 'pt'],
        }),
        meta: {
            title: 'Primary Language',
            required: true,
        }
    })
    public locale = 'en';

    @Input((_, { parent }) => ({
        component: SwitchInput,
        meta: {
            title: 'Auto-Transcibe with AI',
            disabled: parent.kind !== 'captions',
        },
    }))
    public autoTranscribe = true;

    @Input((_, { parent }) => ({
        component: SwitchInput,
        meta: {
            title: 'Detect Speakers',
            description: 'Enable this feature if this file has multiple distinct speakers/presenters.',
            disabled: parent.kind !== 'captions' || !parent.autoTranscribe,
        },
    }))
    public diarize = false;

}

class TranslateForm {

    @Input({
        component: DropDownChoiceInput.of({
            options: ['en', 'es', 'de', 'fr', 'it', 'pl', 'pt'],
        }),
        meta: {
            title: 'Target Language',
            required: true,
            description: 'must not be the same as the source language.',
        },
    })
    public targetLanguage = 'en';

}

interface TranscriptEditorProps {
    fileId: number;
    url: string;
}

function TranscriptEditor({ fileId, url }: TranscriptEditorProps) {

    const { data: transcripts, setState, reload } = useResolver(useCallback(async () => await FileService.listTranscripts(fileId), [fileId]));
    const [editingId, setEditingId] = useState<null | number>(null);
    const editing = transcripts?.find(x => x.id === editingId) ?? null;
    const [player, setPlayer] = useState<null | videojs.VideoJsPlayer>(null);
    const [control, setControl] = useState<null | Shuttle.CodeEditorControl>(null);
    const [open, setOpen] = useState(false);
    const [translate, setTranslate] = useState(0);

    useEffect(() => {
        if (transcripts?.length) {
            setEditingId(prev => {
                if (typeof prev === 'number') return prev;
                return transcripts[0].id;
            })
        }
    }, [transcripts]);

    if (!transcripts) return <CircularProgress />;

    const save = editing && (
        <Button
            color="success"
            variant="contained"
            onClick={async () => {
                const updated = await FileService.updateTranscript(fileId, editing!.id, editing);
                setState(prev => ({
                    ...prev,
                    data: (prev.data ?? []).map(x => x.id === updated.id ? updated : x),
                }));
                alert('saved!');
            }}
            size="small"
        >
            Save Changes
        </Button>
    );

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                    <Suspense fallback="...">
                        <VideoPlayer src={url} onReady={player => setPlayer(player)} />
                    </Suspense>
                    <Box sx={{ height: 400 }}>
                        {
                            player && (
                                <InteractiveTranscript player={player} vtt={editing?.content ?? ''} />
                            )
                        }
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Stack direction="row" spacing={1}>
                        {
                            !!transcripts.length && (
                                <DropDownChoiceInput<FileTextTrackData | null>
                                    value={editing}
                                    onChange={editing => setEditingId(editing?.id ?? null)}
                                    options={transcripts}
                                    choiceRenderer={{
                                        getKey: o => o?.id ?? '',
                                        getLabel: o => o && `${o.locale} (${o.kind}, ${o.status}, ${o.enabled ? 'Enabled' : 'Disabled'})`,
                                        equals: (x, y) => x?.id === y?.id,
                                    }}
                                    title="Text Tracks"
                                />
                            )
                        }
                        <Button
                            size="small"
                            onClick={() => setOpen(true)}
                        >
                            {'Create Text Track'}
                        </Button>
                        <Button
                            size="small"
                            onClick={reload}
                        >
                            Refresh
                        </Button>
                    </Stack>
                    {
                        editing
                            ? (
                                <div>
                                    <Stack spacing={1} direction="row" sx={{ my: 2 }}>
                                        {save}
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={() => {
                                                const currentTime = player?.currentTime();
                                                if (typeof currentTime !== 'number') return;
                                                control?.insertTextAtCursor(
                                                    StringUtil.uuid()
                                                    + '\n'
                                                    + StringUtil.formatVttTimestamp(Math.floor(currentTime))
                                                    + ' --> '
                                                    + StringUtil.formatVttTimestamp(Math.floor(currentTime + 1))
                                                    + '\n'
                                                    + 'caption here'
                                                    + '\n'
                                                );
                                            }}
                                            size="small"
                                        >
                                            Insert Caption
                                        </Button>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={() => setTranslate(editing!.id)}
                                            size="small"
                                        >
                                            Translate
                                        </Button>
                                        <FormControlLabel
                                            label="Enabled"
                                            control={
                                                <Switch
                                                    checked={editing.enabled}
                                                    onChange={e => {
                                                        const enabled = e.target.checked;
                                                        setState(prev => ({
                                                            ...prev,
                                                            data: (prev.data ?? []).map(x => x.id === editing.id ? { ...x, enabled } : x),
                                                        }))
                                                    }}
                                                    size="small"
                                                />
                                            }
                                        />
                                    </Stack>
                                    <CodeInput
                                        value={editing.content}
                                        onChange={content => setState(prev => ({
                                            ...prev,
                                            data: prev.data?.map(t => t.id === editingId ? { ...t, content } : t) ?? null,
                                        }))}
                                        language="vtt"
                                        height={600}
                                        onReady={setControl}
                                        key={editing.status + '-' + editing.id}
                                        wrap
                                    />
                                    <Stack sx={{ mt: 1 }} direction="row" spacing={1}>
                                        {save}
                                        <Button
                                            color="error"
                                            variant="contained"
                                            onClick={async () => {
                                                if (window.confirm('Are you sure you want to delete this?')) {
                                                    await FileService.deleteTranscript(fileId, editing.id);
                                                    setEditingId(null);
                                                    await reload();
                                                }
                                            }}
                                            sx={{
                                                mt: 2,
                                            }}
                                            size="small"
                                        >
                                            Delete This Transcript
                                        </Button>
                                    </Stack>
                                </div>
                            )
                            : (
                                <VerticalCenter>
                                    Select a text track to edit
                                </VerticalCenter>
                            )
                    }
                </Grid>
            </Grid>
            <ModalDialog
                open={open}
                onClose={() => setOpen(false)}
                title="Generate Transcript"
            >
                <DecoratorForm
                    clazz={TranscribeForm}
                    onSubmit={async ({ autoTranscribe, diarize, kind, locale }) => {
                        let next: FileTextTrackData;
                        if (kind === 'captions' && autoTranscribe) {
                            next = await FileService.generateTranscript(fileId, locale, diarize);
                        } else {
                            next = await FileService.createTranscript(fileId, {
                                locale,
                                kind,
                                content: 'WEBVTT\n\n1\n00:00:00.000-->00:00:01.000\nhello there\n',
                                enabled: false,
                                status: 'READY',
                            });
                        } 
                        setState(prev => ({...prev, data: [...prev.data ?? [], next]}));
                        setEditingId(next.id);
                        setOpen(false);
                        return { autoTranscribe, diarize, kind, locale };
                    }}
                />
            </ModalDialog>
            <ModalDialog
                open={!!translate}
                onClose={() => setTranslate(0)}
                title="Translate Transcript"
            >
                <DecoratorForm
                    clazz={TranslateForm}
                    onSubmit={async ({ targetLanguage }) => {
                        const next = await FileService.translateTranscript(fileId, translate, targetLanguage);
                        setState(prev => ({...prev, data: [...prev.data ?? [], next]}));
                        setEditingId(next.id);
                        setTranslate(0);
                        return { targetLanguage };
                    }}
                />
            </ModalDialog>
        </>
    );
}
