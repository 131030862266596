import { useCallback, useContext, useEffect, useState } from "react";
import * as React from "react";
import { Alert, Box, Breadcrumbs, Button, CircularProgress, Grid, Link, List, ListItemButton, Tab, Tabs, Typography } from "@mui/material";
import { Route, RouteComponentProps, Switch, Link as RouterLink, Redirect } from "react-router-dom";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import DownloadIcon from "@mui/icons-material/FileDownload";

import { isValidLabelKey, ValidLabelKey } from "../localization/ValidLabelKey";
import SearchParams from "../../model/SearchParams";
import { Class } from "../../util/Class";
import IColumn from "../../model/IColumn";
import TableView from "../ui/TableView";
import useResolver from "../../hooks/useResolver";
import Label from "../Label";
import DecoratorForm, { IDecoratorFormProps } from "../../form/DecoratorForm";
import LabelledIconButton from "../ui/LabelledIconButton";
import SearchResult from "../../model/SearchResult";
import { Shuttle } from "../../types";
import ResponseData from "../../model/ResponseData";
import { AppContext } from "../AppContext";
import DecoratorInput from "../../form/DecoratorInput";
import VerticalCenter from "../../util/VerticalCenter";
import useString from "../../hooks/useString";
import StorageUtil from "../../util/StorageUtil";
import StringUtil from "../../util/StringUtil";
import ModalDialog from "../ui/ModalDialog";
import { DecoratorWizard, WizardStep } from "../../form/DecoratorWizard";
import { useFeedbackContext } from "../FeedbackContext";
import AnchorPopover from "../ui/AnchorPopover";
import { horizontalTemplates } from "../../form/common";

const KEY_ENTITY_SECTION = Symbol('entity-section');
const KEY_COMPONENT_SECTION = Symbol('component-section');
const KEY_FORM_ACTION = Symbol('form-action');
const KEY_WIZARD_ACTION = Symbol('wizard-action');
const KEY_COMPONENT_ACTION = Symbol('component-action');
const KEY_CLASS_RESOLVER = Symbol('class-resolver');

export const hasRole = (appContext: Shuttle.AppContext, roles: string[]) => !!appContext.user?.roles?.some(r => roles.includes(r));
export const anyStaff = (appContext: Shuttle.AppContext) => hasRole(appContext, ['ROLE_REVIEWER', 'ROLE_SUPPORT', 'ROLE_AUTHOR', 'ROLE_ADMIN']);
export const anyEditor = (appContext: Shuttle.AppContext) => hasRole(appContext, ['ROLE_AUTHOR', 'ROLE_ADMIN']);
export const adminOnly = (appContext: Shuttle.AppContext) => hasRole(appContext, ['ROLE_ADMIN']);

type ComponentSection<X = undefined> = { component: React.ComponentType<{ context: X; } & RouteComponentProps>; };

export interface SectionWrapper<X = void> {
    path: string; 
    label: ValidLabelKey; 
    icon?: React.ComponentType;
    enabled?: (context: X, appContext: Shuttle.AppContext) => boolean;
    section: EntitySection<any, any, X> | ComponentSection<X>;
}

type DetailResolver<T extends object, X = void, P extends SearchParams = SearchParams, R = any> = (props: { data: T; context: X; params: P; setParams: (params: P) => void; history: RouteComponentProps['history']; matchUrl: string; reload(): void; appContext: Shuttle.AppContext; }, index: number, arr: T[]) => R;

interface EntitySection<T extends object, P extends SearchParams = SearchParams, X = void> {

    model: Class<T>;
    params?: Class<P>;

    getUpdateModel?: IDecoratorFormProps<T>['subClazz'];

    getId(data: T): number;
    label: keyof T | ((data: T) => React.ReactNode);
    defaultSort?: string;

    get(id: number, context: X): Promise<ResponseData<T>>;
    search(params: P, context: X): Promise<ResponseData<SearchResult<T>>>;
    update?: (action: T, context: X) => Promise<ResponseData<T>>;
    delete?: (data: T, context: X) => Promise<ResponseData<any>>;

    downloads?: Array<{
        key: string;
        label: React.ReactNode;
        enabled?: (context: X) => boolean;
        download: (params: P, context: X) => Promise<void>;
    }>;

    columns: Array<{ 
        key: string | ValidLabelKey;
        sort?: string;
        align?: IColumn<any>['align'];
        padding?: IColumn<any>['padding'];
        enabled?: (context: X) => boolean;
        renderCell?: DetailResolver<T, X, P, React.ReactNode>;
    }>;

    expand?: DetailResolver<T, X, P, React.ReactNode>;

    actions?: Array<AdminSectionAction<any, X>>;
    moreActions?: (context: X) => React.ReactNode;

    sections?: Array<SectionWrapper<T>>;

    editEnabled?: (params: {
        data: T;
        context: X;
        appContext: Shuttle.AppContext
    }) => boolean;

    renderEdit?: (data: T, context: X) => React.ReactNode;

    renderTableHeader?: (context: X) => React.ReactNode;
}

interface AdminSectionAction<T, X> {
    key: ValidLabelKey;
    enabled?: (context: X, appContext: Shuttle.AppContext) => boolean;
    getInitialData?: (context: X) => T;
    onSubmit(data: T, context: X): Promise<boolean>; // resolve to true to reload list
}

interface FormAction<T extends object, X> extends AdminSectionAction<T, X>  {
    clazz: Class<T>;
    subClazz?: IDecoratorFormProps<T>['subClazz'];
}

interface WizardAction<T extends ReadonlyArray<WizardStep>, X> extends AdminSectionAction<{ [K in keyof T]: T[K] extends WizardStep<infer V> ? InstanceType<V> : never; }, X> {
    steps: T;
}

interface ComponentAction<X> extends AdminSectionAction<any, X> {
    Component: React.ComponentType<{
        close: (reload?: boolean) => void;
        context: X;
    }>;
}

export function createEntitySection<T extends object, P extends SearchParams = SearchParams, X = void>(section: EntitySection<T, P, X>) {
    return { ...section, [KEY_ENTITY_SECTION]: true, } as EntitySection<T, P, X>;
}

export function createComponentSection<X = void>(section: ComponentSection<X>) {
    return { ...section, [KEY_COMPONENT_SECTION]: true, } as ComponentSection<X>;
}

export function createFormAction<T extends object, X = void>(action: FormAction<T, X>) {
    return { ...action, [KEY_FORM_ACTION]: true } as FormAction<T, X>;
}

export function createWizardAction<T extends ReadonlyArray<WizardStep>, X = void>(action: WizardAction<T, X>) {
    return { ...action, [KEY_WIZARD_ACTION]: true } as WizardAction<T, X>;
}

export function createComponentAction<X = void>(action: ComponentAction<X>) {
    return { ...action, [KEY_COMPONENT_ACTION]: true } as ComponentAction<X>;
}

export function createClassResolver<T extends object, R extends T = T, X = void>(resolver: (context: X) => Class<R>) {
    (resolver as any)[KEY_CLASS_RESOLVER] = true;
    return resolver;
}

function isEntitySection<X>(section: any): section is EntitySection<any, any, X> {
    return section[KEY_ENTITY_SECTION] === true;
}

function isComponentSection<X>(section: any): section is ComponentSection<X> {
    return section[KEY_COMPONENT_SECTION] === true;
}

function isFormAction<X>(action: any): action is FormAction<any, X> {
    return action[KEY_FORM_ACTION] === true;
}

function isWizardAction<X>(action: any): action is WizardAction<any, X> {
    return action[KEY_WIZARD_ACTION] === true;
}

function isComponentAction<X>(action: any): action is ComponentAction<X> {
    return action[KEY_COMPONENT_ACTION] === true;
}

export function processSections<X>({ context, sections, appContext, pathPrefix, breadcrumbs }: {
    context: X; 
    sections: Array<SectionWrapper<X>>; 
    appContext: Shuttle.AppContext;
    pathPrefix: string;
    breadcrumbs?: React.ReactNodeArray;
}) {
    const routes: React.ReactNodeArray = [];
    const tabs: Array<{ path: string; title: React.ReactNode; icon?: React.ComponentType<any>; }> = [];
    let redirect = null;

    for (const { enabled, label, icon, section, path } of sections) {
        if (typeof enabled === 'function' && !enabled(context, appContext)) {
            continue;
        }

        const contextPath = (pathPrefix || "") + path;

        const title = <Label k={label} />

        tabs.push({
            path: contextPath,
            title,
            icon,
        });

        const crumbs = breadcrumbs ? [...breadcrumbs] : [];
        crumbs.push(
            <Link component={RouterLink} to={contextPath} key={contextPath}>
                <Label k={label} />
            </Link>
        );

        if (isComponentSection<X>(section)) {
            routes.push(
                <Route 
                    key={contextPath} 
                    path={contextPath} 
                    render={route => React.createElement(section.component, { ...route, context })} 
                />
            );
        }
        
        if (isEntitySection(section)) {
            routes.push(
                <Route 
                    key={contextPath}
                    path={contextPath}
                    exact
                    render={route => React.createElement(EntityTable, { ...route, section, context, title })}
                />,
                <Route 
                    key={contextPath + '/:id'} 
                    path={contextPath + '/:id'} 
                    render={route => React.createElement(EntityEdit, { 
                        ...route, 
                        section, 
                        context, 
                        breadcrumbs: [
                            ...(breadcrumbs || []),
                            (
                                <Link component={RouterLink} to={contextPath} key={contextPath}>
                                    <Label k={label} />
                                </Link>
                            )
                        ] 
                    })}
                />
            );
        }

        if (!redirect) {
            redirect = <Redirect key="__redirect" to={contextPath} />;
        }
    }
    if (redirect) {
        routes.push(redirect);
    }

    return { routes, breadcrumbs, tabs };
}

function EntityTable<T extends object, P extends SearchParams = SearchParams, X = void>(props: { context: X; section: EntitySection<T, P, X>; title?: React.ReactNode; } & RouteComponentProps) {
    const { context, history, section } = props;
    const { url } = props.match;
    const cacheKey = 'a:p:' + url;
    const getInitialParams = () => {
        const clazz = (section.params || SearchParams) as Class<P>;
        const inst = StorageUtil.getJson(cacheKey, clazz) || new clazz();
        if (!(inst.sortBy?.length) && section.defaultSort) {
            inst.sortBy = [section.defaultSort];
        }
        return inst;
    }
    const [appContext] = useContext(AppContext);
    const [params, setParams] = useState<P>(getInitialParams);
    const { data, error, loading, reload } = useResolver(useCallback(async () => section.search(params, context), [section, params, context]));
    const deleteConfirmText = useString('window.confirm');
    const [action, setAction] = useState<AdminSectionAction<any, X> | null>(null);

    useEffect(() => {
        StorageUtil.putJson(cacheKey, params);
    }, [params, cacheKey]);


    const columns: IColumn<T>[] = [];

    for (const col of section.columns) {
        if (typeof col.enabled === 'function' && !col.enabled(context)) {
            continue;
        }
        columns.push({
            ...col, 
            name: isValidLabelKey(col.key) ? <Label k={col.key} /> : null,
            renderCell(o: T, i: number, a: T[]) {
                if (col.renderCell) {
                    return col.renderCell({ context, data: o, history, params, setParams, reload, appContext, matchUrl: url }, i, a);
                }
                return o[col.key as keyof T] || null;
            }
        });
    }

    if (section.update) {
        columns.push({
            key: '__action_edit',
            name: null,
            padding: 'checkbox',
            renderCell: o => (
                <LabelledIconButton
                    k="edit"
                    icon={EditIcon}
                    onClick={() => props.history.push(props.match.url + '/' + section.getId(o))}
                />
            )
        });
    }

    if (section.delete) {
        columns.push({
            key: '__action_delete',
            name: null,
            padding: 'checkbox',
            renderCell: o => (
                <LabelledIconButton 
                    k="delete"
                    icon={DeleteIcon}
                    onClick={
                        () => {
                            if (section.delete && window.confirm(deleteConfirmText || 'Are you sure?')) {
                                section.delete(o, context).then(reload);
                            }
                        }
                    }
                    disabled={Boolean(section.editEnabled && !section.editEnabled({ data: o, context, appContext }))}
                />
            )
        });
    }

    const actions: Array<AdminSectionAction<any, X>> = [];
    if (section.actions?.length) {
        for (const action of section.actions) {
            if (typeof action.enabled === 'function' && !action.enabled(context, appContext)) {
                continue;
            }
            actions.push(action);
        }
    }

    let _action: React.ReactNode = null;
    if (action) {
        if (isFormAction(action)) {
            _action = (
                <DecoratorForm 
                    clazz={action.clazz}
                    subClazz={action.subClazz}
                    initialValue={action.getInitialData ? action.getInitialData(context) : undefined}
                    onSubmit={async value => {
                        if (await action.onSubmit(value, context)) {
                            reload();
                        }
                        setAction(null);
                    }}
                />
            );
        }
        
        if (isWizardAction(action)) {
            _action = (
                <DecoratorWizard 
                    steps={action.steps}
                    initialValue={action.getInitialData ? action.getInitialData(context) : undefined}
                    onSubmit={async value => {
                        if (await action.onSubmit(value, context)) {
                            reload();
                        }
                        setAction(null);
                    }}
                />
            );
        }

        if (isComponentAction(action)) {
            _action = React.createElement(action.Component, {
                close: (shouldReload?: boolean) => {
                    setAction(null);
                    if (shouldReload) {
                        reload();
                    }
                },
                context,
            });
        }
    }

    const downloads = section.downloads?.filter(d => !d.enabled || d.enabled(context)) || [];

    return (
        <Box>
            <Box>
                <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={12} md>
                        {
                            props.title && (
                                <Typography component="h1" variant="h5">
                                    {props.title}
                                </Typography>
                            )
                        }
                        {
                            section.renderTableHeader?.(context)
                        }
                    </Grid>
                    {
                        actions.map(a => (
                            <Grid key={'action-' + a.key} item xs="auto">
                                <Button color="primary" variant="contained" onClick={() => setAction(a)}>
                                    <Label k={a.key} />
                                </Button>
                            </Grid>
                        ))
                    }
                    {
                        section.moreActions?.(context)
                    }
                </Grid>
            </Box>
            <Box>
                <Grid container spacing={2}>
                    <DecoratorInput 
                        clazz={(section.params || SearchParams) as Class<P>}
                        value={params}
                        onChange={next => setParams({...next, page: 0})}
                        templates={horizontalTemplates}
                    />
                    <Grid item>
                        <VerticalCenter>
                            <LabelledIconButton 
                                k="clear"
                                icon={ClearIcon}
                                onClick={() => setParams((section.params ? new section.params() : new SearchParams()) as P)}
                            />
                        </VerticalCenter>
                    </Grid>
                    {
                        !!downloads.length && (
                            <Grid item>
                                <VerticalCenter>
                                    <AnchorPopover
                                        content={
                                            <List>
                                                {
                                                    downloads.map(({ key, label, download }) => (
                                                        <ListItemButton
                                                            key={key}
                                                            onClick={() => download(params, context)}
                                                        >
                                                            {label}
                                                        </ListItemButton>
                                                    ))
                                                }
                                            </List>
                                        }
                                    >
                                        {
                                            setAnchor => (
                                                <LabelledIconButton
                                                    k="download"
                                                    icon={DownloadIcon}
                                                    onClick={e => setAnchor(e.currentTarget)}
                                                />
                                            )
                                        }
                                    </AnchorPopover>
                                </VerticalCenter>
                            </Grid>
                        )
                    }
                </Grid>
            </Box>
            <TableView
                data={(data?.data.items) || []}
                getKey={section.getId}
                value={params}
                onChange={nextParams => setParams(nextParams)}
                total={data?.data.paging.total}
                columns={columns}
                expand={section.expand ? (item, index, arr) => section.expand?.({ data: item, context, history, params, setParams, reload, appContext, matchUrl: url }, index, arr) : undefined}
                dense
            >
                {
                    loading ? (
                        <CircularProgress />
                    ) : error ? (
                        <Alert severity="error">
                            <Label k="error" />
                        </Alert>
                    ) : (
                        <Label k="results.empty" />
                    )
                }
            </TableView>
            <ModalDialog 
                open={Boolean(action)} 
                onClose={() => setAction(null)}
                title={action && <Label k={action.key} />}
                dividers
            >
                {_action}
            </ModalDialog>
        </Box>
    );
}

function EntityEdit<T extends object, P extends SearchParams = SearchParams, X = void>(props: { context: X; section: EntitySection<T, P, X>; breadcrumbs?: React.ReactNodeArray; } & RouteComponentProps<{ id: string; }>) {
    const [appContext] = useContext(AppContext);
    const { context, history, location, match: { url, params: { id } }, section } = props;
    const { data, error, loading, setState } = useResolver(useCallback(async () => section.get(StringUtil.toInteger(id, 0), context), [section, id, context]));
    const feedback = useFeedbackContext();

    if (!data && loading) {
        return (
            <Box p={2} textAlign="center">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Alert severity="error">
                <Label k="error" />
            </Alert>
        );
    }

    if (!data || !data.data) {
        return (
            <Alert severity="info">
                <Label k="notfound" />
            </Alert>
        );
    }

    const { breadcrumbs, routes, tabs } = processSections({ 
        context: data.data, 
        sections: section.sections || [], 
        appContext, 
        pathPrefix: url,
        breadcrumbs: [
            ...(props.breadcrumbs || []),
            (
                <Link key={url} component={RouterLink} to={url}>
                    {typeof section.label === 'function' ? section.label(data.data) : data.data[section.label]}
                </Link>
            )
        ],
    });

    return (
        <React.Fragment>
            <Route 
                path={[url, ...(tabs ? tabs.map(t => t.path) : [])]} 
                exact
                render={() => (
                    <React.Fragment>
                        {
                            breadcrumbs && (
                                <Box py={2}>
                                    <Breadcrumbs>
                                        {breadcrumbs}
                                    </Breadcrumbs>
                                </Box>
                            )
                        }
                        {
                            !!tabs.length && (
                                <Box pb={2}>
                                    <Tabs value={location.pathname} onChange={(_, nextUrl) => history.push(nextUrl)} variant="scrollable">
                                        <Tab key={url} value={url} label={<Label k="edit" />} />
                                        {
                                            tabs.map(({path, title}) => (
                                                <Tab key={path} value={path} label={title} />
                                            ))
                                        }
                                    </Tabs>
                                </Box>
                            )
                        }
                    </React.Fragment>
                )}
            />
            <Switch>
                <Route 
                    path={url}
                    exact
                    render={() => (
                        <Route 
                            key={url}
                            path={url} 
                            exact
                            render={() => {

                                if (section.renderEdit) {
                                    return section.renderEdit(data.data, context);
                                }
                                
                                return (
                                    <Box sx={{ mx: 'auto', maxWidth: 1200, width: '100%' }}>
                                        <DecoratorForm 
                                            key={section.getId(data.data)}
                                            clazz={section.model}
                                            subClazz={section.getUpdateModel}
                                            initialValue={data.data}
                                            onSubmit={async (data: T) => {
                                                const { update } = section;
                                                if (!update) return data;
                                                const next = await update(data, context);
                                                setState({data: next, error: undefined, loading: false});
                                                feedback.push({ intent: 'success', content: <Label k="saved" />, });
                                                return next.data;
                                            }}
                                            disabled={Boolean(section.editEnabled && !section.editEnabled({
                                                data: data.data,
                                                context,
                                                appContext
                                            }))}
                                        />
                                    </Box>
                                );
                            }} 
                        />
                    )}
                />
                {routes}
            </Switch>
        </React.Fragment>
    );
}
