import { createElement, CSSProperties, useState } from "react";
import { Input, IInputProps } from "react-ts-form";
import { Box, Grid, Typography } from "@mui/material";

import ColorInput from "../form/inputs/ColorInput";
import TextInput from "../form/inputs/TextInput";
import FileInput from "../form/inputs/FileInput";
import Label from "../core/Label";
import FileData from "./FileData";
import { ColorRefInput, FontInput, TemplateInput } from "../form/common";
import DropDownChoiceInput from "../form/inputs/DropDownChoiceInput";
import { LabelKeyChoiceRenderer } from "../form/IOptionsProps";
import RunningText from "../core/ui/RunningText";
import { ThemeProvider } from "../core/ThemeProvider";
import DecoratorInput from "../form/DecoratorInput";
import NumberInput from "../form/inputs/NumberInput";
import TemplateData from "./TemplateData";

class TextStyle {

    public key!: string;

    @Input({
        component: NumberInput,
        meta: {
            title: <Label k="fontSize" />
        },
        inputProps: {
            float: true,
            step: 0.1,
            min: 1,
            max: 6,
        }
    })
    public fontSize?: string;

    @Input({
        component: DropDownChoiceInput.ofType<string>(),
        meta: {
            title: <Label k="textTransform" />
        },
        inputProps: {
            options: ['none', 'lowercase', 'uppercase', 'capitalize'],
            choiceRenderer: LabelKeyChoiceRenderer
        }
    })
    public textTransform?: CSSProperties['textTransform'];

    @Input({
        component: DropDownChoiceInput.ofType<number>(),
        meta: {
            title: <Label k="fontWeight" />
        },
        inputProps: {
            options: [100, 200, 300, 400, 500, 600, 700, 800, 900],
        }
    })
    public fontWeight?: number; // 100 -> 900

    @Input({
        component: ColorRefInput,
        meta: {
            title: <Label k="color" />
        }
    })
    public color?: string;

}

interface TextStylesEditorProps extends IInputProps<TextStyle[]> {
    theme: ThemeData;
}

function TextStylesEditor(props: TextStylesEditorProps) {
    const [selected, setSelected] = useState<number>(1);
    const headings = [1, 2, 3, 4, 5, 6];

    const value = props.value?.find(v => v.key === ('h' + selected));
    const handleChange = (next: TextStyle) => {
        next = {...next, key: 'h' + selected};
        props.onChange(!value ? [...(props.value || []), next] : props.value.map(v => v.key === next.key ? next : v));
    }

    return (
        <Grid container spacing={2}>
            <Grid item md={6}>
                <Typography color="textSecondary" gutterBottom>
                    <Label k="format.heading" args={{level: selected}} />
                </Typography>
                <DecoratorInput 
                    clazz={TextStyle}
                    value={value || {}}
                    onChange={handleChange}
                    key={selected}
                />
            </Grid>
            <Grid item md={6}>
                <ThemeProvider theme={props.theme}>
                    <RunningText>
                        {
                            headings.map(level => (
                                <Box key={level}>
                                    {
                                        createElement('h' + level, {
                                            tabIndex: 0,
                                            style: { cursor: 'pointer' },
                                            onClick: () => setSelected(level)
                                        }, <Label k="format.heading" args={{level}} />)
                                    }
                                </Box>
                            ))
                        }
                    </RunningText>
                </ThemeProvider>
            </Grid>
        </Grid>
    );
}

class ThemeProps {

    @Input({
        component: ColorInput,
        meta: {
            title: <Label k="primary" />
        }
    })
    public primaryColor!: string;

    @Input({
        component: ColorInput,
        meta: {
            title: <Label k="secondary" />
        }
    })
    public secondaryColor!: string;

    @Input({
        component: ColorInput,
        meta: {
            title: <Label k="backgroundLight" />
        }
    })
    public defaultBackgroundColor!: string;

    @Input({
        component: ColorInput,
        meta: {
            title: <Label k="backgroundDark" />
        }
    })
    public defaultBackgroundColorDark!: string;

    @Input({
        component: DropDownChoiceInput.of<'default' | 'primary' | 'secondary'>({
            options: ['default', 'primary', 'secondary']
        }),
        meta: {
            title: <Label k="header_color" />
        }
    })
    public headerColor?: 'default' | 'primary' | 'secondary';

    @Input({
        component: FontInput,
        meta: {
            title: <Label k="fontFamily" />
        }
    })
    public fontFamily?: string;

    @Input((_, { root }) => ({
        component: TextStylesEditor,
        inputProps: { theme: root }
    }))
    public textStyles?: TextStyle[];

    @Input({
        component: DropDownChoiceInput,
        meta: {
            title: 'Color Scheme',
        },
        inputProps: {
            options: ['auto', 'light', 'dark'],
        },
    })
    public mode?: 'auto' | 'light' | 'dark';

}

export default class ThemeData {

    public id!: number;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="name" />
        }
    })
    public name!: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="externalId" />
        },
        inputProps: {
            maxLength: 128
        }
    })
    public externalId?: string;

    @Input({
        component: FileInput,
        meta: {
            title: <Label k="logo" />
        }
    })
    public logo?: FileData;

    @Input({
        component: FileInput,
        meta: {
            title: <Label k="logo.inverse" />
        }
    })
    public logoInverse?: FileData;

    @Input({
        component: FileInput,
        meta: {
            title: <Label k="favicon" />,
            description: "32x32"
        }
    })
    public favicon?: FileData;

    @Input({
        component: FileInput,
        meta: {
            title: <Label k="picture" />
        }
    })
    public pictureDefault?: FileData;

    @Input({
        component: FileInput,
        meta: {
            title: <Label k="poster" />
        }
    })
    public posterDefault?: FileData;

    @Input({
        component: TemplateInput,
        meta: {
            title: "footer",
        },
    })
    public footer?: TemplateData;

    @Input({ 
        clazz: ThemeProps
    })
    public properties?: ThemeProps;

    public styles?: string[];
}
