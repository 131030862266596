import { Fieldsets, Input } from "react-ts-form";
import TextInput from "../form/inputs/TextInput";
import SwitchInput from "../form/inputs/SwitchInput";
import ContentTypeData from "./ContentTypeData";
import { ContentTypeInput } from "../form/common";
import Label from "../core/Label";
import { Button } from "@mui/material";
import RadioChoiceInput from "../form/inputs/RadioChoiceInput";
import { Class } from "../util/Class";
import { launcherRegistry } from "../registries";
import Axios from "axios";
import CodeInput from "../form/inputs/CodeInput";

class CustomProperty {
    @Input({
        component: TextInput,
        meta: {
            title: <Label k="lti-tp.custom-key" />,
            required: true
        }
    })
    public name!: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="lti-tp.custom-value" />,
            required: true
        }
    })
    public value!: string;
}

@Fieldsets({
    ltiusersettings: <Label k="ltiusersettings" />
})
class LtiToolHandler {
    @Input({
        component: TextInput,
        meta: {
            title: <Label k="lti-tp.version" />,
            required: true
        }
    })
    public version!: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="lti.shared-secret" />,
            required: true
        }
    })
    public sharedSecret!: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="lti-tp.url" />
        }
    })
    public url!: string;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="lti-tp.score-as-progress" />,
        }
    })
    public scoreAsProgress!: boolean;

    @Input((_, { parent }) => {
        if (!parent.scoreAsProgress) {
            return {
                component: SwitchInput,
                meta: {
                    title: <Label k="lti-tp.mark-complete-on-score-over-zero" />,
                }
            }
        }
        return {};
    })
    public markCompleteOnScoreOverZero!: boolean;
    
    @Input({
        component: RadioChoiceInput.ofType<'none' | 'internal' | 'external'>(),
        inputProps: {
          options: ['none', 'internal', 'external']
        },
        meta: {
            title: <Label k="lti-tp.share.user-id" />,
        },
        fieldset: "ltiusersettings"
     })
     public userId: 'none' | 'internal' | 'external' = 'none';    

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="lti-tp.share.user-given-name" />,
        },
        fieldset: "ltiusersettings"
    })
    public userGivenName!: boolean;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="lti-tp.share.user-family-name" />,
        },
        fieldset: "ltiusersettings"
    })
    public userFamilyName!: boolean;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="lti-tp.share.user-full-name" />,
        },
        fieldset: "ltiusersettings"
    })
    public userFullName!: boolean;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="lti-tp.share.user-email" />,
        },
        fieldset: "ltiusersettings"
    })
    public userEmail!: boolean;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="lti-tp.share.user-sourced-id" />,
        },
        fieldset: "ltiusersettings"
    })
    public sourcedId!: boolean;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="lti-tp.share.user-image" />,
        },
        fieldset: "ltiusersettings"
    })
    public userImage!: boolean;

    @Input({
        clazz: CustomProperty,
        meta: {
            title: <Label k="lti-tp.custom.property" />,
        },
        array: {
            sort: true,
            remove: true,
            addComponent: ({ onAdd }) => {
                return (
                    <Button
                        variant="contained" 
                        color="secondary"
                        onClick={ () => onAdd([new CustomProperty()]) }>
                        <Label k="lti-tp.custom.property.add" />
                    </Button>
                );
            }
        } 
     })
     public customProperties!: CustomProperty[];

}

class ZoomHandler {

    @Input({
        component: TextInput,
        meta: {
            title: 'Account ID',
            required: true,
        }
    })
    public accountId!: string;

    @Input({
        component: TextInput,
        meta: {
            title: 'Client ID',
            required: true,
        }
    })
    public clientId!: string;

    @Input({
        component: TextInput,
        meta: {
            title: 'Client Secret',
            required: true,
        },
    })
    public clientSecret!: string;

}

class CrowdWisdomHandler {

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="api_url" />
        }
    })
    public apiUrl!: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="username" />
        }
    })
    public username!: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="password" />
        }
    })
    public password!: string;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="use_user_external_id" />
        }
    })
    public userExternalId: boolean = false;

    @Input({
        component: SwitchInput,
        meta: {
            title: "Disable Locale Parameter (Temporary)"
        }
    })
    public tempDisableLocale: boolean = false;

}

class WebCallback {

    @Input((name, { root }) => ({
        component: TextInput,
        meta: {
            title: 'Name',
            required: true,
            description: name && root.uuid ? `callback url: ${window.location.origin}${Axios.defaults.baseURL ?? ''}/track/web/providers/${root.uuid}/callbacks/${name}` : undefined,
        },
    }))
    public name = "";

    @Input({
        component: TextInput,
        meta: {
            title: 'Session ID Path',
            description: 'Path to session ID value in request (default: session)',
        },
    })
    public sessionIdPath?: string = "session";

    @Input({
        component: CodeInput,
        meta: {
            title: 'Response Template',
            required: true,
            description: 'Handlebars template to render response.',
        },
        inputProps: {
            language: 'handlebars',
        },
    })
    public template: string = "";

    @Input({
        component: CodeInput,
        meta: {
            title: 'Invalid Session Response Template',
            required: true,
            description: "JSON response payload if invalid or not found. If not defined, endpoint will respond with a 400 status.",
        }
    })
    public invalidSessionTemplate: string = "";

}

class WebHandler {

    @Input({
        clazz: WebCallback,
        meta: {
            title: 'Callbacks',
            description: 'Configure endpoints that your custom web content can call using a learning session ID',
        },
        array: {
            sort: true,
            remove: true,
            addComponent: ({ onAdd }) => (
                <Button
                    variant="contained" 
                    color="secondary"
                    onClick={ () => onAdd([new WebCallback()]) }
                >
                    Add Callback
                </Button>
            ),
        }
    })
    public callbacks: WebCallback[] = [];

}

const types: { [launcher: string]: Class<any> } = {
    'zoom': ZoomHandler,
    'CrowdWisdom': CrowdWisdomHandler,
    'lti.tool': LtiToolHandler,
    'web': WebHandler,
}

export default class ContentProvider {

    public id!: number;

    public uuid!: string;

    @Input({
        component: TextInput,
        meta: {
            title: 'Name',
            required: true,
        },
    })
    public name!: string;

    @Input({
        component: TextInput,
        meta: {
            title: 'External ID',
        },
    })
    public externalId?: string;

    @Input({
        component: SwitchInput,
        meta: {
            title: 'Enabled',
        },
    })
    public enabled!: boolean;

    @Input((_, { parent }) => ({
        component: ContentTypeInput,
        meta: {
            title: 'Content Type',
            required: true,
            disabled: !!parent.id,
        },
        inputProps: {
            optionEnabled: (o: any) => !launcherRegistry.get(o.launcher)?.hasSystem,
        },
    }))
    public type!: ContentTypeData;

    @Input((_, { parent }) => ({
        clazz: types[parent?.type?.launcher ?? ''],
    }))
    public config: any = {};

    public createDate!: string;
    public updateDate!: string;

}
