import { IInputProps, Input } from "react-ts-form";
import TextInput from "../form/inputs/TextInput";
import { Box, Button, CircularProgress, Grid, Paper, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import CodeInput from "../form/inputs/CodeInput";
import { Shuttle } from "../types";
import SwitchInput from "../form/inputs/SwitchInput";
import { useEffect, useRef, useState } from "react";
import { WebhookService } from "../services/WebhookService";
import { DateLabel } from "../core/DateLabel";
import DataObjectIcon from '@mui/icons-material/DataObject';
import PreviewIcon from '@mui/icons-material/Preview';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Rule from "./Rule";
import PasswordInput from "../form/inputs/PasswordInput";

export class Header {

    @Input({
        component: TextInput,
        meta: {
            title: 'Name',
            required: true,
        },
        inputProps: {
            maxLength: 255,
        },
    })
    public name!: string;

    @Input({
        component: TextInput,
        meta: {
            title: 'Value',
            required: true,
        },
        inputProps: {
            maxLength: 255,
        },
    })
    public value!: string;

}

export class Webhook {

    public id!: number;

    @Input({
        component: TextInput,
        meta: {
            title: 'Name',
            required: true,
        },
        inputProps: {
            maxLength: 255,
        },
    })
    public name!: string;

    @Input({
        component: TextInput,
        meta: {
            title: 'External ID',
        },
        inputProps: {
            maxLength: 128
        }
    })
    public externalId?: string;

    @Input({
        component: SwitchInput,
        meta: {
            title: 'Enabled',
        },
    })
    public enabled!: boolean;

    @Input({
        component: TextInput,
        meta: {
            title: 'Endpoint (URL)',
        },
        inputProps: {
            maxLength: 2048
        }
    })
    public uri?: string;

    @Input({
        clazz: Header,
        meta: {
            title: 'Headers',
        },
        array: {
            remove: true,
            sort: true,
            addComponent: ({ onAdd }) => (
                <Button size="small" variant="contained" color="secondary" onClick={() => onAdd([{ name: '', value: '' }])}>
                    Add Header
                </Button>
            )
        }
    })
    public headers: Header[] = [];

    @Input((_, { parent }) => ({
        component: TemplateEditor,
        meta: {
            title: 'Payload Template',
            description: 'Leave this blank to use the default payload.'
        },
        inputProps: {
            rule: parent.rule,
        },
    }))
    public template?: string;

    @Input({
        clazz: Rule,
        meta: {
            title: 'Filter Rule',
        },
    })
    public rule?: Rule;


    @Input({
        component: SwitchInput,
        meta: {
            title: 'Client Authentication',
        },
    })
    public oauthClientAuth?: boolean;

    @Input((_, { parent }) => ({
        component: TextInput,
        meta: {
            title: 'Token Endpoint (URL)',
            required: parent.oauthClientAuth,
        },
        
        exclude:(() => !parent.oauthClientAuth),

        inputProps: {
            maxLength: 2048
        }
    }))
    public oauthClientTokenUrl?: string;

    @Input((_, { parent }) => ({
        component: TextInput,
        meta: {
            title: 'Client ID',
            required: parent.oauthClientAuth,
        },
        exclude: (_, {parent}) => !parent.oauthClientAuth,
        inputProps: {
            maxLength: 128
        }
    }))    
    public oauthClientId?: string;
    
    @Input((_, { parent }) => ({
        component: PasswordInput,
        meta: {
            title: 'Client Secret',
            required: parent.oauthClientAuth,
        },
        exclude:(_, {parent}) => !parent.oauthClientAuth,
    }))
    public oauthClientSecret?: string;

    @Input({
        component: TextInput,
        meta: {
            title: 'Scope',
        },
        
        exclude:(_, {parent}) => !parent.oauthClientAuth,

        inputProps: {
            maxLength: 255
        }
    })
    public oauthClientScope?: string;

    public createDate!: string;
    public updateDate!: string;
    public shutoffDate?: string;

}

interface TemplateEditorProps extends IInputProps<string> {
    rule?: Shuttle.Rule;
}

function TemplateEditor({ rule, value = '', ...rest }: TemplateEditorProps) {

    const [view, setView] = useState<'data' | 'preview'>('data');
    const [loading, setLoading] = useState(false);
    const [events, setEvents] = useState<any[]>([]);

    const timer = useRef<any>();
    useEffect(() => {
        if (timer.current) clearTimeout(timer.current);
        timer.current = setTimeout(async () => {
            setLoading(true);
            const events = await WebhookService.eventPreview({
                rule,
                template: value,
            });
            setEvents(events);
            setLoading(false);
        }, 500);
        return () => {
            clearTimeout(timer.current);
        }
    }, [value, rule]);

    return (
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <CodeInput {...rest} value={value} language="handlebars" />
            </Grid>
            <Grid item xs={6}>
                <ToggleButtonGroup
                    value={view} 
                    onChange={(_, view) => setView(view)}
                    exclusive
                    size="small"
                >
                    <ToggleButton value="data">
                        <DataObjectIcon />
                    </ToggleButton>
                    <ToggleButton value="preview">
                        <PreviewIcon />
                    </ToggleButton>
                </ToggleButtonGroup>
                <Box sx={{ maxHeight: '500px', overflow: 'auto', p: 2 }}>
                    {
                        loading
                            ? <CircularProgress />
                            : events?.length
                            ? events.map(e => {

                                let preview;
                                try {
                                    preview = JSON.stringify(
                                        JSON.parse(view === 'preview' ? e.preview : e.data),
                                        null,
                                        2
                                    );
                                } catch {
                                    preview = 'invalid json data';
                                }
                                
                                return (
                                    <Paper key={e.id} sx={{ p: 1 }}>
                                        <Stack spacing={1} direction="row">
                                            <div style={{ flexGrow: 1 }}>
                                                <Typography fontWeight={700} gutterBottom fontSize="body2">
                                                    {e.type}
                                                </Typography>
                                                <Typography gutterBottom fontSize="body2" color="text.secondary">
                                                    {<DateLabel date={e.eventDate} format="Ppp" />}
                                                </Typography>
                                            </div>
                                            <div>
                                                {
                                                    e.matches
                                                        ? <CheckCircleIcon color="success" />
                                                        : <CancelIcon color="error" />
                                                }
                                            </div>
                                        </Stack>
                                        <Box sx={{ }}>
                                        </Box>
                                        <Box component="code" sx={{ whiteSpace: 'pre', fontSize: 12, overflow: 'scroll' }}>
                                            {preview}
                                        </Box>
                                    </Paper>
                                )
                            })
                            : 'No Events Recorded'
                    }
                </Box>
            </Grid>
        </Grid>
    )
}
