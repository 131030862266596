import { Input } from "react-ts-form";

import Label from "../../core/Label";
import { TagMultiInput } from "../../form/common";
import DropDownChoiceInput from "../../form/inputs/DropDownChoiceInput";
import TextInput from "../../form/inputs/TextInput";
import { QuestionTypeRegistry } from "../../quiz/QuestionType";
import TagData from "../TagData";
import QuestionModel from "./QuestionModel";

export default class QuestionData<T extends QuestionModel = QuestionModel> {

    public id!: number;

    public uuid!: string;

    public revision!: number;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="name" />,
            required: true,
        }
    })
    public name!: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="externalId" />,
        },
        inputProps: {
            maxLength: 128
        }
    })
    public externalId?: string;

    @Input((_, { parent }) => ({
        component: DropDownChoiceInput.ofType<string>(),
        meta: {
            title: <Label k="type" />,
            required: true,
            disabled: Boolean(parent.id),
        },
        inputProps: {
            options: QuestionTypeRegistry.keys(),
        }
    }))
    public type!: string;

    @Input({
        component: TagMultiInput,
        meta: {
            title: <Label k="tags" />
        }
    })
    public tags?: TagData[];

    public createdOn!: number;

    public updatedOn!: number;

    @Input((_, { parent }) => {
        let clazz;
        if (parent.type) {
            const type = QuestionTypeRegistry.get(parent.type);
            if (type) {
                clazz = type.clazz;
            }
        }
        return { clazz };
    })
    public content!: T;
}
