import { AuthProvider } from "../model/AuthProvider";
import createEntityService from "./createEntityService";

const AuthProviderService = {

    ...createEntityService<AuthProvider>({
        path: '/auth-providers',
    }),

};

export default AuthProviderService;
