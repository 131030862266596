import { Shuttle } from "../../types";
import MediaIcon from "@mui/icons-material/VideoLibrary";
import { ReactNode, Suspense, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Input } from "react-ts-form";
import { Avatar, Box, Button, Card, CardContent, CardHeader, CircularProgress, Grid, Skeleton, SxProps, Tab, Theme, Typography } from "@mui/material";
import videojs from 'video.js';
import FileInput from "../../form/inputs/FileInput";
import FileData from "../../model/FileData";
import Label from "../../core/Label";
import { VideoPlayer } from "../../lazy";
import MultimediaTextTrackData from "../../model/MultimediaTextTrackData";
import DurationInput from "../../form/inputs/DurationInput";
import SwitchInput from "../../form/inputs/SwitchInput";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { SettingsContext } from "../../contexts";
import bestLocaleMatch from "../../i18n/util";
import StringUtil from "../../util/StringUtil";
import VerticalCenter from "../../util/VerticalCenter";
import RunningText from "../../core/ui/RunningText";
import { BookmarkList } from "../BookmarkList";
import { InteractiveTranscript } from "./InteractiveTranscript";
import ContentContributorData from "../../model/ContentContributorData";
import useResolver from "../../hooks/useResolver";
import Axios from "axios";
import useLayoutString from "../../layout/useLayoutString";
import TextInput from "../../form/inputs/TextInput";
import NumberInput from "../../form/inputs/NumberInput";
import LangStringInput from "../../form/inputs/LangStringInput";
import { api } from "../../player/api";
import { AttemptCreator, AttemptResults, QuestionRange } from "./Quiz";
import ModalDialog from "../../core/ui/ModalDialog";
import LayoutString from "../../layout/LayoutString";
import DropDownChoiceInput from "../../form/inputs/DropDownChoiceInput";

const positionOptions = [
    'top-left',
    'top-right',
    'bottom-left',
    'bottom-right',
    'custom',
] as const;

class Interaction {

    public id!: string;

    @Input({
        component: NumberInput,
        meta: {
            title: 'Start',
            required: true,
        },
        inputProps: {
            min: 0,
        },
    })
    public start: number = 0;

    @Input({
        component: NumberInput,
        meta: {
            title: 'End',
            description: '(optional) if you do not set an end time, the video will pause automatically when the start time is reached.'
        },
        inputProps: {
            min: 0,
        },
    })
    public end?: number;

    @Input({
        component: SwitchInput,
        meta: {
            title: 'Show as Button with Popup',
        },
    })
    public button?: boolean;

    @Input((_, { parent }) => {
        if (parent.button) {
            return {
                component: LangStringInput,
                meta: {
                    title: 'Title',
                },
            };
        }
        return {};
    })
    public title?: Shuttle.LangString;

    @Input({
        component: DropDownChoiceInput,
        meta: {
            title: 'Position',
            required: true,
        },
        inputProps: {
            options: [...positionOptions],
        },
    })
    public position?: (typeof positionOptions)[number] = 'top-left';

    @Input((_, { parent }) => {
        if (parent.position !== 'custom') return {};
        return {
            component: NumberInput,
            meta: {
                title: 'X Position (% from left)',
                required: true,
            },
            inputProps: {
                min: 0,
                max: 100,
                float: true,
            },
        }
    })
    public x?: number;

    @Input((_, { parent }) => {
        if (parent.position !== 'custom') return {};
        return {
            component: NumberInput,
            meta: {
                title: 'Y Position (% from top)',
                required: true,
            },
            inputProps: {
                min: 0,
                max: 100,
                float: true,
            },
        }
    })
    public y?: number;

    @Input({
        component: LangStringInput,
        meta: {
            title: 'Text',
        },
        inputProps: {
            type: 'richText',
        },
    })
    public text!: Shuttle.LangString;

}

class ExtraTab {

    @Input({
        component: LangStringInput,
        meta: {
            title: 'Title',
            required: true,
        },
    })
    public title: Shuttle.LangString = {};

    @Input({
        component: LangStringInput,
        meta: {
            title: 'Text',
            required: true,
        },
        inputProps: {
            type: 'richText',
        },
    })
    public text: Shuttle.LangString = {};

}

class MultimediaSettings {

    @Input({
        component: FileInput,
        meta: {
            title: <Label k="resource" />
        },
        inputProps: {
            accept: 'video/*,audio/*'
        },
    })
    public resource!: FileData;

    @Input({
        component: SwitchInput,
        meta: {
            title: 'Interactive Transcript',
        },
    })
    public interactiveTranscript?: boolean;

    @Input({
        component: SwitchInput,
        meta: {
            title: 'Disable Transcript Download',
        },
    })
    public disableTranscriptDownload?: boolean;

    @Input({
        component: SwitchInput,
        meta: {
            title: 'Notes',
        },
    })
    public bookmarks?: boolean;

    @Input({
        component: DurationInput,
        meta: {
            title: 'Minimum Played/Watched for Completion',
        },
    })
    public minWatched?: number;

    @Input({
        component: SwitchInput,
        meta: {
            title: 'Sidebar Default Closed',
        },
    })
    public sidebarDefaultClosed?: boolean;

    @Input({
        component: SwitchInput,
        meta: {
            title: 'Always Show Tabs Below Player',
        },
    })
    public sidebarWrap?: boolean;

    @Input({
        component: SwitchInput,
        meta: {
            title: 'Disable Seek Before Completed',
        },
    })
    public disableSeekBeforeCompleted?: boolean;

    @Input({
        component: SwitchInput,
        meta: {
            title: 'Disable Playback Rate Control Before Completed',
        },
    })
    public disableRateControlBeforeCompleted?: boolean;

    @Input({
        component: SwitchInput,
        meta: {
            title: 'Enable Start Screen',
        },
    })
    public enableStartScreen?: boolean;

    @Input({
        component: TextInput,
        meta: {
            title: 'Instructor Email',
            description: 'NOTE: this feature will eventually be migrated to the Instructors module (coming soon).'
        },
    })
    public instructorEmail?: string;

    @Input({
        component: SwitchInput,
        meta: {
            title: 'Show Questions in Sidebar'
        },
    })
    public showQuestionsInSidebar: boolean = false;

    @Input({
        clazz: Interaction,
        meta: {
            title: 'Interactions',
        },
        array: {
            sort: true,
            remove: true,
            addComponent: ({ onAdd }) => (
                <Button
                    size="small"
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                        const i = new Interaction();
                        i.id = StringUtil.uuid();
                        onAdd([i]);
                    }}
                >
                    Add Interaction
                </Button>
            )
        }
    })
    public interactions?: Interaction[];

    @Input({
        clazz: ExtraTab,
        meta: {
            title: 'Additional Tabs',
        },
        array: {
            sort: true,
            remove: true,
            addComponent: ({ onAdd }) => (
                <Button
                    size="small"
                    color="secondary"
                    variant="contained"
                    onClick={() => onAdd([new ExtraTab()])}
                >
                    Add Tab
                </Button>
            )
        },
    })
    public additionalTabs?: ExtraTab[];

}

interface LaunchConfig {
    bookmarks?: boolean;
    minWatched?: number;
    posterUrl?: string;
    sources: any[];
    textTracks: MultimediaTextTrackData[];
    interactiveTranscript: boolean;
    disableTranscriptDownload: boolean;
    sidebarDefaultClosed: boolean;
    sidebarWrap: boolean;
    contributors: ContentContributorData[];
    disableSeekBeforeCompleted: boolean;
    disableRateControlBeforeCompleted: boolean;
    enableStartScreen: boolean;
    instructorEmail?: string;
    sprites?: {
        url: string;
        height: number;
        width: number;
        interval: number;
    }
    interactions: Interaction[];
    additionalTabs?: ExtraTab[];
    hasQuestions: boolean;
    showQuestionsInSidebar: boolean;
}

interface MediaLauncherState {
    played: {
        seconds: number[];
        duration: number;
        bookmark: number;
    };
    quizCompleted?: string;
}

function getCurrentTracking(state: MediaLauncherState) {
    const rec = state.played ?? (state.played = {
        seconds: [],
        duration: 0,
        bookmark: 0,
    });
    if (!Array.isArray(rec.seconds)) rec.seconds = [];
    if (typeof rec.duration !== 'number') rec.duration = 0;
    if (typeof rec.bookmark !== 'number') rec.bookmark = 0;
    return rec;
}

const tempPrepareUrl = (src: string) => src?.includes('@@') ? src.replace('@@', Axios.defaults.baseURL!) : src;

export const MediaLauncher: Shuttle.Launcher = {
    name: 'launcher.multimedia',
    Icon: MediaIcon,
    ConfigClass: MultimediaSettings,
    tracking: true,
    directUploadResource: true,
    hasQuestions: true,
    Component: ({
        content,
        defaultDimension,
        inline,
        session,
        track: {
            commit,
            tracking
        },
    }) => {

        const [{ locale }] = useContext(SettingsContext);

        const contentTitle = useLayoutString({
            text: content.title,
        });

        const { config, entryId } = session;

        const [player, setPlayer] = useState<null | videojs.Player>(null);
        const [playing, setPlaying] = useState(false);
        const [initialized, setInitialized] = useState(false);

        const trackingRef = useRef<MediaLauncherState>(tracking?.state ?? {
            played: {},
        })

        let {
            additionalTabs,
            bookmarks = false,
            contributors,
            disableRateControlBeforeCompleted = false,
            disableSeekBeforeCompleted = false,
            disableTranscriptDownload = false,
            enableStartScreen = false,
            hasQuestions = false,
            instructorEmail,
            interactiveTranscript = false,
            interactions,
            minWatched = 0,
            posterUrl,
            showQuestionsInSidebar = false,
            sidebarDefaultClosed = false,
            sidebarWrap = false,
            sources,
            sprites,
            textTracks,
        } = config as LaunchConfig

        const quiz = useResolver(useCallback(async () => {
            if (entryId && hasQuestions) {
                return await api.quiz.init(entryId);
            }
            return null;
        }, [entryId, hasQuestions]));

        const [resultPage, setResultsPage] = useState(false);

        if (!minWatched || minWatched < 0) {
            minWatched = 0;
        }

        textTracks = useMemo(() => textTracks?.map(tt => ({
            ...tt,
            src: tempPrepareUrl(tt.src),
            pdfUrl: tempPrepareUrl(tt.pdfUrl ?? ''),
        })), [textTracks]);

        const src = sources?.[0]?.src;

        const commitCurrent = useCallback(() => commit(prev => {
            let completion = prev.completion;
            let progress = prev.progress;
            let uniqueTimeViewed = prev.uniqueTimeViewed;
            if (player) {
                const rec = getCurrentTracking(trackingRef.current);
                uniqueTimeViewed = rec.seconds.filter(Boolean).length
                if (completion !== 'Completed' && completion !== 'Passed' && completion !== 'Failed') {
                    if (uniqueTimeViewed >= minWatched && (!hasQuestions || trackingRef.current.quizCompleted)) {
                        completion = 'Completed';
                        progress = 100;
                    } else {
                        progress = Math.min(100, uniqueTimeViewed / minWatched * 100);
                    }
                }
            }
            return {
                ...prev,
                completion,
                progress,
                state: trackingRef.current,
                uniqueTimeViewed,
            };
        }), [player, commit, minWatched, hasQuestions]);

        const intervalRef = useRef<any>()
        useEffect(() => {
            if (playing) {
                commitCurrent();
                intervalRef.current = setInterval(commitCurrent, 30_000);
                return () => {
                    clearInterval(intervalRef.current);
                    commitCurrent();
                };
            }
        }, [commitCurrent, playing]);

        useEffect(() => {
            if (quiz.data?.attempt?.status === 'PUBLISHED' && !trackingRef.current.quizCompleted) {
                if (quiz.data.attempt.result === 'COMPLETED' || quiz.data.attempt.result === 'PASSED') {
                    trackingRef.current.quizCompleted = quiz.data.attempt.uuid;
                    commitCurrent();
                }
            }
        }, [quiz.data, commitCurrent]);

        const progressInterval = useRef<any>();
        useEffect(() => {
            if (progressInterval.current) {
                clearInterval(progressInterval.current);
            }
            if (!player || !playing) return;

            const seekBar = player?.controlBar
                .getChild('progressControl')
                ?.getChild('seekBar')
                ?.el();
            if (seekBar) {
                let progressBar = (
                    seekBar.querySelector<HTMLDivElement>('div[data-simpatico-progress]')
                    ?? document.createElement('div')
                );
                progressBar.setAttribute('data-simpatico-progress', '1');
                if (progressBar.parentElement !== seekBar) {
                    seekBar.appendChild(progressBar);
                }
                const drawProgress = () => {
                    const segments = Array.from(progressBar.querySelectorAll<HTMLElement>('[data-simpatico-progress-segment]'));
                    const duration = Math.floor(player.duration());
                    let { seconds } = getCurrentTracking(trackingRef.current);
                    if (seconds.length > duration) {
                        seconds = seconds.slice(0, duration);
                    }
                    let current: undefined | HTMLElement = undefined;
                    for (let i = 0; i < duration; i++) {
                        if (seconds[i]) {
                            if (!current) {
                                current = segments.shift();
                                if (!current) {
                                    current = document.createElement('div');
                                    current.setAttribute('data-simpatico-progress-segment', '1');
                                    progressBar.appendChild(current);
                                }
                                current.style.left = ((i / duration) * 100) + '%';
                            }
                            current.style.right = (100 - ((i + 1) / duration * 100)) + '%';
                        } else {
                            if (current) {
                                current = undefined;
                            }
                        }
                    }
                    while (segments.length) {
                        current = segments.pop();
                        current?.remove();
                    }
                }
                drawProgress();
                progressInterval.current = setInterval(drawProgress, 1000);
                return () => {
                    clearInterval(progressInterval.current);
                }
            }
        }, [player, playing]);

        useEffect(() => {
            if (player && instructorEmail) {
                const button = player.controlBar.addChild('button');
                button.addClass('vjs-custom-control');
                button.controlText('Ask a Question');
                button.$('.vjs-icon-placeholder').innerHTML = `
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                `;
                button.on('click', () => {
                    const subject = `${contentTitle} - ${StringUtil.formatDuration(Math.floor(player.currentTime()))}`;
                    const w = player.el()?.ownerDocument?.defaultView ?? window;
                    w.open(`mailto:${instructorEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(subject + '\n')}`, '_blank');
                });
                return () => {
                    player?.controlBar.removeChild(button);
                }
            }
        }, [player, instructorEmail, contentTitle]);

        useEffect(() => {
            if (player && disableSeekBeforeCompleted) {
                const progressControl = player.controlBar.getChild('progressControl') as videojs.ProgressControl | undefined;
                if (progressControl) {
                    if (tracking?.completion === 'Completed' || tracking?.completion === 'Passed') {
                        if (!progressControl.enabled()) {
                            progressControl.enable();
                        }
                    } else {
                        if (progressControl.enabled()) {
                            progressControl.disable();
                        }
                    }
                }
            }
        }, [player, disableSeekBeforeCompleted, tracking]);

        useEffect(() => {
            if (player && disableRateControlBeforeCompleted) {
                const rateControl = player.controlBar.getChild('playbackRateMenuButton') as videojs.MenuButton | undefined;
                if (rateControl) {
                    if (tracking?.completion === 'Completed' || tracking?.completion === 'Passed') {
                        rateControl.enable();
                    } else {
                        rateControl.disable();
                    }
                }
            }
        }, [player, disableRateControlBeforeCompleted, tracking]);

        const tabs: Array<{
            key: string;
            title: ReactNode;
            content: ReactNode;
        }> = [];

        if (interactiveTranscript && textTracks) {

            const currentCaption = bestLocaleMatch(textTracks.reduce((a, c) => {
                if (c.kind === 'captions') {
                    a[c.language ?? 'en'] = c;
                }
                return a;
            }, {} as Record<string, MultimediaTextTrackData>), locale);

            if (currentCaption) {
                tabs.push({
                    key: 'transcript',
                    title: <Label k="transcript" />,
                    content: player 
                        ? (
                            <TranscriptLoader
                                contributors={contributors}
                                player={player}
                                src={currentCaption.src}
                                disableNav={disableSeekBeforeCompleted && !(tracking?.completion === 'Completed' || tracking?.completion === 'Passed')}
                                downloadUrl={disableTranscriptDownload ? undefined : currentCaption.pdfUrl + '?title=' + encodeURIComponent(contentTitle)}
                                maxHeight={sidebarWrap ? '500px' : 'none'}
                            />
                        ) : (
                            <VerticalCenter>
                                <CircularProgress />
                            </VerticalCenter>
                        ),
                })
            }
        }

        if (bookmarks) {
            tabs.push({
                key: 'notes',
                title: <Label k="notes" />,
                content: player
                    ? (
                        <Box sx={{ p: 1 }}>
                            <BookmarkList
                                bookmarkTitle={b => {
                                    if (typeof b.data.timestamp === 'number') {
                                        return (
                                            <button
                                                className="button-reset"
                                                onClick={() => {
                                                    player.currentTime(Math.max(0, Math.min(player.duration(), b.data.timestamp)));
                                                    player.play();
                                                }}
                                            >
                                                {StringUtil.formatDuration(b.data.timestamp)}
                                            </button>
                                        );
                                    }
                                    return <Label k="section_note" />
                                }}
                                contentId={content.id}
                                data={(prev) => ({
                                    timestamp: typeof prev.timestamp === 'number'
                                        ? prev.timestamp
                                        : Math.floor(player.currentTime()),
                                })}
                                editable
                                sort={list => list.sort((x, y) => {
                                    const xt = typeof x.data.timestamp === 'number' ? x.data.timestamp : 0;
                                    const yt = typeof y.data.timestamp === 'number' ? y.data.timestamp : 0;
                                    if (xt < yt) return -1;
                                    if (xt > yt) return 1;
                                    return 0;
                                })}
                            />
                        </Box>
                    ) : (
                        <VerticalCenter>
                            <CircularProgress />
                        </VerticalCenter>
                    ),
            })
        }

        if (contributors?.length) {
            tabs.push({
                key: 'speakers',
                title: <Label k="speakers" />,
                content: (
                    <Box sx={{ p: 1 }}>
                        {
                            contributors.map(({ contributor }) => (
                                <Card key={contributor.id} variant="outlined">
                                    <CardHeader
                                        avatar={
                                            <Avatar src={contributor.avatar?.url} alt={contributor.name} />
                                        }
                                        title={contributor.name}
                                        subheader={contributor.organization}
                                    />
                                    {
                                        contributor.bio && (
                                            <CardContent sx={{ pt: 0 }}>
                                                <RunningText html={contributor.bio} />
                                            </CardContent>
                                        )
                                    }
                                </Card>
                            ))
                        }
                    </Box>
                )
            });
        }

        additionalTabs?.forEach(({ text, title }, i) => tabs.push({
            key: `__extra${i}__`,
            title: <LayoutString text={title} />,
            content: (
                <Box p={1}>
                    <LayoutString text={text} html />
                </Box>
            ),
        }));

        const [displayTime, setDisplayTime] = useState(-1);
        const dismissedRef = useRef<number>(-1);
        useEffect(() => {
            if (player && initialized && quiz.data?.attempt) {
                const times = quiz.data.attempt.questions.reduce((a, c) => {
                    let o = a.find(x => x.displayTime === c.displayTime);
                    if (!o) {
                        a.push(o = {
                            displayTime: c.displayTime,
                            answered: 0,
                            total: 0,
                        });
                    }
                    o.total++;
                    if (c.required && c.response) o.answered++;
                    return a;
                }, [] as { displayTime: number; answered: number; total: number }[]);
                const seekBar = player.controlBar
                    .getChild('progressControl')
                    ?.getChild('seekBar')
                    ?.el();
                if (seekBar) {
                    let markersBar = seekBar.querySelector<HTMLDivElement>('[data-question-markers]');
                    if (!markersBar) {
                        seekBar.appendChild(markersBar = document.createElement('div'));
                    }
                    markersBar.setAttribute('data-question-markers', '1');
                    const markers = Array.from(markersBar.querySelectorAll<HTMLElement>('[data-question-marker]'));
                    const duration = player.duration();
                    for (const { displayTime, answered, total } of times) {
                        let marker = markers.shift();
                        if (!marker) {
                            markersBar.appendChild(marker = document.createElement('div'));
                        }
                        marker.setAttribute('data-question-marker', displayTime.toString());
                        marker.classList.toggle('completed', answered >= total);
                        marker.style.left = ((displayTime / duration) * 100) + '%';
                    }
                    while (markers.length) {
                        markers.pop()?.remove();
                    }
                }
                const listener = () => {
                    const sec = Math.floor(player.currentTime());
                    if (dismissedRef.current !== sec) {
                        dismissedRef.current = -1; // reset this when we move on to another second
                    }
                    if (dismissedRef.current !== sec && times.find(t => t.displayTime === sec)) {
                        player.pause();
                        player.controls(false);
                        player.removeClass('vjs-playing');
                        setDisplayTime(sec);
                        if (showQuestionsInSidebar) {
                            setSidebarOpen(true);
                        }
                    }
                };
                player.on('timeupdate', listener);
                return () => {
                    player.off('timeupdate', listener);
                }
            }
        }, [player, quiz.data, initialized, showQuestionsInSidebar]);

        const questions = quiz.data?.attempt && displayTime !== -1 && (
            <QuestionRange
                attempt={quiz.data.attempt}
                sessionEntry={session}
                onChangeQuestions={questions => quiz.setState(prev => {
                    if (prev.data) {
                        return {
                            ...prev,
                            data: {
                                ...prev.data,
                                attempt: {
                                    ...prev.data.attempt!,
                                    questions,
                                },
                            },
                        };
                    }
                    return prev;
                })}
                finishLabel={<Label k="continue" />}
                onFinish={async () => {
                    // auto-submit if all questions have been answered
                    if (quiz.data?.attempt.questions.every(q => q.response)) {
                        quiz.setState(prev => ({ ...prev, loading: true }));
                        const data = await api.quiz.submitAttempt(session.entryId, quiz.data!.attempt!.id);
                        quiz.setState({ data, loading: false });
                    }
                    player?.controls(true);
                    player?.addClass('vjs-playing');
                    player?.play();
                    dismissedRef.current = displayTime;
                    setDisplayTime(-1);
                }}
                displayTime={displayTime}
            />
        );

        const [tab, setTab] = useState(tabs[0]?.key ?? '')

        const sidebar = tabs.length ? (
            <Grid
                item
                xs={12}
                md={sidebarWrap ? 12 : 4}
                sx={{
                    position: 'relative',
                }}
            >
                <Box
                    sx={{
                        
                        top: 0,
                        left: 0,
                        width: '100%',
                        ...sidebarWrap ? {} : {
                            display: {
                                md: 'flex',
                            },
                            flexDirection: {
                                md: 'column',
                            },
                            position: {
                                md: 'absolute',
                            },
                            height: {
                                md: '100%',
                            },
                        },                
                    }}
                >
                    <TabContext value={tab}>
                        <TabList onChange={(_, tab) => setTab(tab)} scrollButtons="auto">
                            {
                                tabs.map(({ key, title }) => (
                                    <Tab key={key} value={key} label={title} />
                                ))
                            }
                        </TabList>
                        {
                            tabs.map(({ key, content }) => (
                                <TabPanel
                                    key={key}
                                    value={key}
                                    sx={{
                                        flexGrow: 1,
                                        overflowY: key === 'transcript' ? 'hidden' : 'auto',
                                        p: 0,
                                    }}
                                >
                                    {content}
                                </TabPanel>
                            ))
                        }
                    </TabContext>
                </Box>
                {
                    questions && showQuestionsInSidebar && (
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                overflowY: 'auto',
                                backgroundColor: theme => theme.palette.background.paper,
                                zIndex: 1,
                                p: 2,
                            }}
                        >
                            {questions}
                        </Box>
                    )
                }
            </Grid>
        ) : null;

        const [sidebarOpen, setSidebarOpen] = useState(Boolean(sidebar && !sidebarDefaultClosed));

        const [splashOpen, setSplashOpen] = useState(enableStartScreen);

        // interactions
        const visibleInteractionsRef = useRef<Interaction[]>([]);
        const [visibleInteractions, setVisibleInteractions] = useState(visibleInteractionsRef.current);
        const [popupInteraction, setPopupInteraction] = useState<null | Interaction>(null);
        useEffect(() => {
            if (interactions.length && player) {
                const listener = () => {
                    const t = Math.floor(player.currentTime());
                    let changed = false;
                    let pause = false;
                    for (const i of interactions) {
                        const start = i.start ?? 0;
                        const end = Math.max(i.end ?? 0, i.start);
                        let active = false;
                        if (start === end && t === start) {
                            active = true;
                            pause = true;
                        } else if (start <= t && t <= end) {
                            active = true;
                        }
                        if (active) {
                            if (!visibleInteractionsRef.current.includes(i)) {
                                changed = true;
                                visibleInteractionsRef.current.push(i);
                            }
                        } else {
                            const idx = visibleInteractionsRef.current.indexOf(i);
                            if (idx !== -1) {
                                changed = true;
                                visibleInteractionsRef.current.splice(idx, 1);
                            }
                        }
                    }
                    if (changed) {
                        setVisibleInteractions([...visibleInteractionsRef.current]);
                        if (pause) {
                            player.pause();
                        }
                    }
                }
                player.on('timeupdate', listener);
                return () => {
                    player.off('timeupdate', listener);
                }
            }
        }, [interactions, player]);

        if (hasQuestions && !quiz.data?.attempt) {
            if (!quiz.data) {
                return <CircularProgress />;
            }
            return (
                <Box sx={{ maxWidth: '800px', mx: 'auto', my: 2, width: '100%' }}>
                    <AttemptCreator
                        init={quiz.data}
                        onCreated={data => quiz.setState(prev => ({ ...prev, data }))}
                        sessionEntry={session}
                    />
                </Box>
            );
        }

        return (
            <Box
                sx={{
                    width: '100%',
                    ...inline ? {} : {
                        mx: 'auto',
                        maxWidth: {
                            xs: '100%',
                            lg: 1200,
                            xl: 1400,
                        },
                    },
                    '& [data-simpatico-progress]': {
                        position: 'absolute',
                        top: '100%',
                        left: 0,
                        right: 0,
                        height: '.3em',
                    },
                    '& [data-simpatico-progress-segment]': {
                        position: 'absolute',
                        height: '100%',
                        backgroundColor: theme => theme.palette.success.light,
                    },
                    '& [data-question-markers]': {
                        position: 'absolute',
                        bottom: '100%',
                        left: 0,
                        right: 0,
                        height: '.5rem',
                    },
                    '& [data-question-marker]': {
                        position: 'absolute',
                        height: '100%',
                        width: '.5rem',
                        border: '1px solid #f5f5f5',
                        borderRadius: '50%',
                    },
                    '& [data-question-marker].completed': {
                        borderColor: theme => theme.palette.success.light,
                        backgroundColor: theme => theme.palette.success.light,
                    },
                }}
            >
                <Suspense fallback={<Skeleton variant="rectangular" sx={{ height: '100%', minHeight: 250, }} />}>
                    <Grid
                        container
                        sx={{
                            alignItems: {
                                md: 'strech',
                            },
                        }}
                    >
                        <Grid item xs={12} md={sidebarOpen && !sidebarWrap ? 8 : 12} position="relative">
                            <VideoPlayer
                                disableBigPlayButton={enableStartScreen}
                                dimension={content.dimension || defaultDimension || (window.location.href === 'about:blank' ? '16x9' : undefined)}
                                poster={posterUrl}
                                src={src}
                                onReady={setPlayer}
                                onLoadedMetadata={player => {
                                    setInitialized(true);
                                    const rec = getCurrentTracking(trackingRef.current);
                                    rec.duration = Math.floor(player.duration())
                                    if (rec.seconds.length < rec.duration) {
                                        rec.seconds = rec.seconds.concat(Array.from({ length: rec.duration - rec.seconds.length }, () => 0));
                                    }
                                    while (rec.seconds.length < rec.duration) {
                                        rec.seconds.push(0);
                                    }
                                    if (typeof rec.bookmark === 'number') {
                                        const startAt = Math.max(0, Math.min(rec.duration, rec.bookmark));
                                        player.currentTime(startAt);
                                    }
                                }}
                                onTimeUpdate={player => {
                                    const rec = getCurrentTracking(trackingRef.current);
                                    const currentSecond = Math.floor(player.currentTime());
                                    if (!rec.seconds[currentSecond]) {
                                        rec.seconds[currentSecond] = 1;
                                    }
                                    rec.bookmark = currentSecond;
                                }}
                                onPlay={() => {
                                    setSplashOpen(false);
                                    setPlaying(true);
                                }}
                                onPause={() => setPlaying(false)}
                                onEnd={async () => {
                                    if (quiz.data?.attempt) {
                                        if (quiz.data.attempt.status !== 'PUBLISHED') {
                                            await api.quiz.submitAttempt(session.entryId, quiz.data.attempt.id);
                                        }
                                        setResultsPage(true);
                                    }
                                }}
                                textTracks={textTracks}
                                enableSidebarButton={Boolean(sidebar)}
                                onSidebarToggle={() => setSidebarOpen(prev => !prev)}
                                sprites={sprites}
                            />
                            {
                                visibleInteractions.map(i => {

                                    const position = positionOptions.includes(i.position!) ? i.position : 'top-left';

                                    const offset = 40;
                                    const y = i.y ?? 0;
                                    const x = i.x ?? 0;

                                    const positionSx: SxProps<Theme> = {
                                        position: 'absolute',
                                        top: position?.startsWith('top-') ? offset : position === 'custom' ? (y + '%') : undefined,
                                        bottom: position?.startsWith('bottom-') ? offset : undefined,
                                        left: position?.endsWith('-left') ? offset : position === 'custom' ? (x + '%') : undefined,
                                        right: position?.endsWith('-right') ? offset : undefined,
                                    };

                                    if (i.button) {
                                        return (
                                            <Button
                                                key={i.id}
                                                sx={positionSx}
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    player?.pause();
                                                    setPopupInteraction(i)
                                                }}
                                            >
                                                <LayoutString text={i.title} />
                                            </Button>
                                        )
                                    }

                                    return (
                                        <RunningText
                                            key={i.id}
                                            sx={{
                                                ...positionSx,
                                                backdropFilter: 'blur(12px)',
                                                background: theme => theme.palette.mode === 'dark'
                                                    ? 'rgba(0, 0, 0, .3)'
                                                    : 'rgba(255, 255, 255, .5)',
                                                borderRadius: theme => theme.shape.borderRadius,
                                                p: 2,
                                                maxHeight: position === 'custom' ? (100 - y) + '%' : `calc(100% - ${offset * 2}px)`,
                                                maxWidth: position === 'custom' ? (100 - x) + '%' : `calc(100% - ${offset * 2}px)`,
                                                overflowY: 'auto',
                                            }}
                                        >
                                            <LayoutString html text={i.text} />
                                        </RunningText>
                                    );
                                })
                            }
                            {
                                splashOpen && (
                                    <Box
                                        sx={{
                                            backdropFilter: 'blur(25px)',
                                            background: theme => theme.palette.mode === 'dark'
                                                ? 'rgba(0, 0, 0, .3)'
                                                : 'rgba(255, 255, 255, .5)',
                                            borderRadius: theme => theme.shape.borderRadius,
                                            position: 'absolute',
                                            left: 40,
                                            right: 40,
                                            bottom: 40,
                                            p: 3,
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => player?.play()}
                                    >
                                        <Typography variant="h6" component="h4" gutterBottom>
                                            {contentTitle}
                                        </Typography>
                                        <Typography variant="subtitle2" color="text.secondary">
                                            {
                                                [
                                                    content.duration && StringUtil.formatDuration(content.duration),
                                                    minWatched > 0 && (
                                                        content.duration && minWatched >= content.duration
                                                            ? (
                                                                <Label key="minWatched" k="watch_entire_video_to_complete" />
                                                            ) : (
                                                                <Label
                                                                    key="minWatched"
                                                                    k="watch_x_to_complete"
                                                                    args={{
                                                                        duration: StringUtil.formatDuration(minWatched),
                                                                    }}
                                                                />
                                                            )
                                                    ),
                                                ].filter(Boolean).flatMap((n, i, a) => {
                                                    if (i < a.length - 1) {
                                                        return [n, ' | '];
                                                    }
                                                    return [n];
                                                })
                                            }
                                        </Typography>
                                        {
                                            content.description && (
                                                <RunningText html={content.description} />
                                            )
                                        }
                                    </Box>
                                )
                            }
                        </Grid>
                        {
                            sidebarOpen && sidebar
                        }
                    </Grid>
                </Suspense>
                {
                    !showQuestionsInSidebar && (
                        <ModalDialog open={!!questions}>
                            {questions}
                        </ModalDialog>
                    )
                }
                {
                    !!quiz.data?.attempt && (
                        <ModalDialog
                            open={resultPage}
                            onClose={() => setResultsPage(false)}
                            title={<Label k="result" />}
                        >
                            {
                                quiz.data.attempt.status === 'PUBLISHED' ? (
                                    <>
                                        {
                                            quiz.data.canCreateAttempt && (
                                                <AttemptCreator
                                                    init={quiz.data}
                                                    onCreated={data => {
                                                        setResultsPage(false);
                                                        quiz.setState({ data, loading: false });
                                                        player?.currentTime(0);
                                                    }}
                                                    sessionEntry={session}
                                                />
                                            )
                                        }
                                        <AttemptResults attempt={quiz.data.attempt} />
                                    </>
                                ) : (
                                    <Box sx={{ textAlign: 'center' }}>
                                        <Typography variant="body1" gutterBottom>
                                            <Label
                                                k="question_review_description"
                                                args={{
                                                    answered: quiz.data?.attempt.questions.filter(q => q.response).length ?? 0,
                                                    count: quiz.data?.attempt.questions.length ?? 0,
                                                }}
                                            />
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={quiz.loading}
                                            onClick={async () => {
                                                quiz.setState(prev => ({ ...prev, loading: true }));
                                                const data = await api.quiz.submitAttempt(session.entryId, quiz.data!.attempt!.id);
                                                quiz.setState({ data, loading: false });
                                            }}
                                        >
                                            <Label k="submit" />
                                        </Button>
                                    </Box>
                                )
                            }
                        </ModalDialog>
                    )
                }
                <ModalDialog
                    open={!!popupInteraction}
                    title={popupInteraction && <LayoutString text={popupInteraction.title} />}
                    onClose={() => setPopupInteraction(null)}
                    maxWidth="md"
                >
                    {
                        popupInteraction && (
                            <RunningText>
                                <LayoutString html text={popupInteraction.text} />
                            </RunningText>
                        )
                    }
                </ModalDialog>
            </Box>
        );
    }
};

interface TranscriptLoaderProps {
    src: string;
    player: videojs.Player;
    contributors?: ContentContributorData[];
    disableNav?: boolean;
    downloadUrl?: string;
    maxHeight?: string | number;
}

function TranscriptLoader({ src, maxHeight = 'none', ...rest }: TranscriptLoaderProps) {
    const { data } = useResolver(useCallback(async () => await fetch(src).then(r => r.text()), [src]));
    if (!data) return <CircularProgress />;
    return (
        <InteractiveTranscript
            {...rest}
            vtt={data}
            sx={{
                maxHeight: {
                    xs: '280px',
                    md: maxHeight,
                },
            }}
        />
    );
}
