import { Input } from "react-ts-form";

import TextInput from "../../form/inputs/TextInput";
import Label from "../../core/Label";
import SwitchInput from "../../form/inputs/SwitchInput";
import NumberInput from "../../form/inputs/NumberInput";
import RadioChoiceInput from "../../form/inputs/RadioChoiceInput";
import { Shuttle } from "../../types";
import LangStringInput from "../../form/inputs/LangStringInput";
import DropDownChoiceInput from "../../form/inputs/DropDownChoiceInput";
import SearchParams from "../SearchParams";

export default class QuizModeData {

    public id!: number;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="name" />,
            required: true,
        }
    })
    public name!: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="externalId" />
        },
        inputProps: {
            maxLength: 128
        }
    })
    public externalId?: string;

    @Input({
        component: LangStringInput,
        meta: {
            title: <Label k="displayName" />
        },
    })
    public displayName!: Shuttle.LangString

    @Input({
        component: LangStringInput,
        meta: {
            title: <Label k="description" />
        },
        inputProps: {
            type: 'richText' as const,
        },
    })
    public description?: Shuttle.LangString

    @Input((_, { parent }) => ({
        component: DropDownChoiceInput,
        meta: {
            title: 'Type',
            required: true,
            disabled: !!parent.id
        },
        inputProps: {
            options: ['QUIZ', 'SURVEY'],
        },
    }))
    public type: 'QUIZ' | 'SURVEY' = 'QUIZ'

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="shuffleQuestions" />
        }
    })
    public shuffleQuestions?: boolean;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="shuffleOptions" />
        }
    })
    public shuffleOptions?: boolean;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="remediation" />
        }
    })
    public remediation?: boolean;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="allowSkip" />
        }
    })
    public allowSkip?: boolean;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="allowPrevious" />
        }
    })
    public allowPrevious?: boolean;

    @Input({
        component: NumberInput,
        meta: {
            title: <Label k="maxQuestionAttempts" />
        },
        inputProps: {
            min: 0
        }
    })
    public maxQuestionAttempts?: number;

    @Input({
        component: NumberInput,
        meta: {
            title: <Label k="questionCount" />
        },
        inputProps: {
            min: 0
        }
    })
    public questionCount?: number;

    @Input({
        component: NumberInput,
        meta: {
            title: <Label k="passScore" />
        },
        inputProps: {
            float: true,
            min: 0,
            max: 100
        }
    })
    public passScore?: number;

    @Input({
        component: RadioChoiceInput.ofType<string>(),
        meta: {
            title: <Label k="navigation" />
        },
        inputProps: {
            options: ['DEFAULT', 'SINGLE_PAGE', 'CUSTOM']
        }
    })
    public navigation?: string;
    
    @Input({
        component: LangStringInput,
        meta: {
            title: <Label k="passedMessage" />
        },
        inputProps: {
            type: 'richText',
        },
    })
    public passedMessage?: Shuttle.LangString

    @Input({
        component: LangStringInput,
        meta: {
            title: <Label k="failedMessage" />
        },
        inputProps: {
            type: 'richText',
        },
    })
    public failedMessage?: Shuttle.LangString

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="allow_question_review" />
        }
    })
    public allowQuestionReview?: boolean;

    @Input({
        component: SwitchInput,
        meta: {
            title: "Question Statistics",
        },
    })
    public questionStatistics?: boolean;

}

export class QuizModeSearchParams extends SearchParams {

    @Input({
        component: DropDownChoiceInput,
        meta: {
            title: <Label k="type" />
        },
        inputProps: {
            options: ['QUIZ', 'SURVEY'],
        },
    })
    public type?: 'QUIZ' | 'SURVEY';

}
