import { IInputProps } from "react-ts-form";
import { ValidLabelKey } from "../core/localization/ValidLabelKey";
import QuestionModel from "../model/quiz/QuestionModel";
import { QuizAttemptQuestionData } from "../model/quiz/QuizNavigationData";
import { Class } from "../util/Class";
import Registry from "../util/Registry";

export interface QuestionTypeComponentProps<M extends QuestionModel, R> extends IInputProps<R> {
    model: M;
    showCorrectAnswer: boolean;
    stats?: QuizAttemptQuestionData<M, R>['stats']
    type: 'QUIZ' | 'SURVEY'
}

export interface IQuestionType<T extends QuestionModel, R> {

    name: string | ValidLabelKey;

    Component: React.ComponentType<QuestionTypeComponentProps<T, R>>;
    
    clazz: Class<T>;
    
}

export const QuestionTypeRegistry = new Registry<IQuestionType<any, any>>();
