import { useMemo } from "react";
import { DatePicker, DateTimePicker } from '@mui/lab';
import { IInputProps } from "react-ts-form";
import { parseDate } from "../../hooks/useDate";
import { useThemeContext } from "../../core/ThemeProvider";
import { TextField } from "@mui/material";

interface IDateTimeInputProps extends IInputProps<string | number | null> {
    disablePast?: boolean;
    disableFuture?: boolean;
    format?: string;
    time?: boolean;
    valueType?: 'string' | 'number';
}

export default function DateTimeInput({ disabled, disablePast, disableFuture, id, onChange, time, value, valueType = typeof value === 'string' ? 'string' : 'number' }: IDateTimeInputProps) {
    const { timeZone } = useThemeContext();
    const dt = useMemo(() => parseDate(value, timeZone) || null, [value, timeZone]);

    const handleChange = (date: Date | null) => {
        onChange(date ? valueType === 'number' ? date.getTime() : date.toISOString() : null);
    };

    if (time) {
        return (
            <DateTimePicker
                value={dt}
                onChange={handleChange}
                ampm
                disabled={disabled}
                disableFuture={disableFuture}
                disablePast={disablePast}
                renderInput={params => <TextField {...params} id={id} />}
            />
        );
    }

    return (
        <DatePicker 
            value={dt}
            onChange={handleChange}
            disabled={disabled}
            disableFuture={disableFuture}
            disablePast={disablePast}
            renderInput={params => <TextField {...params} id={id} />}
        />
    );
}
