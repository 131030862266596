import { Shuttle } from "../types";

class ContentRequiredCondition {

}

const contentRequiredCondition: Shuttle.Condition<ContentRequiredCondition> = {
    name: 'condition_content_required',
    ConfigClass: ContentRequiredCondition,
    evaluate: (_, { contentNode }) => Boolean(contentNode.required),
}

export default contentRequiredCondition;
