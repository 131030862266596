import { Box, Typography } from "@mui/material";
import Axios from "axios";
import { Input } from "react-ts-form";
import { createComponentSection } from "../../core/admin/AdminSection";
import Label from "../../core/Label";
import DecoratorForm from "../../form/DecoratorForm";
import TextInput from "../../form/inputs/TextInput";


class ClearCache {

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="name" />,
            required: true,
        }
    })
    public name!: string;

}

export default createComponentSection({

    component: function Runtime() {

        return (
            <Box>
                
                <Box component="fieldset">
                    <Typography component="legend" variant="h5">
                        <Label k="clear_cache" />
                    </Typography>
                    <DecoratorForm
                        clazz={ClearCache}
                        onSubmit={async ({ name }) => {

                            await Axios.delete('/admin/caches/' + name);

                            alert('submitted cache clear request for: ' + name);

                            return { name };
                        }}
                    />
                </Box>

            </Box>
        );
    }
});
