import axios from "axios";
import ResponseData from "../model/ResponseData";
import SearchParams from "../model/SearchParams";
import SearchResult from "../model/SearchResult";

import TenantInfo from "../model/TenantInfo";
import UserData from "../model/UserData";
import { Shuttle } from "../types";
import createEntityService from "./createEntityService";
import { Consumer } from "../model/Consumer";

const TenantService = {
    ...createEntityService<TenantInfo>({ path: '/tenants' }),

    saveSettings: async (info: TenantInfo) => axios.post<Shuttle.ResponseData<TenantInfo>>('/current-tenant', info).then(res => res.data),

    searchUsers: async (params: SearchParams, tenant: TenantInfo) => axios.get<ResponseData<SearchResult<UserData>>>('/tenants/' + tenant.id + '/users', { params }).then(res => res.data),

    getUser: async (id: number, tenant: TenantInfo) => axios.get<ResponseData<UserData>>('/tenants/' + tenant.id + '/users/' + id).then(res => res.data),

    createUser: async (data: UserData, tenant: TenantInfo) => axios.post<ResponseData<UserData>>('/tenants/' + tenant.id + '/users', data).then(res => res.data),

    updateUser: async (data: UserData, tenant: TenantInfo) => axios.patch<ResponseData<UserData>>('/tenants/' + tenant.id + '/users/' + data.id, data).then(res => res.data),

    searchConsumers: async (tenantId: number, params: Partial<SearchParams> & { defaultOnly?: boolean; }) => await axios
        .get<ResponseData<SearchResult<Consumer>>>(`/tenants/${tenantId}/consumers`, { params })
        .then(r => r.data),
};

export default TenantService;
