import Axios from "axios";
import EnrollmentData from "../model/EnrollmentData";
import EnrollmentSearchParams from "../model/EnrollmentSearchParams";
import EnrollmentQuizAttempt from "../model/quiz/EnrollmentQuizAttempt";
import ResponseData from "../model/ResponseData";
import SearchResult from "../model/SearchResult";
import { Shuttle } from "../types";
import BrowserUtil from "../util/BrowserUtil";
import createEntityService from "./createEntityService";

interface EnrollmentTree {
    content: Shuttle.ContentNode;
    enrollments: EnrollmentData[];
    state: { [name: string]: string[] };
}

const EnrollmentService = {

    ...createEntityService<EnrollmentData, EnrollmentData, EnrollmentData, EnrollmentSearchParams>({ path: '/enrollments' }),

    previewTree: (enrollmentId: number, params?: any) => Axios.get<EnrollmentTree>('/enrollments/' + enrollmentId + '/preview-tree', { params }).then(r => r.data),

    xApiList: (enrollmentId: number) => Axios.get<{ statement: any; createDate: string }[]>('/enrollments/' + enrollmentId + '/x-api/statements').then(r => r.data),

    searchQuizAttempts: (enrollmentId: number, params?: any) => Axios.get<ResponseData<SearchResult<EnrollmentQuizAttempt>>>('/enrollments/' + enrollmentId + '/quiz-attempts', { params }).then(r => r.data),

    downloadContentEnrollments: async (contentId: number, params: any) => {
        const r = await Axios.get('/contents/' + contentId + '/enrollments/download/csv', { responseType: 'blob', params });
        const dis = r.headers['content-disposition'];
        const idx = dis.indexOf('filename="');
        const name = idx > -1 ? dis.substring(idx + 10, dis.length - 1) : 'unknown';
        BrowserUtil.download(r.data, name, r.headers['content-type']);
    },

};

export default EnrollmentService;
