import { Input } from "react-ts-form";
import TextInput from "../form/inputs/TextInput";
import SwitchInput from "../form/inputs/SwitchInput";
import Label from "../core/Label";
import { Alert, Button } from "@mui/material";
import TextAreaInput from "../form/inputs/TextAreaInput";
import ThemeData from "./ThemeData";
import { AuthProviderInput, CollectorInput, LocaleMultiInput, ThemeInput } from "../form/common";
import { Header } from "./Webhook";
import DropDownChoiceInput from "../form/inputs/DropDownChoiceInput";
import CodeInput from "../form/inputs/CodeInput";
import { AuthProvider } from "./AuthProvider";
import Collector from "./Collector";

export class Extractor {

    @Input({
        component: TextInput,
        meta: {
            title: 'Profile Data Field Name',
            required: true,
        },
        inputProps: {
            maxLength: 255,
        },
    })
    public name!: string;

    @Input({
        component: TextInput,
        meta: {
            title: 'Path to Value in Response',
            required: true,
        },
        inputProps: {
            maxLength: 255,
        },
    })
    public value!: string;

}

class ProfileApiCall {

    @Input({
        component: TextInput,
        meta: {
            title: 'URL',
            required: true,
        },
    })
    public url: string = '';

    @Input({
        component: DropDownChoiceInput,
        meta: {
            title: 'Method',
            required: true,
        },
        inputProps: {
            options: ['GET', 'POST', 'PUT', 'PATCH'],
        },
    })
    public method: 'GET' | 'POST' | 'PUT' | 'PATCH' = 'GET';

    @Input({
        clazz: Header,
        meta: {
            title: 'Headers',
        },
        array: {
            remove: true,
            sort: true,
            addComponent: ({ onAdd }) => (
                <Button size="small" variant="contained" color="secondary" onClick={() => onAdd([{ name: '', value: '' }])}>
                    Add Header
                </Button>
            ),
        }
    })
    public headers: Header[] = [];

    @Input((_, { parent }) => {
        if (parent.method === 'GET') return {};
        return {
            component: CodeInput,
            meta: {
                title: 'Body',
                required: true,
            },
            inputProps: {
                language: 'handlebars',
            },
        };
    })
    public body?: string;

    @Input({
        clazz: Extractor,
        meta: {
            title: 'Extracted Values',
            required: true,
        },
        array: {
            remove: true,
            sort: true,
            addComponent: ({ onAdd }) => (
                <Button size="small" variant="contained" color="secondary" onClick={() => onAdd([{ name: '', value: '' }])}>
                    Add Extractor
                </Button>
            ),
        }
    })
    public extract: Header[] = [];

}

class ConsumerConfig {

    @Input({
        component: SwitchInput,
        meta: {
            title: 'Allow Self Registration',
            description: `
                When users are prompted to log into this consumer portal,
                they will be given the option to create a new account.
                Use this feature when you want to create a public portal
                not protected by your SSO.
            `,
        },
    })
    public allowSelfRegistration?: boolean;

    @Input({
        component: SwitchInput,
        meta: {
            title: 'Email as username',
            description: `
                When users create accounts via self registration,
                let them user their email address as their unique
                identifier.
            `
        },
    })
    public emailAsUsername?: boolean;

    @Input({
        component: SwitchInput,
        meta: {
            title: 'Use Access Codes for Login instead of Passwords',
            description: `
                Allow users to login by requesting
                a short-lived access code that is
                sent to their email account.
            `,
        },
    })
    public authCodeLogin?: boolean;

    @Input({
        component: SwitchInput,
        meta: {
            title: 'Anonymize Users',
            description: 'Do not capture user name, email, or phone.'
        },
    })
    public anonymizeUsers?: boolean;

    @Input({
        component: SwitchInput,
        meta: {
            title: 'Explicit Enrollment Lookup',
            description: `
                Create a new enrollment if the LMS provides
                empty suspend data on launch (AICC and SCORM).
                This can enable LMS-controlled retakes
                of courses, but also risks duplicate enrollments if
                the LMS does not properly supply suspend data on launch.
            `
        }
    })
    public explicitEnrollmentResolution = false;

    @Input({
        component: SwitchInput,
        meta: {
            title: 'Always Send Score On Completion',
            description: 'Use this if launching LMS requires a score to be posted even for non-scored content.',
        },
    })
    public alwaysSendScoreOnCompletion = false;

    @Input({
        component: TextAreaInput,
        meta: {
            title: 'Origin',
            description: `
                (optional) restrict launch origins to a list specified here (comma-separated, wildcard '*' supported).
                For example: https://*.example.com,https://something.example2.com
            `,
        },
    })
    public origin?: string;

     @Input({
        component: TextInput,
        meta: {
            title: <Label k="lti.shared-secret" />
        },
        fieldset: 'lti'
    })
    public sharedSecret!: string;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="lti-tc.outcomes-ext" />,
        },
        fieldset: 'lti'
    })
    public outcomesExt!: boolean;

    @Input({
        clazz: ProfileApiCall,
        meta: {
            title: 'Additional Profile Data Loaders',
            description: (
                <Alert severity="warning">
                    This allows you to configure additional API calls to
                    make during authentication to load more profile data
                    and map it into users' accounts. NOTE: if any calls
                    fail, the user will be blocked from entering.
                </Alert>
            ),
        },
        array: {
            sort: true,
            remove: true,
            addComponent: ({ onAdd }) => (
                <Button
                    variant="contained" 
                    color="secondary"
                    onClick={ () => onAdd([new ProfileApiCall()]) }>
                    Add API Call
                </Button>
            ),
        }
    })
    public profileDataLoaders?: ProfileApiCall[];

    @Input({
        component: TextInput,
        meta: {
            title: 'Logout Redirect',
        },
    })
    public logoutRedirect?: string;

}

export class Consumer {

    public id!: number;

    public uuid!: string;

    @Input({
        component: TextInput,
        meta: {
            title: 'Name',
            required: true,
        },
    })
    public name!: string;

    @Input({
        component: TextInput,
        meta: {
            title: 'External ID',
        },
        inputProps: {
            maxLength: 128,
        }
    })
    public externalId?: string;

    @Input({
        component: SwitchInput,
        meta: {
            title: 'Enabled',
        },
    })
    public enabled!: boolean;

    public default_!: boolean;
    
    @Input({
        clazz: ConsumerConfig,
    })
    public config!: ConsumerConfig;

    @Input({
        component: LocaleMultiInput,
        meta: {
            title: 'Languages',
        },
    })
    public locale?: string[];

    @Input({
        component: ThemeInput,
        meta: {
            title: <Label k="theme" />,
        },
    })
    public theme?: ThemeData;

    @Input({
        component: AuthProviderInput,
        meta: {
            title: 'Authentication',
            description: '(optional) require users to go through Single-Sign On to access this consumer portal.'
        },
        inputProps: {
            entityRemoveToken: true,
        },
    })
    public authProvider?: AuthProvider;

    @Input({
        component: CollectorInput,
        meta: {
            title: 'Collector',
            description: '(optional) require users to fill out custom profile information.'
        },
        inputProps: {
            entityRemoveToken: true,
        },
    })
    public collector?: Collector;

    public createDate!: string;
    public updateDate!: string;

    // read only
    public userCount!: number;
    public contentCount!: number;
    public enrollmentCount!: number;
    public completionCount!: number;
    public statsDate?: string;

}
