import dateFormat from "date-fns/locale/ko";
import { koKR } from "@mui/material/locale";
import dictionary from "./ko_KR.json";

import { ILocale } from "../Locale";

const ko_KR: ILocale = {
    rtl: false, // right to left
    dictionary,
    dateFormat,
    muiLocale: koKR
};

export default ko_KR;
//korean