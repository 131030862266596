import { Shuttle } from "../types";

import CardIcon from '@mui/icons-material/ViewDay';
import { Card, CardActionArea, CardActions, CardContent, CardMedia, useTheme } from "@mui/material";
import { Input } from "react-ts-form";
import SwitchInput from "../form/inputs/SwitchInput";
import Label from "../core/Label";
import { LayoutRegion, setElementData } from "../core/ui/Layout";
import { useContext } from "react";
import AssetUrlInput from "../form/inputs/AssetUrlInput";
import TextInput from "../form/inputs/TextInput";
import StringUtil from "../util/StringUtil";
import NumberInput from "../form/inputs/NumberInput";
import { ConstraintsContext, ContainerNavContext, ContentContext, SessionContext } from "../contexts";
import { getAlternative, isLocked } from "../player/utils";
import { launcherRegistry } from "../registries";
import { api } from "../player/api";
import { AppContext } from "../core/AppContext";
import Embed from "../launcher/Embed";

class CardConfig {

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="outlined" />
        }
    })
    public outlined?: boolean;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="picture" />
        }
    })
    public showIcon?: boolean;

    @Input({
        component: AssetUrlInput,
        meta: {
            title: <Label k="url" />
        }
    })
    public iconSrc?: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="dimension" />,
            description: 'e.g. 16x9'
        }
    })
    public iconAspectRatio?: string;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="use_content_picture" />
        }
    })
    public useContentPicture?: boolean;

    @Input({
        component: NumberInput,
        meta: {
            title: <Label k="spacing" />
        },
        inputProps: {
            min: 0,
            max: 10
        }
    })
    public spacing?: number;

    public elements?: string[];

    public actionElements?: string[];

}

const CardElement: Shuttle.LayoutElementType<CardConfig> = {
    name: 'layout_card',
    Icon: CardIcon,
    ConfigClass: CardConfig,
    Component: ({ config: { actionElements, elements = [], iconAspectRatio, iconSrc, outlined, showIcon, spacing = 0, useContentPicture, } }) => {
        const [appContext] = useContext(AppContext);
        const { session } = useContext(SessionContext);
        const navCtx = useContext(ContainerNavContext);
        const constraints = useContext(ConstraintsContext);
        const contentNode = useContext(ContentContext);
        const theme = useTheme();

        let icon = null;

        if (showIcon) {

            const content = contentNode && getAlternative(constraints, contentNode);

            let src = iconSrc || theme.pictureUrl;

            if (useContentPicture && content?.pictureUrl) {
                src = content.pictureUrl;
            }

            if (src) {
                icon = (
                    <CardMedia image={src} sx={{ height: 0, pt: (StringUtil.parseDimension(iconAspectRatio || '', 9 / 16) * 100) + '%' }} />
                );
            }

            if (useContentPicture && content && navCtx) {
                icon = (
                    <CardActionArea 
                        disabled={isLocked(constraints, contentNode)} 
                        onClick={async () => {
                            if (session && content.newWindow) {
                                const [width, height] = (content.dimension || '800x600').split('x');
                                const features = `popup,height=${height},width=${width}`;
                                if (launcherRegistry.get(content.launcher)?.Component === Embed) {
                                    const { uri } = await api.play.path(session.sessionId, content.path);
                                    window.open(uri, 'content_' + content.uuid, features);
                                } else {
                                    window.open(
                                        (appContext.tenant.basePath ?? '') + '/play/popup?path=' + encodeURIComponent(content.path),
                                        'content_' + content.uuid,
                                        features
                                    );
                                }
                                return;
                            }
                            navCtx.setActivePath?.(content.path)
                        }}
                    >
                        {icon}
                    </CardActionArea>
                );
            }
        }

        return (
            <Card
                sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
                variant={outlined ? 'outlined' : 'elevation'}
            >
                {icon}
                <CardContent sx={{ flexGrow: 1 }}>
                    <LayoutRegion keys={elements} spacing={spacing} />
                </CardContent>
                {
                    !!actionElements?.length && (
                        <CardActions>
                            <LayoutRegion keys={actionElements} />
                        </CardActions>
                    )
                }
            </Card>
        );
    },
    getRegions: (config, key) => [
        {
            title: <Label k="contents" />,
            key: 'content',
            keys: config.elements || [],
            setKeys: (layout, elements) => setElementData(layout, key, {...config, elements}),
        },
        {
            title: <Label k="actions" />,
            key: 'actions',
            keys: config.actionElements || [],
            setKeys: (layout, actionElements) => setElementData(layout, key, {...config, actionElements}),
        }
    ],
};

export default CardElement;
