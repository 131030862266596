import { IInputProps } from "react-ts-form";
import { Shuttle } from "../../types";
import { ElementAncestorContext, Layout, LayoutEditContext, setElementData } from "../../core/ui/Layout";
import { createElement, forwardRef, Fragment, ReactNodeArray, Suspense, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Alert, AppBar, Box, Button, Dialog, Divider, Grid, ListItemButton, ListItemIcon, ListItemText, Menu, ToggleButton, ToggleButtonGroup, Toolbar, Typography } from "@mui/material";
import Label from "../../core/Label";
import LabelledIconButton from "../../core/ui/LabelledIconButton";
import CloseIcon from "@mui/icons-material/Close";
import { CodeEditor } from "../../lazy";
import { TreeItemContentProps, useTreeItem, TreeItem, TreeView } from "@mui/lab";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DecoratorInput from "../DecoratorInput";
import { ThemeProvider } from "../../core/ThemeProvider";
import ThemeData from "../../model/ThemeData";
import AddIcon from "@mui/icons-material/Add";
import StringUtil from "../../util/StringUtil";
import clsx from "clsx";
import AlertIcon from "@mui/icons-material/Warning";
import ArrayItemToolbar from "../../core/ui/ArrayItemToolbar";
import { layoutElementRegistry } from "../../registries";
import { ConditionBuilderInput } from "./ConditionBuilderInput";
import AncestorProvider from "../../core/AncestorProvider";
import Constants from "../../util/Constants";
import LoopIcon from '@mui/icons-material/Loop';

interface ILayoutInputProps extends IInputProps<Shuttle.LayoutConfig> {
    readonly?: boolean;
}

interface EditRawProps {
    initialValue: Shuttle.LayoutConfig;
    onSubmit(value: Shuttle.LayoutConfig): void;
    selected?: string;
}

function EditRaw({ initialValue, onSubmit, selected }: EditRawProps) {
    const ref = useRef<Shuttle.CodeEditorControl>();

    const [{ json, error, changed }, setState] = useState({ json: "", error: false, changed: false });

    useEffect(() => {
        setState(prev => ({...prev, changed: false, json: JSON.stringify(initialValue, null, 2) }));
    }, [initialValue]);

    useEffect(() => {
        if (selected) {
            ref.current?.moveToText(`${selected}": {`); // auto-scroll to selected element's config
        }
    }, [selected]);
    
    return (
        <Suspense fallback={null}>
            <Box sx={{ p: 1 }}>
                <Button 
                    color={error ? 'error' : 'primary'}
                    disabled={!changed || error} 
                    fullWidth 
                    onClick={() => {
                        try {
                            const parsed = JSON.parse(json);
                            onSubmit(parsed);
                        } catch {
                            setState(prev => ({...prev, error: true}));
                        }
                    }}
                >
                    <Label k="save" />
                </Button>
            </Box>
            <CodeEditor 
                value={json}
                onChange={json => setState({ changed: true, error: false, json })}
                language="json"
                onReady={control => ref.current = control}
                height={1000}
            />
        </Suspense>
    );
}

function removeOrphans(layout: Shuttle.LayoutConfig) {
    let cleaned = layout;

    while (true) {

        // determine all layout element ids that are referenced at root level or by another element
        const referenced = [
            ...cleaned.root,
            ...Object.entries(cleaned.elements).reduce((a, [key, { id, data }]) => {
                const type = layoutElementRegistry.get(id);
                if (type && type.getRegions) {
                    const children = type.getRegions(data, key)?.reduce((list, item) => {
                        if (item.keys?.length) {
                            list.push(...item.keys);
                        }
                        return list;
                    }, [] as string[]);
                    if (children?.length) {
                        a.push(...children);
                    }
                }
                return a;
            }, [] as string[])
        ];

        const orphans = Object.keys(cleaned.elements).filter(uuid => !referenced.includes(uuid));

        if (!orphans.length) {
            break;
        }

        cleaned = {
            ...cleaned,
            elements: Object.entries(cleaned.elements).reduce((a, [uuid, el]) => {
                if (!orphans.includes(uuid)) {
                    a[uuid] = el;
                }
                return a;
            }, {} as any)
        }
    }

    return cleaned;
}

const CustomTreeItem = forwardRef(function CustomTreeItem({
    classes,
    className,
    label,
    nodeId,
    onClick,
    icon,
    expansionIcon,
    displayIcon,
}: TreeItemContentProps, ref) {

    icon = icon || expansionIcon || displayIcon;

    const {
        disabled,
        expanded,
        selected,
        focused,
        handleExpansion,
        handleSelection,
        preventSelection,
    } = useTreeItem(nodeId);

    return (
        <div 
            ref={ref as any}
            className={clsx(className, classes.root, {
                [classes.expanded]: expanded,
                [classes.selected]: selected,
                [classes.focused]: focused,
                [classes.disabled]: disabled,
            })}
            onMouseDown={preventSelection}
            onClick={onClick}
        >
            <div onClick={handleExpansion} className={classes.iconContainer}>
                {icon}
            </div>
            <div className={classes.label} onClick={onClick ? undefined : handleSelection}>
                {label}
            </div>
        </div>
    );
});

interface TreeRegionProps {
    layout: Shuttle.LayoutConfig;
    region: Shuttle.LayoutRegion;
    regionKey: string;
}

function TreeRegion({ layout, region, regionKey }: TreeRegionProps) {
    const ref = useRef<HTMLLIElement>(null);
    const [open, setOpen] = useState(false);
    const ctx = useContext(LayoutEditContext);
    const ancestors = useContext(ElementAncestorContext);

    if (!ctx) return null;

    return (
        <Fragment>
            {
                region.title && (
                    <Divider textAlign="left">
                        <Typography variant="overline">
                            {region.title}
                        </Typography>
                    </Divider>
                )
            }
            {
                region.keys?.map((key, index, arr) => {
                    const reactKey = key + '-' + index;

                    const target = layout?.elements[key];

                    if (!target) {
                        return (
                            <TreeItem 
                                key={reactKey}
                                ContentComponent={CustomTreeItem}
                                nodeId={key}
                                label={`Invalid target: ${key}`}
                                icon={<AlertIcon />}
                            />
                        );
                    }

                    const type = layoutElementRegistry.get(target.id);

                    if (!type) {
                        return (
                            <TreeItem 
                                key={reactKey}
                                ContentComponent={CustomTreeItem}
                                nodeId={key}
                                label={`Invalid element type: ${target.id}`}
                                icon={<AlertIcon />}
                            />
                        );
                    }

                    const recursive = ancestors.includes(key);

                    if (ancestors.includes(key)) {
                        return (
                            <TreeItem
                                key={reactKey}
                                ContentComponent={CustomTreeItem}
                                nodeId={key + '__ref'}
                                icon={<LoopIcon />}
                                label={
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography sx={{ flexGrow: 1 }}>
                                            <Label k={type.name} />
                                        </Typography>
                                        <div onClick={e => e.stopPropagation()}>
                                            <ArrayItemToolbar
                                                value={arr}
                                                index={index}
                                                onChange={nextKeys => ctx.setLayout(region.setKeys(layout, nextKeys))}
                                                size="small"
                                                disableTooltips
                                            />
                                        </div>
                                    </Box>
                                }
                                onClick={Constants.NO_OP_FUNC}
                            />
                        );
                    }

                    return (
                        <AncestorProvider context={ElementAncestorContext} value={key} key={reactKey}>
                            <TreeItem 
                                ContentComponent={CustomTreeItem}
                                nodeId={key}
                                label={
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography sx={{ flexGrow: 1 }}>
                                            <Label k={type.name} />
                                        </Typography>
                                        <div onClick={e => e.stopPropagation()}>
                                            <ArrayItemToolbar
                                                value={arr}
                                                index={index}
                                                onChange={nextKeys => ctx.setLayout(region.setKeys(layout, nextKeys))}
                                                size="small"
                                                disableTooltips
                                            />
                                        </div>
                                    </Box>
                                }
                            >
                                {
                                    !recursive && type.getRegions?.(target.data || {}, key)?.map(region => (
                                        <TreeRegion
                                            region={region}
                                            key={region.key}
                                            layout={layout}
                                            regionKey={regionKey + '_' + region.key}
                                        />
                                    ))
                                }
                            </TreeItem>
                        </AncestorProvider>
                    );
                })
            }
            <TreeItem 
                ContentComponent={CustomTreeItem}
                ref={ref}
                nodeId={regionKey + '__add'}
                onClick={() => setOpen(true)}
                label={<Label k="add" />}
                icon={<AddIcon />}
            />
            <Menu anchorEl={ref.current} open={open} onClose={() => setOpen(false)}>
                {
                    open && layoutElementRegistry.entries().map(([id, type]) => (
                        <ListItemButton 
                            key={id} 
                            dense
                            onClick={() => {
                                const key = StringUtil.uuid();
                                const data = new type.ConfigClass();
                                setOpen(false);
                                ctx.setSelected([key]);
                                ctx.setLayout(region.setKeys({
                                    ...layout || {},
                                    elements: {
                                        ...layout?.elements || {},
                                        [key]: { id, data }
                                    }
                                }, [...region.keys || [], key]));
                            }}
                        >
                            <ListItemIcon>
                                {createElement(type.Icon)}
                            </ListItemIcon>
                            <ListItemText primary={<Label k={type.name} />} />
                        </ListItemButton>
                    ))
                }
            </Menu>
        </Fragment>
    );
}

export function LayoutInput({ disabled, title, value, onChange }: ILayoutInputProps) {

    const timer = useRef<any>();
    const [mode, setMode] = useState<null | 'editor' | 'json' | 'preview'>(null);
    const [selected, setSelected] = useState<string[]>([]);
    const [expanded, setExpanded] = useState<string[]>([]);
    const [theme] = useState<ThemeData | null>(null);

    useEffect(() => () => {
        if (timer.current) {
            clearTimeout(timer.current);
        }
    }, []);

    const ctx = useMemo(() => ({ 
        selected, 
        setSelected, 
        setLayout: (next: Shuttle.LayoutConfig) => {
            onChange(next);
            if (timer.current) {
                clearTimeout(timer.current);
            }
            timer.current = setTimeout(() => {
                const cleaned = removeOrphans(next);
                if (cleaned !== next) {
                    onChange(cleaned);
                }
            }, 500);
        }
    }), [selected, onChange]);

    const handleClose = () => setMode(null);

    return (
        <Fragment>
            <Button disabled={disabled} onClick={() => setMode('editor')}>
                <Label k="edit" />
            </Button>
            {
                mode && (
                    <Dialog
                        fullScreen
                        open={Boolean(mode)}
                        onClose={handleClose}
                    >
                        <LayoutEditContext.Provider value={mode === 'preview' ? null : ctx}>
                            <AppBar sx={{ position: 'relative' }}>
                                <Toolbar>
                                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                        {title || <Label k="layout" />}
                                    </Typography>
                                    <ToggleButtonGroup color="standard" exclusive size="small" value={mode} onChange={(_, mode) => setMode(mode)}>
                                        <ToggleButton value="editor">
                                            <Label k="edit" />
                                        </ToggleButton>
                                        <ToggleButton value="json">
                                            <Label k="json" />
                                        </ToggleButton>
                                        <ToggleButton value="preview">
                                            <Label k="preview" />
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                    <LabelledIconButton 
                                        k="close"
                                        icon={CloseIcon}
                                        onClick={handleClose}
                                        color="inherit"
                                    />
                                </Toolbar>
                            </AppBar>
                            <Grid container alignItems="stretch" sx={{ flexGrow: 1 }}>
                                {
                                    mode !== 'preview' && (
                                        <Fragment>
                                            <Grid item xs={12} lg>
                                                <TreeView 
                                                    defaultCollapseIcon={<ExpandMoreIcon />}
                                                    defaultExpandIcon={<ChevronRightIcon />}
                                                    expanded={expanded}
                                                    multiSelect
                                                    selected={ctx.selected}
                                                    onNodeToggle={(_, next) => setExpanded(next)}
                                                    onNodeSelect={(_, next) => ctx.setSelected(next)}
                                                >
                                                    <TreeRegion 
                                                        layout={value} 
                                                        region={{ 
                                                            keys: value?.root || [],
                                                            key: 'root',
                                                            setKeys: (layout, root) => ({...layout, root}),
                                                        }}
                                                        regionKey="root"
                                                    />
                                                </TreeView>
                                            </Grid>
                                            <Divider orientation='vertical' />
                                        </Fragment>
                                    )
                                }
                                {
                                    mode !== 'json' && (
                                        <Grid item xs={12} lg={mode === 'preview' ? 12 : 6}>
                                            <ThemeProvider theme={theme || undefined}>
                                                <Layout layout={value} />
                                            </ThemeProvider>
                                        </Grid>
                                    )
                                }
                                {
                                    mode !== 'preview' && (
                                        <Fragment>
                                            <Divider orientation='vertical' />
                                            <Grid item xs={12} lg={mode === 'json' ? 9 : true}>
                                                {
                                                    mode === 'json' ? (
                                                        <EditRaw initialValue={value} onSubmit={onChange} selected={selected[0]} />
                                                    ) : (
                                                        Boolean(selected.length) && selected.map((key, index) => {

                                                            const data = value?.elements?.[key];

                                                            if (!data) {
                                                                return <Fragment key={key} />
                                                            }

                                                            const type = layoutElementRegistry.get(data.id);

                                                            if (!type) {
                                                                return (
                                                                    <Alert key={key} severity='warning' sx={{ my: 2 }}>
                                                                        {'invalid type: ' + data.id}
                                                                    </Alert>
                                                                );
                                                            }
                                                            
                                                            return (
                                                                <Fragment key={key}>
                                                                    {
                                                                        index > 0 && (
                                                                            <Divider variant="middle" />
                                                                        )
                                                                    }
                                                                    <Box sx={{ p: 1 }}>
                                                                        <Typography color='primary' gutterBottom>
                                                                            <Label k={type.name} />
                                                                            {`(${key})`}
                                                                        </Typography>
                                                                        <DecoratorInput 
                                                                            key={key}
                                                                            value={data.data}
                                                                            onChange={next => onChange(setElementData(value, key, next))}
                                                                            clazz={type.ConfigClass}
                                                                        />
                                                                        <Divider />
                                                                        <ConditionBuilderInput
                                                                            value={data.condition as any}
                                                                            onChange={condition => onChange({
                                                                                ...value,
                                                                                elements: {
                                                                                    ...value.elements,
                                                                                    [key]: {
                                                                                        ...value.elements[key],
                                                                                        condition,
                                                                                    },
                                                                                }
                                                                            })}
                                                                            nullable
                                                                        />
                                                                    </Box>
                                                                </Fragment>
                                                            );
                                                        }, [] as ReactNodeArray)
                                                    )
                                                }
                                            </Grid>
                                        </Fragment>
                                    )
                                }
                            </Grid>
                        </LayoutEditContext.Provider>
                    </Dialog>
                )
            }
            
        </Fragment>
    );
}
