import * as React from "react";
import { Box, IconButton, IconButtonProps, Tooltip } from "@mui/material";

import RemoveIcon from '@mui/icons-material/Delete';
import SortUpIcon from "@mui/icons-material/ArrowUpward";
import SortDownIcon from "@mui/icons-material/ArrowDownward";

import IControlledProps from "../../model/IControlledProps";
import CollectionsUtil from "../../util/ArrayUtil";
import useString from "../../hooks/useString";

interface IArrayItemToolbarProps<T> extends IControlledProps<T[]> {
    index: number;
    size?: IconButtonProps["size"];
    disableTooltips?: boolean;
}

export default function ArrayItemToolbar<T = any>(props: React.PropsWithChildren<IArrayItemToolbarProps<T>>) {
    const removeLabel = useString('remove');
    const moveUpLabel = useString('move.up');
    const moveDownLabel = useString('move.down');

    const moveUpDisabled = props.index < 1;
    const moveDownDisabled = props.index >= props.value.length - 1;

    let moveUp = (
        <IconButton 
            aria-label={moveUpLabel}
            disabled={moveUpDisabled} 
            onClick={() => props.onChange(CollectionsUtil.move([...props.value], props.index, props.index - 1))}
            size={props.size}
        >
            <SortUpIcon color="inherit" fontSize="inherit" />
        </IconButton>
    );
    if (moveUpLabel && !moveUpDisabled && !props.disableTooltips) {
        moveUp = (
            <Tooltip title={moveUpLabel}>
                {moveUp}
            </Tooltip>
        )
    }

    let moveDown = (
        <IconButton
            aria-label={moveDownLabel}
            disabled={moveDownDisabled}
            onClick={() => props.onChange(CollectionsUtil.move([...props.value], props.index, props.index + 1))}
            size={props.size}
        >
            <SortDownIcon color="inherit" fontSize="inherit" />
        </IconButton>
    );
    if (moveDownLabel && !moveDownDisabled && !props.disableTooltips) {
        moveDown = (
            <Tooltip title={moveDownLabel}>
                {moveDown}
            </Tooltip>
        );
    }

    let remove = (
        <IconButton
            aria-label={removeLabel}
            onClick={() => props.onChange(props.value.filter((_, i) => i !== props.index))}
            size={props.size}
        >
            <RemoveIcon color="inherit" fontSize="inherit" />
        </IconButton>
    );
    if (removeLabel && !props.disableTooltips) {
        remove = (
            <Tooltip title={removeLabel}>
                {remove}
            </Tooltip>
        );
    }
    
    return (
        <Box display="inline-block">
            {props.children}
            {moveUp}
            {moveDown}
            {remove}
        </Box>
    );
}
