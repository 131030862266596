import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { Alert, Box, FormControlLabel, IconButton, InputAdornment, Stack, Switch, SxProps, TextField, Typography, alpha } from "@mui/material";
import { parse as parseVtt } from '@plussub/srt-vtt-parser';
import StringUtil from "../../util/StringUtil";
import BrowserUtil from "../../util/BrowserUtil";
import ContentContributorData from "../../model/ContentContributorData";
import { Entry } from "@plussub/srt-vtt-parser/dist/src/types";
import Label from "../../core/Label";
import Search from "@mui/icons-material/Search";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import Close from "@mui/icons-material/Close";
import LabelledIconButton from "../../core/ui/LabelledIconButton";
import Download from "@mui/icons-material/Download";

export interface InteractiveTranscriptProps {
    contributors?: ContentContributorData[];
    disableNav?: boolean;
    player: videojs.VideoJsPlayer;
    sx?: SxProps
    vtt: string;
    downloadUrl?: string;
}

export function InteractiveTranscript({ contributors, disableNav = false, downloadUrl, player, sx, vtt }: InteractiveTranscriptProps) {

    const { data, error } = useMemo(() => {
        try {
            const data = parseVtt(vtt + '\n').entries.map((e, i) => {
                if (!e.id)
                    e.id = `__${i}__`;
                e.text = e.text.trim();
                e.from = Math.floor(e.from / 1000);
                e.to = Math.floor(e.to / 1000);
                return e;
            });
            return { data, error: undefined };
        } catch (error) {
            return {
                data: [] as Entry[],
                error,
            };   
        }
    }, [vtt]);

    const transcriptRef = useRef<HTMLDivElement>(null);

    const [autoScroll, setAutoScroll] = useState(true);

    const [active, setActive] = useState<undefined | number>(undefined);

    useEffect(() => {
        if (!data)
            return;
        function handleTimeUpdate(this: videojs.VideoJsPlayer) {
            const ts = this.currentTime();
            const i = data!.findIndex(x => x.from <= ts && x.to >= ts);
            setActive(i > -1 ? i : undefined);
        }
        player.on('timeupdate', handleTimeUpdate);
        return () => {
            player.off('timeupdate', handleTimeUpdate);
        };
    }, [player, data]);

    const [search, setSearch] = useState('');
    const [searchResult, setSearchResult] = useState<null | { search: string; matches: string[]; cycle: number; }>(null);

    const transcript = useMemo(() => {

        return data?.reduce((result, { id, from, to, text }, index) => {
            if (text.startsWith('<v ')) {
                const idx = text.indexOf('>');
                if (idx > -1) {
                    const speakerId = text.substring(3, idx).trim();
                    if (result.lastSpeakerId !== speakerId) {
                        const speaker = contributors?.find(c => c.contributor.externalId === speakerId);
                        result.items.push(
                            <Typography key={id + '__spkr'} variant="subtitle1" sx={{ px: 1 }}>
                                {speaker?.contributor.name ?? speakerId}
                            </Typography>
                        );
                    }
                    result.lastSpeakerId = speakerId;
                }
                text = text.substring(idx + 1, text.endsWith('</v>') ? text.length - 4 : text.length);
            }

            result.items.push(
                <button
                    data-cue-id={id}
                    data-cue-idx={index}
                    data-cue-from={from}
                    data-cue-to={to}
                    className={active === index ? 'active' : ''}
                    key={id}
                    type="button"
                >
                    <span className="timestamp">
                        {StringUtil.formatDuration(from)}
                    </span>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: searchResult?.matches.includes(id)
                                ? StringUtil.highlightSearch(
                                    text,
                                    searchResult.search,
                                    searchResult.matches[searchResult.cycle] === id ? 'match current' : 'match'
                                )
                                : text,
                        }}
                    />
                </button>
            );

            return result;
        }, {
            items: [] as ReactNode[],
            lastSpeakerId: undefined as (undefined | string),
        }).items;

    }, [data, active, contributors, searchResult]);

    useEffect(() => {
        if (transcriptRef.current && searchResult?.matches.length) {
            const id = searchResult.matches[searchResult.cycle];
            const firstMatch = transcriptRef.current?.querySelector<HTMLElement>(`[data-cue-id='${id}']`);
            if (firstMatch) {
                BrowserUtil.scrollElementTo(transcriptRef.current, Math.max(0, firstMatch.offsetTop - 10));
            }
        }
    }, [searchResult]);

    useEffect(() => {
        if (transcriptRef.current && typeof active === 'number' && autoScroll) {
            const firstMatch = transcriptRef.current?.querySelector<HTMLElement>(`[data-cue-idx='${active}']`);
            if (firstMatch) {
                BrowserUtil.scrollElementTo(transcriptRef.current, Math.max(0, firstMatch.offsetTop - 10));
            }
        }
    }, [active, autoScroll]);

    if (error) {
        return (
            <Alert severity="warning">
                Invalid Transcript File
            </Alert>
        );
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                ...sx,
            }}
        >
            <Stack
                component="form"
                onSubmit={(e: any) => {
                    e.preventDefault();
                    setAutoScroll(false);
                    if (search) {
                        if (search === searchResult?.search) {
                            setSearchResult({
                                ...searchResult,
                                cycle: searchResult.cycle < searchResult.matches.length - 1
                                    ? searchResult.cycle + 1
                                    : 0,
                            });
                        } else {
                            const s = search.toLowerCase();
                            const matches = data
                                .filter(e => e.text.toLowerCase().includes(s))
                                .map(e => e.id);
                            setSearchResult({
                                search,
                                matches,
                                cycle: 0,
                            });
                        }
                    } else {
                        setSearchResult(null);
                    }
                }}
                alignItems="center"
                direction="row"
                spacing={1}
                pt={2}
                pb={1}
                px={1}
            >
                <TextField
                    label={<Label k="search" />}
                    size="small"
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton type="submit" size="small">
                                    <Search />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                {
                    searchResult && (
                        searchResult.matches.length
                            ? (
                                <>
                                    <Typography variant="body2">
                                        {`${searchResult.cycle + 1} / ${searchResult.matches.length}`}
                                    </Typography>
                                    <IconButton
                                        size="small"
                                        onClick={() => setSearchResult({
                                            ...searchResult,
                                            cycle: searchResult.cycle > 0 ? searchResult.cycle - 1 : searchResult.matches.length - 1,
                                        })}
                                    >
                                        <ArrowUpward fontSize="inherit" />
                                    </IconButton>
                                    <IconButton
                                        size="small"
                                        onClick={() => setSearchResult({
                                            ...searchResult,
                                            cycle: searchResult.cycle < searchResult.matches.length - 1 ? searchResult.cycle + 1 : 0,
                                        })}
                                    >
                                        <ArrowDownward fontSize="inherit" />
                                    </IconButton>
                                    <IconButton
                                        size="small"
                                        onClick={() => {
                                            setSearch('');
                                            setSearchResult(null);
                                            setAutoScroll(true);
                                        }}
                                    >
                                        <Close fontSize="inherit" />
                                    </IconButton>
                                </>
                            ) : (
                                <Typography variant="body2" color="text.secondary">
                                    <Label k="no_results" />
                                </Typography>
                            )
                    )
                }
            </Stack>
            <Box
                onClick={e => {
                    if (!disableNav && e.target) {
                        let el: HTMLElement | null = e.target as HTMLElement;
                        while (el && !el.hasAttribute('data-cue-from')) {
                            el = el.parentElement;
                        }
                        if (el) {
                            const from = parseInt(el.getAttribute('data-cue-from') ?? '', 10);
                            if (!isNaN(from)) {
                                player.currentTime(from);
                                player.play();
                                setAutoScroll(true);
                            }
                        }
                    }
                }}
                sx={{
                    overflowY: 'auto',
                    flexGrow: 1,
                    minWidth: '100%',
                    position: 'relative',
                    borderWidth: '1px 0',
                    borderStyle: 'solid',
                    borderColor: theme => theme.palette.divider,
                    '& button': {
                        appearance: 'none',
                        border: 0,
                        borderRadius: theme => theme.shape.borderRadius,
                        mx: 1,
                        p: 1,
                        textAlign: 'left',
                        background: 'transparent',
                        color: 'inherit',
                        display: 'flex',
                        flexDirection: 'row',
                        cursor: disableNav ? undefined : 'pointer',
                        boxSizing: 'border-box',
                    },
                    py: 1,
                    '& button:hover': {
                        background: disableNav 
                            ? undefined 
                            : theme => theme.palette.mode === 'dark'
                                ? theme.palette.grey[900]
                                : theme.palette.grey[100],
                    },
                    '& button > .timestamp': {
                        mr: 1,
                        minWidth: 32,
                        color: theme => theme.palette.text.secondary,
                        fontSize: theme => theme.typography.caption.fontSize,
                    },
                    '& button.active': {
                        background: theme => alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
                    },
                    '& .match.current': {
                        color: theme => theme.palette.primary.main,
                    },
                }}
                ref={transcriptRef}
            >
                {transcript}
            </Box>
            <Stack direction="row" alignItems="center" spacing={1} p={1}>
                {
                    downloadUrl && (
                        <div>
                            <LabelledIconButton
                                k="download"
                                icon={Download}
                                onClick={() => {
                                    window.open(downloadUrl, '_blank');
                                }}
                                size="small"
                            />
                        </div>
                    )
                }
                <div style={{ flexGrow: 1 }} />
                <FormControlLabel
                    control={
                        <Switch
                            checked={autoScroll}
                            onChange={e => setAutoScroll(e.target.checked)}
                            size="small"
                        />
                    }
                    label={<Label k="auto_scroll" />}
                />
            </Stack>
        </Box>
    );
}
