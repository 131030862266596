import { Input } from "react-ts-form";

import Label from "../core/Label";
import DropDownChoiceInput from "../form/inputs/DropDownChoiceInput";
import RichTextInput from "../form/inputs/RichTextInput";
import TextInput from "../form/inputs/TextInput";

export default class GlossaryTermData {

    public id!: number;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="name" />
        }
    })
    public name!: string;

    @Input({
        component: DropDownChoiceInput,
        meta: {
            title: <Label k='locale' />,
            required: true,
        },
        inputProps: {
            options: ['en', 'es'],
        },
    })
    public locale: string = 'en'

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="alternatives" />
        }
    })
    public alternatives?: string;

    @Input({
        component: RichTextInput,
        meta: {
            title: <Label k="definition" />
        }
    })
    public definition!: string;

}
