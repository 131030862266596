import dateFormat from "date-fns/locale/zh-CN";
import { zhCN } from "@mui/material/locale";
import dictionary from "./zh_CN.json";

import { ILocale } from "../Locale";

const zh_CN: ILocale = {
    rtl: false, // right to left
    dictionary, 
    dateFormat,
    muiLocale: zhCN
};

export default zh_CN;
//chinese(simplified)