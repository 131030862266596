import { Shuttle } from "../../types";
import LtiIcon from "@mui/icons-material/School";
import Embed from "../Embed";
import { Fieldsets, Input } from "react-ts-form";
import Label from "../../core/Label";
import TextInput from "../../form/inputs/TextInput";

@Fieldsets({
    ltisettings: <Label k="ltisettings" />
})
class LtiSettings {

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="lti-cp.link.id" />
        },
        fieldset: 'ltisettings'
    })
    public contentId?: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="url" />
        },
        fieldset: 'ltisettings'
    })
    public uri!: string;
}

export const LtiToolLauncher: Shuttle.Launcher = {
    name: 'launcher.lti.tool',
    Icon: LtiIcon,
    Component: Embed,
    ConfigClass: LtiSettings,
    hasSystem: true,
};