import { Shuttle } from "../types";

import TabsIcon from "@mui/icons-material/Tab";
import TabView, { ITab } from "../core/ui/TabView";
import { Input } from "react-ts-form";
import SwitchInput from "../form/inputs/SwitchInput";
import Label from "../core/Label";
import { LayoutRegion, setElementData } from "../core/ui/Layout";
import { Button, Theme, useMediaQuery } from "@mui/material";
import StringUtil from "../util/StringUtil";
import LayoutString from "./LayoutString";
import { ConditionBuilderInput } from "../form/inputs/ConditionBuilderInput";
import { useContext, useMemo } from "react";
import { ConstraintsContext, ContentContext, GlossaryContext, SessionContext, SettingsContext } from "../contexts";
import evaluateCondition from "../conditions/evaluateCondition";
import { getAlternative } from "../player/utils";
import LangStringInput from "../form/inputs/LangStringInput";
import TextInput from "../form/inputs/TextInput";
import renderLayoutString from "./renderLayoutString";

class TabData {

    public key!: string;

    @Input({
        component: LangStringInput,
        meta: {
            title: <Label k="title" />
        },
    })
    public title?: Shuttle.LangString

    @Input({
        component: ConditionBuilderInput,
        meta: {
            title: <Label k="enabled_condition" />
        },
        inputProps: {
            nullable: true,
        }
    })
    public enabledCondition?: Shuttle.ConditionNode;

    @Input({
        component: ConditionBuilderInput,
        meta: {
            title: <Label k="visible_condition" />
        },
        inputProps: {
            nullable: true,
        }
    })
    public visibleCondition?: Shuttle.ConditionNode;

    @Input({
        component: TextInput,
        meta: {
            title: 'Link'
        },
    })
    public href?: string;

    @Input({
        component: TextInput,
        meta: {
            title: 'Link Target'
        },
    })
    public target?: string;

    public elements?: string[];

}

class TabsConfig {

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="vertical" />
        }
    })
    public vertical?: boolean;

    @Input({
        clazz: TabData,
        array: {
            addComponent: ({onAdd}) => (
                <Button onClick={() => onAdd([{ key: StringUtil.uuid() }])}>
                    <Label k="add" />
                </Button>
            ),
            remove: true,
            sort: true,
        }
    })
    public tabs?: TabData[];

}

const TabsElement: Shuttle.LayoutElementType<TabsConfig> = {
    name: 'layout_tabs',
    Icon: TabsIcon,
    ConfigClass: TabsConfig,
    Component: ({ config, edit }) => {
        const desktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
        const contentNode = useContext(ContentContext);
        const constraints = useContext(ConstraintsContext);
        const { session } = useContext(SessionContext);
        const glossary = useContext(GlossaryContext);
        const [settings] = useContext(SettingsContext);

        const tabs = useMemo(() => (config.tabs || []).reduce((list, { key, title = {}, enabledCondition, visibleCondition, elements, href, target }, index) => {

            if (edit || !visibleCondition || (contentNode && constraints && session && evaluateCondition(visibleCondition, {
                contentNode,
                content: getAlternative(constraints, contentNode),
                root: session.root,
                tracking: session.tracking,
                state: session.state,
            }))) {

                if (href) {
                    href = renderLayoutString({
                        contentNode,
                        constraints,
                        entryPoint: session,
                        settings,
                        glossary,
                        text: {
                            x: href,
                        }
                    });
                }

                list.push({
                    key: key || (index + ''),
                    title: <LayoutString text={title} skip={edit} />,
                    href,
                    target,
                    content: (
                        <LayoutRegion keys={elements || []} />
                    ),
                    disabled: !edit && enabledCondition && !(contentNode && constraints && session && evaluateCondition(enabledCondition, {
                        contentNode,
                        content: getAlternative(constraints, contentNode),
                        root: session.root,
                        tracking: session.tracking,
                        state: session.state,
                    }))
                });

            }

            return list;
        }, [] as ITab[]), [edit, config, contentNode, constraints, session, glossary, settings]);
        
        return (
            <TabView.Auto tabs={tabs} orientation={config.vertical && desktop ? 'vertical' : 'horizontal'} />
        );
    },
    getRegions(config, key) {
        return config.tabs?.map((tab, index, arr) => {
            return ({
                title: tab.title?.en ?? `(Tab ${index})`,
                key: tab.key || index + '',
                keys: tab.elements || [],
                setKeys: (layout, elements) => setElementData(layout, key, {
                    ...config,
                    tabs: arr.map((t, i) => i === index ? {...t, elements} : t)
                }),
            });
        }) || [];
    }
};

export default TabsElement;
