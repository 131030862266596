import { AwardIssued } from "../model/Award";
import createEntityService from "./createEntityService";

const AwardIssuedService = {
    ...createEntityService<AwardIssued>({
        path: '/award-issued',
        allowDelete: false,
    }),    
}

export default AwardIssuedService;