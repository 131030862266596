import { Input } from "react-ts-form";
import Label from "../core/Label";
import DropDownChoiceInput from "../form/inputs/DropDownChoiceInput";
import NumberInput from "../form/inputs/NumberInput";
import TextInput from "../form/inputs/TextInput";
import { Shuttle } from "../types";

type NumberOperator = 'EQ' | 'LT' | 'LTE' | 'GT' | 'GTE';

class NumberCondition {

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="property_name" />,
            required: true,
        }
    })
    public name!: string;

    @Input({
        component: NumberInput,
        meta: {
            title: <Label k="value" />,
            required: true,
        },
        inputProps: {
            float: true,
        }
    })
    public value!: number;

    @Input({
        component: DropDownChoiceInput.of<NumberOperator>({
            options: ['EQ', 'LT', 'LTE', 'GT', 'GTE'],
        }),
        meta: {
            title: <Label k="operator" />,
            required: true,
        }
    })
    public operator: NumberOperator = 'EQ';

}

const stateNumberCondition: Shuttle.Condition<NumberCondition> = {
    name: 'condition.number',
    ConfigClass: NumberCondition,
    evaluate: ({ config = {} as NumberCondition }, { state }) => {

        for (const str of state[config.name] || []) {

            const parsed = parseFloat(str);
            if (isNaN(parsed)) {
                continue;
            }

            if (config.operator === 'EQ' && config.value === parsed) {
                return true;
            }

            if (config.operator === 'GT' && config.value < parsed) {
                return true;
            }

            if (config.operator === 'GTE' && config.value <= parsed) {
                return true;
            }

            if (config.operator === 'LT' && config.value > parsed) {
                return true;
            }

            if (config.operator === 'LTE' && config.value >= parsed) {
                return true;
            }

        }

        return false;
    }
};

export default stateNumberCondition;
