import SearchParams from "./SearchParams";
import Label from "../core/Label";
import { Input } from "react-ts-form";
import { ConsumerInput } from "../form/common";
import { Consumer } from "../model/Consumer";
import UserData from "./UserData";
import { AwardType } from "./Award";
import ContentData from "./ContentData";

export default class AwardIssuedSearchParams extends SearchParams {
    public types?: AwardType[];

    public content?: ContentData[];

    @Input({
        component: ConsumerInput,
        meta: {
            title: <Label k="consumer" />,
        },
    })    
    public consumer?: Consumer;

    public users?: UserData[];
}