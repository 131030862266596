import { useEffect, useState } from "react";
import { adminOnly, createComponentSection, createEntitySection, createFormAction } from "../../core/admin/AdminSection";
import { Webhook } from "../../model/Webhook";
import { WebhookService } from "../../services/WebhookService";
import { Box, Button, Chip, Grid, Typography, useTheme } from "@mui/material";
import SwitchInput from "../../form/inputs/SwitchInput";
import { DataTable } from "../../core/ui/DataTable";
import { Shuttle } from "../../types";
import { DateLabel } from "../../core/DateLabel";
import ModalDialog from "../../core/ui/ModalDialog";
import { useFormattedDate } from "../../hooks/useDate";
import { Consumer } from "../../model/Consumer";
import { ConsumerMultiInput, ContentMultiInput, UserMultiInput, horizontalTemplates } from "../../form/common";
import { Input } from "react-ts-form";
import UserData from "../../model/UserData";
import ContentData from "../../model/ContentData";
import DecoratorInput from "../../form/DecoratorInput";
import ListMultipleChoiceInput from "../../form/inputs/ListMultipleChoiceInput";

interface ReactivateWebhookProps {
    webhook: Webhook;
    reload: () => void;
    disabled?: boolean;
}

function ReactivateWebhook({ disabled, reload, webhook }: ReactivateWebhookProps) {

    const [open, setOpen] = useState(false);

    const date = useFormattedDate(webhook.shutoffDate, 'Ppp');

    return (
        <>
            <Button
                size="small"
                variant="contained"
                color="error"
                onClick={() => setOpen(true)}
                disabled={disabled}
            >
                {'Deactivated ' + date}
            </Button>
            <ModalDialog
                title="Reactivate Webhook"
                maxWidth="sm"
                open={open}
                onClose={() => setOpen(false)}
                actions={
                    <Button
                        variant="contained"
                        onClick={async () => {
                            setOpen(false);
                            await WebhookService.reactivate(webhook.id);
                            reload();
                        }}
                        disabled={disabled}
                    >
                        Reactivate
                    </Button>
                }
            >
                {`This webhook was automatically shut off because
                it has exceeded the permitted error rate. Please fix your
                endpoint before reactivating.`}
            </ModalDialog>
        </>
    );
}

export default createEntitySection<Webhook>({
    model: Webhook,
    getId: w => w.id,
    label: w => w.name,
    ...WebhookService,
    columns: [
        {
            key: 'name',
            sort: 'name',
        },
        {
            key: 'externalId',
            sort: 'externalId',
        },
        {
            key: 'enabled',
            sort: 'enabled',
            renderCell({appContext, data, reload}) {
                return (
                    <SwitchInput
                        value={!!data.enabled}
                        onChange={
                            enabled => WebhookService.update({...data, enabled}).then(reload)
                        }
                        disabled={!adminOnly(appContext)}
                    />
                );
            }
        },
        {
            key: 'status',
            sort: 'shutoffDate',
            renderCell: ({ appContext, data, reload }) => {
                if (data.shutoffDate) {
                    return (
                        <ReactivateWebhook
                            webhook={data}
                            reload={reload}
                            disabled={!adminOnly(appContext)}
                        />
                    );
                }
                return (
                    <Chip
                        size="small"
                        variant="filled"
                        color={data.enabled ? 'success' : 'default'}
                        label={data.enabled ? 'Running' : 'Inactive'}
                    />
                );
            },
        },
    ],
    editEnabled: ({ appContext }) => adminOnly(appContext),
    actions: [
        createFormAction({
            key: 'create',
            clazz: Webhook,
            onSubmit: async data => {
                await WebhookService.create(data);
                return true;
            },
            enabled: (_, appContext) => adminOnly(appContext),
        })
    ],
    sections: [
        {
            path: '/history',
            label: 'admin.section.webhooks.history',
            section: createComponentSection({
                component: ({ context }) => <WebhookHistory webhookId={context.id} />
            })
        }
    ]
})

class WebhookHistoryFilters {

    public beforeId: number = 0;

    @Input({
        component: ConsumerMultiInput,
        meta: {
            title: 'Consumer(s)',
        }
    })
    public consumers?: Consumer[];

    @Input({
        component: UserMultiInput,
        meta: {
            title: 'User(s)',
        }
    })
    public users?: UserData[];

    @Input({
        component: ContentMultiInput,
        meta: {
            title: 'Course(s)',
        },
    })
    public courses?: ContentData[];

    @Input({
        component: ListMultipleChoiceInput.of({
            options: [
                'course_launch',
                'course_start',
                'course_complete',
                'session_init',
                'session_finish',
                'cw_test_attempt',
            ],
        }),
        meta: {
            title: 'Event Type(s)',
        },
    })
    public types?: string[];

}
const initialFilters = new WebhookHistoryFilters();

function WebhookHistory({ webhookId }: { webhookId: number; }) {

    const [filters, setFilters] = useState(initialFilters);
    const [records, setRecords] = useState<Shuttle.WebhookLog[]>([]);

    useEffect(() => {
        const { beforeId, consumers, courses, types, users } = filters;
        WebhookService
            .history(webhookId, {
                beforeId,
                consumerIds: consumers?.map(c => c.id),
                rootContentIds: courses?.map(c => c.id),
                userIds: users?.map(u => u.id),
                types,
            })
            .then(records => setRecords(prev => beforeId ? prev.concat(records) : records));
    }, [webhookId, filters]);

    return (
        <>
            <Grid container spacing={2}>
                <DecoratorInput
                    clazz={WebhookHistoryFilters}
                    value={filters}
                    onChange={filters => setFilters({ ...filters, beforeId: 0 })}
                    templates={horizontalTemplates}
                />
            </Grid>
            <WebhookLogTable records={records} loadMore={beforeId => setFilters(prev => ({ ...prev, beforeId }))} />
        </>
    );
}

interface WebhookLogTableProps {
    records: Shuttle.WebhookLog[];
    loadMore: (beforeId: number) => void;
}

export function WebhookLogTable({ loadMore, records }: WebhookLogTableProps) {
    const theme = useTheme();
    return (
        <>
            <DataTable
                rows={records}
                columns={[
                    {
                        key: 'createDate',
                        name: 'Recorded',
                    },
                    {
                        key: 'type',
                        name: 'Type',
                    },
                    {
                        key: 'sendDate',
                        name: 'Sent',
                        renderCell: ({ sendDate }) => sendDate ? <DateLabel date={sendDate} format="Ppp" /> : '--',
                    },
                    {
                        key: 'time',
                        name: 'Time',
                        renderCell: ({ completionTime }) => completionTime ? completionTime + 'ms' : '--',
                    },
                    {
                        key: 'uri',
                        name: 'URL',
                    },
                    {
                        key: 'status',
                        name: 'Status',
                        renderCell: rec => {
                            if (rec.errorLogId) {
                                return (
                                    <Box
                                        sx={{
                                            p: 1,
                                            background: theme.palette.error.main,
                                            color: theme.palette.error.contrastText,
                                            fontWeight: 700,
                                            fontSize: 12,
                                        }}
                                        component="span"
                                    >
                                        {rec.response || 'ERROR'}
                                    </Box>
                                )
                            }
                            if (!rec.uri) return null;
                            if (!rec.status) {
                                return (
                                    <Box
                                        sx={{
                                            p: 1,
                                            backgroundColor: theme.palette.grey[100],
                                            color: theme.palette.grey[900],
                                            fontWeight: 700,
                                            fontSize: 12,
                                        }}
                                        component="span"
                                    >
                                        Pending
                                    </Box>
                                )
                            }
                            const error = rec.status >= 400;
                            return (
                                <Box
                                    sx={{
                                        p: 1,
                                        backgroundColor: theme.palette[error ? 'error' : 'success'].main,
                                        color: theme.palette[error ? 'error' : 'success'].contrastText,
                                        fontWeight: 700,
                                        fontSize: 12,
                                    }}
                                    component="span"
                                >
                                    {rec.status}
                                </Box>
                            )
                        },
                    }
                ]}
                expand={item => {

                    let payload = item.payload;
                    try {
                        payload = JSON.stringify(JSON.parse(payload), null, 2);
                    } catch {}

                    let response = item.response ?? '';
                    try {
                        response = JSON.stringify(JSON.parse(response), null, 2);
                    } catch {}
                    
                    return (
                        <Box sx={{ p: 2 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Typography fontWeight={700}>Headers</Typography>
                                    <Box
                                        sx={{
                                            whiteSpace: 'pre-wrap',
                                            overflow: 'auto',
                                            maxHeight: 500,
                                            p: 2,
                                            borderStyle: 'solid',
                                            borderWidth: 1,
                                            borderColor: theme.palette.divider,
                                            borderRadius: theme.shape.borderRadius,
                                        }}
                                    >
                                        {
                                            item.headers?.length
                                                ? item.headers.map(h => `${h.name}: ${h.value}`).join('\n')
                                                : '--'
                                        }
                                    </Box>
                                    <Typography fontWeight={700}>
                                        Payload
                                    </Typography>
                                    <Box
                                        sx={{
                                            whiteSpace: 'pre-wrap',
                                            overflow: 'auto',
                                            maxHeight: 500,
                                            p: 2,
                                            borderStyle: 'solid',
                                            borderWidth: 1,
                                            borderColor: theme.palette.divider,
                                            borderRadius: theme.shape.borderRadius,
                                        }}
                                    >
                                        {payload}
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography fontWeight={700}>
                                        Response
                                    </Typography>
                                    <Box
                                        sx={{
                                            whiteSpace: 'pre-wrap',
                                            overflow: 'auto',
                                            maxHeight: 500,
                                            p: 2,
                                            borderStyle: 'solid',
                                            borderWidth: 1,
                                            borderColor: theme.palette.divider,
                                            borderRadius: theme.shape.borderRadius,
                                        }}
                                    >
                                        {response || '--'}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    )
                }}
            />
            <Box sx={{ p: 1, textAlign: 'center' }}>
                <Button
                    onClick={() => loadMore(records[records.length - 1]?.id ?? 0)}
                    variant="contained"
                    color="primary"
                >
                    Load More
                </Button>
            </Box>
        </>
    )
}
