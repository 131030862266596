import { Input } from "react-ts-form";
import SwitchInput from "../../form/inputs/SwitchInput";
import AnnouncementIcon from '@mui/icons-material/Announcement';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Shuttle } from "../../types";
import { useContext } from "react";
import { SessionContext } from "../../contexts";
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Alert, AlertTitle, Button, Typography } from "@mui/material";
import LayoutString from "../LayoutString";
import DropDownChoiceInput from "../../form/inputs/DropDownChoiceInput";
import { DateLabel } from "../../core/DateLabel";
import Label from "../../core/Label";
import { api } from "../../player/api";

class Config {

    @Input({
        component: SwitchInput,
        meta: {
            title: 'Show New Only',
        },
    })
    public newOnly: boolean = true;

    @Input({
        component: DropDownChoiceInput,
        meta: {
            title: 'Appearance',
        },
        inputProps: {
            options: ['alert', 'accordion'],
        },
    })
    public view?: 'alert' | 'accordion';

}

const ContentAnnouncementsElement: Shuttle.LayoutElementType<Config> = {
    name: 'layout_content_announcements',
    Icon: AnnouncementIcon,
    ConfigClass: Config,
    Component: ({ config }) => {
        const { session, setSession } = useContext(SessionContext);

        let announcements = session?.announcements ?? [];

        if (announcements.length && config.newOnly) {
            announcements = announcements.filter(({ acknowledged, endDate }) => !(acknowledged || (endDate && (new Date(endDate).getTime() < Date.now())))); 
        }

        return (
            <>
                {
                    announcements.map(({ acknowledged, id, displayName, description, startDate, name }) => {

                        const title = <LayoutString text={{ x: name,  ...displayName ?? {} }} />;
                        const date = <DateLabel date={startDate} />
                        const desc = <LayoutString text={description} html />;
                        const dismiss = !acknowledged && (
                            <Button
                                color="inherit"
                                onClick={async () => {
                                    await api.play.announcements.acknowledge(session!.sessionId, id);
                                    setSession(prev => ({
                                        ...prev,
                                        announcements: announcements.map(a => a.id === id ? { ...a, acknowledged: true } : a),
                                    }));
                                }}
                            >
                                <Label k="acknowledge" />
                            </Button>
                        );

                        if (config.view === 'accordion') {
                            return (
                                <Accordion key={id}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography sx={{ flexGrow: 1 }}>
                                            {title}
                                        </Typography>
                                        <Typography sx={{ color: 'text.secondary', mr: 1 }}>
                                            {date}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {desc}
                                    </AccordionDetails>
                                    {
                                        dismiss && (
                                            <AccordionActions>
                                                {dismiss}
                                            </AccordionActions>
                                        )
                                    }
                                </Accordion>
                            );
                        }

                        return (
                            <Alert
                                key={id}
                                severity="info"
                                action={dismiss}
                            >
                                <AlertTitle>
                                    {title}
                                </AlertTitle>
                                <Typography variant="subtitle2" gutterBottom>
                                    {date}
                                </Typography>
                                {desc}
                            </Alert>
                        );
                    })
                }
            </>
        );
    },
};

export default ContentAnnouncementsElement;