import dateFormat from "date-fns/locale/es";
import { esES } from "@mui/material/locale";
import dictionary from "./es_MX.json";

import { ILocale } from "../Locale";

const es_MX: ILocale = {
    rtl: false, // right to left
    dictionary,
    dateFormat,
    muiLocale: esES
};

export default es_MX;
//spanish(mexico)