import { Box, Button, Card, CardContent, CardHeader, useTheme } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { ConstraintsContext, ContentContext, SessionEntryContext, SettingsContext } from "../contexts";
import Label from "../core/Label";
import iFrameResize from 'iframe-resizer/js/iframeResizer';
import { Shuttle } from "../types";
import BrowserUtil from "../util/BrowserUtil";
import StringUtil from "../util/StringUtil";
import { getAlternative } from "../player/utils";
import LayoutString from "../layout/LayoutString";

export const EMBED_FRAME_ATTR = 'data-simpatico-embed';

export default function Embed({ defaultDimension, entryPoint, inline, popup, toolbar }: Shuttle.LauncherProps) {
    const contentNode = useContext(ContentContext);
    const constraints = useContext(ConstraintsContext);
    const session = useContext(SessionEntryContext);
    const [{ locale }] = useContext(SettingsContext)
    const [launched, setLaunched] = useState(false);
    const muiTheme = useTheme();
    const ref = useRef<HTMLIFrameElement>(null);
    const redirectRef = useRef<HTMLDivElement>(null);

    const inFrame = BrowserUtil.inFrame();

    let uri = session?.uri;
    if (inFrame && inline && uri) {
        uri += (uri.includes('?') ? '&' : '?') + 'inline=true';
    }

    const content = contentNode && getAlternative(constraints, contentNode);

    const redirect = Boolean(popup && content?.newWindow);

    useEffect(() => {
        if (redirect && uri) {
            const popupWindow = redirectRef.current?.ownerDocument.defaultView;
            if (popupWindow) {
                popupWindow.location.href = uri;
            }
        }
    }, [redirect, uri]);

    useEffect(() => {
        ref.current?.contentWindow?.postMessage('simpatico:' + JSON.stringify({
            type: 'locale-change',
            locale,
        }), '*')
    }, [locale]);

    if (redirect) {
        return (
            <div ref={redirectRef} />
        );
    }

    if (!session || !contentNode || !content) {
        return null;
    }

    const dimension = content.dimension || defaultDimension || '16x9';
    const parsed = dimension === 'auto' ? 'auto' : StringUtil.parseDimension(content.dimension);

    if (content.newWindow) {
        return (
            <div style={!inline ? { flexGrow: 1 } : undefined}>
                <Card>
                    <CardHeader title={<LayoutString text={content.title} />} />
                    <CardContent>
                        {
                            launched ? (
                                <Label k="launch.new.window.launched" />
                            ) : (
                                <Button
                                    size="large"
                                    variant="contained" 
                                    color="primary"
                                    onClick={() => {
                                        setLaunched(true);
                                        window.open(uri, 'simpatico_popup', 'popup')
                                    }}
                                >
                                    <Label k="launch.new.window" />
                                </Button>
                            )
                        }
                    </CardContent>
                </Card>
            </div>
        );
    }

    const frameProps = {
        frameBorder: 0,
        src: uri,
        style: { backgroundColor: 'transparent', border: 0, display: 'block', minWidth: '100%', width: '1px' },
        ref,
        [EMBED_FRAME_ATTR]: '1',
    } as const;

    if (inline) {
        
        if (parsed === 'auto') {
            return (
                <iframe 
                    {...frameProps}
                    onLoad={e => iFrameResize({ checkOrigin: false, log: true }, e.currentTarget)}
                    title='content'
                />
            );
        }

        return (
            <Box
                sx={{
                    height: 0,
                    overflow: 'hidden',
                    position: 'relative',
                    paddingTop: {
                        xs: 'calc(100vh - 64px)',
                        md: (parsed * 100) + '%',
                    }
                }}
            >
                <iframe 
                    {...frameProps}
                    style={{...frameProps.style, position: 'absolute', top: 0, left: 0, height: '100%'}}
                    title='content'
                />
            </Box>
        );
    }

    return (
        <iframe 
            {...frameProps} 
            style={{...frameProps.style, flexGrow: 1, height: entryPoint ? (toolbar ? `calc(100vh - ${muiTheme.mixins.toolbar.height})` : '100vh'): 'auto'}}
            title='content'
        />
    );
}
