import { IInputProps, Input } from "react-ts-form";
import { Button } from "@mui/material";
import Label from "../../core/Label";
import DropDownChoiceInput from "./DropDownChoiceInput";
import { Class } from "../../util/Class";
import DecoratorInput from "../DecoratorInput";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/RemoveCircle";
import { conditionRegistry } from "../../registries";
import { RegistryKeyChoiceRenderer } from "../IOptionsProps";
import { Shuttle } from "../../types";
import LangStringInput from "./LangStringInput";

class ConditionNode<T = any> implements Shuttle.ConditionNode {

    @Input(() => ({
        component: DropDownChoiceInput.of<string>({
            options: conditionRegistry.keys(),
            choiceRenderer: new RegistryKeyChoiceRenderer(conditionRegistry),
        }),
        meta: {
            title: <Label k="type" />,
            required: true,
        }
    }))
    public type!: string;

    @Input((_, { parent }) => {

        if (parent.type) {
            const type = conditionRegistry.get(parent.type);
            if (type?.ConfigClass) {
                return { clazz: type.ConfigClass };
            }
        }

        return {};
    })
    public config!: T;

    @Input((_, { parent }) => {

        const type = conditionRegistry.get(parent?.type);

        if (parent.children?.length || type?.allowsChildren) {

            return {
                clazz: ConditionNode as Class<any>,
                array: {
                    sort: true,
                    remove: true,
                    addComponent({ onAdd }) {
                        return (
                            <Button onClick={() => onAdd([{ type: 'Wrapper' }])}>
                                <Label k="add" />
                            </Button>
                        );
                    }
                }
            };
        }

        return {};
    })
    public children?: ConditionNode[];

    @Input({
        component: LangStringInput,
        meta: {
            title: <Label k="failedMessage" />
        }
    })
    public failedMessage?: Shuttle.LangString

}

interface ConditionInputProps extends IInputProps<ConditionNode> {
    nullable?: boolean;
}

export function ConditionBuilderInput(props: ConditionInputProps) {

    if (props.nullable) {
        if (!props.value) {
            return (
                <Button 
                    color="secondary" 
                    size="small" 
                    onClick={() => props.onChange({ type: 'Wrapper', config: { operator: 'ALL' }, children: [] })}
                    startIcon={<AddIcon />}
                >
                    <Label k="add.condition" />
                </Button>
            );
        }
    }

    return (
        <>
            {
                Boolean(props.nullable && props.value) && (
                    <Button 
                        sx={{ mb: .5 }} 
                        color="secondary" 
                        size="small" 
                        onClick={() => props.onChange(null as any)}
                        startIcon={<RemoveIcon />}
                    >
                        <Label k="remove" />
                    </Button>
                )
            }
            <DecoratorInput {...props} clazz={ConditionNode} />
        </>
    );
}
