import dateFormat from "date-fns/locale/pt";
import { ptPT } from "@mui/material/locale";
import dictionary from "./pt_PT.json";

import { ILocale } from "../Locale";

const pt_PT: ILocale = {
    rtl: false, // right to left
    dictionary,
    dateFormat,
    muiLocale: ptPT
};

export default pt_PT;
//portuguese