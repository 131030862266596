import { anyEditor, createEntitySection, createFormAction } from "../../core/admin/AdminSection";
import GlossaryData from "../../model/GlossaryData";
import GlossaryTermData from "../../model/GlossaryTermData";
import GlossaryService from "../../services/GlossaryService";

export default createEntitySection<GlossaryData>({

    model: GlossaryData,
    
    getId: o => o.id,
    label: 'name',

    ...GlossaryService,

    columns: [
        {
            key: 'name',
            sort: 'name'
        },
        {
            key: 'externalId',
            sort: 'externalId'
        },
    ],

    editEnabled: ({ appContext }) => anyEditor(appContext),

    actions: [
        createFormAction({
            key: 'create',
            clazz: GlossaryData,
            onSubmit: async data => {
                await GlossaryService.create(data);
                return true;
            },
            enabled: (_, appContext) => anyEditor(appContext),
        })
    ],

    sections: [
        {
            path: '/terms',
            label: 'admin.section.glossary.terms',
            section: createEntitySection({

                model: GlossaryTermData,

                getId: o => o.id,
                label: 'name',

                search: GlossaryService.searchTerms,
                update: GlossaryService.updateTerm,
                get: GlossaryService.getTerm,
                delete: GlossaryService.deleteTerm,

                columns: [
                    {
                        key: 'name',
                        sort: 'name'
                    },
                    {
                        key: 'alternatives'
                    },
                    {
                        key: 'locale',
                        sort: 'locale',
                    },
                ],

                editEnabled: ({ appContext }) => anyEditor(appContext),

                actions: [
                    createFormAction({
                        key: 'create',
                        clazz: GlossaryTermData,
                        onSubmit: async (data, context) => {
                            await GlossaryService.createTerm(data, context);
                            return true;
                        },
                        enabled: (_, appContext) => anyEditor(appContext),
                    })
                ]

            })
        }
    ]

});
