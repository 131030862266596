import { Avatar, Chip, Stack, Typography } from "@mui/material";
import { adminOnly, anyStaff, createComponentSection, createEntitySection, createFormAction, hasRole } from "../../core/admin/AdminSection";
import UserData from "../../model/UserData";
import UserService from "../../services/UserService";
import { DateLabel } from "../../core/DateLabel";
import EnrollmentData from "../../model/EnrollmentData";
import EnrollmentService from "../../services/EnrollmentService";
import Label from "../../core/Label";
import StringUtil from "../../util/StringUtil";
import EnrollmentXApiBrowser from "../../core/EnrollmentXApiHistory";
import EnrollmentTreePreview from "../../core/EnrollmentTreePreview";
import { Link } from "react-router-dom";
import { launcherRegistry } from "../../registries";
import EnrollmentQuizAttempt from "../../model/quiz/EnrollmentQuizAttempt";
import SearchParams from "../../model/SearchParams";
import { ValidLabelKey } from "../../core/localization/ValidLabelKey";
import { Group, UserGroup } from "../../model/Group";
import { Input } from "react-ts-form";
import { GroupInput } from "../../form/common";
import LabelledIconButton from "../../core/ui/LabelledIconButton";
import { LoginIcon } from "../../core/icons";
import { AuthService } from "../../services/AuthService";
import useResolver from "../../hooks/useResolver";
import { useCallback } from "react";
import { DataTable } from "../../core/ui/DataTable";

export const userEnrollments = {
    path: '/enrollments',
    label: 'admin.section.contents.enrollments' as ValidLabelKey,
    section: createEntitySection<EnrollmentData, SearchParams, UserData>({

        model: EnrollmentData,

        getId: o => o.id,
        label: o => o.content.name,

        defaultSort: '-lastVisitDate',

        search: UserService.searchEnrollments,
        get: async id => EnrollmentService.get(id),
        update: async data => EnrollmentService.update(data),

        downloads: [
            {
                key: 'enrollments-csv',
                label: 'Enrollments (CSV)',
                download: (params, context) => EnrollmentService.downloadContentEnrollments(context.id, params),
            },
        ],

        editEnabled: ({ appContext }) => hasRole(appContext, ['ROLE_SUPPORT', 'ROLE_ADMIN']),

        columns: [
            {
                key: 'name',
                sort: 'content.name',
                renderCell: ({data}) => (
                    <Typography component={Link} color="inherit" to={`/admin/contents/${data.content.id}`}>
                        {data.content.name}
                    </Typography>
                ),
            },
            {
                key: 'externalId',
                sort: 'externalId'
            },
            {
                key: 'completion',
                sort: 'completion',
                renderCell: ({data}) => <Label k={data.completion} />,
            },
            {
                key: 'score',
                sort: 'score',
                renderCell: ({data}) => typeof data.score === 'number' ? data.score + '%' : "--",
            },
            {
                key: 'status',
                sort: 'status',
                renderCell: ({data}) => (
                    <Chip
                        label={<Label k={data.status} />}
                        color={
                            data.status === 'Deleted'
                                ? 'error'
                                : data.status === 'Archived'
                                    ? 'default'
                                    : 'info'
                        }
                        size="small"
                    />
                ),
            },
            {
                key: 'timeSpent',
                sort: 'timeSpent',
                renderCell: ({data}) => {
                    return StringUtil.formatHMS(data.timeSpent || 0);
                }
            },
            {
                key: 'uniqueTimeViewed',
                sort: 'uniqueTimeViewed',
                renderCell: ({ data }) => data.uniqueTimeViewed ? StringUtil.formatHMS(data.uniqueTimeViewed) : '--',
            },
            {
                key: 'firstVisitDate',
                sort: 'firstVisitDate',
                renderCell: ({data}) => <DateLabel date={data.firstVisitDate} format="Pp" />,
            },
            {
                key: 'lastVisitDate',
                sort: 'lastVisitDate',
                renderCell: ({data}) => <DateLabel date={data.lastVisitDate} format="Pp" />,
            },
            {
                key: 'controls',
                renderCell: ({data}) => (
                    <Stack direction="row" spacing={1}>
                        <EnrollmentXApiBrowser enrollment={data} />
                        <EnrollmentTreePreview.DialogButton enrollmentId={data.id} />
                    </Stack>
                )
            },
        ],

        sections: [
            {
                path: '/quiz-attempts',
                enabled: e => !!launcherRegistry.get(e.content.type.launcher)?.hasQuestions,
                label: 'admin.section.quiz.attempts',
                section: createEntitySection({

                    model: EnrollmentQuizAttempt,
                    getId: o => o.id,
                    label: o => o.startTime,

                    search: (params, enrollment) => EnrollmentService.searchQuizAttempts(enrollment.id, params),
                    get: async () => ({} as any),

                    columns: [
                        {
                            key: 'startTime',
                            sort: 'startTime',
                            renderCell: ({data}) => data.startTime && <DateLabel date={data.startTime} format="Pp" />
                        },
                        {
                            key: 'endTime',
                            sort: 'endTime',
                            renderCell: ({data}) => data.endTime && <DateLabel date={data.endTime} format="Pp" />
                        },
                        {
                            key: 'status',
                            sort: 'status',
                        },
                        {
                            key: 'result',
                            sort: 'result',
                        },
                        {
                            key: 'score',
                            sort: 'score',
                            renderCell: ({data}) => !!data.maxScore && `${Math.floor(data.score / data.maxScore * 100)}% (${data.score}/${data.maxScore})`,
                        }
                    ],

                })
            }
        ]

    })
}

class AddUserGroup extends UserGroup {

    @Input((_, { parent }) => ({
        component: GroupInput,
        meta: {
            title: 'Group',
            required: true,
        },
        inputProps: {
            params: {
                consumerIds: [parent.user.consumer.id],
            },
        },
    }))
    public group!: Group;

}

export const userGroups = {
    path: '/groups',
    label: 'admin.section.groups' as const,
    section: createEntitySection<UserGroup, SearchParams, UserData>({
        model: UserGroup,
        getId: o => o.group.id,
        label: o => o.group.name,
        search: async (params, group) => await UserService.searchGroups(group.id, params),
        get: async (userId, group) => await UserService.getGroup(group.id, userId),
        update: async ({ user, group, ...data }) => await UserService.addGroup(group.id, user.id, data),
        delete: async ({ group, user }) => await UserService.removeGroup(group.id, user.id),
        columns: [
            {
                key: 'name',
                sort: 'group.name',
                renderCell: ({ data }) => data.group.name,
            },
            {
                key: 'method',
                sort: 'method',
            },
            {
                key: 'role',
                sort: 'role',
            },
            {
                key: 'added',
                sort: 'createDate',
                renderCell: ({ data }) => <DateLabel date={data.createDate} format="Pp" />
            },
        ],
        editEnabled: ({ appContext }) => adminOnly(appContext),
        actions: [
            createFormAction({
                key: 'add',
                getInitialData: user => {
                    const d = new UserGroup();
                    d.user = user;
                    return d;
                },
                clazz: AddUserGroup,
                onSubmit: async ({ group, user, ...rest }) => {
                    await UserService.addGroup(user.id, group.id, rest);
                    return true;
                },
                enabled: (_, appContext) => adminOnly(appContext),
            })
        ],
    })
}

export default createEntitySection<UserData>({

    model: UserData,

    getId: o => o.id,
    label: o => o.firstName + ' ' + o.lastName,

    defaultSort: '-lastActiveDate',
    
    ...UserService,

    columns: [
        {
            key: 'avatar',
            renderCell: ({data}) => (
                <Avatar alt={`${data.firstName} ${data.lastName}`} src={data.avatar?.url}>
                    {`${data.firstName?.charAt(0)}${data.lastName?.charAt(0)}` }
                </Avatar>
            ),
        },
        {
            key: "firstName",
            sort: "firstName",
            renderCell({data}) {
                return data.firstName;
            }
        },
        {
            key: 'lastName',
            sort: 'lastName',
            renderCell({data}) {
                return data.lastName;
            }
        },
        {
            key: 'email',
            sort: 'email',
        },
        {
            key: 'consumer',
            sort: 'consumer.name',
            renderCell: ({ data }) => (
                <Typography component={Link} color="inherit" to={`/admin/consumers/${data.consumer.id}`}>
                    {data.consumer.name}
                </Typography>
            ),
        },
        {
            key: 'externalId',
            sort: 'externalId',
            renderCell({data}) {
                return data.externalId;
            }
        },
        {
            key: 'lastActiveDate',
            sort: 'lastActiveDate',
            renderCell: ({ data }) => data.lastActiveDate && <DateLabel date={data.lastActiveDate} format="P p" />,
        },
        {
            key: 'controls',
            renderCell: ({ appContext, data }) => (
                <>
                    <LabelledIconButton
                        k="impersonate"
                        icon={LoginIcon}
                        disabled={anyStaff({ user: data, tenant: appContext.tenant })}
                        onClick={() => {
                            const url = AuthService.impersonateUrl(data.id);
                            if (data.consumer.id === appContext.tenant.consumerId) {
                                window.location.href = url;
                            } else {
                                window.open(url, 'simpatico_impersonate')
                            }
                        }}
                    />
                </>
            ),
        },
    ],

    editEnabled: ({ appContext }) => adminOnly(appContext),

    actions: [
        createFormAction({
            key: 'create',
            clazz: UserData,
            onSubmit: async user => {
                await UserService.create(user);
                return true;
            },
            enabled: (_, appContext) => adminOnly(appContext),
        }),
    ],

    sections: [
        userGroups,
        userEnrollments,
        {
            path: '/identity-verifications',
            enabled: user => !!user.verification,
            label: 'admin.section.identity.verification',
            section: createComponentSection({
                component: function VerificationList({ context: user }) {
                    const userId = user.id;
                    const { data: verifications } = useResolver(useCallback(async () => {
                        return await UserService.listVerifications(userId);
                    }, [userId]));
                    return (
                        <DataTable
                            rows={verifications ?? []}
                            loading={!verifications}
                            columns={[
                                {
                                    key: 'createDate',
                                    name: 'Date',
                                    renderCell: ({ createDate }) => <DateLabel date={createDate} format="Pppp" />,
                                },
                                {
                                    key: 'status',
                                    name: 'Status',
                                    renderCell: ({ status }) => (
                                        <Chip
                                            color={status === 'FAILURE' ? 'error' : status === 'SUCCESS' ? 'success' : 'warning'}
                                            size="small"
                                            label={status}
                                        />
                                    ),
                                },
                                {
                                    key: 'completionDate',
                                    name: 'Completion Date',
                                    renderCell: ({ completionDate }) => <DateLabel date={completionDate} format="Pppp" />,
                                },
                                {
                                    key: 'ip',
                                    name: 'IP Address',
                                },
                                {
                                    key: 'userAgent',
                                    name: 'User Agent',
                                },
                            ]}
                        />
                    );
                }
            }),
        }
    ],
});
