import { useContext, useState } from "react";
import { Avatar, Box, Button, Divider, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import Label from "./Label";
import { AppContext } from "./AppContext";
import { SettingsContext } from "../contexts";
import { AuthService } from "../services/AuthService";
import { Link } from "react-router-dom";
import { anyStaff, hasRole } from "./admin/AdminSection";
import { AccountIcon, AdminIcon, ExitToAppIcon, LogoutIcon, MyCoursesIcon, SettingsIcon, SuperAdminIcon } from "./icons";
import ModalDialog from "./ui/ModalDialog";
import { CollectorFields } from "./CollectorFields";
import DropDownChoiceInput from "../form/inputs/DropDownChoiceInput";
import { LabelKeyChoiceRenderer } from "../form/IOptionsProps";
import RadioChoiceInput from "../form/inputs/RadioChoiceInput";
import { useThemeContext } from "./ThemeProvider";
import timezones from "../i18n/timezones";

interface SettingsData {
    locale: string;
    timeZone: string;
    mode: 'auto' | 'light' | 'dark';
    properties: Record<string, unknown>;
}

export default function UserToolbar() {
    const { theme } = useThemeContext();
    const [appContext, setAppContext] = useContext(AppContext);
    const [settings, setSettings] = useContext(SettingsContext);
    const [anchor, setAnchor] = useState<null | HTMLElement>(null);
    const [editing, setEditing] = useState<null | SettingsData>(null);
    const { tenant, user } = appContext;
    if (!user) {
        return (
            <>
                <Button
                    component={Link}
                    to="/login"
                    color="inherit"
                >
                    <Label k="sign_in" />
                </Button>
                {
                    tenant.registration === 'SELF' && (
                        <Button
                            component={Link}
                            to="/register"
                            color="inherit"
                        >
                            <Label k="sign_up" />
                        </Button>
                    )
                }
            </>
        );
    }

    const name = user.displayName || `${user.firstName} ${user.lastName}`;

    return (
        <>
            <Button
                color="inherit"
                onClick={e => setAnchor(e.currentTarget)}
                startIcon={
                    user.avatar?.url ? (
                        <Avatar
                            alt={name}
                            src={user.avatar.url}
                            sx={{
                                width: 24,
                                height: 24,
                            }}
                        />
                    ) : (
                        <AccountIcon />
                    )
                }
            >
                {name}
            </Button>
            <Menu
                anchorEl={anchor}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={Boolean(anchor)}
                onClose={() => setAnchor(null)}
            >
                <MenuItem component={Link} to="/">
                    <ListItemIcon>
                        <MyCoursesIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                        <Label k="my_courses" />
                    </ListItemText>
                </MenuItem>
                <MenuItem onClick={() => setEditing({ ...settings, properties: user.properties ?? {} })}>
                    <ListItemIcon>
                        <SettingsIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                        <Label k="settings" />
                    </ListItemText>
                </MenuItem>
                {
                    anyStaff(appContext) && (
                        <MenuItem component={Link} to="/admin">
                            <ListItemIcon>
                                <AdminIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>
                                <Label k="admin" />
                            </ListItemText>
                        </MenuItem>
                    )
                }
                {
                    hasRole(appContext, ['ROLE_SUPER_ADMIN']) && (
                        <MenuItem component={Link} to="/super-admin">
                            <ListItemIcon>
                                <SuperAdminIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>
                                Super Admin
                            </ListItemText>
                        </MenuItem>
                    )
                }
                <Divider />
                <MenuItem component={Link} to="/logout">
                    <ListItemIcon>
                        <ExitToAppIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                        <Label k="sign_out" />
                    </ListItemText>
                </MenuItem>
                {
                    user.impersonated && (
                        <MenuItem onClick={() => AuthService.impersonateExit()}>
                            <ListItemIcon>
                                <LogoutIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>
                                <Label k="exit_impersonation" />
                            </ListItemText>
                        </MenuItem>
                    )
                }
            </Menu>
            <ModalDialog
                title={<Label k="settings" />}
                open={Boolean(editing)}
                onClose={() => setEditing(null)}
                maxWidth="xs"
            >
                {
                    editing && (
                        <form
                            onSubmit={async e => {
                                e.preventDefault();
                                const { locale, mode, timeZone, properties } = editing;
                                setSettings(prev => ({ ...prev, locale, mode, timeZone }));
                                const user = await AuthService.saveProfile({ locale, timeZone, properties });
                                setEditing(null);
                                setAppContext(prev => ({ ...prev, user }));
                            }}
                        >
                            <DropDownChoiceInput
                                placeholder={<Label k="language" />}
                                options={tenant.consumerLocale ?? []}
                                value={editing.locale ?? ''}
                                onChange={locale => setEditing(prev => prev && ({ ...prev, locale }))}
                                choiceRenderer={LabelKeyChoiceRenderer}
                                sx={{
                                    my: 1,
                                }}
                            />
                            <DropDownChoiceInput
                                placeholder={<Label k="timeZone" />}
                                options={timezones}
                                value={editing.timeZone ?? tenant.timeZone ?? ''}
                                onChange={timeZone => setEditing(prev => prev && ({ ...prev, timeZone }))}
                                sx={{
                                    my: 1,
                                }}
                            />
                            {
                                (!theme.properties?.mode || theme.properties?.mode === 'auto') && (
                                    <Box sx={{ my: 1 }}>
                                        <Typography variant="body2" color="text.secondary">
                                            <Label k="settings.colorScheme" />
                                        </Typography>
                                        <RadioChoiceInput
                                        
                                            value={editing.mode ?? theme.properties?.mode ?? 'auto'}
                                            onChange={mode => setEditing(prev => prev && ({ ...prev, mode }))}
                                            options={['auto', 'light', 'dark'] as typeof settings['mode'][]}
                                            choiceRenderer={LabelKeyChoiceRenderer}
                                        />
                                    </Box>
                                )
                            }
                            {
                                !!tenant.collector?.variables?.length && (
                                    <>
                                        <Divider sx={{ my: 2 }} />
                                        <CollectorFields
                                            value={editing.properties ?? {}}
                                            variables={tenant.collector.variables}
                                            onChange={properties => setEditing(prev => prev && ({ ...prev, properties }))}
                                        />
                                    </>
                                )
                            }
                            <Button
                                fullWidth
                                variant="contained"
                                type="submit"
                                sx={{
                                    my: 2,
                                }}
                            >
                                <Label k="save" />
                            </Button>
                        </form>
                    )
                }
            </ModalDialog>
        </>
    );
}
