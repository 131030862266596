import { Input } from "react-ts-form";
import { Shuttle } from "../types";
import DurationInput from "../form/inputs/DurationInput";
import SwitchInput from "../form/inputs/SwitchInput";

class ContentMinTimeMet {

    @Input({
        component: DurationInput,
        meta: {
            title: 'Min Time'
        },
    })
    public duration: number = 0;

    @Input({
        component: SwitchInput,
        meta: {
            title: 'Use Root Enrollment',
        },
    })
    public root: boolean = false;

}

export const contentMinTimeMetCondition: Shuttle.Condition<ContentMinTimeMet> = {
    name: "condition_content_min_time_met",
    ConfigClass: ContentMinTimeMet,
    evaluate: ({ config }, { content, root, tracking }) => {
        if (!config.duration) return true;
        return config.duration <= tracking[config.root ? root.content.uuid : content.uuid]?.timeSpent ?? 0;
    },
};
