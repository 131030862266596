import { Input } from "react-ts-form";
import TextInput from "../../form/inputs/TextInput";

export default class StringData {

    @Input({
        component: TextInput,
    })
    public value!: string;

}
