import { SxProps, styled } from "@mui/material/styles";
import { Theme } from '@mui/material';
import { useContext, useEffect, useRef } from "react";
import { SettingsContext } from "../../contexts";
import bestLocaleMatch from "../../i18n/util";
import { Shuttle } from "../../types";
import BrowserUtil from "../../util/BrowserUtil";

const Wrapper = styled('div')(({ theme }) => {

    const margin = theme.spacing(1, 0);
    
    return {
        ...theme.typography.body1,
        "& :first-of-type": {marginTop: 0},
        "& :last-child": {marginBottom: 0},
        "& h1": {...theme.typography.h1, margin},
        "& h2": {...theme.typography.h2, margin},
        "& h3": {...theme.typography.h3, margin},
        "& h4": {...theme.typography.h4, margin},
        "& h5": {...theme.typography.h5, margin},
        "& h6": {...theme.typography.h6, margin},
        "& a": { color: theme.palette.primary.main },
        "& figure": { margin: 0, padding: 0 },
        "& img": { maxWidth: '100%' },
        "& table": {
            borderCollapse: 'collapse',
            borderSpacing: 0,
            margin,
            width: '100%',
        },
        "& table tr": {
            verticalAlign: 'middle',
            outline: 0,
        },
        "& table td, & table th": {
            verticalAlign: 'inherit',
            borderBottom: '1px solid ' + (theme.palette.divider),
            textAlign: 'left',
            padding: 16,
        },
        "& [data-term]": {
            color: theme.palette.primary.main,
            cursor: 'pointer',
        }
    };
});

type RunningTextProps = React.PropsWithChildren<{ 
    className?: string; 
    dangerouslyInjectScripts?: boolean;
    html?: string | Shuttle.LangString; 
    style?: React.CSSProperties;
    sx?: SxProps<Theme>;
    gutterBottom?: boolean;
}>;

/**
 * applies Material-UI typography styles to corresponding native HTML elements
 */
export default function RunningText({ children, className, dangerouslyInjectScripts = false, html, style, sx }: RunningTextProps) {

    const [settings] = useContext(SettingsContext)

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (dangerouslyInjectScripts && html && ref.current) {
            BrowserUtil.dangerouslyInjectChildScripts(ref.current);
        }
    }, [dangerouslyInjectScripts, html]);

    if (html) {
        return (
            <Wrapper
                className={'text-block ' + (className ?? '')}
                dangerouslySetInnerHTML={{
                    __html: typeof html === 'string' ? html : bestLocaleMatch(html, settings.locale) ?? '',
                }}
                ref={ref}
                style={style}
                sx={sx}
            />
        );
    }
    
    return (
        <Wrapper
            className={'text-block ' + (className ?? '')}
            ref={ref}
            style={style}
            sx={sx}
        >
            {children}
        </Wrapper>
    );
}
