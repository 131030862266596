import dictionary from "../../i18n/dictionary";

export type ValidLabelKey = keyof typeof dictionary;

export type ValidLabelArg = undefined | null | number | string;

export type ValidLabelArgMap = { [name: string]: ValidLabelArg; };

export type ValidateLabelKey<T extends ValidLabelKey> = T;

// type guard function
export function isValidLabelKey(str: string | ValidLabelKey): str is ValidLabelKey {
    return !!dictionary[str as unknown as ValidLabelKey];
}
