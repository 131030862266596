import { Input } from "react-ts-form";

import TextInput from "../form/inputs/TextInput";
import Label from "../core/Label";
import SwitchInput from "../form/inputs/SwitchInput";
import { Shuttle } from "../types";
import LangStringInput from "../form/inputs/LangStringInput";

export default class TagData {

    public id!: number;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="name" />
        }
    })
    public name!: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="externalId" />
        }
    })
    public externalId?: string;

    @Input({
        component: LangStringInput,
        meta: {
            title: 'Display Name',
        },
    })
    public displayName?: Shuttle.LangString;

    @Input({
        component: SwitchInput,
        meta: {
            title: 'Internal',
        },
    })
    public internal?: boolean;

    public count?: number;

}
