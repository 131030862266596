import { ComponentType } from "react";
import { Fieldsets, IInputProps, Input } from "react-ts-form";

import TagData from "./TagData";
import TextInput from "../form/inputs/TextInput";
import ContentTypeData from "./ContentTypeData";
import FileInput from "../form/inputs/FileInput";
import Label from "../core/Label";
import ThemeData from "./ThemeData";
import { BehaviorMultiInput, CollectorInput, ContentTypeInput, GlossaryInput, LocaleMultiInput, TagMultiInput, TemplateInput, ThemeInput } from "../form/common";
import SwitchInput from "../form/inputs/SwitchInput";
import FileData from "./FileData";
import DurationInput from "../form/inputs/DurationInput";
import DateTimeInput from "../form/inputs/DateTimeInput";
import NumberInput from "../form/inputs/NumberInput";
import TemplateData from "./TemplateData";
import ListSingleChoiceInput from "../form/inputs/ListSingleChoiceInput";
import { launcherRegistry } from "../registries";
import GlossaryData from "./GlossaryData";
import Collector from "./Collector";
import BehaviorData from "./BehaviorData";
import LangStringInput from "../form/inputs/LangStringInput";
import { Shuttle } from "../types";
import ContentProviderService from "../services/ContentProviderService";
import ContentProvider from "./ContentProvider";

const contentSystemPickers: { [handler: string]: ComponentType<IInputProps<ContentProvider>> } = {};

function getContentSystemPicker(handler: string) {
    return contentSystemPickers[handler] || (contentSystemPickers[handler] = ListSingleChoiceInput.of({
        options: async params => ContentProviderService.search(params).then(r => r.data),
        choiceRenderer: {
            getKey: o => o.id,
            getLabel: o => o.name,
            equals: (left, right) => left?.id === right?.id,
        },
        entityRemoveToken: true,
    }));
}

@Fieldsets({
    general: <Label k="general" />,
    appearance: <Label k="appearance" />,
    rules: <Label k="rules" />
})
export default class ContentData {

    public id!: number;

    public uuid!: string;

    @Input((_, { parent }) => {
        if (parent.uuid) {
            return {};
        }
        
        return {
            component: ContentTypeInput,
            meta: {
                title: <Label k="type" />,
                required: true
            }
        };
    })
    public type!: ContentTypeData;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="name" />,
            required: true
        },
        fieldset: "general",
    })
    public name!: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="externalId" />

        },
        inputProps: {
            maxLength: 128
        },
        fieldset: "general",
    })
    public externalId?: string;


    @Input((_, { parent }) => ({
        component: LangStringInput,
        meta: {
            title: <Label k="displayName" />
        },
        fieldset: "general",
        inputProps: {
            languages: parent.locale
        },
    }))
    public displayName!: Shuttle.LangString

    @Input((_, { parent }) => ({
        component: LangStringInput,
        meta: {
            title: <Label k="subTitle" />
        },
        fieldset: "general",
        inputProps: {
            languages: parent.locale
        },
    }))
    public subTitle?: Shuttle.LangString

    @Input({
        component: LocaleMultiInput,
        meta: {
            title: <Label k="locale" />,
            description: 'First locale will be default.',
        },
        fieldset: 'appearance'
    })
    public locale?: string;

    /*
    @Input({
        component: ContentStatusInput,
        meta: {
            title: <Label k="status" />
        }
    })
    public status!: 'Draft' | 'Predeployed' | 'Deployed' | 'Expired' | 'Deleted';
    */

    public startDate?: string;
    public endDate?: string;

    @Input({
        component: DateTimeInput,
        meta: {
            title: <Label k='displayStartDate' />
        },
        inputProps: { time: true },
        fieldset: "general",
    })
    public displayStartDate?: string;

    @Input({
        component: DateTimeInput,
        meta: {
            title: <Label k='displayEndDate' />
        },
        inputProps: { time: true },
        fieldset: "general",
    })
    public displayEndDate?: string;

    public createDate!: string;
    public lastModified?: string;

    @Input((_, { parent }) => ({
        component: LangStringInput,
        meta: {
            title: <Label k="description" />
        },
        fieldset: "general",
        inputProps: {
            type: 'richText' as const,
            languages: parent.locale,
        },
    }))
    public description?: Shuttle.LangString

    @Input({
        component: DurationInput,
        meta: {
            title: <Label k="duration" />
        },
        fieldset: 'general',
    })
    public duration?: number;

    @Input({
        component: FileInput,
        meta: {
            title: <Label k="picture" />
        },
        fieldset: 'appearance',
        inputProps: {
            accept: 'image/*',
        },
    })
    public picture?: FileData;

    @Input({
        component: FileInput,
        meta: {
            title: <Label k="icon" />
        },
        fieldset: 'appearance',
        inputProps: {
            accept: 'image/*',
        },
    })
    public icon?: FileData;

    @Input({
        component: TagMultiInput,
        meta: {
            title: <Label k="tags" />
        },
        fieldset: 'general',
    })
    public tags!: null | TagData[];

    @Input({
        component: GlossaryInput,
        meta: {
            title: <Label k="glossary" />
        }
    })
    public glossary!: null | GlossaryData;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="sequential" />
        },
        exclude: (_, { parent }) => !parent.type?.allowsChildren,
        fieldset: 'rules'
    })
    public sequential!: boolean;

    @Input({
        component: NumberInput,
        meta: {
            title: <Label k="passScore" />
        },
        inputProps: {
            float: true,
            min: 0,
            max: 100,
        },
        fieldset: 'rules',
    })
    public score!: number;

    @Input({
        component: DurationInput,
        meta: {
            title: <Label k="minTimeSpent" />,
        },
        fieldset: 'rules',
    })
    public minTimeSpent?: number;

    @Input({
        component: ThemeInput,
        meta: { 
            title: <Label k="theme" />
        },
        fieldset: 'appearance',
    })
    public theme?: ThemeData;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="launch.new.window.switch" />
        },
        fieldset: 'general'
    })
    public newWindow?: boolean;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="dimension" />,
            description: "'auto' or WxH (e.g. 16x9, 4x3)",
        },
        fieldset: 'appearance',
    })
    public dimension?: string;

    @Input((_, { parent }) => {

        if (!parent.type) {
            return {};
        }

        const launcher = launcherRegistry.get(parent.type.launcher);

        if (!launcher?.hasSystem) {
            return {};
        }

        return {
            component: getContentSystemPicker(parent.type.launcher),
            meta: {
                title: "Provider",
            },
        };
    })
    public provider?: ContentProvider;

    @Input((_, { parent }) => {
        if (!parent?.type?.launcher) {
            return {};
        }
        return { clazz: launcherRegistry.get(parent.type.launcher)?.ConfigClass };
    })
    public details?: any;

    @Input((_, { parent }) => {
        if (!launcherRegistry.get(parent.type?.launcher ?? '')?.hasQuestions) {
            return {};
        }
        return {
            component: NumberInput,
            meta: {
                title: <Label k="maxAttemptAllowed" />
            }
        };
    })
    public maxAttempts: number = 0;

    @Input((_, { parent }) => {
        if (!launcherRegistry.get(parent.type?.launcher ?? '')?.hasQuestions) {
            return {};
        }
        return {
            component: SwitchInput,
            meta: {
                title: <Label k="allow_review_attempts" />
            }
        };
    })
    public allowReviewAttempts?: boolean;

    @Input((_, { parent }) => {
        if (parent?.type?.launcher !== 'container' && parent?.type?.launcher !== 'Page') {
            return {};
        }
        return { 
            component: TemplateInput, 
            meta: { title: <Label k="template" /> }
        };
    })
    public template?: TemplateData;

    @Input({
        component: CollectorInput,
        meta: {
            title: <Label k="collector" />
        }
    })
    public collector?: Collector;

    @Input({
        component: BehaviorMultiInput,
        meta: {
            title: <Label k="behaviors" />
        }
    })
    public behaviors?: BehaviorData[];

    //write-only, TODO clean this up
    iconUrl?: string;
    pictureUrl?: string;
    resourceUrl?: string;

}
