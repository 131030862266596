import Axios from "axios";
import SearchParams from "../model/SearchParams";
import SearchResult from "../model/SearchResult";
import { Shuttle } from "../types";

export default function createEntityService<T extends { id: number; }, C = T, U extends T = T, P extends SearchParams = SearchParams>(args: { 
    path: string;
    beforeCreate?: (data: C) => Promise<C>;
    beforeUpdate?: (data: U) => Promise<U>;
    allowDelete?: boolean;
}) {

    return {
        search: async (params: Partial<P>) => Axios.get<Shuttle.ResponseData<SearchResult<T>>>(args.path, { params }).then(res => res.data),
        get: async (id: number) => Axios.get<Shuttle.ResponseData<T>>(args.path + '/' + id).then(res => res.data),
        create: async (data: C) => {
            if (args.beforeCreate) {
                data = await args.beforeCreate(data);
            }
            return Axios.post<Shuttle.ResponseData<T>>(args.path, data).then(res => res.data);
        },
        update: async (data: U) => {
            if (args.beforeUpdate) {
                data = await args.beforeUpdate(data);
            }
            return Axios.patch<Shuttle.ResponseData<T>>(args.path + '/' + data.id, data).then(res => res.data);
        },
        delete: args.allowDelete ? async (data: T | number) => Axios.delete<Shuttle.ResponseData<void>>(args.path + '/' + (typeof data === 'object' ? data.id : data)).then(res => res.data) : undefined,
    };
}
