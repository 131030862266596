import { ExpandMore, Timeline } from "@mui/icons-material";
import { Accordion, AccordionSummary, AccordionDetails, Box, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import useResolver from "../hooks/useResolver";
import EnrollmentData from "../model/EnrollmentData";
import { launcherRegistry } from "../registries";
import EnrollmentService from "../services/EnrollmentService";
import { DateLabel } from "./DateLabel";
import Label from "./Label";
import LabelledIconButton from "./ui/LabelledIconButton";
import ModalDialog from "./ui/ModalDialog";

export default function EnrollmentXApiBrowser({ enrollment }: { enrollment: EnrollmentData }) {

    const [open, setOpen] = useState(false);

    const { data, loading } = useResolver(useCallback(async () => open ? await EnrollmentService.xApiList(enrollment.id) : null, [open, enrollment]));
    
    return (
        <>
            <LabelledIconButton 
                icon={Timeline}
                k="x_api"
                onClick={() => setOpen(true)}
                disabled={!Boolean(launcherRegistry.get(enrollment.content.type.launcher)?.hasXApi)}
            />
            <ModalDialog 
                title={<Label k="x_api" />}
                open={open}
                onClose={() => setOpen(false)}
            >
                {
                    data?.length ? (
                        data.map(({statement, createDate}) => (
                            <Accordion key={statement.id}>
                                <AccordionSummary expandIcon={<ExpandMore />}>
                                    <Typography sx={{ flexGrow: 1 }}>
                                        {
                                            (
                                                statement.verb.display?.['en-US'] 
                                                || statement.verb.display?.['und']
                                                || statement.verb.id
                                            )
                                            + ' ' + (
                                                statement.object.definition?.name?.['en-US']
                                                || statement.object.definition?.name?.['und'] 
                                                || statement.object.id
                                            )
                                            + ' (' + statement.object.objectType + ')'
                                        }
                                    </Typography>
                                    <Typography sx={{ color: 'text.secondary' }}>
                                        <DateLabel date={createDate} format="Pp" />
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box component="code" sx={{ whiteSpace: 'pre-wrap' }}>
                                        {JSON.stringify(statement, null, 2)}
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        ))
                    ) : loading ? 'loading...' : <Label k="no_results" />
                }
            </ModalDialog>
        </>
    );
}
