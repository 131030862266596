import { Input } from "react-ts-form";
import Label from "../core/Label";
import TextInput from "../form/inputs/TextInput";
import { Shuttle } from "../types";
import StringUtil from "../util/StringUtil";

class BooleanCondition {

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="property_name" />,
            required: true,
        }
    })
    public name!: string;
    
}

const stateBooleanCondition: Shuttle.Condition<BooleanCondition> = {
    name: 'condition.boolean',
    ConfigClass: BooleanCondition,
    evaluate: ({ config = {} as BooleanCondition }, { state }) => Boolean(state[config.name]?.some(StringUtil.toBoolean)),
};

export default stateBooleanCondition;
