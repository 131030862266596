import { Shuttle } from "../../types";
import ZoomIcon from "@mui/icons-material/ZoomIn";
import { Fieldsets, Input } from "react-ts-form";
import { Box, Button, Grid, Typography } from "@mui/material";

import Label from "../../core/Label";
import TextInput from "../../form/inputs/TextInput";
import SwitchInput from "../../form/inputs/SwitchInput";
import RunningText from "../../core/ui/RunningText";
import { DateRangeLabel } from "../../core/DateLabel";
import useLayoutString from "../../layout/useLayoutString";

@Fieldsets({
    zoomsettings: <Label k="zoomsettings" />
})
class ZoomSettings {

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="webinar" />,
        }
    })
    public webinar?: boolean;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="meetingId" />
        },
        fieldset: 'zoomsettings'
    })
    public contentId?: string;

}

interface Config {
    url?: string;
}

export const ZoomLauncher: Shuttle.Launcher = {
    name: 'launcher.zoom',
    Icon: ZoomIcon,
    ConfigClass: ZoomSettings,
    hasSystem: true,
    Component: ({ contentNode: { content }, session }) => {

        const title = useLayoutString({
            text: content.title,
        })

        const description = useLayoutString({
            text: content.description,
        })

        const config = session.config as Config;
        
        return (
            <Box my={3} mx="auto" maxWidth="1200px">
                <Grid container spacing={2}>
                    {
                        content.pictureUrl && (
                            <Grid item xs={12} md={3}>
                                <img src={content.pictureUrl} alt={title} style={{width: '100%'}} />
                            </Grid>
                        )
                    }
                    <Grid item xs={12} md={9}>
                        <Typography component="h3" variant="h5" gutterBottom>
                            {title}
                        </Typography>
                        {
                            content.start && (
                                <Typography color="textSecondary" variant="subtitle2" gutterBottom>
                                    <DateRangeLabel start={content.start} end={content.end} />
                                </Typography>
                            )
                        }
                        {
                            content.description && (
                                <RunningText html={description} gutterBottom />
                            )
                        }
                        <Button 
                            disabled={!config.url}
                            component="a" 
                            href={config.url} 
                            target="_zoom" 
                            variant="contained" 
                            color="primary"
                            size="large"
                        >
                            <Label k="open" />
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        );
    }
};
