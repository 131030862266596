import SearchParams from "../model/SearchParams";
import axios from "axios";
import ContentTypeData from "../model/ContentTypeData";
import SearchResult from "../model/SearchResult";


export default class ContentTypeService {

    private static instance: ContentTypeService;

    public static get() {
        return this.instance || (this.instance = new ContentTypeService());
    }

    public async search(params: SearchParams) {
        return axios.get<SearchResult<ContentTypeData>>('/content-types', { params }).then(res => res.data);
    }
}
