import { lazy } from "react";

export const RichTextEditor = lazy(() => import('./vendor/RichTextEditor'));

export const CodeEditor = lazy(() => import('./vendor/CodeEditor'));

export const VideoPlayer = lazy(() => import('./vendor/VideoPlayer'));

export const Player = lazy(() => import('./player/Player'));

export const SumSubSdk = lazy(() => import('@sumsub/websdk-react'));
