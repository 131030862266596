import dateFormat from "date-fns/locale/ru";
import { ruRU } from "@mui/material/locale";
import dictionary from "./ru_RU.json";

import { ILocale } from "../Locale";

const ru_RU: ILocale = {
    rtl: false, // right to left
    dictionary,
    dateFormat,
    muiLocale: ruRU
};

export default ru_RU;
//russian