import { useCallback, useEffect, useRef, useState } from "react";
import * as React from "react";
import { IInputProps } from "react-ts-form";

type DebouncedProps<T extends IInputProps<U>, U = T["value"]> = { component: React.ComponentType<T>; } & T;

export default function Debounced<T extends IInputProps<U>, U = T["value"]>(props: DebouncedProps<T, U>) {

    const timer = useRef<ReturnType<typeof setTimeout>>();
    const synced = useRef(true);
    const handler = useRef<DebouncedProps<T, U>['onChange']>(props.onChange);
    if (handler.current !== props.onChange) {
        handler.current = props.onChange;
    }

    const [temp, setTemp] = useState<{ next: U; } | null>(null);
    
    const onChange = useCallback((next: U) => {
        if (timer.current) {
            clearTimeout(timer.current);
        }
        setTemp({ next });
        timer.current = setTimeout(() => {
            synced.current = false;
            handler.current(next);
        }, 400);
    }, []);

    let value = props.value;

    useEffect(() => {
        if (!synced.current) {
            synced.current = true;
            setTemp(null);
        }
    }, [value]);

    const { component, ...rest } = props;

    value = temp ? temp.next : props.value;

    return React.createElement(component, { ...rest as unknown as T, onChange, value });
}
