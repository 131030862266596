import Axios from "axios";
import EnrollmentData from "../model/EnrollmentData";
import ResponseData from "../model/ResponseData";
import SearchParams from "../model/SearchParams";
import SearchResult from "../model/SearchResult";
import UserData from "../model/UserData";
import createEntityService from "./createEntityService";
import UploadService from "./UploadService";
import { UserGroup } from "../model/Group";

async function saveFiles(user: UserData) {
    if (user.avatar?.file) {
        user = {...user, avatar: await UploadService.get().upload(user.avatar.file)};
    }
    return user;
}

const UserService = {
    ...createEntityService<UserData>({ 
        path: '/users',
        beforeCreate: saveFiles,
        beforeUpdate: saveFiles,
    }),

    searchEnrollments: async (params: SearchParams, context: UserData) => {
        return Axios.get<ResponseData<SearchResult<EnrollmentData>>>('/users/' + context.id + '/enrollments', { params }).then (r => r.data);
    },

    searchGroups: async (userId: number | string, params: SearchParams) => await Axios
        .get<ResponseData<SearchResult<UserGroup>>>(`/users/${userId}/groups`, { params })
        .then(r => r.data),
    getGroup: async (userId: number | string, groupId: number | string) => await Axios
        .get<ResponseData<UserGroup>>(`/users/${userId}/groups/${groupId}`)
        .then(r => r.data),
    addGroup: async (userId: number | string, groupId: number | string, data: Omit<UserGroup, 'user' | 'group'>) => await Axios
        .put<ResponseData<UserGroup>>(`/users/${userId}/groups/${groupId}`, data)
        .then(r => r.data),
    removeGroup: async (userId: number | string, groupId: number | string) => await Axios
        .delete(`/users/${userId}/groups/${groupId}`)
        .then(r => r.data),

    enroll: async (userId: number | string, contentId: number) => await Axios
        .post(`/users/${userId}/enroll`, { contentId })
        .then(r => r.data),

    listVerifications: async (userId: number | string) => await Axios
        .get<{
            id: number;
            uuid: string;
            status: 'PENDING' | 'SUCCESS' | 'FAILURE';
            createDate: string;
            completionDate?: string;
            ip: string;
            userAgent: string;
        }[]>(`/users/${userId}/identity-verifications`)
        .then(r => r.data),
};

export default UserService;
