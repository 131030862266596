import { Shuttle } from "../../types";
import QuizIcon from "@mui/icons-material/Quiz";
import { Alert, AlertTitle, Box, Button, Card, CardActionArea, CardContent, FormControlLabel, Grid, LinearProgress, Stack, Typography } from "@mui/material";
import { createElement, ReactNode, useCallback, useRef, useState } from "react";

import SwitchInput from "../../form/inputs/SwitchInput";
import useResolver from "../../hooks/useResolver";
import { QuizAttemptQuestionData } from "../../model/quiz/QuizNavigationData";
import { QuestionTypeRegistry } from "../../quiz/QuestionType";
import Label from "../../core/Label";
import RunningText from "../../core/ui/RunningText";
import { api } from "../../player/api";
import QuestionModel from "../../model/quiz/QuestionModel";
import Constants from "../../util/Constants";   
import { ValidLabelKey } from "../../core/localization/ValidLabelKey";
import LayoutString from "../../layout/LayoutString";
import useLayoutString from "../../layout/useLayoutString";
import StringUtil from "../../util/StringUtil";

class QuizSettings {

}

const KEY_MODIFIED = Symbol('modified');

interface QuestionDisplayProps<T extends QuestionModel, R> {
    attempt: Shuttle.QuizAttemptData;
    number: number;
    disabled?: boolean;
    question: QuizAttemptQuestionData<T, R>;
    onChange: (res: R) => void;
}

function QuestionDisplay<T extends QuestionModel, R>({ attempt, disabled, number, question, onChange }: QuestionDisplayProps<T, R>) {

    const type = QuestionTypeRegistry.get(question.type);
    disabled = Boolean(disabled || attempt.status === 'PUBLISHED');

    if (!type) {
        return (
            <Alert severity="warning">
                {'Unsupported Question Type: ' + question.type}
            </Alert>
        );
    }

    const hasFeedback = !!question.feedback && Object.values(question.feedback).some(x => (x ?? '').trim());

    return (
        <div>
            <Typography variant="caption" color="text-secondary">
                <Label k="question_index" args={{ number, count: attempt.questions.length }} />
            </Typography>
            <Box sx={{ mb: 1 }}>
                <RunningText html={question.content.stem} />
            </Box>
            {
                createElement(type.Component, {  
                    model: question.content, 
                    value: question.response,
                    stats: question.stats,
                    onChange,
                    disabled: disabled || question.readOnly,
                    showCorrectAnswer: !!question.showCorrectAnswer,
                    type: attempt.type,
                })
            }
            {
                question.showCorrectAnswer && (attempt.type === 'QUIZ' || hasFeedback) && (
                    <Box mt={2}>
                        <Alert 
                            severity={
                                attempt.type === 'SURVEY'
                                    ? 'info'
                                    : question.result === 'CORRECT' 
                                    ? 'success' 
                                    : question.result === 'INCORRECT' 
                                    ? 'error' 
                                    : question.result === 'SKIPPED' 
                                    ? 'warning' 
                                    : undefined
                            }
                        >
                            {
                                attempt.type === 'QUIZ' && !!question.result && (
                                    <AlertTitle>
                                        <Label k={question.result.toLowerCase() as ValidLabelKey} />
                                    </AlertTitle>
                                )
                            }
                            {
                                question.feedback && (
                                    <RunningText html={question.feedback} />
                                )
                            }
                            {
                                (attempt.type === 'QUIZ' && question.stats) && (
                                    <Typography variant="caption" component="div" sx={{ mt: 1 }}>
                                        <Label k="question_statistics_correct" args={{ percent: question.stats.percentCorrect ?? 0 }} />
                                        {
                                            question.stats.percentSkipped > 0 && (
                                                <>
                                                    {' | '}
                                                    <Label k="question_statistics_skipped" args={{ percent: question.stats.percentSkipped }} />
                                                </>
                                            )
                                        }
                                    </Typography>
                                )
                            }
                        </Alert>
                    </Box>
                )
            }
        </div>
    );
}
interface QuestionRangeProps {
    attempt: Shuttle.QuizAttemptData;
    onChangeQuestions(questions: Shuttle.QuizAttemptQuestionData<any, any>[]): void;
    displayTime?: number;
    finishLabel?: ReactNode;
    onFinish(): void;
    sessionEntry: Shuttle.PlaySessionEntryData;
    disabled?: boolean;
    navDisabled?: boolean;
    onChangePage?: (page: number) => void;
}

export function QuestionRange({
    attempt,
    disabled,
    displayTime,
    finishLabel,
    navDisabled,
    onChangePage,
    onChangeQuestions,
    onFinish,
    sessionEntry: { entryId },
}: QuestionRangeProps) {

    const [saving, setSaving] = useState(false);

    const tsRef = useRef(Date.now());

    let range = attempt.questions;

    if (typeof displayTime === 'number') {
        range = range.filter(q => q.displayTime === displayTime);
    }

    const pages = range
        .map(q => q.pageIndex)
        .filter((p, i, a) => a.indexOf(p) === i)
        .sort((a, b) => a - b);

    const [page, setPage] = useState(Math.max(0, pages.indexOf(StringUtil.toInteger(attempt.bookmark ?? '', 0))));

    const current = pages[page];

    range = range.filter(q => q.pageIndex === current).sort((a, b) => a.displayOrder - b.displayOrder);

    const hasChangedResponses = Boolean(range.find(q => (q as any)[KEY_MODIFIED]));
    const nextDisabled = Boolean(saving || navDisabled || range.find(q => q.required && !q.response));

    const saveResponses = async () => {
        let changed = range.filter(q => (q as any)[KEY_MODIFIED]);
        if (!changed.length) {
            return changed;
        }
        setSaving(true);
        const timeSpent = Math.floor((Date.now() - tsRef.current) / 1000);
        changed = await api.quiz.submitResponses(entryId, attempt.id, changed.map(q => ({ ...q, timeSpent })));
        setSaving(false);
        onChangeQuestions(attempt.questions.map(a => changed.find(b => a.id === b.id) ?? a));
        tsRef.current = Date.now();
        return changed;
    }

    const changePage = async (page: number) => {
        await saveResponses();
        setPage(page);
        onChangePage?.(page);
        tsRef.current = Date.now();
    }

    return (
        <>
            {
                range.map(question => (
                    <QuestionDisplay
                        key={question.id}
                        attempt={attempt}
                        question={question}
                        number={question.displayOrder + 1}
                        onChange={response => onChangeQuestions(attempt.questions.map(q => q.id === question.id ? { ...q, response, [KEY_MODIFIED]: true } : q))}
                        disabled={disabled}
                    />
                ))
            }
            <Stack direction="row" spacing={2} justifyContent="center" mt={2}>
                {
                    (attempt.allowPrevious && page > 0) && (
                        <Button
                            disabled={saving}
                            onClick={() => changePage(page - 1)}
                            color="inherit"
                        >
                            <Label k="previous" />
                        </Button>
                    )
                }
                {
                    attempt.remediation && (
                        <Button
                            disabled={saving || !hasChangedResponses}
                            onClick={saveResponses}
                            color="inherit"
                        >
                            <Label k="show_feedback" />
                        </Button>
                    )
                }
                {
                    page < pages.length - 1 ? (
                        <Button
                            disabled={nextDisabled}
                            onClick={() => changePage(page + 1)}
                            color="inherit"
                        >
                            <Label k="next" />
                        </Button>
                    ) : (
                        <Button
                            disabled={nextDisabled}
                            onClick={async () => {
                                await saveResponses();
                                onFinish();
                            }}
                            color="inherit"
                        >
                            {finishLabel || <Label k="submit" />}
                        </Button>
                    )
                }
            </Stack>
        </>
    );
}

interface AttemptResultsProps {
    attempt: Shuttle.QuizAttemptData;
}

export function AttemptResults({ attempt }: AttemptResultsProps) {

    const [incorrectOnly, setIncorrectOnly] = useState(false);

    const message = useLayoutString({
        text: attempt.message,
        html: true,
    })

    const percent = (attempt?.maxScore && attempt.maxScore > 0 ? ((attempt.score || 0) / attempt.maxScore) * 100 : 0);

    return (
        <div>
            {
                message && (
                    <Alert
                        severity={attempt.result === 'PASSED' ? 'success' : attempt.result === 'FAILED' ? 'error' : 'info'}
                        sx={{ my: 2 }}
                    >
                        <RunningText html={message} />
                    </Alert>
                )
            }
            {
                (attempt.result === 'PASSED' || attempt.result === 'FAILED') && (
                    <>
                        <Typography component="h5" variant="overline">
                            {`${attempt.score} / ${attempt.maxScore}`}
                        </Typography>
                        <Box display="flex" alignItems="center">
                            <Box mr={1} width="100%">
                                <LinearProgress 
                                    value={percent}
                                    variant="determinate"
                                />
                            </Box>
                            <Box minWidth={35}>
                                <Typography variant="body2">
                                    {Math.ceil(percent) + '%'}
                                </Typography>
                            </Box>
                        </Box>
                    </>
                )
            }
            {
                attempt.allowQuestionReview && !!attempt.questions?.length && (
                    <Box sx={{ py: 2 }}>
                        {
                            attempt.type === 'QUIZ' && (incorrectOnly || attempt.questions.find(q => q.result === 'INCORRECT')) && (
                                <Stack flexDirection="row-reverse">
                                    <FormControlLabel 
                                        control={
                                            <SwitchInput value={incorrectOnly} onChange={setIncorrectOnly} />
                                        }
                                        label={<Label k="show_incorrect_only" />}
                                    />
                                </Stack>
                            )
                        }
                        {
                            attempt.questions
                                .map((question, index) => ({ question, index }))
                                .filter(({ question }) => !incorrectOnly || question.result === 'INCORRECT')
                                .map(({ question, index }) => (
                                    <Card key={question.id} sx={{ my: 2 }}>
                                        <CardContent>
                                            <QuestionDisplay
                                                attempt={attempt}
                                                question={question}
                                                number={index + 1}
                                                disabled
                                                onChange={Constants.NO_OP_FUNC}
                                            />
                                        </CardContent>
                                    </Card>
                                ))
                        }
                    </Box>
                )
            }
        </div>
    );
}

interface AttemptCreatorProps {
    init: Shuttle.QuizInitData;
    onCreated(init: Shuttle.QuizInitData): void;
    sessionEntry: Shuttle.PlaySessionEntryData;
}

export function AttemptCreator({ init: { attempt, modes }, onCreated, sessionEntry }: AttemptCreatorProps) {

    const [saving, setSaving] = useState(false);

    const createAttempt = async (modeId: number) => {
        setSaving(true);
        const init = await api.quiz.createAttempt(sessionEntry.entryId, modeId);
        setSaving(false);
        onCreated(init);
    }

    if (modes.length === 1) {
        return (
            <Button
                variant="contained"
                color="primary"
                disabled={saving}
                onClick={() => createAttempt(modes[0].id)}
            >
                <Label k={attempt ? (attempt.result === 'FAILED' ? "quiz.retry" : 'quiz.review') : "quiz.start"} />
            </Button>
        );
    }

    return (
        <>
            <Typography variant="h4" gutterBottom>
                <Label k={attempt ? (attempt.result === 'FAILED' ? "quiz.retry" : 'quiz.review') : "choose_mode"} />
            </Typography>
            {
                modes.length ? (
                    <Grid container spacing={2}>
                        {
                            modes.map(m => (
                                <Grid key={m.id} item xs={12} md={6}>
                                    <Card
                                        variant="outlined"
                                        sx={{
                                            borderRadius: theme => theme.shape.borderRadius,
                                            height: '100%',
                                        }}
                                    >
                                        <CardActionArea
                                            onClick={() => createAttempt(m.id)}
                                            style={{ height: '100%' }}
                                        >
                                            <CardContent style={{ height: '100%' }}>
                                                <Typography fontWeight={700}>
                                                    {m.displayName ? <LayoutString text={m.displayName} /> : m.name}
                                                </Typography>
                                                {
                                                    m.description && <RunningText html={m.description} />
                                                }
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            ))
                        }
                    </Grid>
                ) : (
                    <Typography color="text.secondary">
                        <Label k="no_modes_available" />
                    </Typography>
                )
            }
        </>
    );
}

export const QuizLauncher: Shuttle.Launcher = {
    name: 'launcher.quiz',
    Icon: QuizIcon,
    ConfigClass: QuizSettings,
    tracking: true,
    hasQuestions: true,
    Component(props) {
        const { session } = props;
        const { data, loading, setState } = useResolver(useCallback(async () => api.quiz.init(session.entryId), [session]));

        if (!data) {
            return null;
        }
        
        const { attempt, canCreateAttempt } = data;

        return (
            <Box sx={{ maxWidth: '800px', mx: 'auto', my: 3, px: 2, width: '100%' }}>
                {
                    (attempt && (attempt.status === 'ASSIGNED' || attempt.status === 'IN_PROGRESS')) ? (
                        <QuestionRange
                            attempt={attempt}
                            sessionEntry={session}
                            onChangeQuestions={questions => setState(prev => {
                                if (prev.data) {
                                    return {
                                        ...prev,
                                        data: {
                                            ...prev.data,
                                            attempt: {
                                                ...prev.data.attempt,
                                                questions,
                                            },
                                        },
                                    }
                                }
                                return prev;
                            })}
                            onChangePage={page => api.quiz.bookmarkAttempt(session.entryId, attempt.id, page)}
                            onFinish={async () => {
                                setState(prev => ({ ...prev, loading: true }));
                                const data = await api.quiz.submitAttempt(session.entryId, attempt.id);
                                setState({ data, loading: false });
                                setTimeout(() => props.track.commit({
                                    completion: undefined,
                                    score: undefined,
                                    maxScore: undefined,
                                    progress: undefined,
                                }), 500);
                            }}
                            navDisabled={loading}
                        />
                    ) : (
                        <div>
                            {
                                canCreateAttempt && (
                                    <AttemptCreator
                                        init={data}
                                        onCreated={data => setState(prev => ({ ...prev, data }))}
                                        sessionEntry={session}
                                    />
                                )
                            }
                            {
                                attempt && <AttemptResults attempt={attempt} />
                            }
                        </div>
                    )
                }
            </Box>
        );
    }
}
