import { Box, Button, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { createComponentAction, createEntitySection } from "../../core/admin/AdminSection";
import { ApiKey } from "../../model/ApiKey";
import ApiKeyService from "../../services/ApiKeyService";
import SwitchInput from "../../form/inputs/SwitchInput";
import { DateLabel } from "../../core/DateLabel";
import DecoratorForm from "../../form/DecoratorForm";
import { useState } from "react";
import ModalDialog from "../../core/ui/ModalDialog";
import CopyIcon from '@mui/icons-material/ContentCopy';
import LabelledIconButton from "../../core/ui/LabelledIconButton";
import BrowserUtil from "../../util/BrowserUtil";

export default createEntitySection({
    model: ApiKey,
    getId: o => o.id,
    label: o => o.name,
    ...ApiKeyService,
    defaultSort: '-id',
    columns: [
        {
            key: 'name',
            sort: 'name',
            renderCell: ({ data }) => (
                <>
                    <Typography fontWeight={700}>
                        {data.name}
                    </Typography>
                    <Typography color="text.secondary">
                        {data.uuid}
                    </Typography>
                </>
            ),
        },
        {
            key: 'externalId',
            sort: 'externalId',
        },
        {
            key: 'enabled',
            sort: 'enabled',
            renderCell: ({data, reload}) => (
                <SwitchInput 
                    value={!!data.enabled}
                    onChange={
                        enabled => ApiKeyService.update({...data, enabled}).then(reload)
                    }
                />
            ),
        },
        {
            key: 'user',
            sort: 'user.firstName',
            renderCell: ({ data }) => data.user.fullName,
        },
        {
            key: 'consumer',
            renderCell: ({ data }) => data.consumer?.name ?? '--',
        },
        {
            key: 'createDate',
            sort: 'createDate',
            renderCell: ({ data }) => <DateLabel date={data.createDate} format="Pp" />
        },
    ],
    actions: [
        createComponentAction({
            key: 'create',
            onSubmit: async () => true,
            Component: ({ close }) => {

                const [saved, setSaved] = useState<null | ApiKey>(null);
                const [confirmed, setConfirmed] = useState(false);
                
                return (
                    <>
                        <DecoratorForm
                            clazz={ApiKey}
                            onSubmit={async apiKey => {
                                const saved = await ApiKeyService.create(apiKey).then(r => r.data);
                                setSaved(saved);
                                return saved;
                            }}
                        />
                        <ModalDialog open={!!saved}>
                            {
                                saved && (
                                    <Stack sx={{ p: 2 }} direction="column" spacing={2}>
                                        <TextField 
                                            label="Username" 
                                            InputProps={{
                                                readOnly: true,
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <LabelledIconButton
                                                            k="copy.link" 
                                                            icon={CopyIcon}
                                                            onClick={() => {
                                                                BrowserUtil.copyToClipboard(saved.uuid);
                                                                alert('Copied to clipboard');
                                                            }}
                                                        />
                                                    </InputAdornment>
                                                )
                                            }}
                                            fullWidth
                                            value={saved.uuid}
                                            variant="outlined"
                                            helperText="This value will never be shown to anyone again, so be sure to save it somewhere."
                                        />
                                        <TextField 
                                            label="Password" 
                                            InputProps={{
                                                readOnly: true,
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <LabelledIconButton
                                                            k="copy.link" 
                                                            icon={CopyIcon}
                                                            onClick={() => {
                                                                BrowserUtil.copyToClipboard(saved.secret!);
                                                                alert('Copied to clipboard');
                                                            }}
                                                        />
                                                    </InputAdornment>
                                                )
                                            }}
                                            fullWidth
                                            value={saved.secret!}
                                            variant="outlined"
                                            helperText="This value will never be shown to anyone again, so be sure to save it somewhere."
                                        />
                                        <TextField 
                                            label="Example (HTTP Header)" 
                                            InputProps={{
                                                readOnly: true,
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <LabelledIconButton
                                                            k="copy.link" 
                                                            icon={CopyIcon}
                                                            onClick={() => {
                                                                BrowserUtil.copyToClipboard(saved.secret!);
                                                                alert('Copied to clipboard');
                                                            }}
                                                        />
                                                    </InputAdornment>
                                                )
                                            }}
                                            fullWidth
                                            multiline
                                            value={saved.secret!}
                                            variant="outlined"
                                        />
                                        <Box component="code">
                                            {`Authorization: Basic ${btoa(saved.uuid + ':' + saved.secret)}`}
                                        </Box>
                                        <div>
                                            <SwitchInput
                                                value={confirmed}
                                                onChange={setConfirmed}
                                                title="I understand that this value will never be shown to me again"
                                                required
                                            />
                                        </div>
                                        <Button
                                            disabled={!confirmed}
                                            onClick={() => {
                                                setSaved(null);
                                                close(true);
                                            }}
                                        >
                                            {'Confirm and Close'}
                                        </Button>
                                    </Stack>
                                )
                            }
                        </ModalDialog>
                    </>
                );
            },
        })
    ],
})
