import { useFormattedDate, useFormatRelative, useFormattedDuration, useFormattedDateRange } from "../hooks/useDate";

interface IDateLabelProps {
    date: Date | number | string | null | undefined;
    format?: string;
}

export function DateLabel(props: IDateLabelProps) {
    return (
        <span>
            {useFormattedDate(props.date, props.format)}
        </span>
    );
}

interface IRelativeDateLabelProps {
    date: Date | number | string | null | undefined;
}

export function RelativeDateLabel(props: IRelativeDateLabelProps) {
    return (
        <span>
            {useFormatRelative(props.date)}
        </span>
    );
}

export function DurationLabel(props: { className?: string; seconds: number; }) {
    return (
        <span className={props.className}>
            {useFormattedDuration(props.seconds)}
        </span>
    );
}

export function DateRangeLabel(props: { className?: string; start: IDateLabelProps['date']; end: IDateLabelProps['date']; timeOnly?: boolean; }) {
    return (
        <span className={props.className}>
            {useFormattedDateRange(props.start, props.end, props.timeOnly)}
        </span>
    );
}
