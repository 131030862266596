import { useContext, useState } from "react";
import { Shuttle } from "../../types";
import { ConstraintsContext, ContentContext, SessionContext, SettingsContext } from "../../contexts";
import { flattenDescendents } from "./utils";
import { Alert, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, Link, Typography } from "@mui/material";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { getAlternative } from "../../player/utils";
import { Input } from "react-ts-form";
import LangStringInput from "../../form/inputs/LangStringInput";
import Label from "../../core/Label";
import bestLocaleMatch from "../../i18n/util";
import { Col, DataTable } from "../../core/ui/DataTable";
import ArrayUtil from "../../util/ArrayUtil";
import StringUtil from "../../util/StringUtil";
import { api } from "../../player/api";
import LayoutString from "../LayoutString";
import { DateLabel } from "../../core/DateLabel";

class ContentAwardsConfig {

    @Input({
        component: LangStringInput,
        meta: {
            title: 'Claim Button Text',
        },
    })
    public claimButtonText?: Shuttle.LangString;

    @Input({
        component: LangStringInput,
        meta: {
            title: 'Claim Pre-Confirmation Message',
            description: 'Prompt user to confirm this message before claiming.'
        },
    })
    public claimConfirmation?: Shuttle.LangString;

    @Input({
        component: LangStringInput,
        meta: {
            title: 'Successful Claim Feedback Message',
        },
    })
    public claimMessage?: Shuttle.LangString;

    @Input({
        component: LangStringInput,
        meta: {
            title: 'Issued Date Label',
        },
    })
    public issuedDateLabel?: Shuttle.LangString;

    @Input({
        component: LangStringInput,
        meta: {
            title: 'Claim Date Label',
        },
    })
    public claimDateLabel?: Shuttle.LangString;

    @Input({
        component: LangStringInput,
        meta: {
            title: 'Value Column Label',
        },
    })
    public valueLabel?: Shuttle.LangString;

}

export const ContentAwardsElement: Shuttle.LayoutElementType<ContentAwardsConfig> = {
    name: "layout_content_awards",
    Icon: EmojiEventsIcon,
    ConfigClass: ContentAwardsConfig,
    Component({ config }) {

        const { session, setSession } = useContext(SessionContext);
        const constraints = useContext(ConstraintsContext);
        const contentNode = useContext(ContentContext);
        const content = contentNode && getAlternative(constraints, contentNode);
        const [{ locale }] = useContext(SettingsContext);

        const [claimed, setClaimed] = useState(false);

        const awards = content ? flattenDescendents(content, constraints, false)
            .flatMap(({ content }, i, a) => {
                if (a.findIndex(c => c.content.id === content.id) !== i) {
                    return [];
                }
                return (session?.awards[content.uuid] ?? [])
                    .map(award => ({
                        award,
                        content,
                        issued: session?.issuedAwards?.[award.id],
                        tracking: session?.tracking?.[content.uuid],
                    }))
            }) : [];

        const unclaimed = awards.flatMap(({ issued }) => (issued && !issued.claimDate) ? [issued.id] : []);

        const [selected, setSelected] = useState(unclaimed);

        const allSelected = unclaimed.every(i => selected.includes(i));

        const buttonText = bestLocaleMatch(config.claimButtonText, locale);
        
        const confirmation = bestLocaleMatch(config.claimConfirmation, locale)?.trim() ?? '';
        const [confirming, setConfirming] = useState(false);

        const claim = async () => {
            setConfirming(false);
            if (!session || !selected?.length) return;
            const issuedAwards = await api.play.awards.claim(session.sessionId, selected);
            setClaimed(true);
            setSession(prev => ({
                ...prev,
                issuedAwards,
            }));
        };

        const columns: Col<typeof awards[number]>[] = [
            {
                key: 'select',
                invisible: unclaimed.length === 0,
                name: (
                    <Checkbox
                        checked={allSelected}
                        indeterminate={!allSelected && selected.length > 0}
                        onChange={e => setSelected(e.target.checked ? unclaimed : [])}
                        aria-label={allSelected ? 'Unselect all' : 'Selected all'}
                    />
                ),
                padding: 'checkbox',
                renderCell: ({ issued }) => !!issued && !issued.claimDate && (
                    <Checkbox
                        checked={selected.includes(issued.id)}
                        onChange={e => setSelected(ArrayUtil.toggle(selected, issued.id, Object.is, e.target.checked))}
                    />
                ),
            },
            {
                key: 'name',
                name: <Label k="name" />,
                renderCell: ({ content }) => bestLocaleMatch(content.title, locale),
            },
            {
                key: 'issueDate',
                name: bestLocaleMatch(config.issuedDateLabel, locale) || <Label k="award.issueDate" />,
                renderCell: ({ issued }) => issued && <DateLabel date={issued.issueDate} />
            },
            {
                key: 'claimDate',
                name: bestLocaleMatch(config.claimDateLabel, locale) ||<Label k="award.claimDate" />,
                renderCell: ({ issued }) => issued?.claimDate && <DateLabel date={issued.claimDate} />,
            },
        ];

        // only show this if there's more than 1 type
        const types = awards.map(({ award }) => bestLocaleMatch(award.title, locale)).filter((x, i, a) => a.indexOf(x) === i);
        if (types.length > 1) {
            columns.push({
                key: 'type',
                name: <Label k="type" />,
                renderCell: ({ award }) => bestLocaleMatch(award.title, locale),
            });
        }

        columns.push({
            key: 'value',
            name: bestLocaleMatch(config.valueLabel, locale),
            renderCell: ({ award, content, issued, tracking }) => {

                if (award.type === 'Credit') {
                    const value = issued?.value ?? (
                        award.valueExtractor === 'Value' ? award.value
                        : award.valueExtractor === 'Duration' ? content.duration 
                        : award.valueExtractor === 'Score' ? tracking?.score
                        : award.valueExtractor === 'TimeSpent' ? tracking?.timeSpent
                        : award.valueExtractor === 'UniqueTimeViewed' ? tracking?.uniqueTimeViewed
                        : undefined
                    );
                    if (typeof value === 'undefined') return '--';
                    if (award.valueExtractor === 'Duration' || award.valueExtractor === 'TimeSpent' || award.valueExtractor === 'UniqueTimeViewed') {
                        let display = StringUtil.formatHMS(value);
                        if (award.valueExtractor === 'UniqueTimeViewed' && content.duration) {
                            display += ' / ' + StringUtil.formatHMS(content.duration);
                        }
                        return display;
                    }
                    return value.toLocaleString();
                }
                if (award.type === 'Certificate') {
                    if (issued) {
                        return (
                            <Link href={issued.id.toString()} target="cert">
                                <Label k="view" />
                            </Link>
                        )
                    } else {
                        return (
                            <Typography color="disabled">
                                <Label k="view" />
                            </Typography>
                        );
                    }
                }
                return null;
            },
        });

        return (
            <>
                <form
                    onSubmit={async e => {
                        e.preventDefault();
                        if (confirmation) {
                            setConfirming(true);
                        } else {
                            await claim();
                        }
                    }}
                >
                    {
                        claimed && Object.values(config.claimMessage ?? {}).some(v => v?.trim()) && (
                            <Alert severity="success" sx={{ mb: 1 }}>
                                <LayoutString text={config.claimMessage} />
                            </Alert>
                        )
                    }
                    <DataTable rows={awards} columns={columns} />
                    {
                        unclaimed.length > 0 && (
                            <Box sx={{ mt: 1, textAlign: 'right' }}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={!selected.length}
                                >
                                    {
                                        buttonText || <Label k="claim_selected" />
                                    }
                                </Button>
                            </Box>
                        )
                    }
                </form>
                <Dialog open={confirming} onClose={() => setConfirming(false)}>
                    <DialogContent>
                        {confirmation}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={claim}
                            variant="contained"
                        >
                            <Label k="confirm" />
                        </Button>
                        <Button
                            onClick={() => setConfirming(false)}
                            sx={{ ml: 1 }}
                        >
                            <Label k="cancel" />
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    },
};
