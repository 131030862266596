import dateFormat from "date-fns/locale/de";
import { deDE } from "@mui/material/locale";
import dictionary from "./de_DE.json";

import { ILocale } from "../Locale";

const de_DE: ILocale = {
    rtl: false, // right to left
    dictionary,
    dateFormat,
    muiLocale: deDE
};

export default de_DE;
//german