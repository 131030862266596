import { Shuttle } from "../../types";
import ProgressIcon from '@mui/icons-material/DonutLarge';
import { Box, Button, CircularProgress, CircularProgressProps, LinearProgress, Typography, useTheme } from "@mui/material";
import { ReactNode, useContext, useMemo, useState } from "react";
import { Input } from "react-ts-form";
import RadioChoiceInput from "../../form/inputs/RadioChoiceInput";
import Label from "../../core/Label";
import NumberInput from "../../form/inputs/NumberInput";
import DropDownChoiceInput from "../../form/inputs/DropDownChoiceInput";
import { BookmarksContext, ConstraintsContext, ContentContext, SessionContext, SettingsContext } from "../../contexts";
import SwitchInput from "../../form/inputs/SwitchInput";
import { getAlternative } from "../../player/utils";
import TextInput from "../../form/inputs/TextInput";
import { TagNameMultiInput } from "../../form/common";
import LangStringInput from "../../form/inputs/LangStringInput";
import { filterContentChildren } from "./utils";
import StringUtil from "../../util/StringUtil";
import bestLocaleMatch from "../../i18n/util";
import ProgressCircle from "../../core/ui/ProgressCircle";
import { parseDate } from "../../hooks/useDate";
import { addWeeks, addDays } from "date-fns";
import ModalDialog from "../../core/ui/ModalDialog";
import DecoratorForm from "../../form/DecoratorForm";
import DateTimeInput from "../../form/inputs/DateTimeInput";

class ContentProgressConfig {

    @Input({
        component: RadioChoiceInput.of<'linear' | 'circle'>({
            options: ['linear', 'circle']
        }),
        meta: {
            title: <Label k="type" />
        }
    })
    public type?: 'linear' | 'circle' = 'linear';

    @Input({
        component: NumberInput,
        meta: {
            title: <Label k="size" />
        },
        inputProps: {
            float: true,
            min: 0,
        }
    })
    public size?: number;

    @Input({
        component: NumberInput,
        meta: {
            title: <Label k="thickness" />
        },
        inputProps: {
            float: true,
            min: 0,
        }
    })
    public thickness?: number;

    @Input({
        component: DropDownChoiceInput.of<CircularProgressProps['color']>({
            options: ['primary', 'secondary', 'success', 'info'],
        }),
        meta: {
            title: <Label k="color" />
        }
    })
    public color?: Exclude<CircularProgressProps['color'], 'inherit'>;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="rounded" />
        }
    })
    public rounded?: boolean;

    @Input({
        component: SwitchInput,
        meta: {
            title: <Label k="show_label" />
        }
    })
    public showLabel?: boolean;

    @Input({
        component: DropDownChoiceInput,
        meta: {
            title: 'Value Type',
            required: true,
            description: 'Progress (default) value of enrollment, Completions count within course, or Unique Time viewed in media-based content.',
        },
        inputProps: {
            options: ['progress', 'completions', 'uniqueTime', 'studyPace'],
        },
    })
    public valueType: 'progress' | 'completions' | 'uniqueTime' = 'progress';

    @Input((_, { parent }) => {
        if (parent.valueType !== 'completions' && parent.valueType !== 'uniqueTime') {
            return {};
        }
        return {
            component: TextInput,
            meta: {
                title: <Label k="targets" />
            },
            array: {
                addComponent: ({ onAdd }) => (
                    <Button onClick={() => onAdd([""])}>
                        Add Target
                    </Button>
                ),
                sort: true,
                remove: true,
            }
        }
    })
    public targets?: string[];

    @Input((_, { parent }) => {
        if (parent.valueType !== 'completions' && parent.valueType !== 'uniqueTime') {
            return {};
        }
        return{
            component: TagNameMultiInput,
            meta: {
                title: <Label k="tags" />
            }
        }
    })
    public tags?: string[];

    @Input((_, { parent }) => {
        if (parent.valueType !== 'completions' && parent.valueType !== 'uniqueTime') {
            return {};
        }
        return {
            component: SwitchInput,
            meta: {
                title: 'Flatten',
                description: 'Check all levels of nested containers for matches',
            },
        };
    })
    public flatten?: boolean;

    @Input({
        component: LangStringInput,
        meta: {
            title: 'Label Text',
            description: 'variables: {percent}, {completed}, {total}',
        },
    })
    public text?: Shuttle.LangString;

    @Input({
        component: SwitchInput,
        meta: {
            title: 'Enable Study Pace'
        },
    })
    public enableStudyPace?: boolean;

    @Input({
        component: DropDownChoiceInput,
        meta: {
            title: 'Unit',
        },
        inputProps: {
            options: ['days', 'weeks'],
        },
    })
    public unit?: 'days' | 'weeks' = 'days';

    @Input({
        component: NumberInput,
        meta: {
            title: 'Min',
        },
        inputProps: {
            min: 1,
        },
    })
    public min?: number = 1;

    @Input({
        component: NumberInput,
        meta: {
            title: 'Max',
        },
        inputProps: {
            min: 1,
            max: 365,
        }
    })
    public max?: number;

    @Input((_, { parent }) => ({
        component: NumberInput,
        meta: {
            title: (
                <>
                    <Label k="study_time" />
                    {' ('}<Label k={parent.unit ?? 'days'} />{')'}
                </>
            ),
        },
        inputProps: {
            min: parent.min,
            max: parent.max,
            step: 1,
            slider: true,
            float: false,
        },
    }))
    public defaultValue?: number;

}

class SetStudyPace {

    @Input((_, { context }) => ({
        component: NumberInput,
        meta: {
            title: (
                <>
                    <Label k="study_time" />
                    {'('}<Label k={context.unit ?? 'days'} />{')'}
                </>
            ),
            required: true,
        },
        inputProps: {
            min: context.min,
            max: context.max,
            step: 1,
            slider: true,
            float: false,
        },
    }))
    public value!: number;

    @Input({
        component: DateTimeInput,
        meta: {
            title: <Label k="startDate" />,
            required: true,
        },
        inputProps: {
            time: false,
        },
    })
    public startDate!: string;

}

const STUDY_PACE_START = '__studyPaceStart';
const STUDY_PACE_LENGTH = '__studyPaceLength';

const ContentProgressElement: Shuttle.LayoutElementType<ContentProgressConfig> = {
    name: 'layout_content_progress',
    Icon: ProgressIcon,
    ConfigClass: ContentProgressConfig,
    Component({ config }) {
        const theme = useTheme();
        const [{ locale }] = useContext(SettingsContext);
        const { session, setSession } = useContext(SessionContext);
        const { bookmarks } = useContext(BookmarksContext);
        const constraints = useContext(ConstraintsContext);
        const contentNode = useContext(ContentContext);
        const content = contentNode && getAlternative(constraints, contentNode);

        const result = useMemo(() => {

            if (!session || !content) {
                return {
                    completed: '0',
                    total: '100',
                    percent: 0,
                };
            }

            if (config.valueType === 'completions') {

                const { contents } = filterContentChildren({
                    content,
                    constraints,
                    tracking: session.tracking,
                    bookmarks,
                    locale,
                    flatten: config.flatten,
                    distinctContent: config.flatten,
                    tags: config.tags,
                    targets: config.targets,
                });

                const completed = contents.filter(({ content }) => {
                    const t = session.tracking[content.uuid];
                    return t?.completion === 'Completed' || t?.completion === 'Passed';
                }).length
                const total = contents.length;
                
                return {
                    completed: completed.toLocaleString(),
                    total: total.toLocaleString(),
                    percent: total > 0 ? Math.floor((completed / total) * 100) : 0,
                };
            }

            if (config.valueType === 'uniqueTime') {

                const { contents } = filterContentChildren({
                    content,
                    constraints,
                    tracking: session.tracking,
                    bookmarks,
                    locale,
                    flatten: config.flatten,
                    distinctContent: config.flatten,
                    tags: config.tags,
                    targets: config.targets,
                    launchers: ['multimedia'], // may need to refactor this later for webinars
                });

                let total = 0;
                let completed = 0;

                for (const { content } of contents) {
                    if (content.duration) {
                        total += content.duration;
                        completed += session.tracking[content.uuid]?.uniqueTimeViewed ?? 0;
                    }
                }
                
                return {
                    completed: StringUtil.formatHMS(completed),
                    total: StringUtil.formatHMS(total),
                    percent: total > 0 ? Math.floor((completed / total) * 100) : 0,
                };
            }

            const percent = session.tracking[content.uuid]?.progress ?? 0

            return {
                completed: percent,
                total: 100,
                percent,
            }
        }, [config, session, constraints, content, bookmarks, locale]);

        const [open, setOpen] = useState(false);

        const size = typeof config.size === 'number' && !isNaN(config.size) ? Math.max(0, config.size) : undefined;
        const thickness = theme.spacing(config.thickness || .5);

        const label = config.text && Object.values(config.text).some(t => !!t)
            ? StringUtil.formatVars(bestLocaleMatch(config.text, locale) ?? '', name => result[name as keyof typeof result]?.toString() ?? '')
            : `${result.percent}%`;
        
        let elapsed = -1;
        let studyPaceEdit: ReactNode = null;
        if (config.enableStudyPace && session) {
            const now = new Date();
            const start = parseDate(session.state?.[STUDY_PACE_START]?.[0]) ?? now;
            const length = StringUtil.toInteger(session.state?.[STUDY_PACE_LENGTH]?.[0], config.defaultValue ?? 1);
            const end = config.unit === 'weeks'
                ? addWeeks(start, length)
                : addDays(start, length)
            elapsed = end ? Math.min(100, Math.max(0, ((now.getTime() - start.getTime()) / (end.getTime() - start.getTime())) * 100)) : 0;
            studyPaceEdit = (
                <>
                    <Button onClick={() => setOpen(true)} sx={{ mt: 1 }}>
                        <Label k="configure_study_pace" />
                    </Button>
                    <ModalDialog
                        open={open}
                        onClose={() => setOpen(false)}
                        title={<Label k="configure_study_pace" />}
                    >
                        <DecoratorForm
                            clazz={SetStudyPace}
                            context={config}
                            initialValue={{
                                startDate: start.toISOString(),
                                value: length,
                            }}
                            onSubmit={async ({ value, startDate }) => {
                                setSession(prev => ({
                                    ...prev,
                                    state: {
                                        ...prev.state,
                                        [STUDY_PACE_START]: [startDate],
                                        [STUDY_PACE_LENGTH]: [value.toString()],
                                    }
                                }));
                                setOpen(false);
                                return { value, startDate };
                            }}
                        />
                    </ModalDialog>
                </>
            );
        }

        if (config.type === 'circle') {
            return (
                <div style={{ textAlign: 'center' }}>
                    <ProgressCircle
                        variant="determinate"
                        value={result.percent}
                        color={config.color}
                        size={size}
                        thickness={config.thickness}
                        sx={{
                            my: elapsed >= 0 ? .5 : 0,
                            mx: 'auto',
                        }}
                    >
                        {
                            elapsed >= 0 && (
                                <CircularProgress
                                    variant="determinate"
                                    value={1}
                                    color="inherit"
                                    size={size}
                                    thickness={config.thickness}
                                    sx={{
                                        position: 'absolute',
                                        left: 0,
                                        top: 0,
                                    }}
                                    style={{
                                        transform: `rotate(${((elapsed / 100) * 360) - 90}deg)`
                                    }}
                                />
                            )
                        }
                        {
                            config.showLabel && (
                                <Box
                                    sx={{
                                        top: thickness,
                                        left: thickness,
                                        bottom: thickness,
                                        right: thickness,
                                        position: 'absolute',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Typography color="text.secondary" gutterBottom={!!studyPaceEdit}>
                                        {label}
                                    </Typography>
                                </Box>
                            )
                        }
                    </ProgressCircle>
                    {studyPaceEdit}
                </div>
            );
        }

        return (
            <div>
                <Box sx={{ position: 'relative' }}>
                    <LinearProgress
                        color={config.color}
                        sx={{
                            height: config.thickness ? theme.spacing(config.thickness || .5) : undefined,
                            width: size ? theme => theme.spacing(size || 1) : '100%',
                            borderRadius: config.rounded ? theme.spacing(.5) : 0,
                            my: elapsed >= 0 ? .5 : 0,
                        }}
                        variant="determinate"
                        value={result.percent} 
                    />
                    {
                        elapsed >= 0 && (
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: theme.spacing(-.5),
                                    bottom: theme.spacing(-.5),
                                    left: elapsed + '%',
                                    width: theme.spacing(.5),
                                    translateX: theme.spacing(-.25),
                                    backgroundColor: theme.palette.common[theme.palette.mode === 'dark' ? 'white' : 'black'],
                                }}
                            />
                        )
                    }
                </Box>
                {
                    config.showLabel && (
                        <Typography variant="body2" color="text.secondary" mt={.5}>
                            {label}
                        </Typography>
                    )
                }
                {studyPaceEdit}
            </div>
        );
    }
};

export default ContentProgressElement;
