import { Input } from "react-ts-form";
import TextInput from "../form/inputs/TextInput";
import Label from "../core/Label";

export class RegistrationData {

    @Input((_, { context }) => {
        if (context.emailAsUsername) return {};
        return {
            component: TextInput,
            meta: {
                title: <Label k="username" />,
                required: true,
            },
            inputProps: {
                maxLength: 64,
            },
        };
    })
    public username?: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="email" />,
            required: true,
        },
        inputProps: {
            maxLength: 128,
        },
    })
    public email!: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="firstName" />,
            required: true,
        },
        inputProps: {
            maxLength: 64,
        },
    })
    public firstName!: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="lastName" />,
            required: true,
        },
        inputProps: {
            maxLength: 64,
        },
    })
    public lastName!: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="password" />,
            description: <Label k="password_instructions" />,
            required: true,
        },
        inputProps: {
            maxLength: 64,
            type: 'password',
        },
    })
    public password!: string;

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="confirm_password" />,
            required: true,
        },
        inputProps: {
            maxLength: 64,
            type: 'password',
        },
    })
    public passwordConfirm!: string;

}
