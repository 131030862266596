import { Input } from "react-ts-form";
import Label from "../core/Label";
import TextInput from "../form/inputs/TextInput";
import { Shuttle } from "../types";

class ContentHasChildrenConfig {

    @Input({
        component: TextInput,
        meta: {
            title: <Label k="target" />
        }
    })
    public target?: string;

}

const contentHasChildrenCondition: Shuttle.Condition<ContentHasChildrenConfig> = {
    name: 'condition_content_has_children',
    ConfigClass: ContentHasChildrenConfig,
    evaluate({ config: { target } }, { content }) {
        
        let children = content.children;

        if (children && target) {
            children = children.filter(child => child.target === target);
        }

        return Boolean(children?.length);
    },
};

export default contentHasChildrenCondition;
