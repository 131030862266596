import CloneIcon from "@mui/icons-material/FileCopy";

import { anyEditor, createEntitySection, createFormAction } from "../../core/admin/AdminSection";
import LabelledIconButton from "../../core/ui/LabelledIconButton";
import TemplateData from "../../model/TemplateData";
import TemplateService from "../../services/TemplateService";

export default createEntitySection<TemplateData>({

    model: TemplateData,

    getId: o => o.id,
    label: 'name',

    ...TemplateService,

    columns: [
        {
            key: 'name',
            sort: 'name'
        },
        {
            key: 'externalId',
            sort: 'externalId'
        },
        {
            key: 'actions',
            renderCell: ({ appContext, data, history, matchUrl }) => {
                return (
                    <LabelledIconButton 
                        icon={CloneIcon}
                        k="copy"
                        onClick={async () => {
                            const copy = await TemplateService.create({ name: data.name + ' - Copy', config: data.config } as TemplateData);
                            history.push(matchUrl + '/' + copy.data.id);
                        }}
                        disabled={!anyEditor(appContext)}
                    />
                );
            },
            padding: 'checkbox'
        }
    ],

    editEnabled: ({ appContext }) => anyEditor(appContext),

    actions: [
        createFormAction({
            key: 'create',
            clazz: TemplateData,
            onSubmit: async data => {
                await TemplateService.create(data);
                return true;
            },
            enabled: (_, appContext) => anyEditor(appContext),
        })
    ]

});
