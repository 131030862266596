import { Input } from "react-ts-form";
import { DateLabel } from "../../core/DateLabel";
import { adminOnly, anyEditor, createComponentAction, createEntitySection, createFormAction, hasRole } from "../../core/admin/AdminSection";
import SwitchInput from "../../form/inputs/SwitchInput";
import { Consumer } from "../../model/Consumer";
import ConsumerContentData from "../../model/ConsumerContentData";
import ContentData from "../../model/ContentData";
import ConsumerService from "../../services/ConsumerService";
import { ConsumerContentMultiInput, ContentMultiInput, UserMultiInput } from "../../form/common";
import Label from "../../core/Label";
import UserData from "../../model/UserData";
import UserService from "../../services/UserService";
import { userEnrollments, userGroups } from "./Users";
import { Alert, Avatar, Box, Button, Chip, InputAdornment, Skeleton, Stack, TextField, Typography } from "@mui/material";
import Axios from "axios";
import LabelledIconButton from "../../core/ui/LabelledIconButton";
import ContentService from "../../services/ContentService";
import BrowserUtil from "../../util/BrowserUtil";
import CopyIcon from '@mui/icons-material/ContentCopy';
import DownloadIcon from '@mui/icons-material/Download';
import { Link } from "react-router-dom";
import EnrollmentData from "../../model/EnrollmentData";
import EnrollmentService from "../../services/EnrollmentService";
import EnrollmentTreePreview from "../../core/EnrollmentTreePreview";
import EnrollmentXApiBrowser from "../../core/EnrollmentXApiHistory";
import StringUtil from "../../util/StringUtil";
import { Group } from "../../model/Group";
import GroupService from "../../services/GroupService";
import { groupUsers } from "./Groups";
import DateTimeInput from "../../form/inputs/DateTimeInput";
import NumberInput from "../../form/inputs/NumberInput";
import useResolver from "../../hooks/useResolver";
import { Fragment, useRef, useState } from "react";
import { useFeedbackContext } from "../../core/FeedbackContext";
import { Announcement } from "../../model/Announcement";
import ConsumerSearchParams from "../../model/ConsumerSearchParams";

class ConsumerContentAdd {

    @Input({
        component: ContentMultiInput,
        meta: {
            title: 'Contents',
            required: true,
        },
    })
    public contents!: ContentData[];

    @Input({
        component: DateTimeInput,
        meta: {
            title: <Label k="startDate" />
        }
    })
    public startDate?: string;

    @Input({
        component: DateTimeInput,
        meta: {
            title: <Label k="endDate" />
        }
    })
    public endDate?: string;

    @Input({
        component: NumberInput,
        meta: {
            title: <Label k="seatLimit" />
        },
        inputProps: {
            min: 0,
        },
    })
    public seatLimit?: number;

}

class BulkEnroll {

    public consumer!: Consumer;

    @Input((_, { parent }) => ({
        component: UserMultiInput,
        meta: {
            title: "Users",
            required: true,
        },
        inputProps: {
            params: {
                consumerId: parent.consumer.id,
            },
        },
    }))
    public users!: UserData[];

    @Input((_, { parent }) => ({
        component: ConsumerContentMultiInput,
        meta: {
            title: 'Contents',
            required: true,
        },
        inputProps: {
            params: {
                consumerId: parent.consumer.id,
            },
        },
    }))
    public courses!: ConsumerContentData[];

}

function ConsumerStats() {

    const { data } = useResolver(ConsumerService.stats);

    return (
        <Stack direction="row" spacing={1}>
            {
                ([
                    ['consumers', data?.consumers],
                    ['courses', data?.contents],
                    ['users', data?.users],
                    ['enrollments', data?.enrollments],
                    ['completions', data?.completions],
                ] as const).flatMap(([key, value], i) => {
                    if (value === undefined) {
                        return [
                            <Skeleton key={key} variant="text" />
                        ]
                    }
                    const stat = (
                        <Fragment key={key}>
                            <Typography fontWeight={700}>
                                {value.toLocaleString()}
                            </Typography>
                            <Typography color="text.secondary">
                                {StringUtil.snakeToTitle(key)}
                            </Typography>
                        </Fragment>
                    )
                    if (i > 0) {
                        return [
                            <span key={key + '-d'}>
                                {'|'}
                            </span>,
                            stat,
                        ]
                    }
                    return [stat];
                })
            }
        </Stack>
    );
}

export default createEntitySection<Consumer, ConsumerSearchParams>({
    model: Consumer,
    params: ConsumerSearchParams,
    getId: o => o.id,
    label: o => o.name,
    ...ConsumerService,
    renderTableHeader: () => <ConsumerStats />,
    columns: [
        {
            key: 'logo',
            renderCell: ({ appContext, data }) => (
                <Avatar
                    alt={data.name}
                    src={data.theme?.logo?.url ?? appContext.tenant.theme?.logo?.url}
                    variant="rounded"
                    imgProps={{
                        sx: {
                            objectFit: 'contain',
                        },
                    }}
                >
                    {data.name.charAt(0)}
                </Avatar>
            )
        },
        {
            key: 'name',
            sort: 'name',
        },
        {
            key: 'externalId',
            sort: 'externalId',
        },
        {
            key: 'createDate',
            sort: 'createDate',
            renderCell: ({ data }) => <DateLabel date={data.createDate} format="Pp" />
        },
        {
            key: 'default',
            sort: 'default_',
            renderCell: ({ data }) => (
                <SwitchInput
                    value={!!data.default_}
                    onChange={() => {}}
                    disabled
                />
            ),
        },
        {
            key: 'enabled',
            sort: 'enabled',
            renderCell({appContext, data, reload}) {
                return (
                    <SwitchInput
                        value={!!data.enabled}
                        onChange={
                            enabled => ConsumerService.update({...data, enabled}).then(reload)
                        }
                        disabled={!adminOnly(appContext)}
                    />
                );
            }
        },
        {
            key: 'users',
            sort: 'userCount',
            renderCell: ({ data }) => data.userCount,
        },
        {
            key: 'contents',
            sort: 'contentCount',
            renderCell: ({ data }) => data.contentCount,
        },
        {
            key: 'enrollments',
            sort: 'enrollmentCount',
            renderCell: ({ data }) => data.enrollmentCount,
        },
        {
            key: 'completions',
            sort: 'completionCount',
            renderCell: ({ data }) => data.completionCount,
        },
    ],
    editEnabled: ({ appContext }) => adminOnly(appContext),
    actions: [
        createFormAction({
            key: 'create',
            clazz: Consumer,
            enabled: (_, appContext) => adminOnly(appContext),
            onSubmit: async data => {
                await ConsumerService.create(data);
                return true;
            },
        }),
    ],
    sections: [
        {
            path: '/contents',
            label: 'admin.section.contents',
            section: createEntitySection({
                model: ConsumerContentData,
                getId: o => o.content.id,
                label: o => o.content.name,
                search: (params, context) => ConsumerService.searchContents(context.id, params),
                get: (id, context) => ConsumerService.getContent(context.id, id),
                update: ({ consumer, content, ...rest }) => ConsumerService.addContent(consumer.id, content.id, rest),
                delete: ({ consumer, content }) => ConsumerService.removeContent(consumer.id, content.id),
                columns: [
                    {
                        key: 'name',
                        sort: 'content.name',
                        renderCell: ({data}) => (
                            <>
                                <Typography component={Link} color="inherit" to={`/admin/contents/${data.content.id}`}>
                                    {data.content.name}
                                </Typography>
                                <Typography variant="subtitle2" color="text.secondary">
                                    {data.content.externalId}
                                </Typography>
                            </>
                        ),
                    },
                    {
                        key: 'externalId',
                        sort: 'externalId',
                    },
                    {
                        key: 'startDate',
                        sort: 'startDate',
                        renderCell: ({data}) => data.startDate ? <DateLabel date={data.startDate} /> : '--'
                    },
                    {
                        key: 'endDate',
                        sort: 'endDate',
                        renderCell: ({data}) => data.endDate ? <DateLabel date={data.endDate} /> : '--',
                    },
                    {
                        key: 'seatLimit',
                        sort: 'seatLimit',
                        renderCell: ({data}) => data.seatLimit || '--',
                    },
                    {
                        key: 'enrollments',
                        sort: 'enrollmentCount',
                        renderCell: ({ data }) => data.enrollmentCount
                    },
                    {
                        key: 'completions',
                        sort: 'completionCount',
                        renderCell: ({ data }) => data.completionCount,
                    },
                ],
                editEnabled: ({ appContext }) => anyEditor(appContext),
                actions: [
                    createFormAction({
                        key: 'add',
                        clazz: ConsumerContentAdd,
                        onSubmit: async ({ contents, ...rest }, consumer) => {
                            for (const content of contents) {
                                await ConsumerService.addContent(consumer.id, content.id, rest as any);
                            }
                            return true;
                        },
                        enabled: (_, appContext) => anyEditor(appContext),
                    }),
                    createFormAction({
                        key: 'bulk_enroll',
                        clazz: BulkEnroll,
                        getInitialData: consumer => ({
                            consumer,
                            users: [],
                            courses: [],
                        }),
                        onSubmit: async params => {
                            for (const user of params.users ?? []) {
                                for (const cc of params.courses ?? []) {
                                    await UserService.enroll(user.id, cc.content.id);
                                }
                            }
                            return true;
                        },
                        enabled: (_, appContext) => anyEditor(appContext),
                    }),
                ],
                expand: ({ context, data }) => {

                    const launchUrl = `${window.location.protocol}//${window.location.host}${Axios.defaults.baseURL}/launch/${data.content.uuid}/${context.uuid}`;
                    
                    return (
                        <>
                            <TextField 
                                label={<Label k="launch.link" />} 
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <LabelledIconButton
                                                k="copy.link" 
                                                icon={CopyIcon}
                                                onClick={() => {
                                                    BrowserUtil.copyToClipboard(launchUrl);
                                                    alert('Copied to clipboard');
                                                }}
                                            />
                                        </InputAdornment>
                                    )
                                }}
                                fullWidth
                                value={launchUrl}
                                variant="outlined"
                                helperText={<Label k="launch.link.help" />}
                            />
                            <Box py={2}>
                                <Button 
                                    color="primary"
                                    onClick={() => ContentService.downloadScorm(data.content, context.uuid)}
                                    startIcon={<DownloadIcon />}
                                    sx={{ mr: 1 }}
                                    variant="contained"
                                >
                                    <Label k="download.scorm" />
                                </Button>
                                <Button
                                    color="primary"
                                    onClick={() => ContentService.downloadAicc(data.content, context.uuid)}
                                    startIcon={<DownloadIcon />}
                                    variant="contained"
                                >
                                    <Label k="download.aicc" />
                                </Button>
                            </Box>
                        </>
                    );
                },
                sections: [
                    {
                        path: '/enrollments',
                        label: 'admin.section.contents.enrollments',
                        section: createEntitySection({
                            model: EnrollmentData,
                            getId: o => o.id,
                            label: o => o.content.name,
                            search: async (params, context) => await EnrollmentService.search({
                                ...params,
                                contentIds: [context.content.id],
                                consumerIds: [context.consumer.id],
                            }),
                            get: async id => await EnrollmentService.get(id),
                            columns: [
                                {
                                    key: 'user',
                                    sort: 'user.fullName',
                                    renderCell: ({data}) => (
                                        <Typography component={Link} color="inherit" to={`/admin/users/${data.user.id}`}>
                                            {data.user.fullName || `@${data.user.id}`}
                                        </Typography>
                                    )
                                },
                                {
                                    key: 'externalId',
                                    sort: 'externalId',
                                },
                                {
                                    key: 'completion',
                                    sort: 'completion',
                                    renderCell: ({data}) => <Label k={data.completion} />,
                                },
                                {
                                    key: 'progress',
                                    sort: 'progress',
                                    renderCell: ({data}) => `${data.progress || 0}%`,
                                },
                                {
                                    key: 'score',
                                    sort: 'score',
                                    renderCell: ({data}) => typeof data.score === 'number' ? data.score + '%' : "--",
                                },
                                {
                                    key: 'status',
                                    sort: 'status',
                                    renderCell: ({data}) => (
                                        <Chip
                                            label={<Label k={data.status} />}
                                            color={
                                                data.status === 'Deleted'
                                                    ? 'error'
                                                    : data.status === 'Archived'
                                                        ? 'default'
                                                        : 'info'
                                            }
                                            size="small"
                                        />
                                    ),
                                },
                                {
                                    key: 'timeSpent',
                                    sort: 'timeSpent',
                                    renderCell: ({data}) => {
                                        return StringUtil.formatHMS(data.timeSpent || 0);
                                    }
                                },
                                {
                                    key: 'uniqueTimeViewed',
                                    sort: 'uniqueTimeViewed',
                                    renderCell: ({ data }) => data.uniqueTimeViewed ? StringUtil.formatHMS(data.uniqueTimeViewed) : '--',
                                },
                                {
                                    key: 'firstVisitDate',
                                    sort: 'firstVisitDate',
                                    renderCell: ({data}) => <DateLabel date={data.firstVisitDate} format="Pp" />,
                                },
                                {
                                    key: 'lastVisitDate',
                                    sort: 'lastVisitDate',
                                    renderCell: ({data}) => <DateLabel date={data.lastVisitDate} format="Pp" />,
                                },
                                {
                                    key: 'completionDate',
                                    sort: 'completionDate',
                                    renderCell: ({data}) => <DateLabel date={data.completionDate} format="Pp" />
                                },
                                {
                                    key: 'completionTimeSpent',
                                    sort: 'completionTimeSpent',
                                    renderCell: ({data}) => data.completionTimeSpent ? StringUtil.formatHMS(data.completionTimeSpent) : ''
                                },
                                {
                                    key: 'completedBy'
                                },
                                {
                                    key: 'controls',
                                    renderCell: ({data}) => (
                                        <Stack direction="row" spacing={1}>
                                            <EnrollmentXApiBrowser enrollment={data} />
                                            <EnrollmentTreePreview.DialogButton enrollmentId={data.id} />
                                        </Stack>
                                    )
                                },
                            ],
                            editEnabled: ({ appContext }) => hasRole(appContext, ['ROLE_SUPPORT', 'ROLE_ADMIN']),
                        })
                    },
                    {
                        path: '/announcements',
                        label: 'admin.section.announcements',
                        section: createEntitySection({
                            model: Announcement,
                            getId: o => o.id,
                            label: o => o.name,
                            get: async (id, { consumer, content }) => await ConsumerService.getContentAnnouncement(consumer.id, content.id, id),
                            search: async (params, { consumer, content }) => await ConsumerService.searchContentAnnouncements(consumer.id, content.id, params),
                            update: async (data, { consumer, content }) => await ConsumerService.updateContentAnnouncement(consumer.id, content.id, data.id, data),
                            columns: [
                                {
                                    key: 'name',
                                    sort: 'name',
                                },
                                {
                                    key: 'startDate',
                                    sort: 'startDate',
                                    renderCell: ({ data }) => <DateLabel date={data.startDate} format="Pp" />,
                                },
                                {
                                    key: 'endDate',
                                    sort: 'endDate',
                                    renderCell: ({ data }) => <DateLabel date={data.endDate} format="Pp" />,
                                },
                                {
                                    key: 'expiryDate',
                                    sort: 'expiryDate',
                                    renderCell: ({ data }) => <DateLabel date={data.expiryDate} format="Pp" />,
                                },
                            ],
                            actions: [
                                createFormAction({
                                    key: 'create',
                                    clazz: Announcement,
                                    onSubmit: async (data, { content, consumer }) => {
                                        await ConsumerService.createContentAnnouncement(consumer.id, content.id, data);
                                        return true;
                                    },
                                }),
                            ],
                        })
                    }
                ],
            }),
        },
        {
            path: '/users',
            label: 'admin.section.users',
            section: createEntitySection({
                model: UserData,
                getId: o => o.id,
                label: o => o.fullName,
                search: (params, consumer) => ConsumerService.searchUsers(consumer.id, params),
                get: id => UserService.get(id),
                update: user => UserService.update(user),
                defaultSort: '-lastActiveDate',
                columns: [
                    {
                        key: 'avatar',
                        renderCell: ({ data }) => (
                            <Avatar alt={`${data.firstName} ${data.lastName}`} src={data.avatar?.url}>
                                {`${data.firstName.charAt(0)}${data.lastName.charAt(0)}` }
                            </Avatar>
                        )
                    },
                    {
                        key: 'firstName',
                        sort: 'firstName',
                    },
                    {
                        key: 'lastName',
                        sort: 'lastName',
                    },
                    {
                        key: 'email',
                        sort: 'email',
                    },
                    {
                        key: 'phone',
                        sort: 'phone',
                    },
                    {
                        key: 'externalId',
                        sort: 'externalId',
                    },
                    {
                        key: 'lastActiveDate',
                        sort: 'lastActiveDate',
                        renderCell: ({ data }) => <DateLabel date={data.lastActiveDate} format="Pp" />
                    },
                ],
                editEnabled: ({ appContext }) => adminOnly(appContext),
                sections: [
                    userGroups,
                    userEnrollments,
                ],
                actions: [
                    createComponentAction({
                        key: 'import',
                        onSubmit: async () => true,
                        Component: ({ context, close }) => {
                            const feedback = useFeedbackContext();
                            const fileRef = useRef<HTMLInputElement>(null);
                            const [errors, setErrors] = useState<string[]>([]);

                            return (
                                <>
                                    <Typography variant="body2" color="text.secondary" gutterBottom>
                                        {`Import users from an excel spreadsheet.
                                        You can remove or re-order any column(s) other than 'ID',
                                        but do not change any of the column heading names.`}
                                    </Typography>
                                    <Button 
                                        variant="contained"
                                        sx={{ mb: 3 }}
                                        onClick={() => ConsumerService.downloadUserImportTemplate(context.id)}
                                    >
                                        Download Template
                                    </Button>
                                    {
                                        errors.map((e, i) => (
                                            <Alert key={i} severity="error" sx={{ my: .5 }}>
                                                {e}
                                            </Alert>
                                        ))
                                    }
                                    <form
                                        onSubmit={async e => {
                                            e.preventDefault();
                                            const file = fileRef.current?.files?.[0];                            
                                            if (file) {
                                                setErrors([]);
                                                const r = await ConsumerService.importUsers(context.id, file);
                                                if (r.success) {
                                                    close();
                                                    feedback.push({
                                                        intent: 'success',
                                                        content: `Imported ${r.result.totalUsers} users (${r.result.newUsers} new), ${r.result.totalEnrollments} enrollments (${r.result.newEnrollments})`,
                                                    });
                                                } else {
                                                    setErrors(r.errors);
                                                }
                                            }
                                        }}
                                    >
                                        <label>
                                            <input
                                                ref={fileRef}
                                                name="file"
                                                type="file"
                                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                required
                                            />
                                        </label>
                                        <Button
                                            variant="contained"
                                            type="submit"
                                        >
                                            Submit
                                        </Button>
                                    </form>
                                </>
                            );
                        },
                    })
                ]
            }),
        },
        {
            path: '/groups',
            label: 'admin.section.groups',
            section: createEntitySection({
                model: Group,
                getId: o => o.id,
                label: o => o.name,
                search: async (params, consumer) => await GroupService.search({ ...params, consumerIds: [consumer.id] }),
                get: async (id) => await GroupService.get(id),
                update: async (group) => await GroupService.update(group),
                delete: async (group) => await GroupService.delete!(group),
                columns: [
                    {
                        key: 'name',
                        sort: 'name',
                    },
                    {
                        key: 'externalId',
                        sort: 'externalId',
                    },
                    {
                        key: 'internal',
                        sort: 'internal',
                        renderCell: ({ appContext, data, reload }) => (
                            <SwitchInput
                                value={!!data.internal}
                                onChange={internal => GroupService.update({ ...data, internal }).then(reload)}
                                disabled={!adminOnly(appContext)}
                            />
                        ),
                    },
                    {
                        key: 'type',
                        sort: 'dynamic',
                        renderCell: ({ data }) => data.dynamic ? 'Dynamic' : 'Static',
                    },
                    {
                        key: 'createDate',
                        sort: 'createDate',
                        renderCell: ({ data }) => <DateLabel date={data.createDate} />,
                    },
                    {
                        key: 'users',
                        sort: 'userCount',
                        renderCell: ({ data }) => data.userCount ?? 0,
                    },
                ],
                editEnabled: ({ appContext }) => adminOnly(appContext),
                sections: [
                    groupUsers,
                ],
                actions: [
                    createFormAction({
                        key: 'create',
                        getInitialData: consumer => {
                            const d = new Group();
                            d.consumer = consumer;
                            return d;
                        },
                        clazz: Group,
                        onSubmit: async group => {
                            await GroupService.create(group);
                            return true;
                        },
                        enabled: (_, appContext) => adminOnly(appContext),
                    })
                ],
            }),
        },
    ],
})
